import { useMemo } from 'react'
import { useDataValue } from 'Simple/Data.js'

export default function useDataTransform(props, data) {
  let treatment_plans = useDataValue({
    context: 'treatment_plans',
    viewPath: props.viewPath,
  })

  return useMemo(
    () =>
      treatment_plans.length === 1
        ? {
            treatment_plan_id: treatment_plans[0]._id,
          }
        : data,
    [data, treatment_plans]
  )
}
