import { useMemo } from 'react'
import { useDataValue } from 'Simple/Data.js'

/** @type {import('Simple/types.js').useDataTransform} */
export default function useDataTransform(props, data) {
  let { billing_center_name, carrier_name } = useDataValue({
    context: 'plan_details',
    viewPath: props.viewPath,
  })

  return useMemo(() => {
    return {
      ...data,
      carrier_search: carrier_name || '',
      billing_center_search: billing_center_name || '',
    }
  }, [billing_center_name, carrier_name])
}
