// this is an autogenerated file from DesignSystem/PatientOverview/Content/Contact/data.graphql
import { gql } from 'Data/Api'

export default gql`
query app__DesignSystem_PatientOverview_Content_Contact($patient_id: numeric!) {
  emails: patient_contact_method_associations(
    args: { _patient_id: $patient_id, _type: "email" }
    order_by: { preference: asc_nulls_last }
  ) {
    id
    preference
    email {
      id
      address
    }
  }
  phones: patient_contact_method_associations(
    args: { _patient_id: $patient_id, _type: "phone" }
    order_by: { preference: asc_nulls_last }
  ) {
    id
    preference
    phone {
      id
      _id
      type
      number
    }
  }
}

`