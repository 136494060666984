// this is an autogenerated file from App/Account/Content/Settings/AdminContent/SettingsContent/IntegrationsApplications/Content/Connection/Content/SetupAutomations/Content/Content/ActiveStep/Preferences/Doctor/data.graphql
import { gql } from 'Data/Api'

export default gql`
query app__App_Account_Content_Settings_AdminContent_SettingsContent_IntegrationsApplications_Content_Connection_Content_SetupAutomations_Content_Content_ActiveStep_Preferences_Doctor($connection_id: uuid!, $account_id: numeric!) {
  apps_align_itero_doctors(
    connection_id: $connection_id
    account_id: $account_id
  ) {
    doctors {
      id
      name
    }
  }
}

`