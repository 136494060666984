import { useMemo } from 'react'

/** @type {import('Simple/types.js').useDataTransform} */
export default function useDataTransform(props, data) {
  return useMemo(() => {
    if (!data) return data

    return data
      .map(d =>
        d.from_person_record?.insurance_subscriptions?.map(
          i => i.insurance_plan
        )
      )
      .flat()
  }, [data])
}
