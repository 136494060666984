// this is an autogenerated file from App/Account/Content/Calendar/New/Content/Content/Calendar/Content/Content/Topbar/LowerSection/FilterAction/Content/data.graphql
import { gql } from 'Data/Api'

export default gql`
query app__App_Account_Content_Calendar_New_Content_Content_Calendar_Content_Content_Topbar_LowerSection_FilterAction_Content($location_id: uuid!) {
  vaxiom_appointment_templates(
    where: {
      organization: { _id: { _eq: $location_id } }
      deleted: { _eq: false }
    }
    order_by: { full_name_computed: asc }
  ) {
    _id
    full_name: full_name_computed
    color_id: color_id_computed
  }
}

`