import { useDataChange, useDataValue } from 'Simple/Data.js'
import {
  getAdjustedTreatmentFeeAfterInsurances,
  changeDiscountsAppliedToPayorsShare,
  changePayorsConfiguration,
} from 'Data/payment-plan.js'

export function useOnChange(props) {
  let index = useDataValue({
    context: 'payor_item',
    path: 'index',
    viewPath: props.viewPath,
  })
  let change = useDataChange({
    context: 'payment_plan',
    viewPath: props.viewPath,
  })

  return function onChange(value) {
    change(next => {
      let payor = next.payors[index]
      payor.share.amount = value
      payor.share.type = 'amount'

      let treatment_fee = getAdjustedTreatmentFeeAfterInsurances(next)
      payor.share.percentage =
        treatment_fee > 0 ? Math.round((value / treatment_fee) * 100) : 0

      if (next.payors.length > 1) {
        let other_payor = next.payors.find(
          (_, other_index) => other_index !== index
        )
        other_payor.share.percentage = 100 - payor.share.percentage
        other_payor.share.amount = parseFloat(
          (treatment_fee - value).toFixed(2)
        )
      }

      changeDiscountsAppliedToPayorsShare(next)
      changePayorsConfiguration(next)
    })
  }
}
