// this is an autogenerated file from DesignSystem/DataVaxiomPatientInsuranceForm/Employers/EmployerItem/Employer/Content/Options/Create/Content/mutation.graphql
import { gql } from 'Data/Api'

export default gql`
mutation app__DesignSystem_DataVaxiomPatientInsuranceForm_Employers_EmployerItem_Employer_Content_Options_Create_Content($employer_details: json!) {
  insert_employer(args: { employer_details: $employer_details }) {
    id
  }
}

`