import { phoneNumberUS } from 'Data/format.js'
import { useDataChange, useDataValue } from 'Simple/Data.js'

export function getContactMethods(contact_methods) {
  return contact_methods
    .filter(item => ['phone', 'email'].includes(item.dtype))
    .map(item => ({
      id: item.id,
      text:
        item.dtype === 'phone'
          ? phoneNumberUS(item.phone.number)
          : item.email.address,
    }))
}

export function useOnChange(props) {
  let contact_methods = useDataValue({
    context: 'contact_methods',
    viewPath: props.viewPath,
  })

  let change = useDataChange({
    context: 'schedule_via',
    viewPath: props.viewPath,
  })

  return function onChange(value) {
    let selected_contact_method = contact_methods.find(cm => cm.id === value)
    if (selected_contact_method) {
      change(next => {
        next.selected_contact_method = selected_contact_method
      })
    }
  }
}
