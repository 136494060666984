import { useDataSubmit, useDataValue } from 'Simple/Data.js'
import { normalizePath, useSetFlowTo } from 'Simple/Flow.js'

export function useOnClick(props) {
  let internal = useDataValue({
    viewPath: props.viewPath,
    context: 'replace',
    path: 'selected_patient',
  })
  let submit = useDataSubmit({
    context: 'match',
    viewPath: props.viewPath,
  })
  let setFlowTo = useSetFlowTo(props.viewPath)

  return function onClick() {
    submit({
      type: 'REPLACE_MATCH',
      internal,
    })
    setFlowTo(normalizePath(props.viewPath, '../../No'))
  }
}
