import { useDataValue, useDataChange } from 'Simple/Data.js'

/** @type {import('Simple/types.js').useDataOnChange} */
export default function useDataOnChange(props) {
  let change = useDataChange({
    context: 'appointment_overlay',
    viewPath: props.viewPath,
  })
  let is_external_any = useDataValue({
    viewPath: props.viewPath,
    context: 'appointment_overlay',
    path: 'is_external_any',
  })

  return function onChange(value) {
    if (is_external_any) return

    let preferred = value?.find(item => item.is_default === true)
    let selected = preferred || value?.[0]
    if (selected) {
      change(next => {
        next.appointment_type_id = selected.id
        next.template_id = selected.template_id
        next.duration = selected.duration || null
      })
    }
  }
}
