import { useMemo } from 'react'
import { phoneNumberUS } from 'Data/format.js'

/** @type {import('Simple/types.js').useDataTransform} */
export default function useDataTransform(props, data) {
  return useMemo(() => {
    if (!data) return data

    return data.map(item => ({
      id: item.id,
      text: `${phoneNumberUS(item.phone.number)} (${item.person.first_name} ${
        item.person.last_name
      })`,
    }))
  }, [data])
}
