import { useCallback, useRef, useState, useEffect } from 'react'
import { useDataValue } from 'Simple/Data.js'
import {
  notifyError,
  notifySuccess,
  useNotifications,
} from 'Logic/Notifications.js'
import { useMutation } from 'Data/Api.js'
import debounce from 'lodash/debounce.js'

import mutationInsert from './insert-mutation.graphql.js'
import mutationUpdate from './update-mutation.graphql.js'

/** @type {import('Simple/types.js').useDataOnChange} */
export default function useDataOnChange(props, data) {
  let [, notify] = useNotifications()
  let [, executeMutationInsert] = useMutation(mutationInsert)
  let [, executeMutationUpdate] = useMutation(mutationUpdate)
  let previous_note = useRef(data)

  let event = useDataValue({
    viewPath: props.viewPath,
    context: 'event',
  })

  return useQueueAsync(onChange, isBatched)

  async function onChange(value, change) {
    // the note already has an id, but a change may be received with id null if the user kept typing while the note was being saved
    let id =
      value.state === 'create' &&
      value.temp_id === previous_note.current.temp_id
        ? value.id || previous_note.current.id
        : value.id
    // prevent updating the first time when switching to a different note
    if (id !== previous_note.current.id) {
      previous_note.current = value
      return
    }

    if (
      !value.note ||
      (previous_note.current.note === value.note &&
        Number(previous_note.current.selected_user_id) ===
          Number(value.selected_user_id) &&
        previous_note.current.visible_in_schedule === value.visible_in_schedule)
    ) {
      return
    }

    let mutationRespone
    if (id) {
      mutationRespone = await executeMutationUpdate({
        id,
        note: value.note,
        user_id: value.selected_user_id,
        visible_in_schedule: value.visible_in_schedule,
      })
      if (mutationRespone?.error) {
        notify(
          notifyError(
            'Something went wrong. Please, try again or contact support if the problem persists.'
          )
        )
        return
      }

      previous_note.current = {
        ...previous_note.current,
        ...mutationRespone.data.update_vaxiom_notes_by_pk,
      }
    } else {
      mutationRespone = await executeMutationInsert({
        note: value.note,
        target_type: 'com.axpm.treatments.core.internal.domain.Appointment',
        target_id: event.appointment.id,
        user_id: value.selected_user_id,
        visible_in_schedule: value.visible_in_schedule,
      })
      if (mutationRespone?.error) {
        notify(
          notifyError(
            'Something went wrong. Please, try again or contact support if the problem persists.'
          )
        )
        return
      }

      previous_note.current = {
        ...previous_note.current,
        ...mutationRespone.data.insert_vaxiom_notes_one,
      }
      change(next => {
        next.id = mutationRespone.data.insert_vaxiom_notes_one.id
      })
    }

    notify(notifySuccess('Note saved'), { extendExistingNotification: true })
  }

  function isBatched(previous, next) {
    return (
      previous.id === next.id ||
      (next.state === 'create' && previous.temp_id === next.temp_id)
    )
  }
}

function useQueueAsync(fn, isBatched) {
  let [running, setRunning] = useState(false)
  let [executions, setExecutions] = useState([])

  useEffect(() => {
    if (!executions.length || running) return

    async function execute() {
      setRunning(true)

      let [execution, ...rest] = executions
      setExecutions(rest)

      await fn(...execution.args)

      setRunning(false)
    }

    execute()
  }, [executions, running]) // eslint-disable-line react-hooks/exhaustive-deps

  // eslint-disable-next-line react-hooks/exhaustive-deps
  return useCallback(
    debounce(function fn(...args) {
      // schedule the next execution with the provided arguments
      setExecutions(previous => {
        if (!previous.length) return [{ args }]
        let last = previous[previous.length - 1]
        let rest = previous.slice(0, previous.length - 1)
        // check if the last element should be updated or a new execution should be added
        return isBatched(last.args[0], args[0])
          ? [...rest, { args }]
          : [...previous, { args }]
      })
    }, 250),
    []
  )
}
