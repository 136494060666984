// this is an autogenerated file from DesignSystem/DataVaxiomRelationshipsItem/Edit/NonPatient/Phone/Phones/Content/Phone/Edit/Content/editPersonContactMethodMutation.graphql
import { gql } from 'Data/Api'

export default gql`
mutation app__DesignSystem_DataVaxiomRelationshipsItem_Edit_NonPatient_Phone_Phones_Content_Phone_Edit_Content($person_id: numeric!, $contact_method: json!) {
  edit_person_contact_method(
    args: { person_id: $person_id, contact_method: $contact_method }
  ) {
    id
  }
}

`