import mutation from './mutation.graphql.js'
import { useMutation, useQuery } from 'Data/Api'
import query from './category_query.graphql.js'
import { useSetFlowTo, normalizePath } from 'Simple/Flow.js'
import {
  notifySuccess,
  notifyError,
  useNotifications,
} from 'Logic/Notifications.js'
import { useDataChange } from 'Simple/Data'

function validate(value) {
  if (!value.carrier || !value.billing_center || !value.number) {
    return true
  }
  return false
}

function useDataSave(props) {
  let [, executeMutation] = useMutation(mutation)
  let [, notify] = useNotifications()
  let setFlowTo = useSetFlowTo(props.viewPath)
  let change = useDataChange({
    context: 'filter',
    viewPath: props.viewPath,
  })

  let [{ data }] = useQuery({ query })

  return async function onSave(value) {
    if (validate(value)) {
      return true
    }
    try {
      let tx_category_id = data.vaxiom_tx_categories?.[0].id || 1

      let mutationResponse = await executeMutation({
        insurance_plan: {
          description: value.description,
          insurance_billing_center_id: value.billing_center,
          insurance_company_id: value.carrier,
          name: value.name,
          number: value.number,
          insurance_plan_employers: value.insurance_plan_employers.map(
            v => v.id
          ),
        },
        ortho: value.ortho_coverage,
        ortho_coverage: {
          age_limit_cutoff: value.age_limit_effective.toString(),
          cob_type: value.cob_type,
          deductible_amount: value.annual_deductible,
          dependent_age_limit: parseInt(value.dependent_age_limit),
          downpayment_amount: parseInt(value.downpayment_amount),
          downpayment_percentage: value.downpayment_percentage,
          downpayment_percentage_base:
            value.downpayment_percentage_base.toString(),
          downpayment_type: value.downpayment_type,
          inprogress: value.inprogress,
          max_lifetime_coverage: value.lifetime_coverage,
          reimbursement_percentage: value.reimbursement_rate,
          waiting_period: value.waiting_period,
        },
        tx_category_id,
      })

      if (mutationResponse.error) {
        notify(notifyError(`Cannot create insurance plan. Please try again.`))
        return
      }

      notify(notifySuccess('Insurance plan created successfully.'))
      change(next => {
        next.assign_insurance =
          mutationResponse.data.insert_patient_insurance_plan[0]._id
        next.assign_insurance_id =
          mutationResponse.data.insert_patient_insurance_plan[0].id
      })
      setFlowTo(normalizePath(props.viewPath, '../No'))
    } catch (err) {
      notify(notifyError('Cannot create insurance plan. Please try again.'))
    }
  }
}

/** @type {import('Simple/types.js').useDataOnSubmit} */
export default function useDataOnSubmit(props, data) {
  let setFlowTo = useSetFlowTo(props.viewPath)
  let onSave = useDataSave(props)
  return async function onSubmit({ value, originalValue, args, change }) {
    switch (args?.type) {
      case 'cancel': {
        setFlowTo(normalizePath(props.viewPath, '../No'))
        return
      }
      case 'submit': {
        return onSave(value)
      }
      default: {
        return
      }
    }
  }
}
