// this is an autogenerated file from App/Account/Content/Patients/Content/Content/Sections/ActiveTab/Profile/Content/NewProfile/Content/Insurance/AddAction/Content/Content/Assign/Content/category_query.graphql
import { gql } from 'Data/Api'

export default gql`
query app__App_Account_Content_Patients_Content_Content_Sections_ActiveTab_Profile_Content_NewProfile_Content_Insurance_AddAction_Content_Content_Assign_Content{
  vaxiom_tx_categories(where: { name: { _eq: "Ortho" } }) {
    id
  }
}

`