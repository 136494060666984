import { useMemo } from 'react'

export default function useDataTransform(props, data) {
  return useMemo(() => {
    if (!data) return data
    return data.map(item => {
      let phones = item.from_person_record.phones
        .map(item => {
          let number = item.contact_method.phone?.number

          if (!number) return false

          return {
            id: item.id,
            number,
            description: item.description,
            preference: item.preference,
          }
        })
        .filter(Boolean)

      let addresses = item.from_person_record.addresses
        .map(item => {
          let address_line1 = item.contact_method.postal_address?.address_line1
          let city = item.contact_method.postal_address?.city
          let state = item.contact_method.postal_address?.state
          let zip = item.contact_method.postal_address?.zip

          if (!address_line1 || !city || !state || !zip) return false

          return {
            id: item.id,
            address_line1,
            city,
            state,
            zip,
          }
        })
        .filter(Boolean)

      let emails = item.from_person_record.emails
        .map(item => {
          let address = item.contact_method.email?.address

          if (!address) return false

          return {
            id: item.id,
            address,
            description: item.description,
            preference: item.preference,
          }
        })
        .filter(Boolean)

      return {
        ...item,
        emails,
        phones,
        addresses,
      }
    })
  }, [data])
}
