// this is an autogenerated file from App/Account/Content/Patients/Content/Content/Sections/ActiveTab/TreatmentCard/Content/TreatmentPlans/New/Steps/Content/PaymentPlans/Content/Content/PaymentPlan/Content/Content/Request/Content/Content/Contract/Content/Preview/data.graphql
import { gql } from 'Data/Api'

export default gql`
query app__App_Account_Content_Patients_Content_Content_Sections_ActiveTab_TreatmentCard_Content_TreatmentPlans_New_Steps_Content_PaymentPlans_Content_Content_PaymentPlan_Content_Content_Request_Content_Content_Contract_Content_Preview($treatment_plan_id: numeric!, $person_id: numeric!) {
  vaxiom_tx_contracts(
    where: {
      tx_payer: {
        person_id: { _eq: $person_id }
        person_payer_temp_accounts: { tx_plan_id: { _eq: $treatment_plan_id } }
      }
    }
  ) {
    id
    document_template_id
    patient_id
    signed_date
    file
    html_file_uuid
  }
}

`