// this is an autogenerated file from App/Account/Content/Settings/AdminContent/SettingsContent/TreatmentCaseCloser/Configuration/Frequencies/AddRuleset/Division/data.graphql
import { gql } from 'Data/Api'

export default gql`
query app__App_Account_Content_Settings_AdminContent_SettingsContent_TreatmentCaseCloser_Configuration_Frequencies_AddRuleset_Division($parent_company_id: uuid!) {
  vaxiom_sys_organizations(
    where: {
      level: { _eq: "div" }
      deleted: { _eq: false }
      parent: { _id: { _eq: $parent_company_id } }
    }
    order_by: { name: asc }
  ) {
    _id
    text: name
  }
}

`