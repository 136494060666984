import { useMemo } from 'react'

/** @type {import('Simple/types.js').useDataTransform} */
export default function useDataTransform(props, data) {
  return useMemo(() => {
    if (!data) return data

    return [...Array(5).keys()].map(a => ({
      id: new Date().getFullYear() - a,
      text: new Date().getFullYear() - a,
    }))
  }, [data])
}
