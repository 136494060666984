// this is an autogenerated file from App/Account/Content/Patients/Content/Content/Sections/ActiveTab/Profile/Content/NewProfile/Content/ContactInfo/Phone/Phones/Content/Phone/Number/Apps/data.graphql
import { gql } from 'Data/Api'

export default gql`
query app__App_Account_Content_Patients_Content_Content_Sections_ActiveTab_Profile_Content_NewProfile_Content_ContactInfo_Phone_Phones_Content_Phone_Number_Apps($location_id: uuid!) {
  apps_launchers(slot: ADMIN_PATIENT_PHONE, location_id: $location_id) {
    id
    shortName
    launchers {
      id
      app_id
      icon
      name
      active
      connected
      type
      description
    }
  }
}

`