import { useDataValue } from 'Simple/Data'
import { useSetFlowTo, normalizePath } from 'Simple/Flow.js'
import {
  notifyError,
  notifySuccess,
  useNotifications,
} from 'Logic/Notifications.js'
import { useMutation } from 'Data/Api'
import delete_scheduling_preferences from './deleteSchedulingPreferencesMutation.graphql.js'

export function useOnDelete(props) {
  let scheduling_preferences = useDataValue({
    viewPath: props.viewPath,
    context: 'scheduling_preferences',
  })

  let [, notify] = useNotifications()
  let [, execDeleteMutation] = useMutation(delete_scheduling_preferences)

  let setFlowTo = useSetFlowTo(props.viewPath)
  return async function onClick() {
    let mutationResponse = await execDeleteMutation({
      sp_id: scheduling_preferences.id,
    })
    if (mutationResponse.error) {
      notify(notifyError('Something went wrong. Please, try again.'))
      return
    }
    notify(notifySuccess('Scheduling preferences deleted!'))
    setFlowTo(normalizePath(props.viewPath, '../../../No'))
  }
}
