// this is an autogenerated file from App/Account/Content/Settings/AdminContent/SettingsContent/IntegrationsApplications/Content/Apps/mutation-api-authorize.graphql
import { gql } from 'Data/Api'

export default gql`
mutation app__App_Account_Content_Settings_AdminContent_SettingsContent_IntegrationsApplications_Content_Apps(
  $app_id: uuid!
  $connection_id: uuid
  $connection_properties: jsonb!
  $location_id: uuid!
) {
  apps_external_api_authorize(
    app_id: $app_id
    connection_id: $connection_id
    connection_properties: $connection_properties
    location_id: $location_id
  ) {
    id
    url
  }
}

`