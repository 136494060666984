// this is an autogenerated file from App/Account/Content/Patients/Content/Content/Sections/ActiveTab/TreatmentCard/Content/TreatmentPlans/New/Steps/Content/TreatmentPlans/Content/TreatmentPlan/Appointment/Add/Content/AppointmentType/data.graphql
import { gql } from 'Data/Api'

export default gql`
query app__App_Account_Content_Patients_Content_Content_Sections_ActiveTab_TreatmentCard_Content_TreatmentPlans_New_Steps_Content_TreatmentPlans_Content_TreatmentPlan_Appointment_Add_Content_AppointmentType($location_id: numeric!) {
  vaxiom_appointment_templates(
    where: { organization_id: { _eq: $location_id }, deleted: { _eq: false } }
    order_by: { full_name_computed: asc }
  ) {
    id
    appointment_type_id
    name: full_name_computed
    color_id: color_id_computed
  }
}

`