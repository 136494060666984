import { useMutation } from 'Data/Api.js'
import { notifyError, useNotifications } from 'Logic/Notifications.js'
import { normalizePath, useSetFlowTo } from 'Simple/Flow.js'

import mutation from './mutation.graphql.js'

/** @type {import('Simple/types.js').useDataOnSubmit} */
export default function useDataOnSubmit(props, data) {
  let [, notify] = useNotifications()
  let setFlowTo = useSetFlowTo(props.viewPath)
  let [, executeMutation] = useMutation(mutation)

  return async function onSubmit({ value, originalValue, args, change }) {
    let mutationResponse = await executeMutation({
      patient_id: value.patient_id,
      location_id: value.location_id,
      include_notes: value.include_notes,
    })

    if (mutationResponse.error) {
      return notify(
        notifyError(
          'An unexpected error has occurred exporting the patient data'
        )
      )
    }

    window.open(
      mutationResponse.data?.vaxiom_patient_treatment_cards_export.url,
      '_blank'
    )

    setFlowTo(normalizePath(props.viewPath, '../No'))
  }
}
