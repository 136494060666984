// this is an autogenerated file from DesignSystem/DataVaxiomPatientInsuranceAssignForm/Content/Employer/Options/Create/Content/mutation.graphql
import { gql } from 'Data/Api'

export default gql`
mutation app__DesignSystem_DataVaxiomPatientInsuranceAssignForm_Content_Employer_Options_Create_Content($employer_details: json!) {
  insert_employer(args: { employer_details: $employer_details }) {
    id
  }
}

`