// this is an autogenerated file from DesignSystem/DataVaxiomRelationshipsItem/Edit/NonPatient/Postal/NewAddress/Content/Form/Content/Fields/City/Cities/data.graphql
import { gql } from 'Data/Api'

export default gql`
query app__DesignSystem_DataVaxiomRelationshipsItem_Edit_NonPatient_Postal_NewAddress_Content_Form_Content_Fields_City_Cities($search_term: String) {
  cities(
    where: { city: { _ilike: $search_term } }
    limit: 20
    order_by: { city: asc }
    distinct_on: city
  ) {
    id: city
    value: city
  }
}

`