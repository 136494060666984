// this is an autogenerated file from DesignSystem/DataVaxiomRelationshipsList/data.graphql
import { gql } from 'Data/Api'

export default gql`
query app__DesignSystem_DataVaxiomRelationshipsList($to_person: uuid!, $role: String!) {
  vaxiom_relationships(
    where: {
      to_person_record: { _id: { _eq: $to_person } }
      role: { _eq: $role }
    }
  ) {
    id
    from_person
    role
    type
    permitted_to_see_info
    from_person_record {
      id
      title
      first_name
      middle_name
      last_name
      greeting
      gender
      birth_date
      ssn
      profile_pic_url
      patient {
        _id
        id
      }
      phones: contact_method_associations(
        where: { contact_method: { dtype: { _eq: "phone" } } }
      ) {
        id
        description
        preference
        contact_method {
          id
          phone {
            id
            number
          }
        }
      }
      addresses: contact_method_associations(
        where: { contact_method: { dtype: { _eq: "postal" } } }
      ) {
        id
        description
        contact_method {
          id
          postal_address {
            id
            address_line1
            city
            state
            zip
          }
        }
      }
      emails: contact_method_associations(
        where: { contact_method: { dtype: { _eq: "email" } } }
      ) {
        id
        description
        preference
        contact_method {
          id
          email {
            id
            address
          }
        }
      }
    }
  }
}

`