import { normalizePath, useSetFlowTo } from 'Simple/Flow.js'
import { useDataValue } from 'Simple/Data.js'
export function useOnClick(props) {
  let setFlowTo = useSetFlowTo(props.viewPath)
  let employer = useDataValue({
    context: 'employer',
    viewPath: props.viewPath,
    path: 'id',
  })
  return function onClick(action) {
    switch (action) {
      case 'EDIT':
        if (employer !== null) {
          return editEmployer()
        }
        return
      case 'ADD':
        return addEmployer()
      default:
        return
    }
  }

  function editEmployer() {
    setFlowTo(normalizePath(props.viewPath, './Edit/Content'))
  }
  function addEmployer() {
    setFlowTo(normalizePath(props.viewPath, './Create/Content'))
  }
}
