// this is an autogenerated file from App/Account/Content/PatientsList/Filters/StatusChooser/data.graphql
import { gql } from 'Data/Api'

export default gql`
query app__App_Account_Content_PatientsList_Filters_StatusChooser{
  vaxiom_txs(
    where: { tx_status_name: { hidden: { _eq: false } } }
    distinct_on: [status]
    order_by: [{ status: asc }]
  ) {
    id
    status
    tx_status_name {
      id
      name
    }
  }
}

`