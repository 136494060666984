import { useDataValue } from 'Simple/Data.js'

/** @type {import('Simple/types.js').useDataConfiguration} */
export default function useDataConfiguration(props) {
  let location_id = useDataValue({
    context: 'global',
    path: 'current_location._id',
    viewPath: props.viewPath,
  })

  let resource_id = useDataValue({
    viewPath: props.viewPath,
    path: 'patient_id',
    context: 'tab',
  })

  return {
    variables: {
      location_id,
      resource_id,
    },
    requestPolicy: 'cache-and-network',
  }
}
