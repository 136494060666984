// this is an autogenerated file from App/Account/Content/Patients/Content/Content/Sections/ActiveTab/Profile/Content/NewProfile/Content/Insurance/AssignedInsurance/Content/AssignedInsuranceItem/InsuredTreatments/Content/InsuredTreatment/data.graphql
import { gql } from 'Data/Api'

export default gql`
query app__App_Account_Content_Patients_Content_Content_Sections_ActiveTab_Profile_Content_NewProfile_Content_Insurance_AssignedInsurance_Content_AssignedInsuranceItem_InsuredTreatments_Content_InsuredTreatment($insured_id: numeric!) {
  vaxiom_tx_payers(where: { insured_id: { _eq: $insured_id } }) {
    id
    primary_payer
    insured {
      id
      insurance_subscription {
        id
        member_id
        person {
          id
          first_name
          middle_name
          last_name
        }
        insurance_plan {
          id
          insurance_billing_center {
            id
            name
          }
          insurance_company {
            carrier_name
          }
        }
      }
    }
    tx {
      id
      tx_plan {
        id
        name
      }
    }
  }
}

`