import { useDataSubmit, useDataChange } from 'Simple/Data.js'

export function useOnSubmit(props) {
  let submit = useDataSubmit({
    context: 'settings',
    viewPath: props.viewPath,
  })

  return function onSubmit(selected) {
    submit({
      key: 'selected_appointment_templates',
      value: selected,
    })
    if (typeof props.closePopover === 'function') props.closePopover()
  }
}

export function useOnChange(props) {
  let change = useDataChange({
    context: 'tab',
    path: 'selected.topbar_appointment_templates',
    viewPath: props.viewPath,
  })
  return async function onChange(value) {
    change(value)
  }
}

export function useOnFilter(props) {
  return function onFilter(value, items) {
    let valueRegex = new RegExp(value, 'i')
    return items.filter(item => valueRegex.test(item.full_name))
  }
}
