import View from './view.js'
import { useBeforeunload } from 'react-beforeunload'

// https://www.npmjs.com/package/react-beforeunload
// ⚠️ Some browsers used to display the rreturned string in the confirmation dialog, enabling the event handler to display a custom message to the user.
// However, this is deprecated and no longer supported in most browsers.

export default function Logic(props) {
  useBeforeunload(
    true
      ? event => {
          event.preventDefault()
          return 'You have unsaved changes. Are you sure you want to leave the page?'
        }
      : null
  )
  return <View {...props} />
}
