import React, { useEffect } from 'react'
import { normalizePath, useSetFlowTo } from 'Simple/Flow.js'
import { useDataValue } from 'Simple/Data.js'

import View from './view.js'

export default function Logic(props) {
  let setFlowTo = useSetFlowTo(props.viewPath)
  let ssn = useDataValue({
    context: 'profile_patient',
    viewPath: props.viewPath,
    path: 'person.ssn',
  })

  useEffect(() => {
    if (!!!ssn) {
      // show ssn if initially it is empty
      setFlowTo(normalizePath(props.viewPath, 'Show'))
    }
  }, [])

  return <View {...props} />
}
