import { useDataValue, useDataSubmit } from 'Simple/Data.js'

export function useOnClick(props) {
  let hipaa = useDataValue({
    context: 'settings',
    path: 'hipaa',
    viewPath: props.viewPath,
  })

  let submit = useDataSubmit({
    context: 'settings',
    viewPath: props.viewPath,
  })

  return function onClick() {
    return submit({
      key: 'hipaa',
      value: !hipaa,
    })
  }
}
