import React from 'react'

import View from './view.js'
import { useDataValue } from 'Simple/Data.js'

export default function Logic(props) {
  let key = useDataValue({
    context: 'payor',
    path: 'contract.file',
    viewPath: props.viewPath,
  })
  return <View viewPath={props.viewPath} getKey={getKey} />

  // a single file is uploaded and will preserve the S3 object key
  function getKey() {
    return key
  }
}
