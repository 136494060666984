export function getView(insurance_subscription) {
  let view = 'Inactivated'
  if (insurance_subscription.active) {
    if (!insurance_subscription.reactivated) {
      view = 'Assigned'
    } else {
      view = 'Reactivated'
    }
  }
  return view
}
