// this is an autogenerated file from App/Account/Content/Patients/Content/Content/Sections/ActiveTab/TreatmentCard/Content/TreatmentPlans/New/Steps/Content/PaymentPlans/Content/Content/PaymentPlan/Content/Content/Send/Content/ContactMethod/Email/data.graphql
import { gql } from 'Data/Api'

export default gql`
query app__App_Account_Content_Patients_Content_Content_Sections_ActiveTab_TreatmentCard_Content_TreatmentPlans_New_Steps_Content_PaymentPlans_Content_Content_PaymentPlan_Content_Content_Send_Content_ContactMethod_Email($treatment_plan_id: numeric!) {
  treatments_contact_method_associations_by_treatment_plan(
    args: { treatment_plan_id: $treatment_plan_id }
    where: { contact_method: { dtype: { _eq: "email" } } }
    order_by: { contact_order: asc, preference: asc }
  ) {
    id
    email {
      id
      address
    }
    person {
      id
      first_name
      last_name
    }
  }
}

`