// this is an autogenerated file from App/Account/Content/Patients/Content/Content/Sections/ActiveTab/Profile/Content/NewProfile/Content/ContactInfo/Email/Emails/Content/Email/Delete/Content/Actions/DeleteAction/mutation.graphql
import { gql } from 'Data/Api'

export default gql`
mutation app__App_Account_Content_Patients_Content_Content_Sections_ActiveTab_Profile_Content_NewProfile_Content_ContactInfo_Email_Emails_Content_Email_Delete_Content_Actions_DeleteAction($association_id: numeric!) {
  delete_vaxiom_contact_method_associations_by_pk(id: $association_id) {
    id
  }
}

`