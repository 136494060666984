import { useDataSubmit, useDataValue } from 'Simple/Data.js'
export function useOnClick(props) {
  let submit = useDataSubmit({
    context: 'new_phone',
    viewPath: props.viewPath,
  })
  let type = useDataValue({
    context: 'new_phone',
    viewPath: props.viewPath,
    path: 'type',
  })
  return function onClick() {
    if (type === 'existing') {
      submit()
    } else {
      submit({
        type: 'form_submit',
      })
    }
  }
}
