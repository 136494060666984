// this is an autogenerated file from DesignSystem/DataVaxiomEmployeeProfessionalsRelationshipsItem/Edit/Content/Email/Emails/Content/Email/Actions/editPreferencesMutation.graphql
import { gql } from 'Data/Api'

export default gql`
mutation app__DesignSystem_DataVaxiomEmployeeProfessionalsRelationshipsItem_Edit_Content_Email_Emails_Content_Email_Actions(
  $id: numeric!
  $preference: String!
  $person_id: numeric!
) {
  update_vaxiom_contact_method_associations(
    where: { person_id: { _eq: $person_id } }
    _set: { preference: null }
  ) {
    affected_rows
  }

  update_vaxiom_contact_method_associations_by_pk(
    pk_columns: { id: $id }
    _set: { preference: $preference }
  ) {
    id
  }
}

`