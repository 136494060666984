import { useMemo } from 'react'

/** @type {import('Simple/types.js').useDataTransform} */
export default function useDataTransform(props, data) {
  return useMemo(() => {
    if (!data) return []

    return [
      ...data.vaxiom_insurance_companies.map(insurance => ({
        id: `insurance_${insurance.id}`,
        text: `${insurance.carrier_name} ${
          insurance.installment_interval === null
            ? ``
            : `(${insurance.installment_interval})`
        } ${
          insurance.continuation_claims_required ? 'Continuation Claims' : ''
        }`,
        object_id: insurance.id,
        is_carrier: true,
      })),
      ...data.vaxiom_employers.map(employer => ({
        id: `employer_${employer.id}`,
        text: employer.name,
        object_id: employer.id,
        is_carrier: false,
      })),
    ].sort((a, b) => a.text.localeCompare(b.text))
  }, [data])
}
