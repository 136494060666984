import { useDataSubmit } from 'Simple/Data'

export function useOnClick(props) {
  let submit = useDataSubmit({
    viewPath: props.viewPath,
    context: 'contact_method_association',
  })
  return async function onClick() {
    submit({ type: 'update_phone', update_all: false })
  }
}
