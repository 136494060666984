// this is an autogenerated file from App/Account/Content/Patients/Content/Content/Sections/ActiveTab/TreatmentCard/Content/TreatmentCard/Treatment/Content/Actions/Content/ChangeStatus/Content/Status/data.graphql
import { gql } from 'Data/Api'

export default gql`
query app__App_Account_Content_Patients_Content_Content_Sections_ActiveTab_TreatmentCard_Content_TreatmentCard_Treatment_Content_Actions_Content_ChangeStatus_Content_Status($parent_company_id: uuid!) {
  vaxiom_tx_statuses(
    where: {
      parent_company: { _id: { _eq: $parent_company_id } }
      hidden: { _eq: false }
    }
    order_by: { name: asc_nulls_last }
  ) {
    id
    type
    name
  }
}

`