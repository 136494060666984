import React, { useMemo } from 'react'
import { useDataValue } from 'Simple/Data.js'

import View from './view.js'

export default function Logic(props) {
  let day_slots = useDataValue({
    viewPath: props.viewPath,
    context: 'day_slots',
  })
  let slot_id = useDataValue({
    viewPath: props.viewPath,
    context: 'tab',
    path: 'scheduling.slot_id',
  })
  let selected = useMemo(
    () => day_slots?.find(v => v.id === slot_id),
    [day_slots, slot_id]
  )
  let selectedText = useMemo(() => {
    return selected
      ? `${selected.time_formatted} (${selected.chair_name})`
      : undefined
  }, [selected])

  return (
    <View
      {...props}
      selectedText={selectedText}
      ignoreClickOutsideIf={event =>
        event?.target?.dataset?.['viewPath']?.includes?.(props.viewPath)
      }
    />
  )
}
