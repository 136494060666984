// this is an autogenerated file from DesignSystem/DataVaxiomOtherProfessionalsRelationshipsItem/Menu/Option/deleteProfessionalRelationshipMutation.graphql
import { gql } from 'Data/Api'

export default gql`
mutation app__DesignSystem_DataVaxiomOtherProfessionalsRelationshipsItem_Menu_Option($id: numeric!) {
  delete_vaxiom_professional_relationships_by_pk(id: $id) {
    id
  }
}

`