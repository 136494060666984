import { useDataValue } from 'Simple/Data'
import { useSetFlowTo, normalizePath } from 'Simple/Flow.js'
import { notifyError, useNotifications } from 'Logic/Notifications.js'
import { useMutation } from 'Data/Api'
import mutation_delete_person_referrals from './personReferralsDeleteMutation.graphql.js'
import mutation_delete_template_referrals from './templateReferralsDeleteMutation.graphql.js'

export function useOnDelete(props) {
  let referral = useDataValue({
    context: 'profile_patient_referral',
    viewPath: props.viewPath,
  })

  let [, notify] = useNotifications()
  let [, executeDeletePersonReferralMutation] = useMutation(
    mutation_delete_person_referrals
  )
  let [, executeDeleteTemplateReferralMutation] = useMutation(
    mutation_delete_template_referrals
  )
  let setFlowTo = useSetFlowTo(props.viewPath)
  return async function onClick() {
    if (referral.person_id) {
      let deletePersonReferralMutationResponse =
        await executeDeletePersonReferralMutation({
          person_referrals_ids: [referral._id],
        })
      if (deletePersonReferralMutationResponse.error) {
        notify(notifyError('Something went wrong. Please, try again.'))
        return
      }
      setFlowTo(normalizePath(props.viewPath, '../../../No'))
    } else {
      let deleteTemplateReferralMutationResponse =
        await executeDeleteTemplateReferralMutation({
          template_referrals_ids: [referral._id],
        })
      if (deleteTemplateReferralMutationResponse.error) {
        notify(notifyError('Something went wrong. Please, try again.'))
        return
      }
      setFlowTo(normalizePath(props.viewPath, '../../../No'))
    }
  }
}
