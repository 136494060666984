// this is an autogenerated file from DesignSystem/DataVaxiomPatientInsuranceAssignForm/Content/PolicyHolder/Options/AddRelationship/Content/Phone/Existing/data.graphql
import { gql } from 'Data/Api'

export default gql`
query app__DesignSystem_DataVaxiomPatientInsuranceAssignForm_Content_PolicyHolder_Options_AddRelationship_Content_Phone_Existing($person_id: uuid!) {
  vaxiom_contact_method_associations(
    where: {
      person: { _id: { _eq: $person_id } }
      contact_method: { dtype: { _eq: "phone" } }
    }
    order_by: { id: asc }
  ) {
    id
    description
    phone {
      id
      type
      number
    }
  }
}

`