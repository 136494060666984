import { useDataSubmit } from 'Simple/Data.js'

export function useOnClick(props) {
  let submit = useDataSubmit({
    viewPath: props.viewPath,
    context: 'appointment_overlay',
  })

  return function onClick() {
    submit({ type: 'schedule' })
  }
}

export function isDisabled(scheduling) {
  return !scheduling?.slot_id && !scheduling.untemplated_slot.id
}
