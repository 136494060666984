export function getView(error, url_login) {
  return error ? 'Error' : url_login ? 'Content' : 'No'
}

let SECTION_PATHS = {
  calendar: '/ui#!',
  tasks: '/ui/#!tasks',
  chairs: '/ui/#!chairs',
  notes: '/ui/#!notes',
  admin: '/ui/#!admin',
  adminjobs: '/admin',
  hr_admin: '/ui/#!hr-admin',
  financial: '/ui/#!financial',
  work_hours: '/ui/#!workhours',
  employee_work_hours: '/ui/#!adminworkhours',
  patients: '/ui/#!patient',
  chair_card: '/ui/#!chairCard',
  reporting: '/ui/#!reports',
}

export function getUrls({
  section_type,
  location_id,
  vaxiom_location_id,
  user_id,
  vaxiom_user_id,
  token,
  params = {},
}) {
  let path = encodeURIComponent(getSectionPath(section_type, params))

  let baseUrl = process.env.REACT_APP_CORE_EMBED_APP_URL.replace(
    'embed-app',
    `${user_id.split('-')[0]}${location_id.split('-')[0]}.${
      section_type === 'reporting' ? 'reporting-app' : 'users'
    }` // reduce the length to be less than the max allowed domain name size
  )

  return {
    url_access: `${baseUrl}/${path}`,
    url_login: `${baseUrl}/signin-token?token=${token}&username=${vaxiom_user_id}&location=${vaxiom_location_id}&redirectUri=${path}`,
    url_logout: `${baseUrl}/logout`,
  }
}

function getSectionPath(section_type, params) {
  if (!(section_type in SECTION_PATHS)) {
    throw new Error(`Section type is invalid "${section_type}"`)
  }

  let path = SECTION_PATHS[section_type]

  switch (section_type) {
    case 'patients':
      return getPatientsPath(path, params)
    case 'calendar':
      return getCalendarPath(path, params)
    case 'chairs':
      return params.is_edit
        ? getChairCardPath(SECTION_PATHS['chair_card'], params)
        : getChairsPath(path, params)
    case 'tasks':
      return getTasksPath(path, params)
    case 'reports':
      return getReportsPath(path, params)
    case 'employee_work_hours':
      return getEmployeeWorkHoursPath(path, params)
    case 'financial':
      return getFinancialPath(path, params)
    case 'reporting':
      return getReportingPath(path, params)
    default: {
      return path
    }
  }
}

function getCalendarPath(base_path, params) {
  let path = base_path
  if (params.subview) {
    path = `${path}/subview=${params.subview}`
  }
  if (params.appointment_id) {
    path = `${path}/appointment=${params.appointment_id}`
  }
  if (params.patient_id) {
    path = `${path}/patient=${params.patient_id}`
  }
  if (params.location_id) {
    path = `${path}/location=${params.location_id}`
  }
  if (params.date) {
    path = `${path}/date=${params.date}`
  }
  if (params.mode) {
    path = `${path}/mode=${params.mode}`
  }
  if (typeof params.is_rescheduling === 'boolean') {
    path = `${path}/isRescheduling=${params.is_rescheduling}`
  }
  if (params.is_create_appointment) {
    path = `${path}/appointment=new`
  }
  if (params.zoomed) {
    path = `${path}/zoomed=${params.zoomed}`
  }
  if (typeof params.is_settings === 'boolean') {
    path = `${path}/isSettings=${params.is_settings}`
  }

  return path
}

function getReportingPath(base_path, params) {
  let path = base_path
  if (params.subview) {
    path = `${path}/${params.subview}`
  }
  return path
}

function getPatientsPath(base_path, params) {
  let path = `${base_path}/patient=${params.patient_id}`

  if (params.tab) {
    path = `${path}/${params.tab}`
  }
  // TODO: check if images is still a valid tab
  if (params.tab === 'images' && params.image_id) {
    path = `${path}/item=${params.image_id}`
  }
  if (params.appointment_id) {
    // make sure the appointment was not deleted in the meantime
    path = `${path}/appointment=${params.appointment_id}`
  }
  if (params.booking_id) {
    path = `${path}/booking=${params.booking_id}`
  }
  if (params.snapshot_id) {
    path = `${path}/snapshot=${params.snapshot_id}`
  }
  if (params.diagnosis_id) {
    path = `${path}/diagnosis=${params.diagnosis_id}`
  }
  if (params.treatment_id) {
    path = `${path}/treatment=${params.treatment_id}`
  }
  if (params.tab === 'ledger' && typeof params.payor === 'number') {
    path = `${path}/selected_payor=${params.payor}`
  }

  if (params.tab === 'treatment_card') {
    // TODO: double check, can we add a switch per tab?
    // eg appointment_id is alreayd set above if params.appointment_id exists
    if (params.appointment_id) {
      path = `${path}/appointment=${params.appointment_id}`
    }
    if (params.tx_card_id) {
      path = `${path}/card=${params.tx_card_id}`
    }
    if (params.expand_all) {
      path = `${path}/expandAll=true`
    }
    if (params.collapse_all) {
      path = `${path}/collapseAll=true`
    }
    if (params.reorder_entries) {
      path = `${path}/reorderEntries=true`
    }
    if (params.hide_top_bar) {
      path = `${path}/hideTopBar=true`
    }
    if (params.hide_side_bar) {
      path = `${path}/hideSideBar=true`
    }
    if (params.go_to_latest) {
      path = `${path}/goToLatest=true`
    }
  }

  return path
}

function getChairCardPath(base_path, params) {
  let path = base_path
  if (params.chair_id) {
    path = `${path}/${params.chair_id}`
  }
  if (params.sub_view) {
    path = `${path}/${params.sub_view}`
  }
  return path
}

function getChairsPath(base_path, params) {
  let path = base_path
  if (params.is_create) {
    path = `${path}/add`
  }
  path = `${path}/orgId=${params.organization_id}`
  return path
}

function getTasksPath(base_path, params) {
  let path = base_path
  if (params.task_id) {
    path = `${path}/task=${params.task_id}`
  }
  if (params.is_letter) {
    path = `${path}/letter`
  }
  if (params.sub_view) {
    path = `${path}/${params.sub_view}`
  }
  return path
}

function getReportsPath(base_path, params) {
  let path = base_path
  if (params.sub_view) {
    path = `${path}/${params.sub_view}`
  }
  return path
}

function getEmployeeWorkHoursPath(base_path, params) {
  let path = base_path
  if (params.date) {
    path = `${path}/${params.date}`
  }
  return path
}

function getFinancialPath(base_path, params) {
  let path = base_path
  if (params.sub_view) {
    path = `${path}/${params.sub_view}`
  }
  return path
}
