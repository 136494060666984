// this is an autogenerated file from App/Account/Content/Patients/Content/Content/Sections/ActiveTab/TreatmentCard/Content/TreatmentCard/Events/Content/Content/Events/Content/Compact/Past/Content/data.graphql
import { gql } from 'Data/Api'

export default gql`
query app__App_Account_Content_Patients_Content_Content_Sections_ActiveTab_TreatmentCard_Content_TreatmentCard_Events_Content_Content_Events_Content_Compact_Past_Content($user_id: uuid!, $location_id: uuid!) {
  user_location_preferences_by_pk(
    user_id: $user_id
    location_id: $location_id
  ) {
    user_id
    location_id
    compact_table_presets
  }
}

`