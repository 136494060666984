import { useMutation } from 'Data/Api.js'
import {
  notifyError,
  notifySuccess,
  useNotifications,
} from 'Logic/Notifications.js'
import differenceInMinutes from 'date-fns/differenceInMinutes'
import mutationAddScheduleNote from './mutation-add-schedule-note.graphql.js'
import mutationEditScheduleNote from './mutation-edit-schedule-note.graphql.js'

/** @type {import('Simple/types.js').useDataOnSubmit} */
export default function useDataOnSubmit(props, data) {
  let onActionAdd = useDataOnActionAdd(props, data)
  let onActionEdit = useDataOnActionEdit(props, data)
  let onActionCancel = useDataOnActionCancel(props, data)

  return async function onSubmit({ args, value, originalValue, change }) {
    switch (args.type) {
      case 'cancel': {
        return onActionCancel({ args, value, originalValue, change })
      }
      case 'add': {
        return onActionAdd({ args, value, originalValue, change })
      }
      case 'edit': {
        return onActionEdit({ args, value, originalValue, change })
      }
      default: {
      }
    }
  }
}

/** @type {import('Simple/types.js').useDataOnSubmit} */
export function useDataOnActionAdd(props, data) {
  let [, executeMutation] = useMutation(mutationAddScheduleNote)
  // @ts-ignore
  let [, notify] = useNotifications()

  return async function onAction({ value, change, originalValue }) {
    let objects = value.selected_resources
      .map(chair_id => {
        let vaxiom_chair_id = value.resources.find(
          r => r.id === chair_id
        )?.vaxiom_id

        if (typeof chair_id === 'undefined') return undefined
        return {
          organization_id: value.location_id,
          chair_id: vaxiom_chair_id,
          start_time: value.start,
          duration: differenceInMinutes(
            new Date(value.end),
            new Date(value.start)
          ),
          content: value.content,
          alert_this_day: value.alert_this_day,
          is_blocking_time:
            value.type === 'blockTime' || value.is_blocking_time,
        }
      })
      .filter(Boolean)

    let mutationResponse = await executeMutation({ objects })

    if (mutationResponse.error) {
      notify(
        notifyError(
          value.type === 'blockTime'
            ? 'Could not add blocked time. Please try again.'
            : 'Could not add calendar note. Please try again.'
        )
      )
      return
    }

    notify(
      notifySuccess(
        value.type === 'blockTime'
          ? 'Blocked time was successfully added.'
          : 'Calendar note was successfully added.'
      )
    )
    change(originalValue)
    return props.onComplete?.()
  }
}

/** @type {import('Simple/types.js').useDataOnSubmit} */
export function useDataOnActionEdit(props, data) {
  let [, executeMutation] = useMutation(mutationEditScheduleNote)
  // @ts-ignore
  let [, notify] = useNotifications()

  return async function onAction({ change, originalValue, args, value }) {
    let mutationResponse = await executeMutation({
      id: value.id,
      edit_input: {
        content: value.content,
        alert_this_day: value.alert_this_day,
        is_blocking_time: value.type === 'blockTime' || value.is_blocking_time,
      },
      insert_objects: value.selected_resources
        .filter(chair_id => chair_id !== originalValue.selected_resources[0])
        .map(chair_id => {
          let vaxiom_chair_id = value.resources.find(
            r => r.id === chair_id
          )?.vaxiom_id

          if (typeof chair_id === 'undefined') return undefined
          return {
            organization_id: value.location_id,
            chair_id: vaxiom_chair_id,
            start_time: value.start,
            duration: differenceInMinutes(
              new Date(value.end),
              new Date(value.start)
            ),
            content: value.content,
            alert_this_day: value.alert_this_day,
            is_blocking_time:
              value.type === 'blockTime' || value.is_blocking_time,
          }
        })
        .filter(Boolean),
    })

    if (mutationResponse.error) {
      notify(
        notifyError(
          value.type === 'blockTime'
            ? 'Could not edit blocked time. Please try again.'
            : 'Could not edit calendar note. Please try again.'
        )
      )
      return
    }

    notify(
      notifySuccess(
        value.type === 'blockTime'
          ? 'Blocked time was successfully edited.'
          : 'Calendar note was successfully edited.'
      )
    )
    change(originalValue)
    return props.onComplete?.()
  }
}

/** @type {import('Simple/types.js').useDataOnSubmit} */
export function useDataOnActionCancel(props, data) {
  return function onAction({ originalValue, change }) {
    change(originalValue)
    return props.onComplete?.()
  }
}
