import { format } from 'date-fns'
import { phoneNumberUS } from 'Data/format.js'

let HEADER_HANDLER = {
  create_treatment_plan: 'Create treatment plan',
  resume_treatment_plan: 'Resume treatment plan',
  create_payment_plan: 'Create the payment plan',
  resume_payment_plan: 'Resume payment plan',
  start_contract: 'Contract not yet started!',
  view_case_closer_request: 'Case closer request sent',
}

export function getHeader(treatment_plan_action) {
  return HEADER_HANDLER[treatment_plan_action] ?? ''
}

let DESCRIPTION_HANDLER = {
  create_treatment_plan: () =>
    'No treatment plan has been selected for this patient yet.',
  resume_treatment_plan: () =>
    'No treatment plan has been applied for this patient yet.',
  create_payment_plan: () =>
    'A contract has not yet been created for this treatment. Complete the payment setup.',
  resume_payment_plan: () =>
    'A contract has not yet been created for this treatment. Complete the payment setup.',
  start_contract: () =>
    'The contract for this treatment has not been started but the patient has an active treatment status.',
  view_case_closer_request: request => getCaseCloserRequestText(request),
}

export function getDescription(treatment_plan_action, case_closer_request) {
  return DESCRIPTION_HANDLER[treatment_plan_action]
    ? DESCRIPTION_HANDLER[treatment_plan_action](case_closer_request)
    : ''
}

function getCaseCloserRequestText(request) {
  if (!request.contact_method_association) {
    return `Request has been sent to the payor ${request.person.first_name} ${
      request.person.last_name
    } on ${format(new Date(request.created_at), 'MM/dd/yyyy')}`
  } else {
    return `Request has been sent to the payor ${request.person.first_name} ${
      request.person.last_name
    }'s ${
      request.contact_method_association.contact_method.dtype
    } - ${getContactMethod(
      request.contact_method_association.contact_method
    )} on ${format(new Date(request.created_at), 'MM/dd/yyyy')}.`
  }
}

function getContactMethod(contact_method) {
  return contact_method.dtype === 'email'
    ? contact_method.email?.address
    : phoneNumberUS(contact_method.phone?.number)
}
