// this is an autogenerated file from App/Account/Content/Patients/Content/Content/Sections/ActiveTab/Profile/Content/NewProfile/Content/Relations/AddRelation/NewPerson/Content/Phone/Existing/data.graphql
import { gql } from 'Data/Api'

export default gql`
query app__App_Account_Content_Patients_Content_Content_Sections_ActiveTab_Profile_Content_NewProfile_Content_Relations_AddRelation_NewPerson_Content_Phone_Existing($person_id: uuid!) {
  vaxiom_contact_method_associations(
    where: {
      person: { _id: { _eq: $person_id } }
      contact_method: { dtype: { _eq: "phone" } }
    }
    order_by: { id: asc }
  ) {
    id
    description
    phone {
      id
      type
      number
    }
  }
}

`