import { useDataValue } from 'Simple/Data'
import { useSetFlowTo, normalizePath } from 'Simple/Flow.js'
import {
  notifyError,
  notifySuccess,
  useNotifications,
} from 'Logic/Notifications.js'
import { useMutation } from 'Data/Api'
import mutation from './mutation.graphql.js'

export function useOnDelete(props) {
  let patient_person_id = useDataValue({
    viewPath: props.viewPath,
    context: 'profile_patient',
    path: 'person.id',
  })
  let relative_person_id = useDataValue({
    viewPath: props.viewPath,
    context: 'relationship',
    path: 'from_person',
  })

  let [, notify] = useNotifications()
  let [, executeMutation] = useMutation(mutation)
  let setFlowTo = useSetFlowTo(props.viewPath)
  return async function onClick() {
    let mutationResponse = await executeMutation({
      patient_person_id,
      relative_person_id,
    })
    if (mutationResponse.error) {
      notify(notifyError('Something went wrong. Please, try again.'))
      return
    }
    notify(notifySuccess('Email deleted!'))
    setFlowTo(normalizePath(props.viewPath, '../../../No'))
  }
}
