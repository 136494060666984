// this is an autogenerated file from App/Account/Content/Patients/Content/Content/Sections/ActiveTab/Profile/Content/NewProfile/Content/ContactInfo/Email/Emails/Content/Email/Address/Related/data.graphql
import { gql } from 'Data/Api'

export default gql`
query app__App_Account_Content_Patients_Content_Content_Sections_ActiveTab_Profile_Content_NewProfile_Content_ContactInfo_Email_Emails_Content_Email_Address_Related($contact_method_id: numeric!, $person_id: numeric) {
  vaxiom_contact_method_associations(
    where: {
      contact_method_id: { _eq: $contact_method_id }
      person_id: { _neq: $person_id }
    }
  ) {
    id
    error_location_id
    person {
      id
      first_name
      last_name
    }
  }
}

`