// this is an autogenerated file from App/Account/Content/Patients/Content/Content/Sections/ActiveTab/Profile/Content/NewProfile/Content/ContactInfo/Email/NewEmail/Content/updatePersonRelationshipsMutation.graphql
import { gql } from 'Data/Api'

export default gql`
mutation app__App_Account_Content_Patients_Content_Content_Sections_ActiveTab_Profile_Content_NewProfile_Content_ContactInfo_Email_NewEmail_Content($relationships: json) {
  update_person_relationships(
    args: { _relationships: $relationships, add_contact_method_to_self: false }
  ) {
    id
  }
}

`