// this is an autogenerated file from App/Auth/CallbackOAuth2/Loading/mutation.graphql
import { gql } from 'Data/Api'

export default gql`
mutation app__App_Auth_CallbackOAuth2_Loading($current_url: String!) {
  apps_external_api_token_swap(current_url: $current_url) {
    status
  }
}

`