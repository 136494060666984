// this is an autogenerated file from App/Account/Content/Calendar/New/Content/Content/AppointmentOverlay/Content/Form/Content/TreatmentSelect/data.graphql
import { gql } from 'Data/Api'

export default gql`
query app__App_Account_Content_Calendar_New_Content_Content_AppointmentOverlay_Content_Form_Content_TreatmentSelect($patient_id: uuid!) {
  vaxiom_patients(where: { _id: { _eq: $patient_id } }) {
    id
    _id
    tx_cards(
      where: { deleted: { _eq: false }, txs: { deleted: { _eq: false } } }
    ) {
      id
      _id
      name
      latest_appointment_booking_start_time
      txs(where: { deleted: { _eq: false } }) {
        id
        _id
        name
        status
        tx_status_name {
          id
          name
        }
      }
    }
  }
}

`