import { useMutation } from 'Data/Api.js'
import mutationDeleteScheduleNote from './mutation-delete-schedule-note.graphql.js'
import {
  notifyError,
  notifySuccess,
  useNotifications,
} from 'Logic/Notifications.js'

/** @type {import('Simple/types.js').useDataOnSubmit} */
export default function useDataOnSubmit(props, data) {
  let onActionDelete = useDataOnActionDelete(props, data)

  return async function onSubmit({ args, value, originalValue, change }) {
    switch (args.type) {
      case 'delete': {
        return await onActionDelete({ args, value, originalValue, change })
      }
      default: {
      }
    }
  }
}

/** @type {import('Simple/types.js').useDataOnSubmit} */
function useDataOnActionDelete(props, data) {
  let [, executeMutation] = useMutation(mutationDeleteScheduleNote)

  // @ts-ignore
  let [, notify] = useNotifications()

  return async function onActionDelete({ value }) {
    let mutationResponse = await executeMutation({
      id: value.schedule_note.id,
    })

    if (mutationResponse.error) {
      notify(notifyError('Could not delete calendar note. Please try again.'))
      return false
    }

    notify(notifySuccess('Calendar note deleted sucessfully.'))
    return true
  }
}
