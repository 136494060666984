// this is an autogenerated file from App/Account/Content/Settings/AdminContent/SettingsContent/TreatmentCaseCloser/Configuration/FeatureAccess/Rulesets/data.graphql
import { gql } from 'Data/Api'

export default gql`
query app__App_Account_Content_Settings_AdminContent_SettingsContent_TreatmentCaseCloser_Configuration_FeatureAccess_Rulesets($parent_company_id: uuid!, $message_key: String!) {
  vaxiom_application_properties(
    where: {
      parent_id: { _eq: $parent_company_id }
      message_key: { _eq: $message_key }
    }
  ) {
    organization_id
    message_key
    message_value
    organization {
      id
      level
      parent {
        id
        level
        parent {
          id
          level
        }
      }
    }
  }
}

`