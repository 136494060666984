import { useDataValue } from 'Simple/Data.js'
/** @type {import('Simple/types.js').useDataConfiguration} */
export default function useDataConfiguration(props, data) {
  let to_person_id = useDataValue({
    viewPath: props.viewPath,
    context: 'profile_patient',
    path: 'person._id',
  })
  return {
    variables: { to_person_id },
    // pause: false,
  }
}
