import { useDataOriginalValue, useDataValue } from 'Simple/Data'
import { useMemo } from 'react'
import isEqual from 'lodash/isEqual.js'

export default function useDataTransform(props, data) {
  let patient = useDataValue({
    viewPath: props.viewPath,
    context: 'profile_patient',
  })
  let originalPatient = useDataOriginalValue({
    viewPath: props.viewPath,
    context: 'profile_patient',
  })
  return useMemo(() => {
    return {
      has_changes: !isEqual(patient, originalPatient),
    }
  }, [originalPatient, patient])
}
