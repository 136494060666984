import { useDataSubmit } from 'Simple/Data'

export function useOnClick(props) {
  let submit = useDataSubmit({
    viewPath: props.viewPath,
    context: 'new_email',
  })
  return function useOnClick(value) {
    submit({ type: 'new_email_for_patient' })
  }
}
