import { useMemo } from 'react'

export default function useDataTransform(props, data) {
  return useMemo(() => {
    if (!data) return data

    let res = data
      // multiple entries of the contact method in the DB, and not all have an association
      .filter(obj => obj.contact_method?.association?.person)
      .map(obj => ({
        ...obj.contact_method.association.person,
        contact_method_id: obj.contact_method.id,
      }))
    return res
  }, [data])
}
