import { normalizePath, useSetFlowTo } from 'Simple/Flow'
import { useMutation } from 'Data/Api'
import { useDataValue, useDataSubmit, useDataChange } from 'Simple/Data'
import {
  notifyError,
  notifySuccess,
  useNotifications,
} from 'Logic/Notifications.js'
import mutationCreateAutomations from './mutation-create-automations.graphql.js'
import mutationUpdatePreferences from './mutation-update-preferences.graphql.js'

// TODO: this likely needs to be mapped to the new type/fields because
// I think it references an app automation's event not the actual event?
function validate(value) {
  return value
    .filter(step => step.step_type === 'automation')
    .map(({ event }) => {
      if (!event?.data) {
        return null
      }
      switch (event.type) {
        case 'txs_status_update': {
          if (!event.data.value_to?.length) {
            return 'Please select treatment status'
          }

          break
        }
        case 'appointment_bookings_state_update': {
          if (
            !event.data.value_to?.length ||
            !event.data.appointment_type_ids?.length
          ) {
            return 'Please select appointment types and status'
          }

          break
        }
        case 'custom_resource_insert': {
          if (!event.data.custom_resource_type) {
            return 'Please select the custom resource type'
          }
          break
        }

        default:
          return null
      }

      return null
    })
    .filter(Boolean)
}

/** @type {import('Simple/types.js').useDataOnSubmit} */
export default function useDataOnSubmit(props, data) {
  let location_id = useDataValue({
    viewPath: props.viewPath,
    context: 'global',
    path: 'current_location._id',
  })
  let connection_title = useDataValue({
    viewPath: props.viewPath,
    context: 'connection_definition',
    path: 'title',
  })
  let app_id = useDataValue({
    viewPath: props.viewPath,
    context: 'connection_definition',
    path: 'app_id',
  })
  let connection_level = useDataValue({
    viewPath: props.viewPath,
    context: 'connection_resource',
    path: 'type',
  })
  let submitConnection = useDataSubmit({
    context: 'connection',
    viewPath: props.viewPath,
  })
  let setRefetchAutomations = useDataChange({
    context: 'tab',
    path: 'selected.refetch_automations',
    viewPath: props.viewPath,
  })

  let [, executeCreateAutomationsMutation] = useMutation(
    mutationCreateAutomations
  )
  let [, executeUpdatePreferencesMutation] = useMutation(
    mutationUpdatePreferences
  )
  let setFlow = useSetFlowTo(props.viewPath)
  let [, notify] = useNotifications()

  return async function onSubmit({ value, originalValue, args, change }) {
    switch (args.type) {
      case 'changeStatus': {
        let isLast = value.current_index === value.steps.length - 1

        if (isLast) {
          await submit(
            value.steps.map((step, index) => {
              if (index === value.current_index) {
                return {
                  ...step,
                  status: args.value,
                }
              }

              return step
            })
          )

          setRefetchAutomations(Date.now())
          setFlow(normalizePath(props.viewPath, '../No'))
        } else {
          change(next => {
            if (next.steps[next.current_index]) {
              next.steps[next.current_index].status = args.value

              next.current_index = next.current_index + 1
            }
          })
        }

        return
      }
      default: {
        await submit(
          value.steps.map((step, index) => {
            if (index === value.current_index) {
              return {
                ...step,
                status: 'completed',
              }
            }

            return step
          })
        )
        setRefetchAutomations(Date.now())
        setFlow(normalizePath(props.viewPath, '../No'))

        return
      }
    }
  }

  async function submit(steps) {
    let errorMessages = validate(steps)

    if (errorMessages?.length) {
      return notify(notifyError(errorMessages.join('\n')))
    }

    let preferences_step = steps.find(step => step.step_type === 'preferences')

    if (preferences_step) {
      let properties = {
        doctorId: preferences_step.properties.doctor_id,
        accountId: preferences_step.properties.account_id,
      }
      let mutationResponse = await executeUpdatePreferencesMutation({
        id: preferences_step.id,
        properties,
      })
      if (mutationResponse.error) {
        notify(
          notifyError(
            'Something went wrong. Please, try again or contact support if the problem persists.'
          )
        )

        return
      }
    }

    let mutationResponse = await executeCreateAutomationsMutation({
      connection_level,
      location_id,
      events: steps
        .filter(
          item => item.step_type === 'automation' && item.status === 'completed'
        )
        .map(({ event, required }) => ({ ...event, required })),
      app_id,
    })

    if (mutationResponse.error) {
      notify(
        notifyError(
          'Something went wrong. Please, try again or contact support if the problem persists.'
        )
      )

      return
    } else {
      notify(
        notifySuccess(`Connection to ${connection_title} is ready to use.`)
      )
    }

    submitConnection({ type: 'refetch' })

    return notify(notifySuccess('The application was connected successfully'))
  }
}
