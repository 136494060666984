export let FILTERS = {
  EMPLOYEE: 'EMPLOYER_FILTER',
  CARRIER: 'CARRIER_FILTER',
  NUMBER: 'NUMBER_FILTER',
}

export function getType(filters) {
  if (filters.filter_object_id !== '' && !filters.is_carrier) {
    return FILTERS.EMPLOYEE
  }

  if (filters.is_carrier) {
    return FILTERS.CARRIER
  }

  return FILTERS.NUMBER
}
