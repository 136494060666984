// this is an autogenerated file from App/Account/AutomatedEventNotifications/Content/Content/data.graphql
import { gql } from 'Data/Api'

export default gql`
subscription app__App_Account_AutomatedEventNotifications_Content_Content(
  $updated_at: timestamptz!
  $action_ids: [uuid!]!
) {
  events_action_executions_stream(
    cursor: { initial_value: { updated_at: $updated_at }, ordering: ASC }
    batch_size: 5
    where: {
      status: { _in: [failed, completed] }
      action_id: { _in: $action_ids }
    }
  ) {
    id
    action_id
    created_at
    status
    data
  }
}

`