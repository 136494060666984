// this is an autogenerated file from App/Account/Content/Settings/AdminContent/SettingsContent/TreatmentCaseCloser/Configuration/DefaultPaymentPlans/AddRuleset/Location/data.graphql
import { gql } from 'Data/Api'

export default gql`
query app__App_Account_Content_Settings_AdminContent_SettingsContent_TreatmentCaseCloser_Configuration_DefaultPaymentPlans_AddRuleset_Location($parent_company_id: uuid!) {
  vaxiom_sys_organizations(
    where: {
      level: { _eq: "location" }
      deleted: { _eq: false }
      _or: [
        { parent: { _id: { _eq: $parent_company_id } } }
        { parent: { parent: { _id: { _eq: $parent_company_id } } } }
      ]
      _not: { default_payment_plans: {} }
    }
    order_by: { name: asc }
  ) {
    _id
    name
    parent {
      _id
    }
  }
}

`