// this is an autogenerated file from DesignSystem/DataVaxiomEmployeeProfessionalsRelationshipsItem/Edit/Content/updateProfessionalMutation.graphql
import { gql } from 'Data/Api'

export default gql`
mutation app__DesignSystem_DataVaxiomEmployeeProfessionalsRelationshipsItem_Edit_Content(
  $person_id: numeric!
  $greeting: String
  $title: String
  $provider_id: numeric!
  $resource_type_id: numeric!
  $note_id: numeric
  $note: String
  $create_note: Boolean!
  $update_note: Boolean!
  $location_id: numeric!
) {
  update_vaxiom_persons_by_pk(
    pk_columns: { id: $person_id }
    _set: { greeting: $greeting, title: $title }
  ) {
    id
  }
  update_vaxiom_resources_by_pk(
    pk_columns: { id: $provider_id }
    _set: { resource_type_id: $resource_type_id }
  ) {
    id
  }

  update_vaxiom_notes(where: { id: { _eq: $note_id } }, _set: { note: $note })
    @include(if: $update_note) {
    returning {
      id
      note
    }
  }

  insert_vaxiom_notes_one(
    object: {
      note: $note
      target_type: "com.axpm.base.person.domain.Person"
      target_id: $person_id
      org_id: $location_id
      alert: false
    }
  ) @include(if: $create_note) {
    id
  }
}

`