import React from 'react'
// import { isValidUrl } from 'Data/validate.js'
import { useDataIsSubmitting, useDataValue } from 'Simple/Data.js'

import View from './view.js'

export default function Logic(props) {
  let isSubmitting = useDataIsSubmitting({
    viewPath: props.viewPath,
    context: 'automation',
  })

  let automation = useDataValue({
    viewPath: props.viewPath,
    context: 'automation',
  })
  return <View isDisabled={isSubmitting || !isEnabled(automation)} {...props} />
}

export function isEnabled(current_value) {
  if (!current_value.event_id) {
    return validateData(current_value)
  } else {
    return validateData(current_value)
  }
}

function validateData({ actions, automation_type }) {
  return (
    Array.isArray(actions) &&
    actions.length &&
    actions.every(action => isValid(action, automation_type))
  )
}

function isValid({ type, data }, automation_type) {
  switch (type) {
    case 'task_insert': {
      if (automation_type === 'automatic') {
        return data.title && data.description && data.task_basket_id
      } else {
        return true
      }
    }
    case 'status_update': {
      if (automation_type === 'automatic') {
        return data.new_status
      } else {
        let isValid = true

        if (data.is_subset) {
          isValid = !!data.subset_statuses.length
        }

        if (isValid && data.has_default_status) {
          isValid = !!data.default_status
        }

        return isValid
      }
    }
    case 'notification': {
      let isValid = true

      if (data.email_template.is_subset) {
        isValid = !!data.email_template.subset.length
      }

      if (isValid && data.email_template.has_default_value) {
        isValid = !!data.email_template.default_value
      }

      if (isValid && data.sms_template.is_subset) {
        isValid = !!data.sms_template.subset.length
      }

      if (isValid && data.sms_template.has_default_value) {
        isValid = !!data.sms_template.default_value
      }

      return isValid
    }
    case 'http': {
      // TODO: implement
      // if (!isValidUrl(data.request?.url) || !data.request?.method) {
      //   return false
      // }

      // This code is commented for now because in theory, the archive patient endpoint would have an empty body
      // if (['POST', 'PUT', 'PATCH'].includes(data.req?.method)) {
      //   return !!data.req?.body
      // }

      return true
    }
    case 'check_requirements':
      return (
        !!data?.requirements?.length &&
        !!data?.requirements?.every(isRequirementValid)
      )
    default:
      return false
  }
}

function isRequirementValid(requirement) {
  // if a task is created when skipping requirements, then a task title should be provided and a target task basket selected
  return (
    !requirement.create_task ||
    (requirement.task_title && requirement.task_basket_id)
  )
}
