import React, { useEffect } from 'react'
import { useDataSubmit, useDataValue } from 'Simple/Data.js'

import View from './view.js'

export default function Logic(props) {
  let event_update_timestamp = useDataValue({
    context: 'tab',
    path: 'event_update_timestamp',
    viewPath: props.viewPath,
  })
  let submit = useDataSubmit({
    context: 'events',
    viewPath: props.viewPath,
  })

  useEffect(() => {
    if (!event_update_timestamp) return
    submit({ type: 'reFetch' })
  }, [event_update_timestamp]) // eslint-disable-line react-hooks/exhaustive-deps

  return <View {...props} />
}
