import View from './view.js'
import React from 'react'

export default function Logic(props) {
  let text = props.item ? props.item.text : props.text
  let id = props.item ? props.item.id : props.id

  let isSelected = props.isSelected
    ? props.isSelected(id)
    : props.selected != null
    ? id === props.selected
    : !!props.item?.selected

  return (
    <View
      {...props}
      text={text}
      isSelected={isSelected}
      onClick={() => props.onClick(props.returnIndex ? props.index : id)}
    />
  )
}
