// this is an autogenerated file from App/Account/Content/Patients/Content/Content/Sections/ActiveTab/Profile/Content/NewProfile/Content/Main/ReferralsSection/Referrals/Referral/Kind/Template/data.graphql
import { gql } from 'Data/Api'

export default gql`
query app__App_Account_Content_Patients_Content_Content_Sections_ActiveTab_Profile_Content_NewProfile_Content_Main_ReferralsSection_Referrals_Referral_Kind_Template($template_id: numeric!) {
  vaxiom_referral_templates_by_pk(id: $template_id) {
    id
    free_type
  }
}

`