// this is an autogenerated file from App/Account/Content/Patients/Content/Content/Sections/ActiveTab/Profile/Content/NewProfile/Content/Insurance/AddAction/Content/Content/Filter/CarrierSelect/data.graphql
import { gql } from 'Data/Api'

export default gql`
query app__App_Account_Content_Patients_Content_Content_Sections_ActiveTab_Profile_Content_NewProfile_Content_Insurance_AddAction_Content_Content_Filter_CarrierSelect($organization_id: numeric!, $search_term: String!) {
  vaxiom_insurance_companies(
    where: {
      organization_id: { _eq: $organization_id }
      carrier_name: { _ilike: $search_term }
    }
    distinct_on: carrier_name
    limit: 200
    order_by: { carrier_name: asc }
  ) {
    id
    carrier_name
    installment_interval
    continuation_claims_required
  }
  vaxiom_employers(
    where: {
      organization_id: { _eq: $organization_id }
      name: { _ilike: $search_term }
    }
    distinct_on: name
    limit: 200
    order_by: { name: asc }
  ) {
    id
    name
  }
}

`