import { useDataSubmit } from 'Simple/Data.js'
import { useMutation } from 'Data/Api.js'
import {
  notifyError,
  notifySuccess,
  useNotifications,
} from 'Logic/Notifications.js'
import { equals } from 'Data/aggregate.js'

import mutation from './mutation.graphql.js'

/** @type {import('Simple/types.js').useDataOnSubmit} */
export default function useDataOnSubmit(props, data, reExecuteQuery) {
  let submit = useDataSubmit({
    context: 'tab',
    viewPath: props.viewPath,
  })
  let [, executeMutation] = useMutation(mutation)
  let [, notify] = useNotifications()

  return async function onSubmit({ value, originalValue, args, change }) {
    switch (args.type) {
      case 'refetch': {
        reExecuteQuery({ requestPolicy: 'network-only' })
        return
      }
      case 'update': {
        await updateAutomation(value, originalValue)
        return
      }

      default: {
        return
      }
    }
  }

  async function updateAutomation(value, original_value) {
    let { actions: original_actions, ...original_event } = original_value
    let { actions: current_actions, ...current_event } = value
    let eventsData = getEventDifferences(original_event, current_event)
    let actions = getActionsDifferences(
      original_actions,
      current_actions,
      current_event.event_id
    )

    let response = await executeMutation({
      ...eventsData,
      insert_actions: !!actions.action_inserts.length,
      action_inserts: actions.action_inserts,
      update_actions: !!actions.action_updates.length,
      action_updates: actions.action_updates,
      delete_actions: !!actions.action_deletes.length,
      action_deletes: actions.action_deletes,
    })

    if (response.error) {
      return notify(
        notifyError('An error has occurred when updating automation')
      )
    }

    notify(notifySuccess('Automation updated!'))

    submit({ type: 'back' })
  }
}

function generateActions(actions) {
  return Array.isArray(actions)
    ? { data: actions.map(({ id, type, data }) => ({ id, type, data })) }
    : {}
}

function getActionsDifferences(original_array, current_array, event_id) {
  let actions_data = current_array.reduce(
    (acc, current_element) => {
      if (!current_element.id) {
        // New Element
        acc.action_inserts.push({ ...current_element, event_id })
        acc.insert_actions = true
      } else {
        let original_element_index = acc.action_deletes.findIndex(
          element => element.id === current_element.id
        )

        if (original_element_index !== -1) {
          let original_element = acc.action_deletes[original_element_index]
          if (!equals(original_element, current_element)) {
            // Updated element
            let { id, action_executions, ...action } = current_element
            acc.action_updates.push({
              where: { id: { _eq: id } },
              _set: { ...action },
            })
            acc.update_actions = true

            // The element was updated, so don't need to be deleted
            acc.action_deletes.splice(original_element_index, 1)
          } else {
            // The element exists but it doesn't need to be deleted
            acc.action_deletes.splice(original_element_index, 1)
          }
        }
      }

      return acc
    },
    {
      insert_actions: false,
      update_actions: false,
      delete_actions: true,
      action_inserts: [],
      action_updates: [],
      action_deletes: [...original_array],
    }
  )

  actions_data.action_deletes = actions_data.action_deletes.map(({ id }) => id)

  actions_data.delete_actions = !!actions_data.action_deletes.length

  return actions_data
}

function generateData(event) {
  switch (event.event_type) {
    case 'APPOINTMENT_BOOKING_STATE_UPDATE':
      return {
        value_to: event.appointment_status,
        appointment_type_ids: event.appointment_type_ids,
        appointment_type_names: event.appointment_type_names,
      }
    case 'TREATMENT_STATUS_UPDATE':
      return {
        value_to: event.tx_status,
        status_name: event.tx_status_name,
      }
    case 'RESOURCE_INSERT':
      return {
        custom_resource_type: event.custom_resource_type,
        app_id: event.app_id,
      }
    default:
      return {}
  }
}

function processEventData(eventData) {
  return {
    id: eventData.event_id,
    name: eventData.event_name,
    organization_id: eventData.location_id,
    type: eventData.event_type,
    automation_type: eventData.automation_type,
    trigger_type: eventData.trigger_type,
    data: generateData(eventData),
    actions: generateActions(eventData.actions),
  }
}

function getEventDifferences(original_event, current_event) {
  let original_object = processEventData(original_event)
  let current_object = processEventData(current_event)
  return Object.keys(original_object)
    .filter(key => key !== 'organization_id')
    .reduce(
      (acc, key) => {
        let original_value = original_object[key]
        let current_value = current_object[key]

        if (!equals(original_value, current_value)) {
          acc.event[key] = current_value
          acc.update_event = true
        }

        return acc
      },
      {
        update_event: false,
        event_id: original_event.event_id,
        event: {},
      }
    )
}
