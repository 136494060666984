// this is an autogenerated file from App/Account/Content/Settings/AdminContent/SettingsContent/Onboarding/Inputs/data.graphql
import { gql } from 'Data/Api'

export default gql`
query app__App_Account_Content_Settings_AdminContent_SettingsContent_Onboarding_Inputs{
  onboarding_inputs(order_by: { created_at: desc }) {
    id
    type
    data_url
    created_at
    created_by_name
  }
}

`