import { useDataChange, useDataValue } from 'Simple/Data'

export function useOnClick(props) {
  let payors = useDataValue({
    context: 'payors',
    viewPath: props.viewPath,
  })
  let payor_id = useDataValue({
    context: 'selected',
    path: 'payor_id',
    viewPath: props.viewPath,
  })
  let change = useDataChange({
    context: 'selected',
    path: 'payor_id',
    viewPath: props.viewPath,
  })

  return function onClick() {
    let index = payors.findIndex(item => item.id === payor_id)
    change(payors[index + 1].id)
  }
}

export function isLast(payors, payor_id) {
  let index = payors.findIndex(item => item.id === payor_id)
  return index === payors.length - 1
}
