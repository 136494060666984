export function tableDataQuestions(questions) {
  return {
    columns: [
      {
        header: 'Question',
        accessorKey: 'question',
      },
      {
        header: 'Answer',
        accessorKey: 'answer',
      },
    ],
    data: questions,
  }
}
