// this is an autogenerated file from App/Account/Content/Patients/Content/Content/Sections/ActiveTab/Profile/Content/NewProfile/Content/ContactInfo/Email/Emails/Content/Email/Address/Related/Content/Popup/Content/Persons/Person/Relation/data.graphql
import { gql } from 'Data/Api'

export default gql`
query app__App_Account_Content_Patients_Content_Content_Sections_ActiveTab_Profile_Content_NewProfile_Content_ContactInfo_Email_Emails_Content_Email_Address_Related_Content_Popup_Content_Persons_Person_Relation($from_person_id: numeric!, $to_person_id: numeric!) {
  vaxiom_relationships(
    where: {
      from_person: { _eq: $from_person_id }
      to_person: { _eq: $to_person_id }
    }
  ) {
    id
    type
  }
}

`