import { useMemo } from 'react'
import { useDataValue } from 'Simple/Data.js'

/** @type {import('Simple/types.js').useDataTransform} */
export default function useDataTransform(props, data) {
  let location_id = useDataValue({
    viewPath: props.viewPath,
    context: 'global',
    path: 'current_location._id',
  })

  return useMemo(() => {
    let settings = data?.[0]?.data

    return {
      id: location_id,
      is_financials_managed_externally:
        settings?.is_financials_managed_externally || false,
      minimum_downpayment_amount: settings?.minimum_downpayment_amount || 0,
      default_downpayment_amount: settings?.default_downpayment_amount || 0,
      maximum_payment_plan_length: settings?.maximum_payment_plan_length || 60,
    }
  }, [data, location_id])
}
