// this is an autogenerated file from App/Account/Content/Patients/Content/Content/Sections/ActiveTab/TreatmentCard/Content/TreatmentCard/Treatment/data.graphql
import { gql } from 'Data/Api'

export default gql`
query app__App_Account_Content_Patients_Content_Content_Sections_ActiveTab_TreatmentCard_Content_TreatmentCard_Treatment($patient_id: uuid!) {
  vaxiom_tx_cards(
    where: { patient: { _id: { _eq: $patient_id } }, deleted: { _eq: false } }
    order_by: { latest_appointment_booking_start_time: desc_nulls_last }
  ) {
    id
    _id
    name
    tx_category {
      id
      name
    }
    patient {
      id
      _id
    }
    txs(where: { deleted: { _eq: false } }) {
      id
      _id
      has_unrealized_invoices
      name
      status
      is_active
      start_date
      estimated_start_date
      end_date
      estimated_end_date
      colors
      notes
      organization {
        id
        _id
        computed_parent_company_id
      }
    }
  }
}

`