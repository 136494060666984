import {
  useDataSubmit,
  useDataValue,
  useDataIsSubmitting,
} from 'Simple/Data.js'

export function useOnChange(props) {
  let submit = useDataSubmit({
    context: 'payor',
    viewPath: props.viewPath,
  })

  return function onChange(value) {
    submit({
      type: 'change-contract-template',
      document_template_id: value,
    })
  }
}

export function useDisabled(props) {
  let contract = useDataValue({
    context: 'payor',
    path: 'contract',
    viewPath: props.viewPath,
  })
  let isSubmitting = useDataIsSubmitting({
    context: 'payor',
    viewPath: props.viewPath,
  })

  return (
    contract.signed_date ||
    (contract.signature_request &&
      contract.signature_request?.status !== 'cancelled') ||
    isSubmitting
  )
}
