// this is an autogenerated file from App/Account/Content/Patients/Content/Content/Sections/Tabs/Comms/queryDiscussion.graphql
import { gql } from 'Data/Api'

export default gql`
query app__App_Account_Content_Patients_Content_Content_Sections_Tabs_Comms($patient_id: String!) {
  comms_threads(
    where: {
      type: { _eq: internal }
      resources_ids: { _has_keys_any: [$patient_id] }
    }
  ) {
    id
  }
}

`