import { useDataValue, useDataChange } from 'Simple/Data.js'
import { numberMoney } from 'Data/format.js'
import { useSetFlowTo, normalizePath } from 'Simple/Flow.js'
import React, { useMemo } from 'react'
import View from './view.js'
import Check from 'DesignSystem/Svgs/Check/view.js'

let columns = [
  {
    header: 'Location',
    accessorKey: 'organization.name',
  },
  {
    header: 'Name',
    accessorKey: 'name',
  },
  {
    header: 'Fee',
    accessorKey: 'fee',
    cell: cell => (cell.getValue() ? numberMoney(cell.getValue()) : null),
  },
  {
    header: 'Length (weeks)',
    accessorKey: 'length_in_weeks',
  },
  {
    header: 'Insurance Code',
    accessorKey: 'insurance_code.code',
  },
  {
    header: 'Tx Plan Group',
    accessorKey: 'tx_plan_group.name',
  },
  {
    header: 'Category',
    accessorKey: 'tx_category.name',
  },
  {
    header: 'Deleted',
    accessorKey: 'deleted',
    cell: cell => (cell.getValue() ? <Check /> : null),
  },
]

export default function Logic(props) {
  let txPlanTemplates = useDataValue({
    context: 'tx_plan_templates',
    viewPath: props.viewPath,
  })

  let selectedChange = useDataChange({
    context: 'selected',
    path: 'tx_plan_template_id',
    viewPath: props.viewPath,
  })

  let setFlowTo = useSetFlowTo(props.viewPath)

  let value = useMemo(() => {
    return {
      columns,
      data: txPlanTemplates || [],
    }
  }, [txPlanTemplates])

  function onRowClick(row) {
    selectedChange(row.original.id)
    setFlowTo(
      normalizePath(props.viewPath, '../../../../TxPlanTemplate/Content/Edit')
    )
  }

  return (
    <View value={value} onRowClick={onRowClick} viewPath={props.viewPath} />
  )
}
