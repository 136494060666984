// this is an autogenerated file from App/Account/Content/Patients/Content/Content/Sections/ActiveTab/TreatmentCard/Content/TreatmentCard/Summary/data.graphql
import { gql } from 'Data/Api'

export default gql`
query app__App_Account_Content_Patients_Content_Content_Sections_ActiveTab_TreatmentCard_Content_TreatmentCard_Summary($treatment_card_id: uuid!) {
  vaxiom_tx_cards(where: { _id: { _eq: $treatment_card_id } }) {
    id
    _id
    name
    tx_category {
      id
      name
    }
    txs(where: { deleted: { _eq: false } }) {
      id
      _id
      name
      status
      is_active
      start_date
      has_unrealized_invoices
      estimated_start_date
      end_date
      estimated_end_date
      colors
      notes
      treatment_plan_action
      organization {
        id
        _id
        computed_parent_company_id
      }
      is_contract_applied
      is_treatment_plan_applied
      tx_plan {
        id
        _id
        name
      }
      tx_plans {
        id
        _id
        treatment_requests(
          where: { status: { _in: [active, sent] } }
          order_by: { created_at: desc }
        ) {
          id
          status
          created_at
          person {
            id
            first_name
            last_name
          }
          contact_method_association {
            id
            contact_method {
              id
              dtype
              phone {
                number
              }
              email {
                address
              }
            }
          }
        }
      }
    }
  }
}

`