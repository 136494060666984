import { useDataChange } from 'Simple/Data.js'

export default function useDataOnChange(props) {
  let change = useDataChange({
    context: 'tab',
    path: 'selected.topbar_appointment_templates',
    viewPath: props.viewPath,
  })

  return function onChange(value) {
    change(value.selected_appointment_templates)
  }
}
