// this is an autogenerated file from App/Account/Content/Settings/AdminContent/SettingsContent/TreatmentCaseCloser/Configuration/MaxDiscount/AddRuleset/mutation.graphql
import { gql } from 'Data/Api'

export default gql`
mutation app__App_Account_Content_Settings_AdminContent_SettingsContent_TreatmentCaseCloser_Configuration_MaxDiscount_AddRuleset($organization_id: uuid!, $value: numeric!) {
  insert_treatments_max_discounts_one(
    object: { organization_id: $organization_id, value: $value }
  ) {
    id
  }
}

`