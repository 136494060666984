/** @type {import('Simple/types.js').useDataOnSubmit} */
export default function useDataOnSubmit(props) {
  let onClose = useOnClose(props)

  return async function onSubmit({ args, ...rest }) {
    switch (args.type) {
      case 'close':
        return onClose({ args, ...rest })
      default: {
      }
    }
  }
}

/** @type {import('Simple/types.js').useDataOnSubmit} */
function useOnClose(props) {
  return function onClose({ change }) {
    change(next => {
      next.selected.showInfoDialog = false
      next.selected.showActionsDialog = false
    })

    return true
  }
}
