import { useEffect } from 'react'
import View from './view.js'
import { useDataChange, useDataValue } from 'Simple/Data.js'
import { useSetFlowTo, normalizePath } from 'Simple/Flow.js'

export default function Logic(props) {
  let selected_tab = useDataValue({
    context: 'tab',
    viewPath: props.viewPath,
    path: 'selected.tab',
  })

  let change = useDataChange({
    context: 'tab',
    viewPath: props.viewPath,
    path: 'selected.tab',
  })

  let setFlowTo = useSetFlowTo(props.viewPath)

  useEffect(() => {
    /**
     * 1. If the selected tab is not 'schedules', we select that
     * 2. Show the sidebar, if it is not showing
     */
    if (selected_tab !== 'schedules') {
      change('schedules')
    }

    setFlowTo(normalizePath(props.viewPath, '../../Menu/Content'))
  }, [])

  return <View {...props} />
}
