import { useDataSubmit } from 'Simple/Data.js'

export function useOnChange(props) {
  let submit = useDataSubmit({
    context: 'match',
    viewPath: props.viewPath,
  })
  return function onChange() {
    submit({ type: 'TOGGLE_SELECTED' })
  }
}
