import { useDataChange, useDataValue } from 'Simple/Data.js'
export function useOnClick(props) {
  let change = useDataChange({
    context: 'carrier',
    viewPath: props.viewPath,
  })

  let phone = useDataValue({
    context: 'phone',
    viewPath: props.viewPath,
  })

  return function onClick() {
    change(next => {
      next.insurance_company_phone_associations =
        next.insurance_company_phone_associations.filter(item => {
          return !(item.id === phone.id)
        })
    })
  }
}
