import React from 'react'

import View from './view.js'

export default function Logic(props) {
  return (
    <View
      {...props}
      ignoreClickOutsideIf={event =>
        event?.target?.dataset?.['viewPath']?.includes?.(props.viewPath)
      }
    />
  )
}
