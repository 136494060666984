import mutation from './mutation.graphql.js'
import { useMutation, useQuery } from 'Data/Api'
import { useDataValue, useDataChange } from 'Simple/Data.js'
import { formatDate } from 'Simple/Data/format.js'

import query from './category_query.graphql.js'

import { useSetFlowTo, normalizePath } from 'Simple/Flow.js'
import {
  notifySuccess,
  notifyError,
  useNotifications,
} from 'Logic/Notifications.js'
/** @type {import('Simple/types.js').useDataOnSubmit} */

function useOnSave(props) {
  let setFlowTo = useSetFlowTo(props.viewPath)
  let [, executeMutation] = useMutation(mutation)

  let [{ data }] = useQuery({ query })

  let [, notify] = useNotifications()

  let insurance_plan_id = useDataValue({
    viewPath: props.viewPath,
    context: 'filter',
    path: 'assign_insurance_id',
  })

  let patient_id = useDataValue({
    viewPath: props.viewPath,
    context: 'profile_patient',
    path: 'id',
  })

  return async function onSubmit(value) {
    try {
      let tx_category_id = data.vaxiom_tx_categories?.[0].id || 1
      let mutationResponse = await executeMutation({
        insurance_subscription: {
          ...value.insured.insurance_subscription,
          insurance_plan_id,
        },
        insured_patient_id: patient_id,
        ortho_insured: { ...value.ortho_insured },
        tx_category_id: tx_category_id,
      })
      if (mutationResponse.error) {
        notify(notifyError(`Cannot assign insurance plan. Please try again.`))
        return
      }

      notify(notifySuccess('Insurance plan assigned successfully.'))
      setFlowTo(normalizePath(props.viewPath, '../../../../No'))
    } catch (err) {
      notify(notifyError(`Cannot assign insurance plan. Please try again.`))
    }
    return
  }
}

export default function useDataOnSubmit(props, data) {
  let setFlowTo = useSetFlowTo(props.viewPath)
  let onSave = useOnSave(props)
  let changeIns = useDataChange({ context: 'filter', viewPath: props.viewPath })
  return async function onSubmit({ value, originalValue, args, change }) {
    switch (args?.type) {
      case 'cancel': {
        changeIns(next => {
          next.assign_insurance = null
          next.assign_insurance_id = null
        })
        setFlowTo(normalizePath(props.viewPath, '../No'))
        break
      }
      case 'submit': {
        // prompt if value changed
        if (value.ortho_insured.used_lifetime_coverage > 0) {
          setFlowTo(normalizePath(props.viewPath, 'Confirmation/Content'))
          return
        }
        return onSave(value)
      }
      case 'save': {
        return onSave(value)
      }
      case 'save_update': {
        return onSave({
          ...value,
          ortho_insured: { ...value.ortho_insured, last_verified: new Date() },
        })
      }
      default: {
        return
      }
    }
  }
}
