import mutation from './mutation.graphql.js'
import {
  notifySuccess,
  notifyError,
  useNotifications,
} from 'Logic/Notifications.js'
import { useSetFlowTo, normalizePath } from 'Simple/Flow.js'
import { useMutation } from 'Data/Api'
import { formatDate } from 'Simple/Data/format.js'

function useOnSave(props) {
  let [, executeMutation] = useMutation(mutation)
  let [, notify] = useNotifications()
  let setFlowTo = useSetFlowTo(props.viewPath)
  return async function onSubmit(value) {
    let mutationResponse = await executeMutation({
      id: value.ortho_insured._id,
      used_lifetime_coverage: value.ortho_insured.used_lifetime_coverage,
      year_deductible_paid_last: value.ortho_insured.year_deductible_paid_last,
      insurance_subscription_id: value.insured.insurance_subscription.id,
      employer_id: value.insured.insurance_subscription.employer_id,
      enrollment_date: value.insured.insurance_subscription.enrollment_date,
      member_id: value.insured.insurance_subscription.member_id,
      person_id: value.insured.insurance_subscription.person_id,
      notes: value.insured.insurance_subscription.notes,
      last_verified: value.ortho_insured.last_verified,
    })

    if (mutationResponse.error) {
      notify(notifyError(`Cannot edit subscription. Please try again.`))
      return
    }
    notify(notifySuccess(`Subscription edited successfully.`))
    setFlowTo(normalizePath(props.viewPath, '../../No'))
  }
}

/** @type {import('Simple/types.js').useDataOnSubmit} */
export default function useDataOnSubmit(props, data) {
  let onSave = useOnSave(props)
  let setFlowTo = useSetFlowTo(props.viewPath)
  return async function onSubmit({ value, originalValue, args, change }) {
    switch (args?.type) {
      case 'cancel': {
        setFlowTo(normalizePath(props.viewPath, '../../No'))
        return
      }
      case 'submit': {
        if (
          value.ortho_insured.last_verified ===
          originalValue.ortho_insured.last_verified
        ) {
          setFlowTo(
            normalizePath(props.viewPath, 'Content/Confirmation/Content')
          )
          return
        }
      }
      case 'save': {
        return onSave(value)
      }
      case 'save_update': {
        let last_verified = formatDate(
          new Date().toISOString(),
          'iso',
          'MM/dd/yyyy'
        )
        return onSave({
          ...value,
          ortho_insured: { ...value.ortho_insured, last_verified },
        })
      }
      default: {
        return
      }
    }
  }
}
