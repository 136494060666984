import { useDataChange } from 'Simple/Data.js'
export function useOnChange(props) {
  let change = useDataChange({
    context: 'new_relation',
    viewPath: props.viewPath,
  })

  return function onChange(value) {
    change(next => {
      next.selected_phone = null
      next.phone.description = value
    })
  }
}
