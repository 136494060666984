// this is an autogenerated file from App/Account/Content/Patients/Content/Content/Sections/ActiveTab/Profile/Content/NewProfile/patientMutation.graphql
import { gql } from 'Data/Api'

export default gql`
mutation app__App_Account_Content_Patients_Content_Content_Sections_ActiveTab_Profile_Content_NewProfile($patient_id: uuid!, $notes: String!) {
  update_vaxiom_patients(
    where: { _id: { _eq: $patient_id } }
    _set: { notes: $notes }
  ) {
    returning {
      id
    }
  }
}

`