import mutation_update_patient from './patientMutation.graphql.js'
import mutation_update_person from './personMutation.graphql.js'
import mutation_update_txs from './txsMutation.graphql.js'
import mutation_edit_preferences from './editPreferencesMutation.graphql.js'
import mutation_add_comms_preferences from './addCommsPreferencesMutation.graphql.js'
import mutation_remove_comms_preferences from './removeCommsPreferencesMutation.graphql.js'
import {
  notifyError,
  useNotifications,
  notifySuccess,
} from 'Logic/Notifications.js'
import { useMutation } from 'Data/Api'
import { name, birthday } from 'Data/validate.js'

function getDifference(a, b) {
  // eslint-disable-next-line compat/compat
  return Object.fromEntries(
    // eslint-disable-next-line compat/compat
    Object.entries(b).filter(([key, val]) => key in a && a[key] !== val)
  )
}

export default function useDataOnSubmit(props, data, reExecuteQuery) {
  let [, notify] = useNotifications()

  let [, executeMutationPatient] = useMutation(mutation_update_patient)
  let [, executeMutationPerson] = useMutation(mutation_update_person)
  let [, executeMutationTxs] = useMutation(mutation_update_txs)
  let [, executeEditPreferencesMutation] = useMutation(
    mutation_edit_preferences
  )
  let [, executeAddCommsPreferencesMutation] = useMutation(
    mutation_add_comms_preferences
  )
  let [, executeRemoveCommsPreferencesMutation] = useMutation(
    mutation_remove_comms_preferences
  )

  return async function onSubmit({
    value: patient,
    originalValue: originalPatient,
    args,
    change,
  }) {
    switch (args?.type) {
      case 'cancel': {
        change(originalPatient)
        return
      }

      default: {
        if (
          !patient.person.first_name ||
          !patient.person.last_name ||
          !patient.person.gender ||
          !patient.person.birth_date
        ) {
          return true
        }

        if (
          !name(patient.person.first_name) ||
          !name(patient.person.last_name) ||
          !birthday(patient.person.birth_date)
        ) {
          return true
        }

        let mutationPromisePatient
        let mutationPromisePerson
        let mutationPromiseTxs
        let mutationPromisePreferredContactMethod
        let mutationsPromiseAppointmentReminders = []
        let diff = getDifference(originalPatient, patient)
        if (diff.person) {
          mutationPromisePerson = executeMutationPerson({
            person_id: patient.person._id,
            ...patient.person,
            ssn: patient.person.ssn?.trim() === '' ? null : patient.person.ssn,
          })
        }
        if (diff.txs) {
          let diffTxs = getDifference(originalPatient.txs, patient.txs)
          let updates = Object.values(diffTxs).map(value => {
            return {
              where: { id: { _eq: value.id } },
              _set: { organization_id: value.organization_id },
            }
          })
          mutationPromiseTxs = executeMutationTxs({
            updates,
          })
        }

        if (diff.notes !== undefined && diff.notes !== null) {
          mutationPromisePatient = executeMutationPatient({
            patient_id: patient._id,
            notes: diff.notes,
          })
        }

        if (diff.preferred_contact_method) {
          mutationPromisePreferredContactMethod =
            executeEditPreferencesMutation({
              id: diff.preferred_contact_method,
              preference: 'ANY',
              person_id: patient.person.id,
            })
        }

        if (diff.appointment_reminders) {
          mutationsPromiseAppointmentReminders = Object.keys(
            diff.appointment_reminders
          ).map(key => {
            let k = parseInt(key)
            if (diff.appointment_reminders[k]) {
              return executeAddCommsPreferencesMutation({
                patient_id: patient.id,
                association_id: k,
              })
            } else {
              return executeRemoveCommsPreferencesMutation({
                comms_pref_id: patient.communication_preferences.find(
                  cp => cp.contact_method_association_id === k
                ).id,
              })
            }
          })
        }

        let [
          mutationResponsePerson,
          mutationResponseTxs,
          mutationResponsePatient,
          mutationResponsePreferredContactMethod,
          mutationResponseAppointmentReminders,
        ] = await Promise.all(
          [
            mutationPromisePerson,
            mutationPromiseTxs,
            mutationPromisePatient,
            mutationPromisePreferredContactMethod,
            ...mutationsPromiseAppointmentReminders,
          ].filter(Boolean)
        )

        if (
          mutationResponsePatient?.error ||
          mutationResponsePerson?.error ||
          mutationResponseTxs?.error ||
          mutationResponsePreferredContactMethod?.error ||
          mutationResponseAppointmentReminders?.error
        ) {
          notify(
            notifyError(
              'Something went wrong. Please, try again or contact support if the problem persists.'
            )
          )
          return
        }
        reExecuteQuery({ requestPolicy: 'network-only' })
        notify(notifySuccess('Profile updated!'))
      }
    }
  }
}
