// this is an autogenerated file from App/Account/Content/Settings/AdminContent/SettingsContent/FeatureFlags/data.graphql
import { gql } from 'Data/Api'

export default gql`
query app__App_Account_Content_Settings_AdminContent_SettingsContent_FeatureFlags{
  vaxiom_sys_organizations(
    order_by: { name: asc }
    where: { level: { _in: ["location", "parent"] } }
  ) {
    id
    _id
    name
    level
    application_properties(
      where: {
        message_key: {
          _in: [
            "new.reporting.default.enabled"
            "new.marketing-analytics.enabled"
            "new.calendar.enabled"
            "new.calendar.default.enabled"
            "new.tx.planning.enabled"
            "new.tx.planning.default.enabled"
          ]
        }
      }
    ) {
      message_key
      message_value
      organization_id
    }
  }
}

`