import { useDataValue } from 'Simple/Data.js'

/** @type {import('Simple/types.js').useDataConfiguration} */
export default function useDataConfiguration(props, data) {
  let treatment_plan_id = useDataValue({
    context: 'treatment_plan',
    path: '_id',
    viewPath: props.viewPath,
  })

  return {
    variables: {
      treatment_plan_id,
    },
  }
}
