import { useDataSubmit } from 'Simple/Data.js'

export function useOnClick(props) {
  let submit = useDataSubmit({
    context: 'insurance_subscription',
    viewPath: props.viewPath,
  })

  return async function onClick() {
    submit({ type: 'reactivate' })
    return
  }
}
