// this is an autogenerated file from DesignSystem/TimegridScheduleNote/mutation-add-schedule-note.graphql
import { gql } from 'Data/Api'

export default gql`
mutation app__DesignSystem_TimegridScheduleNote($objects: [vaxiom_schedule_notes_insert_input!]!) {
  insert_vaxiom_schedule_notes(objects: $objects) {
    affected_rows
    returning {
      id
    }
  }
}

`