// this is an autogenerated file from App/Account/Content/Patients/Content/Content/Sections/ActiveTab/Images/Content/Content/Content/Content/AlignItero/Content/data.graphql
import { gql } from 'Data/Api'

export default gql`
query app__App_Account_Content_Patients_Content_Content_Sections_ActiveTab_Images_Content_Content_Content_Content_AlignItero_Content($parent_company_id: uuid!, $location_id: uuid!) {
  apps_connection_resources(
    where: {
      _or: [
        { resource_id: { _eq: $parent_company_id }, type: { _eq: COMPANY } }
        { resource_id: { _eq: $location_id }, type: { _eq: LOCATION } }
      ]
      # TODO: ideally it should also check the app_id
      connection: {
        app: { metadata: { _contains: { tags: ["itero"] } } }
        status: { _eq: active }
      }
      status: { _eq: enabled }
    }
  ) {
    id
    resource_id
    connection {
      id
      name
      app_id
    }
  }
  apps_connection_resource_properties(
    where: {
      _or: [
        { resource_id: { _eq: $parent_company_id }, type: { _eq: COMPANY } }
        { resource_id: { _eq: $location_id }, type: { _eq: LOCATION } }
      ]
      # TODO: ideally it should also check the app_id
      connection: {
        app: { metadata: { _contains: { tags: ["itero"] } } }
        status: { _eq: active }
      }
    }
  ) {
    id
    resource_id
    connection_id
    properties
  }
}

`