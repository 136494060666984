import React, { useEffect, useMemo } from 'react'
import { useDataValue, useDataChange, useDataSubmit } from 'Simple/Data.js'
import { dateISOToDateAndTime } from 'Data/format.js'

import Connection from './Connection/index.js'
import Locations from './Locations/index.js'
import Actions from './Actions/index.js'

import View from './view.js'

export default function Logic(props) {
  let connections = useDataValue({
    context: 'connections',
    viewPath: props.viewPath,
  })

  let refetch = useDataValue({
    context: 'tab',
    path: 'selected.refetch_connections',
    viewPath: props.viewPath,
  })
  let setRefetchConnections = useDataChange({
    context: 'tab',
    path: 'selected.refetch_connections',
    viewPath: props.viewPath,
  })
  let submit = useDataSubmit({
    context: 'connections',
    viewPath: props.viewPath,
  })

  useEffect(() => {
    if (!refetch) return

    submit({ type: 'refetch' })
    setRefetchConnections(null)
  }, [refetch]) // eslint-disable-line react-hooks/exhaustive-deps
  // ignore submit

  let tableData = useMemo(
    () => ({
      columns: [
        {
          header: 'Connection',
          cell: cell => (
            <Connection
              value={cell.row.original}
              viewPath={`${props.viewPath}/Connection(${cell.row.original.id})`}
            />
          ),
        },
        {
          header: 'Used by',
          cell: cell => (
            <Locations
              value={cell.row.original}
              viewPath={`${props.viewPath}/Locations(${cell.row.original.id})`}
            />
          ),
        },
        {
          header: 'Added by',
          accessorKey: 'user',
          cell: cell =>
            cell.getValue()
              ? `${cell.getValue().vaxiom_person.first_name} ${
                  cell.getValue().vaxiom_person.last_name
                }`
              : '-',
        },
        {
          header: 'Added on',
          accessorKey: 'created_at',
          cell: cell => dateISOToDateAndTime(cell.getValue()),
        },
        {
          // Actions
          header: '',
          accessorKey: 'name',
          cell: cell => (
            <Actions
              value={cell.row.original}
              viewPath={`${props.viewPath}/Actions(${cell.row.original.id})`}
            />
          ),
        },
      ],
      data: connections ?? [],
    }),
    [connections]
  )

  return <View viewPath={props.viewPath} tableData={tableData} />
}
