// this is an autogenerated file from App/Account/Content/Patients/Content/Content/Sections/ActiveTab/TreatmentCard/Content/TreatmentCard/Events/Content/Content/Events/Content/Compact/Past/Content/Content/TreatmentFieldCell/data.graphql
import { gql } from 'Data/Api'

export default gql`
query app__App_Account_Content_Patients_Content_Content_Sections_ActiveTab_TreatmentCard_Content_TreatmentCard_Events_Content_Content_Events_Content_Compact_Past_Content_Content_TreatmentFieldCell(
  $appointment_id: uuid!
  $treatment_field_id: numeric!
) {
  appointments_treatment_field_value(
    args: {
      _appointment_id: $appointment_id
      _field_definition_id: $treatment_field_id
    }
  ) {
    tx_card_field_definition_id
    appointment_id
    tracked_appointment_id
    # value set explicitly for the current appointment
    values {
      appointment_id
      field_definition_id
      field_option_id
      field_option {
        id
        name: value
      }
      text_value
    }
    # value inferred from previous appointments (only relevant for tracked fields)
    tracked_values {
      appointment_id
      field_definition_id
      field_option_id
      field_option {
        id
        name: value
      }
      text_value
    }
  }
}

`