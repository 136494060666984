import { useDataChange, useDataValue } from 'Simple/Data.js'
import { useSetFlowTo, normalizePath } from 'Simple/Flow.js'
export function useOnClick(props) {
  let change = useDataChange({
    viewPath: props.viewPath,
    context: 'city_search',
    path: 'term',
  })
  let search_results = useDataValue({
    viewPath: props.viewPath,
    context: 'cities',
  })

  let selected_id = useDataValue({
    viewPath: props.viewPath,
    context: 'city',
    path: 'id',
  })
  let changeAddressCity = useDataChange({
    context: 'new_address',
    viewPath: props.viewPath,
    path: 'city',
  })
  let setFlowTo = useSetFlowTo(props.viewPath)
  return function onClick(value) {
    let selected = search_results.find(item => item.id === selected_id)
    change(selected.value)
    changeAddressCity(selected.value)
    setFlowTo(normalizePath(props.viewPath, '../../No'))
  }
}
