import { useMemo } from 'react'
import { useDataValue } from 'Simple/Data'

/** @type {import('Simple/types.js').useDataTransform} */
export default function useDataTransform(props, data) {
  let insurance_subscription = useDataValue({
    context: 'assigned_insurance',
    viewPath: props.viewPath,
    path: 'insurance_subscription',
  })
  return useMemo(() => {
    if (!data) return insurance_subscription

    return insurance_subscription
  }, [data, insurance_subscription])
}
