import { v4 as uuid } from 'uuid'
import { useSetFlowTo, normalizePath } from 'Simple/Flow.js'
import { useDataValue, useDataSubmit, useDataChange } from 'Simple/Data.js'
import { useMutation } from 'Data/Api.js'
import { notifyError, useNotifications } from 'Logic/Notifications.js'
import { URL_HUB_SIGN } from 'Data/constants.js'

import mutation from './mutation.graphql.js'

let MESSAGE = {
  INAPP:
    'Your signature is requested on a contract for patient {Patient_Name}: {Link}',
  PHONE: 'You have a signature request for {Patient_Name}: {Link}',
}

export function useOnSubmit(props) {
  let submit = useDataSubmit({
    context: 'tab',
    viewPath: props.viewPath,
  })
  let setFlowTo = useSetFlowTo(props.viewPath)

  return function onSubmit(action) {
    if (action.type === 'showQrCode') {
      setFlowTo(normalizePath(props.viewPath, '../../Content/Content'))
    } else {
      submit(action)
    }
  }
}

export function useOnSubmitPre(props) {
  let payor = useDataValue({
    context: 'payor',
    viewPath: props.viewPath,
  })
  let patient_person = useDataValue({
    context: 'patient',
    path: 'person',
    viewPath: props.viewPath,
  })
  let change = useDataChange({
    context: 'payor',
    viewPath: props.viewPath,
  })
  let [, notify] = useNotifications()
  let [, executeMutation] = useMutation(mutation)

  return async function onSubmitPre({ args, props, value }) {
    let mutationResponse = await executeMutation({
      signature_request_id: uuid(),
      contract_id: payor.contract.id,
      document_template_id: payor.contract.document_template_id,
      template_file_key: payor.contract.html_file_uuid,
      initial_document_data: {},
      signatory_person_id: value.send_to_person_id,
      allowed_birth_dates: {
        PATIENT: patient_person.birth_date,
        PAYER: payor.person.birth_date,
      },
      resource_metadata: {
        id: payor.contract.id,
        table: 'tx_contracts',
        schema: 'vaxiom',
      },
      signed_document_data: {},
      required_document_data: {},
    })

    if (mutationResponse.error) {
      notify(notifyError(`Couldn't send the contract for signature`))
      return false
    }

    // update the context to show the signature
    change(next => {
      next.contract.signature_request_id =
        mutationResponse.data.insert_documents_signature_requests_one.id
      next.contract.signature_request =
        mutationResponse.data.insert_documents_signature_requests_one
    })

    let { id } = mutationResponse.data.insert_documents_signature_requests_one

    let message =
      value.contact_method.type === 'Phone' ? MESSAGE.PHONE : MESSAGE.INAPP

    return {
      message: message
        .replace(
          '{Patient_Name}',
          `${patient_person.first_name} ${patient_person.last_name}`
        )
        .replace('{Link}', `${URL_HUB_SIGN}/${id}`),
      tags: ['documents.signature_requests', 'request', id],
    }
  }
}
