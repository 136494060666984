import { useMutation } from 'Data/Api.js'
import mutationCheckInPatient from './mutation-check-in-patient.graphql.js'
import mutationSeat from './mutation-seat.graphql.js'
import mutationUnseat from './mutation-unseat.graphql.js'
import mutationCheckout from './mutation-check-out.graphql.js'
import mutationRollabackAppointmentState from './mutation-rollback-appointment-state.graphql.js'
import {
  notifyError,
  notifySuccess,
  useNotifications,
} from 'Logic/Notifications.js'
import { normalizePath, useSetFlowTo } from 'Simple/Flow.js'
import { useDataValue, useDataSubmit } from 'Simple/Data.js'

/** @type {import('Simple/types.js').useDataOnSubmit} */
export default function useDataOnSubmit(props, data) {
  let onActionCheckIn = useDataOnActionCheckIn(props, data)
  let onActionSeat = useDataOnActionSeat(props, data)
  let onActionUnseat = useDataOnActionUnseat(props, data)
  let onActionCheckout = useDataOnActionCheckOut(props, data)
  let onActionCancelCheckout = useDataOnActionCancelCheckout(props, data)
  let onActionSchedule = useDataOnActionSchedule(props, data)

  return async function onSubmit({ args, ...params }) {
    switch (args.type) {
      case 'checkIn': {
        await onActionCheckIn({ args, ...params })
        break
      }
      case 'seat': {
        await onActionSeat({ args, ...params })
        break
      }
      case 'unseat': {
        await onActionUnseat({ args, ...params })
        break
      }
      case 'checkOut': {
        await onActionCheckout({ args, ...params })
        break
      }
      case 'cancelCheckOut': {
        await onActionCancelCheckout({ args, ...params })
        break
      }
      case 'reschedule':
      case 'schedule': {
        await onActionSchedule({ args, ...params })
        break
      }
      default: {
      }
    }
  }
}

/** @type {import('Simple/types.js').useDataOnSubmit} */
function useDataOnActionCheckIn(props, data) {
  let id = useDataValue({
    context: 'event',
    viewPath: props.viewPath,
    path: 'appointment.booking.0._id',
  })
  let [, executeMutation] = useMutation(mutationCheckInPatient)
  // @ts-ignore
  let [, notify] = useNotifications()

  return async function onActionCheckIn({ value }) {
    let mutationResponse = await executeMutation({
      id,
    })
    if (mutationResponse.error) {
      notify(
        notifyError(
          `There was a problem checking the patient in. Please try again.`
        )
      )
      return
    }

    notify(notifySuccess(`The patient was successfully checked in.`))
  }
}

/** @type {import('Simple/types.js').useDataOnSubmit} */
function useDataOnActionSeat(props, data) {
  let id = useDataValue({
    context: 'event',
    viewPath: props.viewPath,
    path: 'appointment.booking.0._id',
  })

  let [, executeMutation] = useMutation(mutationSeat)
  // @ts-ignore
  let [, notify] = useNotifications()

  return async function onActionSeat({ value, args }) {
    if (!args.chair_id || !args.provider_id || !args.assistant_id) {
      return true
    }

    let mutationResponse = await executeMutation({
      id,
      provider_id: args.provider_id,
      assistant_id: args.assistant_id,
      chair_id: args.chair_id,
    })
    if (mutationResponse.error) {
      notify(
        notifyError(
          `There was a problem seating the patient. Please try again.`
        )
      )
      return
    }

    notify(notifySuccess(`The patient was successfully seated.`))
  }
}

/** @type {import('Simple/types.js').useDataOnSubmit} */
function useDataOnActionUnseat(props, data) {
  let id = useDataValue({
    context: 'event',
    viewPath: props.viewPath,
    path: 'appointment.booking.0._id',
  })

  let [, executeMutation] = useMutation(mutationUnseat)
  // @ts-ignore
  let [, notify] = useNotifications()

  let setFlowTo = useSetFlowTo(props.viewPath)

  return async function onActionUnseat({ value, args }) {
    let mutationResponse = await executeMutation({
      id,
      skip_required_fields: args?.skip_required_fields || false,
    })

    if (mutationResponse.error) {
      notify(
        notifyError('Cannot unseat selected appointment. Please try again.')
      )
      return
    }

    if (
      mutationResponse.data.vaxiom_appointment_booking_unseat.status ===
      'missing_required_treatment_fields'
    ) {
      setFlowTo(
        normalizePath(
          props.viewPath,
          'Unseat/Content/MissingRequiredTreatmentFieldsDialog/Content'
        )
      )
    }

    notify(notifySuccess(`The patient was successfully unseated.`))
  }
}

/** @type {import('Simple/types.js').useDataOnSubmit} */
function useDataOnActionCheckOut(props, data) {
  let id = useDataValue({
    context: 'event',
    viewPath: props.viewPath,
    path: 'appointment.booking.0._id',
  })

  let [, executeMutation] = useMutation(mutationCheckout)
  // @ts-ignore
  let [, notify] = useNotifications()

  return async function onActionCheckOut({ value, args }) {
    let mutationResponse = await executeMutation({
      id,
    })

    if (mutationResponse.error) {
      notify(notifyError('Cannot checkout patient. Please try again.'))
      return
    }

    notify(notifySuccess(`The patient was successfully checked out.`))
  }
}

/** @type {import('Simple/types.js').useDataOnSubmit} */
function useDataOnActionCancelCheckout(props, data) {
  let id = useDataValue({
    context: 'event',
    viewPath: props.viewPath,
    path: 'appointment.booking.0._id',
  })

  let [, executeMutation] = useMutation(mutationRollabackAppointmentState)
  // @ts-ignore
  let [, notify] = useNotifications()

  return async function onActionCancelCheckout({ value }) {
    let mutationResponse = await executeMutation({
      id,
    })
    if (mutationResponse.error) {
      notify(
        notifyError(
          `There was a problem cancelling the appointment booking state. Please try again.`
        )
      )
      return
    }

    notify(notifySuccess(`The checkout was successfully cancelled.`))
  }
}

/** @type {import('Simple/types.js').useDataOnSubmit} */
function useDataOnActionSchedule(props, data) {
  let appointment_id = useDataValue({
    viewPath: props.viewPath,
    context: 'event',
    path: 'appointment._id',
  })
  let patient_id = useDataValue({
    viewPath: props.viewPath,
    context: 'patient',
    path: '_id',
  })
  let global = useDataValue({
    viewPath: props.viewPath,
    context: 'global',
  })
  let submitGlobal = useDataSubmit({
    viewPath: props.viewPath,
    context: 'global',
  })

  return async function () {
    if (global.tab_id !== 'Calendar') {
      await submitGlobal({
        type: global.tabs.includes('Calendar') ? 'tabs/select' : 'tabs/add',
        tab: {
          id: 'Calendar',
          name: 'Calendar',
          type: 'Calendar',
          viewPath: '/App/Account/Content/Calendar',
        },
      })
    }
    submitGlobal({
      type: 'appointmentOverlay/open',
      patient_id,
      appointment_id,
    })
  }
}
