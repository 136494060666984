// this is an autogenerated file from App/Account/AutomatedEventNotifications/data.graphql
import { gql } from 'Data/Api'

export default gql`
query app__App_Account_AutomatedEventNotifications($organization_id: uuid!) {
  events_actions(
    where: {
      event: {
        organization_id: { _eq: $organization_id }
        automation_type: { _eq: automatic }
      }
    }
  ) {
    id
    data
    event {
      id
      name
      data
      app {
        id
        tags: metadata(path: "tags")
      }
    }
  }
}

`