// this is an autogenerated file from DesignSystem/TimelineEvent/Appointment/Expanded/Content/Notes/insert-mutation.graphql
import { gql } from 'Data/Api'

export default gql`
mutation app__DesignSystem_TimelineEvent_Appointment_Expanded_Content_Notes(
  $note: String!
  $target_id: numeric!
  $target_type: String!
  $user_id: numeric!
  $visible_in_schedule: Boolean!
) {
  insert_vaxiom_notes_one(
    object: {
      note: $note
      target_id: $target_id
      target_type: $target_type
      author: $user_id
      alert: false
      visible_in_schedule: $visible_in_schedule
    }
  ) {
    id
    note
    author
  }
}

`