// this is an autogenerated file from App/Account/Content/Patients/Content/Content/Sections/ActiveTab/TreatmentCard/Content/TreatmentCard/Images/ActiveTab/Images/Content/Content/Content/ImageViewer/Content/PatientImages/AllImages/PatientImage/data.graphql
import { gql } from 'Data/Api'

export default gql`
query app__App_Account_Content_Patients_Content_Content_Sections_ActiveTab_TreatmentCard_Content_TreatmentCard_Images_ActiveTab_Images_Content_Content_Content_ImageViewer_Content_PatientImages_AllImages_PatientImage($resource_id: numeric!) {
  vaxiom_resource_object_meta(resource_id: $resource_id, resource_type: image) {
    preview_url
  }
}

`