// this is an autogenerated file from DesignSystem/DataVaxiomPatientInsuranceAssignForm/Content/PolicyHolder/data.graphql
import { gql } from 'Data/Api'

export default gql`
query app__DesignSystem_DataVaxiomPatientInsuranceAssignForm_Content_PolicyHolder($to_person_id: uuid!) {
  vaxiom_relationships(
    where: { to_person_record: { _id: { _eq: $to_person_id } } }
  ) {
    from_person
    from_person_record {
      id
      first_name
      last_name
    }
  }
}

`