import View from './view.js'

export default function Logic(props) {
  let duration = props?.selected?.[0]?.duration

  if (typeof duration === 'number') {
    return <View {...props} text={`Default duration is ${duration} min.`} />
  }

  return null
}
