// this is an autogenerated file from App/Account/Content/Patients/Content/Content/Sections/ActiveTab/Profile/Content/NewProfile/Content/Main/ReferralsSection/Add/Content/personReferralsInsertMutation.graphql
import { gql } from 'Data/Api'

export default gql`
mutation app__App_Account_Content_Patients_Content_Content_Sections_ActiveTab_Profile_Content_NewProfile_Content_Main_ReferralsSection_Add_Content(
  $person_referrals: vaxiom_patient_person_referrals_insert_input!
) {
  insert_vaxiom_patient_person_referrals_one(object: $person_referrals) {
    id
  }
}

`