import { useDataChange } from 'Simple/Data.js'

export function useOnClick(props) {
  let change = useDataChange({
    context: 'slot_dialog',
    viewPath: props.viewPath,
  })

  return function onClick() {
    change(next => {
      next.view = 'content'
      next.is_dialog_open = true
      next.schedule_note.type = 'note'
      next.schedule_note.is_blocking_time = false
    })
  }
}
