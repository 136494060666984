import { normalizePath, useSetFlowTo } from 'Simple/Flow'
import { useDataChange } from 'Simple/Data'

/** @type {import('Simple/types.js').useDataOnSubmit} */
export default function useDataOnSubmit(props, data, reExecuteQuery) {
  let setFlowTo = useSetFlowTo(props.viewPath)
  let changeTab = useDataChange({
    context: 'tab',
    viewPath: props.viewPath,
  })
  return async function onSubmit({ value, args }) {
    switch (args.type) {
      case 'reFetch': {
        reExecuteQuery({ requestPolicy: 'cache-and-network' })
        return
      }
      case 'treatment_plan/create_treatment_plan':
      case 'treatment_plan/resume_treatment_plan':
      case 'treatment_plan/create_payment_plan':
      case 'treatment_plan/resume_payment_plan':
      case 'treatment_plan/view_case_closer_request': {
        changeTab(next => {
          next.selected.create_treatment_plan = next.selected
            .create_treatment_plan
            ? {
                ...next.selected.create_treatment_plan,
                treatment_id: value.txs.id,
              }
            : {
                treatment_id: value.txs.id,
              }
        })

        setFlowTo(normalizePath(props.viewPath, '../../TreatmentPlans'))

        break
      }
      case 'treatment_plan/start_contract': {
        setFlowTo(
          normalizePath(
            props.viewPath,
            '../Treatment/Content/Actions/Content/StartContract/Content'
          )
        )

        break
      }
      default: {
        break
      }
    }
  }
}
