import { useDataSubmit } from 'Simple/Data'

export function useOnRowClick(props) {
  let submit = useDataSubmit({
    viewPath: props.viewPath,
    context: 'tab',
  })

  return function onRowClick(row) {
    submit({
      type: 'edit',
      event_id: row.original.id,
    })
  }
}

export function getBackgroundRowColor(row) {
  return row.original.app_id ? '#F5F7FA' : 'transparent'
}
