import { useMutation } from 'Data/Api'
import {
  notifyError,
  useNotifications,
  notifySuccess,
} from 'Logic/Notifications.js'
import { useSetFlowTo, normalizePath } from 'Simple/Flow.js'
import { RELATIONSHIPS } from 'Data/constants.js'
import mutation_update_person_relationships from './addRelationshipMutation.graphql.js'

import { useDataValue } from 'Simple/Data.js'

/** @type {import('Simple/types.js').useDataOnSubmit} */
export default function useDataOnSubmit(props, data) {
  let [, notify] = useNotifications()
  let setFlowTo = useSetFlowTo(props.viewPath)

  let [, executeMutationUpdatePersonRelationships] = useMutation(
    mutation_update_person_relationships
  )

  let patient_gender = useDataValue({
    viewPath: props.viewPath,
    context: 'profile_patient',
    path: 'person.gender',
  })

  let patient_person_id = useDataValue({
    viewPath: props.viewPath,
    context: 'profile_patient',
    path: 'person.id',
  })

  let ROLES_MAPING = {
    RESPONSIBLE: 'DEPENDENT',
    DEPENDENT: 'RESPONSIBLE',
  }

  return async function onSubmit({ value, originalValue, args, change }) {
    switch (args?.type) {
      case 'save': {
        let relationships = value
          .filter(v => 'type' in v && 'role' in v)
          .map(v => ({
            from_person: {
              id: v.from_person_record.id,
              type: v.type,
              role: v.role,
              permitted_to_see_info: v.permitted_to_see_info || false,
            },
            to_person: {
              id: patient_person_id,
              type: RELATIONSHIPS.find(r => r.id === v.type)[patient_gender],
              role: ROLES_MAPING[value.role] ?? 'OTHER',
              permitted_to_see_info: false,
            },
          }))

        if (!relationships.length) return

        let mutationUpdatePersonRelationshipsResponse =
          await executeMutationUpdatePersonRelationships({
            relationships: relationships,
          })

        if (mutationUpdatePersonRelationshipsResponse.error) {
          notify(
            notifyError(
              'Something went wrong. Please, try again or contact support if the problem persists.'
            )
          )
          return
        }
        notify(notifySuccess('Relationships saved!'))
        setFlowTo(
          normalizePath(props.viewPath, '../../../../../../../../../No')
        )
      }
      default: {
        return
      }
    }
  }
}
