import { useMemo } from 'react'

/** @type {import('Simple/types.js').useDataTransform} */
export default function useDataTransform(props, data) {
  return useMemo(() => {
    if (!data) return data

    return data.map(treatment_plan => ({
      ...treatment_plan,
      appointments: sortAppointments(treatment_plan.appointments),
    }))
  }, [data])
}

function sortAppointments(appointments) {
  if (!appointments?.length || appointments.length === 1) {
    return appointments
  }

  let actual = appointments.find((appointment, _, array) =>
    array.every(element => element.next_appointment_id !== appointment.id)
  )

  if (!actual) {
    return appointments
  }

  let ordered = []

  while (actual) {
    ordered.push({ ...actual })
    let next_id = actual.next_appointment_id
    actual = appointments.find(item => item.id === next_id)
  }

  return ordered
}
