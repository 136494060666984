import { useDataChange, useDataValue } from 'Simple/Data.js'

export function useOnClick(props) {
  let note = useDataValue({
    viewPath: props.viewPath,
    context: 'appointment_note',
  })
  let change = useDataChange({
    viewPath: props.viewPath,
    context: 'edited_note',
  })

  return function onClick() {
    change({
      id: note.id,
      note: note.note,
      selected_user_id: note.author,
      state: 'update',
      visible_in_schedule: note.visible_in_schedule,
    })
  }
}
