import { personName, capitalize } from 'Data/format.js'

/**
 *
 * @param {string} selected
 * @param {{id: string, person: object, role: 'Provider' | 'Assistant'}[]} providers_and_assistants
 * @returns
 */
export function joinWithComma(selected, providers_and_assistants) {
  let resources = providers_and_assistants.filter(
    pa => pa.role === (selected === 'providers' ? 'Provider' : 'Assistant')
  )

  if (resources.length === 0) return `No ${capitalize(selected)} present`
  return resources.map(pa => personName(pa.person)).join(', ')
}
