import React from 'react'
import { DataProvider } from 'Simple/Data.js'

import useDataTransform from './useDataContextTransform.js'
import useDataOnSubmit from './useDataContextOnSubmit.js'
import View from './view'

export default function Logic(props) {
  let data = useDataTransform(props, null)
  let onSubmit = useDataOnSubmit(props, data)

  return (
    <DataProvider
      context="contact_method_association"
      value={data}
      viewPath={props.viewPath}
      onSubmit={onSubmit}
    >
      <View {...props} />
    </DataProvider>
  )
}
