/**
 * This file is automatically generated by Simple and will be overwritten
 * when the morpher runs. If you want to contribute to how it's generated, eg,
 * improving the algorithms inside, etc, see this:
 * https://github.com/use-simple/morpher/blob/master/ensure-data.js
 */

// import get from 'dlv';
import produce from 'immer'
import camelCase from 'lodash/camelCase'
import get from 'lodash/get'
// import set from 'dset';
import isEqual from 'lodash/isEqual.js'
import set from 'lodash/set'
import React, {
  useContext,
  useEffect,
  useLayoutEffect,
  useMemo,
  useReducer,
  useRef,
} from 'react'

import { alert } from './DataHelpers'
import {
  getFlowDefinitionKey,
  normalizePath,
  useFlow,
  useSetFlowTo,
} from './Flow'
import { useToolsData } from './Tools'
import makeDebug from './debug'

let debug = makeDebug('simple/data')

/** @type {import('./types.d.ts').DataProviderActionSet} */
let SET = 'data/SET'
/** @type {import('./types.d.ts').DataProviderActionSetFn} */
let SET_FN = 'data/SET_FN'
/** @type {import('./types.d.ts').DataProviderActionReset} */
export let RESET = 'data/RESET'
/** @type {import('./types.d.ts').DataProviderActionForceRequired} */
let FORCE_REQUIRED = 'data/FORCE_REQUIRED'
/** @type {import('./types.d.ts').DataProviderActionIsSubmitting} */
let IS_SUBMITTING = 'data/IS_SUBMITTING'

/** @type {typeof import('./types.d.ts').dataProviderReducer} */
function reducer(state, action) {
  // eslint-disable-next-line default-case
  switch (action.type) {
    case SET: {
      return {
        ...state,
        _touched: action.touched
          ? new Set([...state._touched, action.touched])
          : state._touched,
        value: produce(set)(state.value, action.path, action.value),
      }
    }

    case SET_FN: {
      return {
        ...state,
        _touched: action.touched
          ? new Set([...state._touched, action.touched])
          : state._touched,
        value: produce(action.fn)(state.value, set, get),
      }
    }

    case RESET: {
      return {
        value: action.value,
        _touched: new Set(action._touched || []),
        _isSubmitting: action._isSubmitting || false,
        _forceRequired: action._forceRequired || false,
      }
    }

    case IS_SUBMITTING: {
      return { ...state, _isSubmitting: action.value }
    }

    case FORCE_REQUIRED: {
      return { ...state, _forceRequired: true, _isSubmitting: false }
    }
  }
}

// TODO: an optimisation here could be to create all these contexts and the maps
// at compile time. Alternatively, we localise contexts at point of use instead
// of having a global Data file. It will play nicely with types but it will also
// need extra morpher logic to make it work.
// Either of those are more complex and with the optimisations done already,
// to only create contexts for the raw viewPath + context instead of all variants
// with viewPath arguments it might be enough.
/** @type {import('./types.d.ts').DataContexts} */
let DataContexts = {}
let DataContextToViewPathMap = {}
let DataConsumerToContextMap = {}

/** @type {import('./types.d.ts').DataProvider} */
export function DataProvider(props) {
  if (process.env.NODE_ENV === 'development') {
    if (!props.context) {
      debug({
        type: 'missing-context-value',
        viewPath: props.viewPath,
        message: `You're missing the context value in DataProvider. Eg: <DataProvider context="namespace" value={value}>. You're using the default one now instead.`,
      })
    }
  }

  let key = getContextKey({ context: props.context, viewPath: props.viewPath })
  if (!(key in DataContexts)) {
    DataContexts[key] = React.createContext([])
    DataContexts[key].displayName = `${props.viewPath.split('/').pop()}(${
      props.context
    })`
    if (!(props.context in DataContextToViewPathMap)) {
      DataContextToViewPathMap[props.context] = []
    }
    DataContextToViewPathMap[props.context].push(
      getFlowDefinitionKey(props.viewPath)
    )
    DataContextToViewPathMap[props.context].sort((a, b) =>
      a === b ? 0 : a > b ? -1 : 1
    )
  }

  let [state, dispatch] = useReducer(reducer, {
    value: props.value,
    _touched: new Set(),
  })

  let isSubmitting = useRef(false)

  useLayoutEffect(() => {
    if (isSubmitting.current) {
      debug({
        type: 'reset-while-submitting',
        context: props.context,
        viewPath: props.viewPath,
        message: `This generally happens when the current data provider is submitting
        and the changes it emits change its value from the outside.
        It used to return early here but after upgrading to React v18 this became a
        problem and the update wouldn't run anymore.
        Dropping the constraint for now as tests showed it wasn't really a problem.
        If you see this warning and have issues, check if it could be related to your
        submit function calling its change param and an external change that updates
        the value it receives through props.value.`,
      })
    }

    if (isEqual(props.value, state.value)) return

    dispatch({ type: RESET, value: props.value })
  }, [props.value]) // eslint-disable-line
  // ignore state.value, we only care about props.value changing

  let onSubmit = useRef(props.onSubmit)
  useLayoutEffect(() => {
    onSubmit.current = props.onSubmit
  }, [props.onSubmit])

  let onChange = useRef(props.onChange)
  useLayoutEffect(() => {
    onChange.current = props.onChange
  }, [props.onChange])

  let propsValueRef = useRef(props.value)
  useLayoutEffect(() => {
    propsValueRef.current = props.value
  }, [props.value])

  let stateValueRef = useRef(state.value)
  useLayoutEffect(() => {
    stateValueRef.current = state.value
  }, [state.value])

  useEffect(() => {
    if (typeof onChange.current !== 'function') return

    try {
      onChange.current(state.value, (fn) => dispatch({ type: SET_FN, fn }))
    } catch (error) {
      debug({
        type: 'onChange/error',
        error,
        value: state.value,
        onChangeFn: onChange.current,
      })
    }
  }, [state.value])

  let value = useMemo(
    /** @returns {import('./types.d.ts').DataProviderContextValue} */
    () => {
      return [state, dispatch, _onSubmit, props.value]

      /** @type {typeof import('./types.d.ts').dataContextOnSubmit} */
      async function _onSubmit(args) {
        if (typeof onSubmit.current !== 'function') return
        if (isSubmitting.current) return
        isSubmitting.current = true
        let res

        try {
          dispatch({ type: IS_SUBMITTING, value: true })

          res = await onSubmit.current({
            value: stateValueRef.current,
            originalValue: propsValueRef.current,
            args,
            change: (value, changePath = null) => {
              if (typeof value === 'function') {
                dispatch({ type: SET_FN, fn: value })
              } else if (!changePath) {
                dispatch({ type: RESET, value })
              } else {
                dispatch({ type: SET, path: changePath, value })
              }
            },
          })
          isSubmitting.current = false

          if (!res) {
            dispatch({ type: IS_SUBMITTING, value: false })
            return res
          }
        } catch (_error) {
          isSubmitting.current = false
        }

        dispatch({ type: FORCE_REQUIRED })
        return res
      }
    },
    [state, props.value]
  )

  useToolsData({
    context: props.context,
    contextKey: key,
    value,
    viewPath: props.viewPath,
  })

  // eslint-disable-next-line
  let Context = DataContexts[key]
  return <Context.Provider value={value}>{props.children}</Context.Provider>
}

function getContextKey({ context, viewPath }) {
  return `${getFlowDefinitionKey(viewPath)}:${context}`
}

// Look for the DataProvider's key for a context.
// The viewPath here belongs to the consumer, so we need to
// go up the chain to find the right one.
// Caching is added to avoid repeated find operations.
function getDataConsumerContextKey({ context, viewPath }) {
  let originalKey = getContextKey({ context, viewPath })

  if (originalKey in DataConsumerToContextMap) {
    return DataConsumerToContextMap[originalKey]
  } else if (originalKey in DataContexts) {
    return originalKey
  } else if (context in DataContextToViewPathMap) {
    let rviewPath = getFlowDefinitionKey(viewPath)
    let parentViewPath = DataContextToViewPathMap[context].find((item) =>
      rviewPath.startsWith(item)
    )
    if (parentViewPath) {
      DataConsumerToContextMap[originalKey] = getContextKey({
        context,
        viewPath: parentViewPath,
      })
      return DataConsumerToContextMap[originalKey]
    }
  }

  return null
}

let devMissingContext = {}
/** @type {import('./types.d.ts').useDataContext} */
export function useDataContext({ context, viewPath }) {
  let key = getDataConsumerContextKey({ context, viewPath })

  let dataContext = DataContexts[key]
  if (!dataContext) {
    if (key === null) {
      key = getContextKey({ context, viewPath })
    }
    debug({ type: 'missing-context', context, viewPath, key })

    // remind engineer that they forgot to add the data context in development mode
    if (process.env.NODE_ENV === 'development') {
      dataContext = React.createContext([{}])
      if (!(key in devMissingContext)) {
        devMissingContext[key] = 0
      }
      if (devMissingContext[key] % 25 === 0) {
        alert(JSON.stringify({ type: 'missing-context', context, viewPath }))
      }
      devMissingContext[key]++
    }
  }
  return useContext(dataContext)
}

/** @type {typeof import('./types.d.ts').useDataValue} */
export function useDataValue({ context, path = null, viewPath }) {
  let [data] = useDataContext({ context, viewPath })
  return path ? get(data.value, path) : data.value
}

/** @type {typeof import('./types.d.ts').useDataOriginalValue} */
export function useDataOriginalValue({ context, viewPath }) {
  let [, , , originalValue] = useDataContext({ context, viewPath })
  return originalValue
}

/** @type {typeof import('./types.d.ts').useDataFormat} */
export function useDataFormat({ context, path = null, format, viewPath }) {
  let [data] = useDataContext({ context, viewPath })
  let value = useDataValue({ context, path, viewPath })

  try {
    return format(value, data.value)
  } catch (error) {
    if (process.env.NODE_ENV === 'development') {
      debug({
        type: 'runtime-format',
        viewPath,
        context,
        format,
        message: `format function failed to run`,
        error,
      })
    }
    return value
  }
}

/** @type {typeof import('./types.d.ts').useDataChange} */
export function useDataChange({
  context,
  path = null,
  formatOut = null,
  viewPath,
}) {
  let [data, dispatch] = useDataContext({ context, viewPath })
  let isMounted = useRef(true)
  useEffect(() => {
    return () => {
      isMounted.current = false
    }
  }, [])

  return function change(value, changePath = path) {
    if (!isMounted.current) return

    if (typeof value === 'function') {
      dispatch({ type: SET_FN, fn: value, touched: changePath })
    } else if (!changePath) {
      dispatch({ type: RESET, value })
    } else {
      let valueSet = value
      if (formatOut) {
        try {
          valueSet = formatOut(value, data.value)
        } catch (error) {
          if (process.env.NODE_ENV === 'development') {
            debug({
              type: 'runtime-formatOut',
              viewPath,
              context,
              formatOut,
              message: `format function failed to run`,
              error,
            })
          }
        }
      }

      dispatch({
        type: SET,
        path: changePath,
        value: valueSet,
        touched: changePath,
      })
    }
  }
}

/** @type {typeof import('./types.d.ts').useDataSubmit} */
export function useDataSubmit({ context, viewPath }) {
  let [, , submit] = useDataContext({ context, viewPath })
  return submit
}

/** @type {typeof import('./types.d.ts').useDataIsSubmitting} */
export function useDataIsSubmitting({ context, viewPath }) {
  let [data] = useDataContext({ context, viewPath })
  return data._isSubmitting
}

function isValidInitial({ context, value, validate, viewPath }) {
  let isValidInitial = true
  if (validate) {
    try {
      isValidInitial = !!validate(value)
    } catch (error) {
      if (process.env.NODE_ENV === 'development') {
        debug({
          type: 'runtime-validate',
          viewPath,
          context,
          validate,
          message: `validate function failed to run`,
          error,
        })
      }
    }
  }

  return isValidInitial
}

/** @type {typeof import('./types.d.ts').useDataIsValidInitial} */
export function useDataIsValidInitial({
  context,
  path = null,
  validate,
  viewPath,
}) {
  let value = useDataValue({ context, path, viewPath })
  return isValidInitial({
    context,
    value,
    validate,
    viewPath,
  })
}

/** @type {typeof import('./types.d.ts').useDataIsValid} */
export function useDataIsValid({
  context,
  path = null,
  validate,
  required = false,
  viewPath,
}) {
  let [data] = useDataContext({ context, viewPath })
  let value = useDataValue({ context, path, viewPath })

  let isValid =
    data._touched.has(path) || (required && data._forceRequired)
      ? isValidInitial({
          context,
          value,
          validate,
          viewPath,
        })
      : true

  return isValid
}

// TODO: use data from data context instead of query/async?
/** @type {typeof import('./types.d.ts').useSetFlowToBasedOnData} */
export function useSetFlowToBasedOnData({
  data,
  fetching,
  error,
  viewPath,
  pause = false,
}) {
  let flow = useFlow()
  let contentPath = useMemo(() => {
    // TODO: I think we can remove this now.
    //
    // Alex's comment on 3/5/22 for ref:
    // after the latest update I have a problem as the flow shortcuts don’t seem
    // to work anymore. I have a setup to either show the patient’s documents or
    // images and it always shows the documents even though the path is set to
    // images (it worked before, not sure what happens now).
    // another issue I just noticed is that when the data changes, when there is
    // a separate flow based on data it still renders first the old view which
    // could cause some problems.
    // https://github.com/use-simple/morpher/pull/3
    //
    // I believe that the steps logic introduced into FlowShortcuts should be
    // handling that now because every step points to a data loading view
    if (flow.flow[viewPath] === 'Content') {
      let result = Object.entries(flow.flow).find(([key]) =>
        key.includes(`${viewPath}/Content`)
      )
      if (result) {
        let [key, value] = result
        return `${key.replace(`${viewPath}/`, '')}/${value}`
      }
    }

    return 'Content'
  }, []) // eslint-disable-line
  // ignore flow.flow

  let value = error
    ? 'Error'
    : pause && !data
    ? 'No'
    : fetching
    ? 'Loading'
    : isEmpty(data)
    ? 'Empty'
    : contentPath

  useSetFlowToBasedOnDataValue({ value, viewPath })
}

export function getQueryFlowBasedOnDataView({
  data,
  fetching,
  error,
  pause = false,
}) {
  return error
    ? 'Error'
    : pause && !data
    ? 'No'
    : fetching
    ? 'Loading'
    : isEmpty(data)
    ? 'Empty'
    : 'Content'
}

/** @type {typeof import('./types.d.ts').useSetFlowToBasedOnDataValue} */
export function useSetFlowToBasedOnDataValue({ value, viewPath }) {
  let setFlowTo = useSetFlowTo(viewPath)

  // We use a layout effect because we want the setFlowTo to execute
  // before a change in data re-renders the UI, even if it blocks
  // React from re-rendering. Performance might be hurt but with the
  // current model, it seems like the only safe option. Otherwise,
  // views may render with the wrong data contexts.
  useLayoutEffect(() => {
    let target = value
    switch (typeof target) {
      case 'boolean': {
        target = target ? 'Content' : 'No'
        break
      }
      case 'string': {
        if (!target.includes('/')) {
          target = toCapitalisedCamelCase(target)
        }
        break
      }
      default: {
        debug({
          type: 'flow-target-invalid-type',
          target,
          valueTypeOf: typeof target,
          viewPath,
        })
        return
      }
    }

    setFlowTo(normalizePath(viewPath, target))
  }, [value, viewPath, setFlowTo])
}

/** @type {typeof import('./types.d.ts').useSetFlowToBasedOnDataIsValid} */
export function useSetFlowToBasedOnDataIsValid({
  context,
  path,
  viewPath,
  validate,
  validateInitial = true,
}) {
  let dataIsValidInitial = useDataIsValidInitial({
    context,
    path,
    validate,
    viewPath,
  })
  let dataIsValid = useDataIsValid({
    context,
    path,
    validate,
    viewPath,
  })

  let isContent = validateInitial ? dataIsValidInitial : dataIsValid
  let value = isContent ? 'IsValid' : 'No'
  useSetFlowToBasedOnDataValue({ value, viewPath })
}

/** @type {typeof import('./types.d.ts').useSetFlowToBasedOnDataIsInvalid} */
export function useSetFlowToBasedOnDataIsInvalid({
  context,
  path,
  viewPath,
  validate,
  validateInitial = true,
}) {
  let dataIsValidInitial = useDataIsValidInitial({
    context,
    path,
    validate,
    viewPath,
  })
  let dataIsValid = useDataIsValid({
    context,
    path,
    validate,
    viewPath,
  })

  let isContent = validateInitial ? !dataIsValidInitial : !dataIsValid
  let value = isContent ? 'IsInvalid' : 'No'
  useSetFlowToBasedOnDataValue({ value, viewPath })
}

/** @type {typeof import('./types.d.ts').useSetFlowToBasedOnDataIsSubmitting} */
export function useSetFlowToBasedOnDataIsSubmitting({ context, viewPath }) {
  let dataIsSubmitting = useDataIsSubmitting({ context, viewPath })
  let value = dataIsSubmitting ? 'IsSubmitting' : 'No'
  useSetFlowToBasedOnDataValue({ value, viewPath })
}

function toCapitalisedCamelCase(value) {
  return value && capitalize(camelCase(value))
}

function capitalize(value) {
  if (!value || typeof value !== 'string') return ''
  return value.charAt(0).toUpperCase() + value.slice(1)
}

function isEmpty(data) {
  return Array.isArray(data) ? data.length === 0 : !data
}

// This hook may be removed in the future.
// Use it to prevent loops between synced data providers, eg a list item
// updating the list it belongs to, eg:
//
// ```js
// import { useDataChange, useOnChangeLastValueGuard } from 'Simple/Data'
//
// export default function useListItemDataOnChange(props) {
//   let change = useDataChange({
//     context: 'list',
//     viewPath: props.viewPath,
//   })
//
//   return useOnChangeLastValueGuard(function onChange(next) {
//     change(inext => {
//       inext[props.index] = next
//     })
//   })
// }
// ```
export function useOnChangeLastValueGuard(onChange) {
  let lastValue = useRef(null)

  return function onChangeGuard(next) {
    if (lastValue.current === next) return
    lastValue.current = next

    onChange(next)
  }
}
