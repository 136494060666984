import { useDataChange, useDataValue } from 'Simple/Data.js'
export function useOnClick(props) {
  let change = useDataChange({
    context: 'plan_details',
    viewPath: props.viewPath,
  })

  let employer = useDataValue({
    context: 'employer',
    viewPath: props.viewPath,
  })

  return function onClick() {
    change(next => {
      next.insurance_plan_employers = next.insurance_plan_employers.filter(
        item => {
          return !(item.id === employer.id)
        }
      )
    })
  }
}
