import { useDataValue, useDataSubmit } from 'Simple/Data.js'

export function useOnClick(props) {
  let event = useDataValue({
    viewPath: props.viewPath,
    context: 'event',
  })
  let submit = useDataSubmit({
    viewPath: props.viewPath,
    context: 'tab',
  })

  return () => {
    submit({
      type: 'scheduling/selectUntemplatedSlot',
      ...event.config,
      focus: false,
    })
  }
}
