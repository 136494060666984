import React from 'react'
import { useDataValue } from 'Simple/Data.js'

import View from './view.js'

export default function Logic(props) {
  let number_of_people_with_address = useDataValue({
    viewPath: props.viewPath,
    context: 'number_of_people_with_address',
  })

  return (
    <View
      {...props}
      text={
        number_of_people_with_address === 1
          ? `There is one other person that has the same address. Do you want to update it with the new address or just this profile?`
          : `There are ${number_of_people_with_address} people that have the same address. Do you want to update all the selected profiles with this address or just this profile?`
      }
    />
  )
}
