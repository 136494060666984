// this is an autogenerated file from App/Account/Content/Patients/Content/Content/Sections/ActiveTab/Profile/Content/NewProfile/Content/Main/ReferralsSection/Add/Content/Templates/Content/Referral/Value/Search/Patient/Search/Content/Profiles/data.graphql
import { gql } from 'Data/Api'

export default gql`
query app__App_Account_Content_Patients_Content_Content_Sections_ActiveTab_Profile_Content_NewProfile_Content_Main_ReferralsSection_Add_Content_Templates_Content_Referral_Value_Search_Patient_Search_Content_Profiles(
  $search_term: String!
  $parent_company_id: uuid!
  $whole_parent_company: Boolean!
) {
  vaxiom_persons_search(
    search_term: $search_term
    parent_company_id: $parent_company_id
    whole_parent_company: $whole_parent_company
  ) {
    person: vaxiom_person {
      id
      title
      first_name
      middle_name
      last_name
      search_name
      greeting
      gender
      birth_date
      ssn
      profile_pic_url
      public_user {
        id
      }
      patient {
        id
        _id
        human_readable_id
        legacy_id
      }
    }
  }
}

`