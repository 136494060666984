import { useDataValue } from 'Simple/Data.js'
import { useMutation } from 'Data/Api'
import { normalizePath, useSetFlowTo } from 'Simple/Flow.js'
import mutation_set_past from './setPastMutation.graphql.js'
import mutation_delete_professional_relationship from './deleteProfessionalRelationshipMutation.graphql.js'

export function useOnClick(props) {
  let professional = useDataValue({
    context: 'professional',
    viewPath: props.viewPath,
  })
  let [, executeMutationSetPast] = useMutation(mutation_set_past)
  let [, executeMutationDeleteProfessionalRelationship] = useMutation(
    mutation_delete_professional_relationship
  )
  let setFlowTo = useSetFlowTo(props.viewPath)
  let action = useDataValue({
    context: 'option',
    viewPath: props.viewPath,
    path: 'id',
  })
  return async function onClick() {
    switch (action) {
      case 'REMOVE':
        return remove()
      case 'EDIT':
        return edit()
      case 'MARK_AS_PAST':
        return await markAsPast()
      case 'REMOVE_FROM_PAST':
        return removeFromPast()
      default:
        return
    }
  }

  async function remove() {
    await executeMutationDeleteProfessionalRelationship({
      id: professional.id,
    })
  }
  function edit() {
    setFlowTo(normalizePath(props.viewPath, '../../Edit/Content'))
  }
  async function markAsPast() {
    await executeMutationSetPast({ id: professional.id, past: true })
  }
  async function removeFromPast() {
    await executeMutationSetPast({ id: professional.id, past: false })
  }
}
