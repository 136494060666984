// this is an autogenerated file from App/Account/Topbar/Options/Content/Apps/Content/App/mutation.graphql
import { gql } from 'Data/Api'

export default gql`
mutation app__App_Account_Topbar_Options_Content_Apps_Content_App($id: String!, $location_id: uuid!) {
  apps_launchers_resolve(id: $id, location_id: $location_id) {
    ok
    url
    component
    message
  }
}

`