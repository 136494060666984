import { add } from 'lodash'
import { useMemo } from 'react'

export default function useDataTransform(props, data) {
  return useMemo(() => {
    if (!data) return

    return data
      .filter(
        item =>
          !!item.person &&
          item.person.other_professional_relationships?.length > 0
      )
      .map(item => {
        let is_external_professional =
          item.person.other_professional_relationships?.length > 0

        let resourceName =
          item.person.other_professional_relationships?.[0].employee_type.name
        let location =
          item.person.other_professional_relationships?.[0].external_office

        return {
          ...item.person,
          is_external_professional,
          resourceName,
          location,
        }
      })
      .filter(Boolean)
  }, [data]) // eslint-disable-line
  // ignore transform
}
