import { useMemo } from 'react'

/** @type {import('Simple/types.js').useDataTransform} */
export default function useDataTransform(props, data) {
  return useMemo(() => {
    if (!data) return data

    return data[0]?.tx_cards?.map(tx_card => {
      let tx = tx_card.txs[0]
      return {
        ...tx,
        status_name: tx.tx_status_name?.[0]?.name,
        tx_card,
      }
    })
  }, [data])
}
