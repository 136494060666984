import { useDataSubmit, useDataValue, useDataChange } from 'Simple/Data.js'

/** @type {import('Simple/types.js').useDataOnChange} */
export default function useDataOnChange(props) {
  let submitTab = useDataSubmit({
    viewPath: props.viewPath,
    context: 'tab',
  })
  let change = useDataChange({
    viewPath: props.viewPath,
    context: 'appointment_overlay',
  })
  let appointment_id = useDataValue({
    context: 'appointment_overlay',
    path: 'appointment_id',
    viewPath: props.viewPath,
  })
  let duration = useDataValue({
    context: 'appointment_overlay',
    path: 'duration',
    viewPath: props.viewPath,
  })
  let preselect_scheduling_slot_config = useDataValue({
    context: 'appointment_overlay',
    path: 'preselect_scheduling_slot_config',
    viewPath: props.viewPath,
  })
  let has_calendar_schedule_conflict = useDataValue({
    context: 'global',
    path: 'current_location.permissions.has_calendar_schedule_conflict',
    viewPath: props.viewPath,
  })

  return async function onChange(slots) {
    if (!slots) return

    // TODO: investigate which some other submit is blocking us and remove this
    await new Promise(resolve => {
      setTimeout(resolve, 100)
    })

    await submitTab({
      type: 'scheduling/setTemplatedSlots',
      slots,
      appointment_id,
      duration,
    })

    let slotsDate = slots?.[0]?.date
    let { date, start_min, chair_id } = preselect_scheduling_slot_config

    if (
      !appointment_id ||
      !date ||
      typeof start_min !== 'number' ||
      !chair_id ||
      typeof duration !== 'number'
    ) {
      return
    }

    // in case we have some stale slots
    // special case, this might happen when calendar is on different date than expected
    // we dont want to preselect just yet
    if (slotsDate && slotsDate !== date) return

    let templatedSlot = slots?.find(
      slot => slot.start_min === start_min && slot.chair_id === chair_id
    )

    // reset, so it does not affect further interactions
    change(next => {
      next.preselect_scheduling_slot_config = {
        start_min: null,
        date: null,
        chair_id: null,
      }
    })

    if (templatedSlot?.id) {
      submitTab({
        type: 'scheduling/selectTemplatedSlot',
        id: templatedSlot.id,
        focus: true,
      })
      return
    }

    if (has_calendar_schedule_conflict) {
      await submitTab({
        type: 'scheduling/selectUntemplatedSlot',
        focus: true,
        start_min,
        end_min: start_min + duration,
        date,
        chair_id,
      })
    }
  }
}
