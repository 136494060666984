import React from 'react'
import useIsHovered from 'Logic/useIsHovered.js'

import View from './view.js'
import { useIsDisabled } from './helpers.js'

export default function Logic(props) {
  let [hovered, , hoverBind] = useIsHovered({
    isDisabled: false,
    isSelected: false,
    onMouseEnterDelay: 500,
  })
  let isDisabled = useIsDisabled(props)

  return (
    <View
      {...props}
      hovered={hovered}
      onMouseEnterManual={hoverBind.onMouseEnter}
      onMouseLeaveManual={hoverBind.onMouseLeave}
      viewPath={props?.dsViewPath || props.viewPath}
      isDisabled={isDisabled}
    />
  )
}
