import { useRef } from 'react'
import {
  notifyError,
  useNotifications,
  notifySuccess,
} from 'Logic/Notifications.js'
import { useDataValue, useDataSubmit } from 'Simple/Data.js'
import { useMutation } from 'Data/Api.js'

import mutationApiAuthorize from './mutation-api-authorize.graphql.js'
import mutationDeleteConnection from './mutation-delete-connection.graphql.js'

export default function useListItemDataOnSubmit(props, data) {
  let [, executeApiAuthorizeMutation] = useMutation(mutationApiAuthorize)
  let [, executeDeleteConnectionMutation] = useMutation(
    mutationDeleteConnection
  )

  let [, notify] = useNotifications()

  let location_id = useDataValue({
    viewPath: props.viewPath,
    context: 'global',
    path: 'current_location._id',
  })
  let submit = useDataSubmit({
    context: 'tab',
    viewPath: props.viewPath,
  })
  let authWindow = useRef(null)

  return async function onSubmit({ value, args, change }) {
    switch (args.type) {
      case 'connect': {
        change(next => {
          next.is_connecting = true
        })

        let mutationResponse = await executeApiAuthorizeMutation({
          app_id: value.id,
          connection_id: null,
          location_id,
          // TO DO: take this from dynamically generated connection configuration inputs
          // see: https://www.notion.so/getgreyfinch/App-Connections-Refactor-Proposal-96b3ef598e3e4db9b6880b42da3aae09?pvs=4
          connection_properties: {},
        })
        if (
          mutationResponse.error ||
          !mutationResponse.data.apps_external_api_authorize.id
        ) {
          change(next => {
            next.is_connecting = false
          })
          return notify(
            notifyError(
              'An error occurred trying to connect the app. Please refresh the page and try again.'
            )
          )
        }

        if (mutationResponse.data.apps_external_api_authorize.url) {
          change(next => {
            next.connection_id =
              mutationResponse.data.apps_external_api_authorize.id
          })

          authWindow.current = window.open(
            mutationResponse.data.apps_external_api_authorize.url,
            'popup',
            `height=768,width=1024,top=${(window.innerHeight - 768) / 2},left=${
              (window.innerWidth - 1024) / 2
            }`
          )
        }

        return
      }

      case 'cancel': {
        try {
          authWindow.current?.close()

          // delete newly created connection as not used anyway
          await executeDeleteConnectionMutation({
            connection_id: value.connection_id,
          })
        } catch (_) {
        } finally {
          setTimeout(() => {
            change(next => {
              next.is_connecting = false
            })
          }, 100)
        }

        return
      }

      case 'active': {
        // TODO: see if we still need this, with the right data contexts we shouldn't
        // eg without having apps be so global like it is to this feature
        // reExecuteQuery({ requestPolicy: 'network-only' })
        change(next => {
          next.is_connecting = false
        })
        submit({ type: 'manage', app_id: value.id, title: value.name })

        return notify(
          notifySuccess('The application was connected successfully')
        )
      }

      default: {
        return notify(notifyError('Invalid action'))
      }
    }
  }
}
