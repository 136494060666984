import React, { useEffect, useRef } from 'react'
import { useSubscription } from 'Data/Api.js'
import useDataConfiguration from './useDataConfiguration'
import query from './query.graphql.js'
import View from './view.js'
import { debounce } from 'lodash'
import { useDataChange, useDataValue } from 'Simple/Data.js'
import { dateShortOut } from 'Data/format.js'

export default function Logic(props) {
  let date = useDataValue({
    context: 'tab',
    path: 'selected.date',
    viewPath: props.viewPath,
  })
  let change = useDataChange({
    context: 'tab',
    path: 'event_update_timestamp',
    viewPath: props.viewPath,
  })

  let pendingUpdates = useRef([])
  let debouncedUpdates = useRef(null)
  let selectedDate = useRef(date)

  let configuration = useDataConfiguration(props)
  let [{ data }] = useSubscription({ query, ...configuration })

  useEffect(() => {
    selectedDate.current = date
  }, [date])

  useEffect(() => {
    if (data?.calendar_event_updates?.length) {
      // Add the new updates to pendingUpdates but don't apply them immediately
      let dates = data.calendar_event_updates.map(
        item => item.key.split(',')[0]
      )
      pendingUpdates.current = [...pendingUpdates.current, ...dates]

      // Debounce applying updates to the UI
      if (!debouncedUpdates.current) {
        debouncedUpdates.current = debounce(() => {
          applyPendingUpdates()
        }, 1000)
      }

      debouncedUpdates.current()
    }
  }, [data]) // eslint-disable-line react-hooks/exhaustive-deps

  function applyPendingUpdates() {
    if (pendingUpdates.current.includes(dateShortOut(selectedDate.current))) {
      change(Date.now())
    }
    // Clear the pending updates after applying
    pendingUpdates.current = []
  }

  return <View {...props} />
}
