import { useDataChange, useDataValue } from 'Simple/Data.js'

export function useOnClick(props) {
  let payor_id = useDataValue({
    context: 'tab',
    path: 'id',
    viewPath: props.viewPath,
  })
  let change = useDataChange({
    context: 'selected',
    path: 'payor_id',
    viewPath: props.viewPath,
  })

  return function onChange() {
    change(payor_id)
  }
}
