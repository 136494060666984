import { useDataValue, useDataIsSubmitting } from 'Simple/Data.js'

export function useIsDisabled(props) {
  let selected = useDataValue({
    viewPath: props.viewPath,
    context: 'selected',
  })
  let is_submitting = useDataIsSubmitting({
    viewPath: props.viewPath,
    context: 'selected',
  })

  return !Object.keys(selected).length || is_submitting
}
