import { normalizePath, useSetFlowTo } from 'Simple/Flow'
import { useMutation } from 'Data/Api.js'
import { useDataChange, useDataValue } from 'Simple/Data.js'
import {
  notifyError,
  notifySuccess,
  useNotifications,
} from 'Logic/Notifications.js'

import mutation from './mutation.graphql.js'

export default function useListItemDataOnSubmit(props) {
  let patient_id = useDataValue({
    viewPath: props.viewPath,
    path: 'patient_id',
    context: 'tab',
  })
  let location_id = useDataValue({
    context: 'global',
    path: 'current_location._id',
    viewPath: props.viewPath,
  })

  let setFlowTo = useSetFlowTo(props.viewPath)

  let [, executeMutation] = useMutation(mutation)
  let [, notify] = useNotifications()

  let changeComponent = useDataChange({
    viewPath: props.viewPath,
    context: 'component',
  })

  return async function onSubmit({ args, value, change }) {
    if (value.type === 'ACTION_EXTERNAL_MAPPING_REMOVE') {
      if (!window.confirm(`Are you sure?`)) return
    }

    let mutationResponse = await executeMutation({
      id: value.id,
      resource_id: patient_id,
      location_id,
    })
    if (mutationResponse.error) {
      return notify(notifyError(`There was a problem launching the app.`))
    }

    if (!mutationResponse.data.apps_launchers_resolve.ok) {
      return notify(
        notifyError(mutationResponse.data.apps_launchers_resolve.message)
      )
    }

    switch (value.type) {
      case 'URL_STATIC': {
        window.open(mutationResponse.data.apps_launchers_resolve.url)
        changeComponent(null)
        // setFlowTo(normalizePath(props.viewPath, '../../../../../No'))
        break
      }

      case 'URL_HTTP': {
        window.open(mutationResponse.data.apps_launchers_resolve.url)
        changeComponent(null)
        // setFlowTo(normalizePath(props.viewPath, '../../../../../No'))
        break
      }

      // TODO: implement
      case 'ACTION_QR': {
        // setFlowTo(normalizePath(props.viewPath, 'ActionQr/Content'))
        changeComponent(null)
        break
      }

      // TODO: implement
      case 'ACTION_COMPONENT': {
        changeComponent(mutationResponse.data.apps_launchers_resolve.component)
        // TODO: make it internal to AppAction like the ACTION_QR one is on the topbar
        setFlowTo(normalizePath(props.viewPath, '../../../../AppComponent'))
        break
      }

      case 'ACTION_HTTP': {
        changeComponent(null)
        break
      }

      // TODO: implement
      case 'ACTION_EXTERNAL_MAPPING_REMOVE': {
        // setFlowTo(normalizePath(props.viewPath, 'ActionQr/Content'))
        changeComponent(null)
        break
      }

      default: {
        break
      }
    }

    if (mutationResponse.data.apps_launchers_resolve.message) {
      return notify(
        notifySuccess(mutationResponse.data.apps_launchers_resolve.message)
      )
    }
  }
}
