import { useDataChange, useDataValue } from 'Simple/Data'
import { v4 as uuid } from 'uuid'
export function useOnClick(props) {
  let change = useDataChange({
    context: 'new_professional',
    viewPath: props.viewPath,
  })
  return function onClick() {
    change(next => {
      next.addresses.push({ id: uuid() })
    })
  }
}
