import { normalizePath, useSetFlowTo } from 'Simple/Flow.js'
import { useDataValue } from 'Simple/Data.js'
export function useOnClick(props) {
  let setFlowTo = useSetFlowTo(props.viewPath)
  let carrier_id = useDataValue({
    context: 'plan_details',
    viewPath: props.viewPath,
    path: 'carrier',
  })
  return function onClick(action) {
    switch (action) {
      case 'EDIT':
        if (carrier_id !== null) {
          return editCarrier()
        }
        return
      case 'ADD':
        return addCarrier()
      default:
        return
    }
  }

  function editCarrier() {
    setFlowTo(normalizePath(props.viewPath, './Edit/Content'))
  }
  function addCarrier() {
    setFlowTo(normalizePath(props.viewPath, './Create/Content'))
  }
}
