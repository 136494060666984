// this is an autogenerated file from App/Account/Content/Settings/AdminContent/SettingsContent/IntegrationsApplications/Content/Connection/Content/Connection/data.graphql
import { gql } from 'Data/Api'

export default gql`
query app__App_Account_Content_Settings_AdminContent_SettingsContent_IntegrationsApplications_Content_Connection_Content_Connection($app_id: uuid!) {
  apps_connections(where: { app_id: { _eq: $app_id } }) {
    id
    text: display_name
    status
    user {
      id
      vaxiom_person {
        id
        first_name
        last_name
      }
    }
  }
}

`