import { useDataChange } from 'Simple/Data.js'
import { v4 as uuid } from 'uuid'
export function useOnClick(props) {
  let change = useDataChange({
    context: 'plan_details',
    viewPath: props.viewPath,
  })
  return function onClick() {
    change(next => {
      next.insurance_plan_employers = [
        ...(next.insurance_plan_employers || []),
        {
          id: uuid(),
        },
      ]
    })
  }
}
