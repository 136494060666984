// this is an autogenerated file from App/Account/Content/Patients/Content/Content/Sections/ActiveTab/Profile/Content/NewProfile/Content/SchedulingPreferences/data.graphql
import { gql } from 'Data/Api'

export default gql`
query app__App_Account_Content_Patients_Content_Content_Sections_ActiveTab_Profile_Content_NewProfile_Content_SchedulingPreferences($patient_id: numeric!) {
  vaxiom_scheduling_preferences(where: { patient_id: { _eq: $patient_id } }) {
    id
    start_hour
    end_hour
    days: scheduling_preferences_week_days {
      week_days
    }
    employees: scheduling_preferred_employees {
      employee_id
      employee {
        id
        employment_contract {
          id
          person {
            id
            first_name
            last_name
          }
        }
        resource {
          id
          resource_type {
            id
            is_provider
            is_assistant
          }
        }
      }
    }
  }
}

`