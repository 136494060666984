import { useMemo } from 'react'

/** @type {import('Simple/types.js').useDataTransform} */
export default function useDataTransform(props, data) {
  return useMemo(() => {
    let options = []
    for (let i = 0; i <= 100; i += 5) {
      options.push({
        text: `${i}%`,
        id: i / 100,
      })
    }
    return options
  }, [data])
}
