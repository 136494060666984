// this is an autogenerated file from App/Account/Content/Settings/AdminContent/SettingsContent/IntegrationsApplications/Content/Apps/mutation-delete-connection.graphql
import { gql } from 'Data/Api'

export default gql`
mutation app__App_Account_Content_Settings_AdminContent_SettingsContent_IntegrationsApplications_Content_Apps($connection_id: uuid!) {
  delete_apps_connection_resources(
    where: { connection_id: { _eq: $connection_id } }
  ) {
    affected_rows
  }
  delete_apps_connection_resource_properties(
    where: { connection_id: { _eq: $connection_id } }
  ) {
    affected_rows
  }
  delete_auth_challenges(where: { connection_id: { _eq: $connection_id } }) {
    affected_rows
  }
  delete_apps_connections_by_pk(id: $connection_id) {
    id
  }
}

`