// this is an autogenerated file from DesignSystem/DataVaxiomRelationshipsItem/Edit/NonPatient/Email/NewEmail/Content/SearchForEmail/Content/data.graphql
import { gql } from 'Data/Api'

export default gql`
query app__DesignSystem_DataVaxiomRelationshipsItem_Edit_NonPatient_Email_NewEmail_Content_SearchForEmail_Content($address: String!) {
  vaxiom_contact_emails(where: { address: { _eq: $address } }) {
    id
    contact_method {
      id
      association {
        id
        person {
          id
          first_name
          last_name
        }
      }
    }
  }
}

`