export function getView(status) {
  switch (status) {
    case 'processed': {
      return 'SyncPatients'
    }

    case 'error': {
      return 'Error'
    }

    default: {
      return 'Syncing'
    }
  }
}
