// this is an autogenerated file from DesignSystem/DataVaxiomEmployeeProfessionalsRelationshipsItem/Edit/Content/Address/Addresses/Content/Address/Delete/Content/Actions/DeleteAction/mutation.graphql
import { gql } from 'Data/Api'

export default gql`
mutation app__DesignSystem_DataVaxiomEmployeeProfessionalsRelationshipsItem_Edit_Content_Address_Addresses_Content_Address_Delete_Content_Actions_DeleteAction($association_id: numeric!) {
  delete_vaxiom_contact_method_associations_by_pk(id: $association_id) {
    id
  }
}

`