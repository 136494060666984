import { useDataChange } from 'Simple/Data.js'

/** @type {import('Simple/types.js').useDataOnChange} */
export default function useDataOnChange(props, data) {
  let change = useDataChange({
    context: 'apps',
    path: 'show',
    viewPath: props.viewPath,
  })

  return function onChange(value) {
    if (value?.id) change(true)
  }
}
