import { useDataChange, useDataValue } from 'Simple/Data.js'
export function useOnClick(props) {
  let change = useDataChange({
    context: 'filter',
    viewPath: props.viewPath,
  })
  let insurance_plan = useDataValue({
    context: 'insurance',
    viewPath: props.viewPath,
  })

  return function onClick() {
    change(value => {
      value.assign_insurance = insurance_plan._id
      value.assign_insurance_id = insurance_plan.id
    })
  }
}
