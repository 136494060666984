import { useMemo } from 'react'

/** @type {import('Simple/types.js').useDataTransform} */
export default function useDataTransform(props, data) {
  return useMemo(() => {
    return {
      ...data,
      ...props.schedule_note,
      mode:
        typeof props.schedule_note.id === 'undefined' ||
        props.schedule_note.id === null
          ? 'add'
          : 'edit',
    }
  }, [data, props.schedule_note])
}
