import { useDataChange } from 'Simple/Data.js'
import View from './view.js'

export default function Logic(props) {
  let change = useDataChange({
    viewPath: props.viewPath,
    context: 'new_relation',
  })

  let onChooseAddress = ({ street, city, state, zip }) => {
    change(next => {
      next.address.address_line1 = street || ''
      next.address.city = city || ''
      next.address.state = state || ''
      next.address.zip = zip || ''
    })
  }

  return <View {...props} onChooseAddress={onChooseAddress} />
}
