import { useNotifications, OK, ERROR } from 'Logic/Notifications.js'
import View from './view.js'
import React from 'react'

export default function Logic(props) {
  let [notifications, , close] = useNotifications()

  return notifications.map(n => (
    <View
      key={n.id}
      hasAction={Boolean(n.action)}
      textAction={n.action?.text}
      onClickAction={n.action?.onClick}
      isError={n.status === ERROR}
      isOk={n.status === OK}
      title={n.title}
      message={n.message}
      onClose={() => close({ id: n.id })}
      viewPath={props.viewPath}
    />
  ))
}
