import { normalizePath, useSetFlowTo } from 'Simple/Flow.js'
import { useDataValue } from 'Simple/Data.js'

export function useOnClick(props) {
  let setFlowTo = useSetFlowTo(props.viewPath)
  let action = useDataValue({
    context: 'option',
    viewPath: props.viewPath,
    path: 'id',
  })

  return function onClick() {
    switch (action) {
      case 'VIEW_SUBSCRIPTION':
        return viewSubscription()
      case 'VIEW_INSURANCE_PLAN':
        return viewInsurance()
      case 'REMOVE_COVERAGE':
        return remove()
      case 'INACTIVATE_INSURANCE':
        return viewInactive()
      case 'ACTIVATE_INSURANCE':
        return viewActivate()
      case 'VIEW_INSURED_TREATMENTS':
        return viewInsuredTreatments()
      default:
        return
    }
  }

  function viewInsuredTreatments() {
    setFlowTo(normalizePath(props.viewPath, '../../InsuredTreatments/Content'))
  }

  function viewSubscription() {
    setFlowTo(normalizePath(props.viewPath, '../../Subscription/Content'))
  }
  function viewInsurance() {
    setFlowTo(normalizePath(props.viewPath, '../../InsurancePlan/Content'))
  }
  function remove() {
    setFlowTo(normalizePath(props.viewPath, '../../Remove/Content'))
  }
  function viewInactive() {
    setFlowTo(normalizePath(props.viewPath, '../../Inactivate/Content'))
  }
  function viewActivate() {
    setFlowTo(normalizePath(props.viewPath, '../../Reactivate/Content'))
  }
}
