import React, { useEffect, useRef } from 'react'
import View from './view.js'
import useIsHovered from 'Logic/useIsHovered.js'
import {
  useFloating,
  useClientPoint,
  flip,
  offset,
  shift,
  autoUpdate,
} from '@floating-ui/react'
import { useDataChange, useDataValue } from 'Simple/Data.js'

export default function Logic(props) {
  let timerRef = useRef(null)
  let [hovered, , hoverBind] = useIsHovered({
    isDisabled: false,
    isSelected: false,
    onMouseEnter: undefined,
    onMouseLeave: undefined,
  })

  let event = useDataValue({
    context: 'event',
    viewPath: props.viewPath,
  })
  let open_dialog_near_click = useDataValue({
    context: 'timegrid',
    path: 'open_dialog_near_click',
    viewPath: props.viewPath,
  })

  let change = useDataChange({
    context: 'event',
    viewPath: props.viewPath,
  })

  let { refs, x, y, context, update } = useFloating({
    open: event.selected.showInfoDialog || event.selected.showActionsDialog,
    placement: 'right-start',
    strategy: 'fixed',
    middleware: [
      offset(-12),
      flip({
        fallbackPlacements: ['right-end', 'left-start', 'left-end'],
      }),
      shift(),
    ],
    whileElementsMounted: autoUpdate,
  })

  useEffect(() => {
    change(next => {
      next.update = update
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [update])

  let { reference } = useClientPoint(context, {
    enabled:
      !event.selected.showInfoDialog &&
      !event.selected.showActionsDialog &&
      open_dialog_near_click,
  })

  return (
    <View
      {...props}
      hovered={hovered}
      onMouseEnter={event => {
        hoverBind.onMouseEnter()
        if (reference && typeof reference.onMouseEnter !== 'undefined')
          reference.onMouseEnter(event)
      }}
      onMouseMove={event => {
        if (reference && typeof reference.onMouseMove !== 'undefined')
          reference.onMouseMove(event)
      }}
      onMouseLeave={() => {
        hoverBind.onMouseLeave()
        clearTimeout(timerRef.current)
        timerRef.current = setTimeout(() => {
          change(next => {
            next.selected.showInfoDialog = false
            next.selected.showActionsDialog = false
          })
        }, 200)
      }}
      onMouseEnterContent={() => {
        if (timerRef.current) clearTimeout(timerRef.current)
      }}
      triggerRef={refs.setReference}
      contentRef={refs.setFloating}
      left={x}
      top={y}
    />
  )
}
