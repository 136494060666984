import View from './view.js'
import React from 'react'
import { useDataIsSubmitting, useDataValue } from 'Simple/Data'

export default function Logic(props) {
  let isSubmitting = useDataIsSubmitting({
    viewPath: props.viewPath,
    context: 'new_email',
  })

  let persons = useDataValue({
    context: 'same_email_persons',
    viewPath: props.viewPath,
  })
  let shouldDisable = persons.filter(p => p.type).length === 0

  return <View {...props} disabled={shouldDisable || isSubmitting} />
}
