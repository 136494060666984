import { useMemo } from 'react'

export default function useDataTransform(props, data) {
  return useMemo(() => {
    if (!data) return

    return data
      .filter(item => !!item.person)
      .map(item => {
        let is_patient = !!item.person.patient
        let is_payer = item.person.from_person_relationships?.length > 0
        let is_employee = item.person.employment_contracts?.length > 0
        let is_external_professional =
          item.person.other_professional_relationships?.length > 0

        return {
          ...item.person,
          contact_method_association_id: item.id,
          is_patient,
          is_payer,
          is_employee,
          is_external_professional,
        }
      })
  }, [data]) // eslint-disable-line
  // ignore transform
}
