// this is an autogenerated file from App/Account/Content/Patients/Content/Content/Sections/ActiveTab/Profile/Content/NewProfile/Content/ContactInfo/Phone/Phones/Content/Phone/Number/Apps/Content/App/mutation.graphql
import { gql } from 'Data/Api'

export default gql`
mutation app__App_Account_Content_Patients_Content_Content_Sections_ActiveTab_Profile_Content_NewProfile_Content_ContactInfo_Phone_Phones_Content_Phone_Number_Apps_Content_App(
  $id: String!
  $resource_id: uuid!
  $location_id: uuid!
) {
  apps_launchers_resolve(
    id: $id
    resource_id: $resource_id
    location_id: $location_id
  ) {
    ok
    url
    component
    message
  }
}

`