// this is an autogenerated file from App/Account/Content/Patients/Content/Content/Sections/ActiveTab/Profile/Content/NewProfile/Content/ContactInfo/Phone/NewPhone/Content/newContactMethodAssociationMutation.graphql
import { gql } from 'Data/Api'

export default gql`
mutation app__App_Account_Content_Patients_Content_Content_Sections_ActiveTab_Profile_Content_NewProfile_Content_ContactInfo_Phone_NewPhone_Content(
  $contact_method_id: numeric!
  $person_id: numeric!
  $communication_preferences: vaxiom_communication_preferences_arr_rel_insert_input
) {
  insert_vaxiom_contact_method_associations_one(
    object: {
      person_id: $person_id
      contact_method_id: $contact_method_id
      communication_preferences: $communication_preferences
    }
  ) {
    id
    contact_method_id
  }
}

`