import React, { useEffect } from 'react'
import { useSetFlowTo, normalizePath } from 'Simple/Flow.js'
import { isCoreEmbedMessage } from 'Data/isCoreEmbedMessage.js'

import View from './view.js'

export default function Logic(props) {
  let setFlowTo = useSetFlowTo(props.viewPath)

  useEffect(() => {
    window.addEventListener('message', listener, false)

    return () => {
      window.removeEventListener('message', listener)
    }

    async function listener(event) {
      if (!isCoreEmbedMessage(event) || event.data.type !== 'close_section') {
        return
      }

      setFlowTo(normalizePath(props.viewPath, '../../TreatmentCard'))
    }
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  return <View {...props} />
}
