// this is an autogenerated file from App/Account/Content/Patients/Content/Content/Sections/ActiveTab/Profile/Content/NewProfile/Content/Professionals/AddAction/Search/Content/Profiles/Content/Profile/mutation_add_employee_professional_relationship.graphql
import { gql } from 'Data/Api'

export default gql`
mutation app__App_Account_Content_Patients_Content_Content_Sections_ActiveTab_Profile_Content_NewProfile_Content_Professionals_AddAction_Search_Content_Profiles_Content_Profile(
  $patient_id: numeric!
  $provider_id: numeric
) {
  insert_vaxiom_professional_relationships_one(
    object: {
      dtype: "emploProfessionalRelationship"
      patient_id: $patient_id
      past: false
      employee: { data: { provider_id: $provider_id } }
    }
  ) {
    id
  }
}

`