import { PutObjectCommand } from '@aws-sdk/client-s3'
import { useAwsCredentials, useAwsS3Client } from 'Data/s3.js'
import { useDataChange, useDataValue } from 'Simple/Data.js'
import { useMutation } from 'Data/Api.js'
import { notifyError, useNotifications } from 'Logic/Notifications.js'

import mutation from './mutation-update-contract-pdf.graphql.js'

/** @type {import('Simple/types.js').useDataOnSubmit} */
export default function useDataOnSubmit(props, data) {
  let getAwsCredentials = useAwsCredentials(props)
  let getS3Client = useAwsS3Client(props)
  let key = useDataValue({
    context: 'payor',
    path: 'contract.html_file_uuid',
    viewPath: props.viewPath,
  })
  let contract_id = useDataValue({
    context: 'payor',
    path: 'contract.id',
    viewPath: props.viewPath,
  })
  let setRefresh = useDataChange({
    context: 'preview',
    path: 'refresh',
    viewPath: props.viewPath,
  })
  let [, notify] = useNotifications()
  let [, executeMutation] = useMutation(mutation)

  return async function onSubmit({ value, originalValue, args, change }) {
    let awsCredentials = await getAwsCredentials()
    let s3 = await getS3Client()

    await s3.send(
      new PutObjectCommand({
        Bucket: awsCredentials.legacy_storage_bucket_name,
        Key: `patientdoc/${key}`,
        Body: value.content,
        ContentType: 'text/html',
      })
    )
    let mutationResponse = await executeMutation({
      id: contract_id,
    })

    if (mutationResponse.error) {
      notify(
        notifyError(
          'There was a problem saving the contract. Please try again.'
        )
      )
      return
    }

    change(next => {
      next.disabled = true
    })

    setRefresh(Date.now())
  }
}
