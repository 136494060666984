import { useDataValue, useDataSubmit } from 'Simple/Data.js'

export function useOnClick(props) {
  let type_id = useDataValue({
    context: 'selected',
    path: 'type_id',
    viewPath: props.viewPath,
  })
  let submit = useDataSubmit({
    context: 'appointment',
    viewPath: props.viewPath,
  })

  return function onClick() {
    submit({ type: 'edit', type_id })
  }
}
