import { personName, numberMoney } from 'Data/format.js'

export function getPayorName(payment_account) {
  switch (payment_account.account_type) {
    case 'PersonPaymentAccount':
      return personName(payment_account.person_payment_account.payer_person)
    case 'InsurancePaymentAccount':
      return (
        payment_account.insurance_payment_accounts[0]?.insurance_company
          .carrier_name ?? 'Insurance'
      )
    default:
      return 'N/A'
  }
}

/**
 *
 * @param {number} due_now
 * @param {Object} payment_account
 * @param {boolean} isLast
 * @returns
 */
export function getText(due_now, payment_account, isLast) {
  return `${numberMoney(due_now)}: ${getPayorName(payment_account)}`
}
