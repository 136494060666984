import { useDataChange, useDataValue } from 'Simple/Data.js'

export function useOnChange(props) {
  let change = useDataChange({
    context: 'automation',
    viewPath: props.viewPath,
  })
  let resources = useDataValue({
    context: 'resources',
    viewPath: props.viewPath,
  })
  return function onChange(value) {
    change(next => {
      next.custom_resource_type = value
      if (value) {
        next.app_id = resources.find(item => item.id === value).appId
      }
    })
  }
}
