import React, { useMemo } from 'react'
import ChangeStatusAction from './ChangeStatusAction'
import { useDataValue } from 'Simple/Data'

/** @type {import('Simple/types.js').useDataTransform} */
export default function useDataTransform(props) {
  let default_provider_location = useDataValue({
    viewPath: props.viewPath,
    context: 'default_provider_location',
  })

  return useMemo(() => {
    return {
      columns: [
        {
          header: 'Name',
          accessorKey: 'name',
          cell: cell => cell.getValue() ?? '-',
        },
        {
          header: 'Description',
          accessorKey: 'description',
          cell: cell => cell.getValue() ?? '-',
        },
        {
          header: 'Enabled',
          id: 'is_active',
          accessorKey: 'is_active',
          cell: cell => (
            <ChangeStatusAction
              viewPath={`${props.viewPath}/TableX/TableActions`}
              isActive={
                cell.row.original?.is_active && !cell.row.original?.is_disabled
              }
              disabledChangeStatus={cell.row.original?.is_disabled}
            />
          ),
        },
      ],
      data: [
        {
          name: 'Greyfinch Pay',
          description:
            'Automatically send request for payment when autopay fails. (available only with Payabli)',
          is_active:
            default_provider_location?.[0]?.location?.feature_flags
              ?.is_pay_app_enabled,
          is_disabled: default_provider_location?.[0]?.provider !== 'payabli',
        },
      ],
    }
  }, [default_provider_location])
}
