import { useMemo } from 'react'

export default function useDataTransform(props, data) {
  return useMemo(() => {
    if (!data) return

    return data
      .filter(item => !!item.person)
      .map(item => {
        let phones = item.person.phones
          .map(item => {
            let number = item.contact_method.phone?.number

            if (!number) return false

            return {
              id: item.id,
              number,
              description: item.description,
            }
          })
          .filter(Boolean)

        let is_employee = item.person.employment_contracts?.length > 0
        let is_external_professional =
          item.person.other_professional_relationships?.length > 0
        if (!is_employee && !is_external_professional) return false
        let resourceName = is_employee
          ? item.person.employment_contracts?.[0].employee_resources?.[0]
              ?.resource?.resource_type?.name
          : item.person.other_professional_relationships?.[0].employee_type.name
        let resourceId = is_employee
          ? item.person.employment_contracts?.[0].employee_resources?.[0]
              ?.resource?.resource_type?.id
          : item.person.other_professional_relationships?.[0].employee_type.id
        let location = is_employee
          ? item.person.employment_contracts?.[0].organization
          : item.person.other_professional_relationships?.[0].external_office

        return {
          ...item.person,
          is_employee,
          is_external_professional,
          resourceName,
          resourceId,
          location,
          phones,
        }
      })
      .filter(Boolean)
  }, [data]) // eslint-disable-line
  // ignore transform
}
