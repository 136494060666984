// this is an autogenerated file from DesignSystem/Data/DataVaxiom/DataVaxiomTxCardFieldDefinitions/DataVaxiomTxCardFieldDefinitionsList/data.graphql
import { gql } from 'Data/Api'

export default gql`
query app__DesignSystem_Data_DataVaxiom_DataVaxiomTxCardFieldDefinitions_DataVaxiomTxCardFieldDefinitionsList($location_id: uuid!) {
  vaxiom_tx_card_field_definitions(
    where: {
      organization: { _id: { _eq: $location_id } }
      type: { deleted: { _is_null: true } }
      deleted: { _is_null: true }
    }
    order_by: { number: asc_nulls_last }
  ) {
    id
    alert
    hide_for_future
    name
    short_name
    multi_value
    number
    tracked
    type_id
    mandatory
    type {
      id
      name
    }
  }
}

`