import { APPOINTMENT_TYPE_COLORS } from 'Data/constants.js'
import {
  capitalize,
  formatTimegridAppointmentTime,
  minutesToThreeDigitsTime,
  timeToMinutes,
} from 'Data/format.js'

/**
 *
 * @param {string} appointment_type_name
 * @returns
 */
export function formatAppointmentTypeName(appointment_type_name) {
  return appointment_type_name.split(' ').map(capitalize).join(' ')
}

export function getAppointmentTypeColor(display_color_id) {
  return APPOINTMENT_TYPE_COLORS[display_color_id]
}

export function getAppointmentBookingTime(appointment_booking) {
  let local_end_time = minutesToThreeDigitsTime(
    timeToMinutes(appointment_booking.local_start_time) +
      appointment_booking.duration
  )

  let start = `${appointment_booking.local_start_date}T${appointment_booking.local_start_time}`
  let end = `${appointment_booking.local_start_date}T${local_end_time}`
  return formatTimegridAppointmentTime({ start, end })
}
