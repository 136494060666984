// this is an autogenerated file from App/Account/Content/Patients/Content/Content/Sections/ActiveTab/Profile/Content/NewProfile/Content/Professionals/AddAction/New/mutation.graphql
import { gql } from 'Data/Api'

export default gql`
mutation app__App_Account_Content_Patients_Content_Content_Sections_ActiveTab_Profile_Content_NewProfile_Content_Professionals_AddAction_New(
  $person_details: json
  $contact_methods: json
  $office_details: json
  $employee_type_id: numeric
  $patient_id: numeric
  $addresses: json
) {
  insert_other_professional_relationship(
    args: {
      person_details: $person_details
      contact_methods: $contact_methods
      office_details: $office_details
      employee_type_id: $employee_type_id
      patient_id: $patient_id
      addresses: $addresses
    }
  ) {
    id
  }
}

`