import { useDataValue } from 'Simple/Data.js'

export default function useDataConfiguration(props) {
  let person_id = useDataValue({
    viewPath: props.viewPath,
    context: 'profile_patient',
    path: 'person.id',
  })
  let contact_method_id = useDataValue({
    viewPath: props.viewPath,
    context: 'address',
    path: 'postal_address.id',
  })

  return {
    variables: { contact_method_id, person_id },
    pause: !contact_method_id || !person_id,
  }
}
