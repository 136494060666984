import { useMemo } from 'react'

export default function useDataTransform(props, data) {
  return useMemo(() => {
    if (!data) return data

    let res = data.map(obj => ({
      ...obj?.person,
      contact_method_id: obj.contact_method_id,
    }))
    return res
  }, [data])
}
