import { useDataValue, useDataChange } from 'Simple/Data.js'
import {
  changeInsurancesEstimatedReimbursementAmount,
  changePayorsShare,
  changeDiscountsAppliedToPayorsShare,
  changePayorsConfiguration,
} from 'Data/payment-plan.js'

export function useOnChange(props) {
  let types = useDataValue({
    context: 'charge_types',
    viewPath: props.viewPath,
  })
  let treatment_plan = useDataValue({
    context: 'treatment_plan',
    viewPath: props.viewPath,
  })
  let index = useDataValue({
    context: 'charge_item',
    path: 'index',
    viewPath: props.viewPath,
  })
  let change = useDataChange({
    context: 'payment_plan',
    viewPath: props.viewPath,
  })

  return function onChange(value) {
    change(next => {
      let charge = next.charges[index]
      let type = types.find(item => item.id === value)

      // the price could also be 0
      if (typeof type?.price === 'number') {
        charge.type_id = type.id
        charge.name = type.name
        charge.amount = type.price
        charge.is_included_in_insurance_claim =
          type.is_included_in_insurance_claim

        changeInsurancesEstimatedReimbursementAmount(next, treatment_plan)
        changePayorsShare(next)
        changeDiscountsAppliedToPayorsShare(next)
        changePayorsConfiguration(next)
      } else {
        // custom value selected
        charge.type_id = value
        charge.name = value

        // price hasn't changed, no need to refresh the payor's share etc.
      }
    })
  }
}
