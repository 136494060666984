// this is an autogenerated file from App/Account/Content/Settings/AdminContent/SettingsContent/TreatmentCaseCloser/Configuration/DefaultPaymentPlans/AddRuleset/mutation.graphql
import { gql } from 'Data/Api'

export default gql`
mutation app__App_Account_Content_Settings_AdminContent_SettingsContent_TreatmentCaseCloser_Configuration_DefaultPaymentPlans_AddRuleset(
  $rulesets: [treatments_default_payment_plans_insert_input!]!
) {
  insert_treatments_default_payment_plans(objects: $rulesets) {
    affected_rows
    returning {
      id
    }
  }
}

`