import { useMemo } from 'react'
import { useDataValue } from 'Simple/Data.js'

/** @type {import('Simple/types.js').useDataTransform} */
export default function useDataTransform(props, data) {
  let chairs = useDataValue({
    context: 'schedule_note',
    path: 'resources',
    viewPath: props.viewPath,
  })

  return useMemo(() => {
    return chairs.map(item => ({
      id: item.id,
      text: item.name,
    }))
  }, [chairs])
}
