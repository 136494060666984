// this is an autogenerated file from DesignSystem/CommsThread/Content/Topbar/Internal/OpenPatientAction/query.graphql
import { gql } from 'Data/Api'

export default gql`
query app__DesignSystem_CommsThread_Content_Topbar_Internal_OpenPatientAction($id: uuid!) {
  comms_threads_by_pk(id: $id) {
    id
    resources
  }
}

`