// this is an autogenerated file from App/Account/Content/Patients/Content/Content/Sections/ActiveTab/Profile/Content/NewProfile/Content/Insurance/AddAction/Content/Content/SearchTable/data.graphql
import { gql } from 'Data/Api'

export default gql`
query app__App_Account_Content_Patients_Content_Content_Sections_ActiveTab_Profile_Content_NewProfile_Content_Insurance_AddAction_Content_Content_SearchTable(
  $number: String
  $search_term: String
  $organization_id: numeric!
  $employer_id: numeric
  $with_employer_id: Boolean!
  $carrier_id: numeric
  $with_carrier_id: Boolean!
  $with_filters_only: Boolean!
) {
  plans_with_employer_id: vaxiom_patient_insurance_plans(
    where: {
      insurance_company: { organization_id: { _eq: $organization_id } }
      _and: {
        number: { _like: $number }
        _and: {
          insurance_plan_employers: { employer_id: { _eq: $employer_id } }
        }
      }
    }
  ) @include(if: $with_employer_id) {
    _id
    id
    number
    insurance_company {
      id
      carrier_name
    }
    insurance_plan_employers {
      employer_id
      employer {
        name
      }
    }
    insurance_billing_center {
      id
      name
    }
  }
  plans_with_carrier_id: vaxiom_patient_insurance_plans(
    where: {
      insurance_company: { organization_id: { _eq: $organization_id } }
      _and: {
        number: { _like: $number }
        _and: { insurance_company: { id: { _eq: $carrier_id } } }
      }
    }
  ) @include(if: $with_carrier_id) {
    _id
    id
    number
    insurance_company {
      id
      carrier_name
    }
    insurance_plan_employers {
      employer_id
      employer {
        name
      }
    }
    insurance_billing_center {
      id
      name
    }
  }
  plans_by_filters: vaxiom_patient_insurance_plans(
    where: {
      insurance_company: { organization_id: { _eq: $organization_id } }
      _and: {
        number: { _like: $number }
        _or: [
          { insurance_company: { carrier_name: { _ilike: $search_term } } }
          {
            insurance_plan_employers: {
              employer: { name: { _ilike: $search_term } }
            }
          }
        ]
      }
    }
  ) @include(if: $with_filters_only) {
    _id
    id
    number
    insurance_company {
      id
      carrier_name
    }
    insurance_plan_employers {
      employer_id
      employer {
        name
      }
    }
    insurance_billing_center {
      id
      name
    }
  }
}

`