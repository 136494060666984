import '@uppy/core/dist/style.css'
import '@uppy/dashboard/dist/style.css'
import './Dashboard.css'
import { DashboardModal } from '@uppy/react'
import { useUppy, useUppyPluginContext } from '../FileUploaderProvider/react'
import React, { useEffect, useRef } from 'react'
import { FloatingPortal } from '@floating-ui/react'

export default function FileUploaderDashboardModal(props) {
  let { isOpen, onClose, restrictionNote, height, width } = props
  let uppy = useUppy(props)
  let [plugins] = useUppyPluginContext(props)

  // uppy was using an old ref of the fn after the upgrade to v3
  // this ensures we have the latest one temporarily
  let onCloseRef = useRef(onClose)
  useEffect(() => {
    onCloseRef.current = onClose
  }, [onClose])

  return (
    <FloatingPortal>
      <DashboardModal
        closeAfterFinish
        closeModalOnClickOutside
        doneButtonHandler={null}
        onRequestClose={onRequestClose}
        open={isOpen}
        proudlyDisplayPoweredByUppy={false}
        showRemoveButtonAfterComplete={true}
        plugins={plugins}
        // added key to trigger rerender so that Uppy picks up the plugins
        key={JSON.stringify(plugins)}
        uppy={uppy}
        note={restrictionNote || ''}
        width={width}
        height={height}
      />
    </FloatingPortal>
  )

  function onRequestClose(...args) {
    if (typeof onCloseRef.current === 'function') {
      onCloseRef.current(...args)
    }
  }
}
