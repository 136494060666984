import { useMemo } from 'react'

export default function useDataTransform(props, data) {
  return useMemo(() => {
    if (!data || !props.patientId) return data

    return {
      ...data,
      patient_id: Number(props.patientId),
      send_to_person_id: null,
      send_to_person_vaxiom_id:
        typeof props.personId === 'number'
          ? Number(props.personId)
          : data.send_to_person_vaxiom_id,
      auto_uploaded_attachments:
        typeof props.fileId === 'number' && props.fileType
          ? [
              {
                resource_id: Number(props.fileId),
                resource_type: props.fileType,
              },
            ]
          : data.auto_uploaded_attachments,
      message: props.message || data.message,
      tags: props.tags || data.tags,
      include_professionals:
        typeof props.includeProfessionals === 'boolean'
          ? props.includeProfessionals
          : true,
      include_external_professionals:
        typeof props.includeExternalProfessionals === 'boolean'
          ? props.includeExternalProfessionals
          : true,
    }
  }, [
    data,
    props.patientId,
    props.personId,
    props.fileId,
    props.fileType,
    props.message,
    props.tags,
    props.includeProfessionals,
    props.includeExternalProfessionals,
  ])
}
