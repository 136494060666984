// this is an autogenerated file from App/Account/Content/Patients/Content/Content/Sections/ActiveTab/Profile/Content/NewProfile/Content/Insurance/AssignedInsurance/Content/AssignedInsuranceItem/Remove/Content/RemoveAction/mutation.graphql
import { gql } from 'Data/Api'

export default gql`
mutation app__App_Account_Content_Patients_Content_Content_Sections_ActiveTab_Profile_Content_NewProfile_Content_Insurance_AssignedInsurance_Content_AssignedInsuranceItem_Remove_Content_RemoveAction($subscription_id: numeric!) {
  update_vaxiom_insured(
    where: { id: { _eq: $subscription_id } }
    _set: { deleted: true }
  ) {
    returning {
      id
    }
  }
}

`