import { useMemo } from 'react'
import { useDataValue } from 'Simple/Data.js'

/** @type {import('Simple/types.js').useDataTransform} */
export default function useDataTransform(props, data) {
  let patient_id = useDataValue({
    viewPath: props.viewPath,
    context: 'overlay',
    path: 'patient_id',
  })
  let appointment_id = useDataValue({
    viewPath: props.viewPath,
    context: 'overlay',
    path: 'appointment_id',
  })
  let treatment_id = useDataValue({
    viewPath: props.viewPath,
    context: 'overlay',
    path: 'treatment_id',
  })

  return useMemo(() => {
    if (!data) return data

    let newData = {
      ...data,
      ...(patient_id
        ? {
            patient_id,
            patient_option: 'existing',
            is_external_patient_id: true,
          }
        : {}),
      ...(appointment_id
        ? {
            appointment_id,
            is_external_appointment_id: true,
          }
        : {}),
      ...(treatment_id ? { treatment_id, is_external_treatment_id: true } : {}),
    }

    newData.is_external_any =
      newData.is_external_patient_id === true ||
      newData.is_external_appointment_id === true ||
      newData.is_external_treatment_id === true

    return newData
  }, [data, patient_id, appointment_id, treatment_id])
}
