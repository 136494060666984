import { useDataIsSubmitting, useDataSubmit, useDataValue } from 'Simple/Data'

export function useDisabled(props) {
  let isSubmitting = useDataIsSubmitting({
    viewPath: props.viewPath,
    context: 'appointment_actions',
  })

  let selected_contact_method = useDataValue({
    context: 'schedule_via',
    viewPath: props.viewPath,
    path: 'selected_contact_method',
  })

  return isSubmitting || !selected_contact_method
}

export function useOnClick(props) {
  let selected_contact_method = useDataValue({
    context: 'schedule_via',
    viewPath: props.viewPath,
    path: 'selected_contact_method',
  })

  let template_type = useDataValue({
    context: 'schedule_via',
    viewPath: props.viewPath,
    path: 'template_type',
  })

  let custom_instructions = useDataValue({
    context: 'schedule_via',
    viewPath: props.viewPath,
    path: 'custom_instructions',
  })

  let submit = useDataSubmit({
    context: 'appointment_actions',
    viewPath: props.viewPath,
  })

  return function onClick() {
    submit({
      type: 'scheduleVia',
      selected_contact_method,
      custom_instructions:
        template_type === 'custom' ? custom_instructions : null,
    })
  }
}
