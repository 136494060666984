import { useMemo } from 'react'

let TIME_INTERVALS = ['0-30', '31-60', '61-90', '90-120', '> 120']

/** @type {import('Simple/types.js').useDataTransform} */
export default function useDataTransform(props, data) {
  return useMemo(() => {
    if (!data) return data
    else if (!Array.isArray(data)) return data

    return data
      .map(d => {
        let dues = [
          d.due_now - d.due_30_days,
          d.due_30_days - d.due_60_days,
          d.due_60_days - d.due_90_days,
          d.due_90_days - d.due_120_days,
          d.due_120_days,
        ]
        return dues
          .filter(due => due > 0)
          .map((due, index) => ({
            ...d,
            due,
            time_interval: TIME_INTERVALS[index],
          }))
          .flat()
      })
      .flat()
  }, [data])
}
