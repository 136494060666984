import { useMemo } from 'react'
import { useDataValue } from 'Simple/Data.js'

/** @type {import('Simple/types.js').useDataTransform} */
export default function useDataTransform(props, data) {
  let alerts = useDataValue({
    context: 'calendar',
    viewPath: props.viewPath,
    path: 'alerts',
  })
  let resources = useDataValue({
    context: 'calendar',
    viewPath: props.viewPath,
    path: 'resources',
  })

  return useMemo(() => {
    if (!alerts.length) return data

    let groupedData = alerts.reduce(
      (accumulator, item) => {
        let key = `${item.created_by?.person?.id}|${item.start}|${item.end}|${item.duration}|${item.content}`

        if (!accumulator[item.type][key]) {
          accumulator[item.type][key] = {
            created_by: item.created_by,
            start: item.start,
            end: item.end,
            duration: item.duration,
            content: item.content,
            sys_created: item.sys_created,
            type: item.type,
            resources: [],
          }
        }

        accumulator[item.type][key].resources.push(
          resources.find(resource => resource.id === item.resourceId)
        )

        return accumulator
      },
      { note: {}, blockTime: {} }
    )

    return {
      notes: Object.values(groupedData.note),
      blocked_times: Object.values(groupedData.blockTime),
      has_alerts: true,
    }
  }, [alerts, resources, data])
}
