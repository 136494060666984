import { useMemo } from 'react'

export default function useDataTransform(props, data) {
  return useMemo(() => {
    if (!data) return

    return data
      .map(item => {
        if (item.person && item.person.patient) {
          return {
            ...item.person,
            is_patient: true,
          }
        }
      })
      .filter(Boolean)
  }, [data]) // eslint-disable-line
  // ignore transform
}
