// this is an autogenerated file from App/Account/Content/Patients/Content/Content/Sections/ActiveTab/Profile/Content/NewProfile/removeCommsPreferencesMutation.graphql
import { gql } from 'Data/Api'

export default gql`
mutation app__App_Account_Content_Patients_Content_Content_Sections_ActiveTab_Profile_Content_NewProfile($comms_pref_id: numeric!) {
  delete_vaxiom_communication_preferences_by_pk(id: $comms_pref_id) {
    id
  }
}

`