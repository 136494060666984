import { useMemo } from 'react'

/** @type {import('Simple/types.js').useDataTransform} */
export default function useDataTransform(props, data) {
  return useMemo(() => {
    if (!data) return []
    return data.employers.concat(
      data.selected_employer ? data.selected_employer : []
    )
  }, [data])
}
