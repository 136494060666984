// this is an autogenerated file from App/Account/WaitingRoom/Content/Content/Agenda/Appointments/Content/Appointment/Action/Action/CheckIn/query_patient_due_amount.graphql
import { gql } from 'Data/Api'

export default gql`
query app__App_Account_WaitingRoom_Content_Content_Agenda_Appointments_Content_Appointment_Action_Action_CheckIn($patient_id: uuid!) {
  financial_patient_due_amount(
    args: { _patient_id: $patient_id }
    distinct_on: [payment_account_id]
  ) {
    id: payment_account_id
    due_now
    due_30_days
    due_60_days
    due_90_days
    due_120_days
    payment_account {
      id
      account_type
    }
  }
}

`