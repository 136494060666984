// this is an autogenerated file from DesignSystem/DataVaxiomRelationshipsItem/Edit/NonPatient/Postal/NewAddress/Content/query.graphql
import { gql } from 'Data/Api'

export default gql`
query app__DesignSystem_DataVaxiomRelationshipsItem_Edit_NonPatient_Postal_NewAddress_Content(
  $address_line1: String!
  $city: String!
  $state: String!
  $zip: String!
) {
  vaxiom_contact_postal_addresses(
    where: {
      address_line1: { _eq: $address_line1 }
      city: { _eq: $city }
      state: { _eq: $state }
      zip: { _eq: $zip }
    }
  ) {
    id
  }
}

`