// this is an autogenerated file from App/Account/Content/Patients/Content/Content/Sections/ActiveTab/TreatmentCard/Content/TreatmentCard/Treatment/Content/AddNewTreatmentAction/NewTreatmentModal/Content/mutation.graphql
import { gql } from 'Data/Api'

export default gql`
mutation app__App_Account_Content_Patients_Content_Content_Sections_ActiveTab_TreatmentCard_Content_TreatmentCard_Treatment_Content_AddNewTreatmentAction_NewTreatmentModal_Content($patient_id: uuid!, $location_id: uuid!) {
  vaxiom_treatment_card_create(
    patient_id: $patient_id
    location_id: $location_id
  ) {
    id
    tx_card {
      id
      _id
      txs {
        id
        _id
      }
    }
  }
}

`