import { useDataValue } from 'Simple/Data.js'

export default function useDataConfiguration(props) {
  let from_person_id = useDataValue({
    viewPath: props.viewPath,
    context: 'person',
    path: 'person.id',
  })
  let to_person_id = useDataValue({
    viewPath: props.viewPath,
    context: 'profile_patient',
    path: 'person.id',
  })
  return {
    variables: { from_person_id, to_person_id },
  }
}
