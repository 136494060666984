import { useMemo } from 'react'

export default function useDataTransform(props, data) {
  return useMemo(() => {
    if (!data) return data

    let result = data?.[0]
    if (!result) {
      return {}
    }
    result.providers = result.employees.filter(
      e => e.employee?.resource?.resource_type?.is_provider
    )
    result.assistants = result.employees.filter(
      e => e.employee?.resource?.resource_type?.is_assistant
    )

    return result
  }, [data])
}
