import { useMemo } from 'react'
import { useDataValue } from 'Simple/Data.js'

/** @type {import('Simple/types.js').useDataTransform} */
export default function useDataTransform(props, data) {
  let treatment_plans = useDataValue({
    context: 'treatment_plans',
    viewPath: props.viewPath,
  })

  return useMemo(
    () =>
      treatment_plans
        // tx_plans coming from conversions don't have a template
        .filter(item => !!item.tx_plan_template)
        .reduce(
          (acc, item) => ({
            ...acc,
            [item.tx_plan_template.id]: true,
          }),
          {}
        ),
    [treatment_plans]
  )
}
