import { useMutation, useClient } from 'Data/Api.js'
import { useDataValue } from 'Simple/Data.js'
import mutation from './mutation.graphql.js'
import query from './query.graphql.js'
import {
  notifyError,
  notifySuccess,
  useNotifications,
} from 'Logic/Notifications.js'

import { useAwsS3Client, useAwsCredentials } from 'Data/s3.js'
import { Upload } from '@aws-sdk/lib-storage'
import { base64ToBlob } from 'Logic/base64.js'
import { v4 as uuid } from 'uuid'

let PATIENT_IMAGE_PREFIX = 'patientimg'

export function useOnUploadCaptures(props) {
  let [, executeMutation] = useMutation(mutation)

  let patient_id = useDataValue({
    context: 'patient',
    path: 'id',
    viewPath: props.viewPath,
  })

  let imageseries_id = useDataValue({
    context: 'tab',
    path: 'selected.images.image_series_id',
    viewPath: props.viewPath,
  })
  let getAwsCredentials = useAwsCredentials({
    viewPath: props.viewPath,
  })

  let s3 = useAwsS3Client(props)

  let [, notify] = useNotifications()

  let client = useClient()

  return async function onUploadCaptures(captures) {
    try {
      let objects = await Promise.all(
        captures.map(async content => {
          let fileKey = uuid()
          let body = base64ToBlob(
            content.replace(/^data:image\/\w+;base64,/, ''),
            'image/png'
          )

          let destination_key = `${PATIENT_IMAGE_PREFIX}/${fileKey}`
          let credentials = await getAwsCredentials()

          await upload(
            {
              Bucket: credentials.legacy_storage_bucket_name,
              Key: destination_key,
              Body: body,
              ContentType: 'image/png',
            },
            s3
          )

          return {
            patient_id,
            imageseries_id,
            type_key: 'misc',
            slot_key: null,
            file_key: fileKey,
          }
        })
      )
      let mutationResponse = await executeMutation({
        objects,
      })

      if (mutationResponse.error) {
        throw mutationResponse.error
      }
      notify(
        notifySuccess('The captures were uploaded successful to Misc section')
      )

      try {
        // Refresh images
        await client
          .query(query, { image_series_id: imageseries_id })
          .toPromise()
      } catch (error) {}

      return mutationResponse.data.insert_vaxiom_patient_images?.returning
    } catch (error) {
      notify(
        notifyError(
          'Something went wrong. Please, try again or contact support if the problem persists.'
        )
      )

      return []
    }
  }
}

async function upload(params, s3Client) {
  let upload = new Upload({
    client: s3Client,
    params,
  })

  return upload.done()
}
