import { useMemo } from 'react'
import { useDataValue } from 'Simple/Data.js'

export default function useDataConfiguration(props) {
  let actions = useDataValue({
    context: 'actions',
    viewPath: props.viewPath,
  })

  let updated_at = useMemo(() => new Date(), [])
  return {
    variables: {
      action_ids: Object.keys(actions), // actions are keyed by action ids
      updated_at,
    },
  }
}
