// this is an autogenerated file from App/Account/Content/Patients/Content/Content/Sections/ActiveTab/TreatmentCard/Content/TreatmentPlans/New/Steps/Content/TreatmentPlanTemplates/Content/data.graphql
import { gql } from 'Data/Api'

export default gql`
query app__App_Account_Content_Patients_Content_Content_Sections_ActiveTab_TreatmentCard_Content_TreatmentPlans_New_Steps_Content_TreatmentPlanTemplates_Content($treatment_id: uuid!) {
  vaxiom_tx_plans(
    where: {
      deleted: { _eq: false }
      tx: { _id: { _eq: $treatment_id } }
      tx_plan_template: { deleted: { _eq: false } }
    }
  ) {
    id
    _id
    tx_plan_template {
      id
      tx_plan_group_id
    }
  }
}

`