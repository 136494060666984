import { useDataValue } from 'Simple/Data.js'
import {
  generateSignedUrl,
  getS3ObjectUrl,
  useAwsCredentials,
  useAwsS3Client,
} from 'Data/s3.js'

export function useOnClick(props) {
  let getAwsCredentials = useAwsCredentials(props)
  let getS3Client = useAwsS3Client(props)
  let key = useDataValue({
    context: 'payor',
    path: 'contract.file',
    viewPath: props.viewPath,
  })
  let template_name = useDataValue({
    context: 'payor',
    path: 'contract.template_name',
    viewPath: props.viewPath,
  })

  return async function onClick() {
    let awsCredentials = await getAwsCredentials()
    let url = getS3ObjectUrl({
      bucket: awsCredentials.legacy_storage_bucket_name,
      key: `patientdoc/${key}`,
      region: awsCredentials.region,
    })
    let presigend_url = await generateSignedUrl(getS3Client, url, {
      download: true,
      filename: `${template_name}.pdf`,
      content_type: 'application/json',
    })
    window.open(presigend_url, '_blank')
  }
}
