// this is an autogenerated file from App/Account/Content/Calendar/New/Content/Content/AppointmentOverlay/Content/mutation-schedule-new-patient.graphql
import { gql } from 'Data/Api'

export default gql`
mutation app__App_Account_Content_Calendar_New_Content_Content_AppointmentOverlay_Content(
  $appointment_type_id: uuid!
  $location_id: uuid!
  $date: date!
  $start_time: time!
  $end_time: time!
  $chair_id: uuid!
  $patient_form: jsonb!
  $note: String
  $unplanned: Boolean!
) {
  calendar_scheduling_admin_for_new_patient(
    args: {
      _appointment_type_id: $appointment_type_id
      _location_id: $location_id
      _date: $date
      _start_time: $start_time
      _end_time: $end_time
      _chair_id: $chair_id
      _patient_form: $patient_form
      _note: $note
      _unplanned: $unplanned
    }
  ) {
    _id
    id
  }
}

`