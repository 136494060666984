import React from 'react'

import View from './view.js'

let MAX_FILE_SIZE_IN_APP = 1024 * 1024 * 50 // 50MB
let ALLOWED_FILE_TYPES_IN_APP = ['application/pdf']
let restrictions = {
  maxFileSize: MAX_FILE_SIZE_IN_APP,
  allowedFileTypes: ALLOWED_FILE_TYPES_IN_APP,
  maxNumberOfFiles: 1,
  minNumberOfFiles: 1,
}

export default function Logic(props) {
  return <View viewPath={props.viewPath} restrictions={restrictions} />
}
