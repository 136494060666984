// this is an autogenerated file from App/Account/Content/Patients/Content/Content/Sections/Tabs/Apps/data.graphql
import { gql } from 'Data/Api'

export default gql`
query app__App_Account_Content_Patients_Content_Content_Sections_Tabs_Apps($location_id: uuid!, $resource_id: uuid!) {
  apps_launchers(
    slot: ADMIN_PATIENT
    location_id: $location_id
    resource_id: $resource_id
  ) {
    id
    shortName
    launchers {
      id
      app_id
      icon
      name
      active
      connected
      type
      description
    }
  }
}

`