import { useMutation } from 'Data/Api.js'
import { notifyError, useNotifications } from 'Logic/Notifications.js'
import { useDataSubmit, useDataValue } from 'Simple/Data.js'

import mutationRequirementsCheck from './mutation-requirements-check.graphql.js'

export default function useDataOnSubmit(props) {
  let id = useDataValue({
    context: 'event',
    path: 'appointment.booking.0._id',
    viewPath: props.viewPath,
  })

  let submit = useDataSubmit({
    context: 'bottom_action',
    viewPath: props.viewPath,
  })

  let [, executeMutationRequirementsCheck] = useMutation(
    mutationRequirementsCheck
  )
  let [, notify] = useNotifications()

  return async function onSubmit({ args }) {
    if (!args?.skip_required_fields) {
      let mutationRequirementsCheckResponse =
        await executeMutationRequirementsCheck({
          id,
          params: { id, skip_required_fields: false },
        })
      if (mutationRequirementsCheckResponse.error) {
        notify(
          notifyError('Cannot unseat selected appointment. Please try again.')
        )
        return
      }

      if (
        mutationRequirementsCheckResponse.data
          .create_pre_appointment_bookings_state_update_automations.is_pending
      ) {
        // cannot unseat yet as some requirements need to be checked
        return
      }
    }

    return await submit({
      type: 'unseat',
      skip_required_fields: args?.skip_required_fields || false,
    })
  }
}
