// this is an autogenerated file from DesignSystem/DataVaxiomPatientInsuranceAssignForm/data.graphql
import { gql } from 'Data/Api'

export default gql`
query app__DesignSystem_DataVaxiomPatientInsuranceAssignForm($id: uuid!) {
  vaxiom_patient_insurance_plans(where: { _id: { _eq: $id } }) {
    id
    insurance_company {
      id
      carrier_name
    }
    number
    name
    insurance_billing_center {
      id
      name
    }
    tx_category_coverages {
      id
      ortho_coverages {
        id
        max_lifetime_coverage
      }
    }
  }
}

`