// this is an autogenerated file from DesignSystem/DataVaxiomProfessionalsRelationshipsList/data.graphql
import { gql } from 'Data/Api'

export default gql`
query app__DesignSystem_DataVaxiomProfessionalsRelationshipsList(
  $patient_id: uuid!
  $dtype: String_comparison_exp
  $past: Boolean
) {
  vaxiom_professional_relationships(
    where: {
      dtype: $dtype
      patient: { _id: { _eq: $patient_id } }
      past: { _eq: $past }
    }
  ) {
    id
    dtype
    past
    employee {
      id
      provider {
        id
        resource_type {
          id
          name
        }
        employee_resource {
          id
          employment_contract {
            id
            organization {
              id
              name
              postal_address {
                id
                address_line1
                city
                state
                zip
              }
            }
            person {
              id
              first_name
              middle_name
              last_name
              title
              greeting
              note {
                id
                note
              }
              profile_pic_url
              preferred_contact_methods: contact_method_associations {
                id
                postal_address {
                  id
                  address_line1
                  city
                  state
                  zip
                }
                phone {
                  id
                  number
                }
                email {
                  id
                  address
                }
              }
            }
          }
        }
      }
    }

    other {
      id
      employee_type {
        id
        name
      }
      person {
        id
        first_name
        middle_name
        last_name
        title
        greeting
        profile_pic_url
        note {
          id
          note
        }
        preferred_contact_methods: contact_method_associations {
          id
          phone {
            id
            number
          }
          email {
            id
            address
          }
        }
      }
      external_office {
        id
        name
        postal_address {
          id
          address_line1
          city
          state
          zip
        }
      }
    }
  }
}

`