import { useMemo } from 'react'
import { useDataValue } from 'Simple/Data'

export default function useDataTransform(props, data) {
  let patient_id = useDataValue({
    viewPath: props.viewPath,
    context: 'relationship',
    path: 'from_person_record.patient._id',
  })
  return useMemo(() => {
    if (!data) return data

    return [
      ...data,
      ...(patient_id
        ? [
            {
              id: 'OPEN_CARD',
              text: 'Open Patient Card',
            },
          ]
        : []),
    ]
  }, [data])
}
