// this is an autogenerated file from App/Account/Content/Patients/Content/Content/Sections/ActiveTab/Profile/Content/NewProfile/Content/ContactInfo/Email/Emails/sendHubInviteMutation.graphql
import { gql } from 'Data/Api'

export default gql`
mutation app__App_Account_Content_Patients_Content_Content_Sections_ActiveTab_Profile_Content_NewProfile_Content_ContactInfo_Email_Emails($email: citext!, $location_id: numeric!) {
  auth_patient_invite(email: $email, location_id: $location_id) {
    ok
  }
}

`