// this is an autogenerated file from DesignSystem/AppointmentActions/CheckInPatient/Content/query_patient_due_amount.graphql
import { gql } from 'Data/Api'

export default gql`
query app__DesignSystem_AppointmentActions_CheckInPatient_Content($patient_id: uuid!) {
  financial_patient_due_amount(
    args: { _patient_id: $patient_id }
    distinct_on: [payment_account_id]
  ) {
    id: payment_account_id
    due_now
    due_30_days
    due_60_days
    due_90_days
    due_120_days
    payment_account {
      id
      account_type
    }
  }
}

`