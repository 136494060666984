import { useDataValue, useDataSubmit } from 'Simple/Data.js'
import { useMutation } from 'Data/Api.js'
import { notifyError, useNotifications } from 'Logic/Notifications.js'
import { toPostgresArray } from 'Data/format.js'

import mutation from './mutation.graphql.js'

/** @type {import('Simple/types.js').useDataOnSubmit} */
export default function useDataOnSubmit(props, data) {
  let treatment_id = useDataValue({
    viewPath: props.viewPath,
    context: 'tab',
    path: 'treatment_id',
  })
  let current_location_id = useDataValue({
    viewPath: props.viewPath,
    context: 'global',
    path: 'current_location.id',
  })
  let submit = useDataSubmit({
    viewPath: props.viewPath,
    context: 'wizard',
  })
  let [, notify] = useNotifications()
  let [, executeMutation] = useMutation(mutation)

  return async function onSubmit({ value, originalValue, args, change }) {
    let mutationResponse = await executeMutation({
      treatment_id,
      current_location_id,
      treatment_plan_template_ids: toPostgresArray(
        Object.keys(value).map(Number)
      ),
    })
    if (mutationResponse.error) {
      notify(
        notifyError(
          `There was a problem creating the treatment plans. Please try again.`
        )
      )
      return
    }

    submit({
      type: 'continue',
    })
  }
}
