import { useDataValue } from 'Simple/Data.js'

export default function useDataConfiguration(props) {
  let selectedLocations = useDataValue({
    context: 'selected',
    path: 'locations',
    viewPath: props.viewPath,
  })

  let showDeleted = useDataValue({
    context: 'selected',
    path: 'show_deleted',
    viewPath: props.viewPath,
  })

  let isSupport = useDataValue({
    context: 'auth',
    path: 'access_token_data.is_support',
    viewPath: props.viewPath,
  })

  let whereConditions = isSupport
    ? {}
    : {
        _or: [
          { tx_category: { name: { _neq: 'Ortho' } } },
          {
            _and: [
              { name: { _nilike: 'New tx' } },
              { tx_category: { name: { _eq: 'Ortho' } } },
            ],
          },
        ],
      }

  return {
    variables: {
      where: {
        organization_id: {
          _in: selectedLocations?.map(location => location.id) || [],
        },
        deleted: { _in: showDeleted ? [true, false] : [false] },
        ...whereConditions,
      },
    },
    pause: !selectedLocations?.length,
  }
}
