import { useDataValue, useDataSubmit } from 'Simple/Data.js'
import { useClient } from 'Data/Api.js'
import { notifyError, useNotifications } from 'Logic/Notifications.js'
import query from './query.graphql.js'

export function useOnClick(props) {
  let client = useClient()
  let [, notify] = useNotifications()

  let thread_id = useDataValue({
    context: 'thread',
    path: 'id',
    viewPath: props.viewPath,
  })

  let submit = useDataSubmit({
    context: 'global',
    viewPath: props.viewPath,
  })

  return async function onClick() {
    let response = await client.query(query, { id: thread_id }).toPromise()
    if (!response.data?.comms_threads_by_pk) {
      return notify(notifyError(`Couldn't find patient`))
    }

    let resource = response.data.comms_threads_by_pk.resources.find(
      item => item.table === 'patients' && item.id
    )
    if (!resource) {
      return notify(notifyError(`Couldn't find patient`))
    }

    submit({
      type: 'tabs/add',
      tab: {
        id: resource.id,
        type: 'Patient',
        name: 'Patient',
        tab: 'profile',
        viewPath: '/App/Account/Content/Patients',
        patient_id: resource.id,
      },
    })
  }
}
