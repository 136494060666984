import {
  useDataIsSubmitting,
  useDataFormat,
  useDataSubmit,
} from 'Simple/Data.js'

export function useIsDisabled(props) {
  let isSubmitting = useDataIsSubmitting({
    context: 'payment_plan',
    viewPath: props.viewPath,
  })
  let isMissingInsurance = useDataFormat({
    context: 'payment_plan',
    path: 'insurances',
    // couldn't save if there is any insurance without a selected subscription
    format: insurances => insurances.some(insurance => !insurance.insured_id),
    viewPath: props.viewPath,
  })
  let isMissingPayor = useDataFormat({
    context: 'payment_plan',
    path: 'payors',
    // couldn't save if there is no payor selected
    format: payors => payors.some(payor => !payor.person_id),
    viewPath: props.viewPath,
  })

  return isSubmitting || isMissingInsurance || isMissingPayor
}

export function useOnClick(props) {
  let submit = useDataSubmit({
    context: 'payment_plan',
    viewPath: props.viewPath,
  })

  return function onClick() {
    submit({ type: 'view-contracts' })
  }
}
