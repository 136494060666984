import { useSetFlowTo, normalizePath } from 'Simple/Flow.js'
import { useMutation } from 'Data/Api'
import {
  notifyError,
  useNotifications,
  notifySuccess,
} from 'Logic/Notifications.js'
import { useDataValue } from 'Simple/Data.js'
import { textInput, name, street, city, state, zip } from 'Data/validate.js'
import mutation_update_professional from './updateProfessionalMutation.graphql.js'

export default function useDataOnSubmit(props, data) {
  let [, notify] = useNotifications()
  let setFlowTo = useSetFlowTo(props.viewPath)
  let [, executeMutationUpdateProfessional] = useMutation(
    mutation_update_professional
  )
  let location_id = useDataValue({
    context: 'global',
    path: 'current_location.id',
    viewPath: props.viewPath,
  })

  return async function onSubmit({ value, originalValue, args }) {
    let { person } =
      value.employee.provider.employee_resource.employment_contract
    let { provider } = value.employee
    if (!name(person.first_name) || !name(person.last_name)) {
      return true
    }
    let update_note = typeof person.note?.id !== 'undefined'
    let mutationUpdateProfessionalResponse =
      await executeMutationUpdateProfessional({
        person_id: person.id,
        title: person.title,
        greeting: person.greeting,
        provider_id: provider.id,
        resource_type_id: provider.resource_type.id,
        location_id,
        note: person.note?.note,
        note_id: person.note?.id || null,
        update_note,
        create_note: !update_note ? !!person.note?.note : false,
      })
    if (mutationUpdateProfessionalResponse.error) {
      notify(
        notifyError(
          'Something went wrong. Please, try again or contact support if the problem persists.'
        )
      )
      return
    }
    notify(notifySuccess('Professional edited successfully.'))
    setFlowTo(normalizePath(props.viewPath, '../No'))
  }
}
