import { useMemo } from 'react'
import { useDataValue } from 'Simple/Data.js'

export default function useDataTransform(props, data) {
  let is_treatment_plan_applied = useDataValue({
    context: 'treatment',
    path: 'is_treatment_plan_applied',
    viewPath: props.viewPath,
  })
  let treatment_plan_action = useDataValue({
    context: 'treatment',
    path: 'treatment_plan_action',
    viewPath: props.viewPath,
  })

  return useMemo(() => {
    let steps = is_treatment_plan_applied
      ? [
          'treatment_plan_templates',
          'treatment_plans',
          'payment_plans',
          'contracts',
        ]
      : ['payment_plans', 'contracts']
    let current_step
    if (
      [
        'view_case_closer_request',
        'create_payment_plan',
        'resume_payment_plan',
      ].includes(treatment_plan_action)
    ) {
      current_step = 'payment_plans'
    } else if (treatment_plan_action === 'resume_treatment_plan') {
      current_step = 'treatment_plans'
    } else {
      current_step = 'treatment_plan_templates'
    }

    return {
      ...data,
      steps,
      current_step,
    }

    // only allow setting up the payment plan if the treatment plan already applied
  }, [data, is_treatment_plan_applied, treatment_plan_action])
}
