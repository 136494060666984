// this is an autogenerated file from DesignSystem/DataVaxiomPatientInsuranceAssignForm/Content/PolicyHolder/Options/AddRelationship/Content/Email/Fields/EmailAddress/data.graphql
import { gql } from 'Data/Api'

export default gql`
query app__DesignSystem_DataVaxiomPatientInsuranceAssignForm_Content_PolicyHolder_Options_AddRelationship_Content_Email_Fields_EmailAddress($person_id: uuid!) {
  vaxiom_contact_method_associations(
    where: {
      person: { _id: { _eq: $person_id } }
      contact_method: { dtype: { _eq: "email" } }
    }
    order_by: { email: { address: asc } }
  ) {
    id
    contact_method_id
    description
    email {
      id
      address
    }
  }
}

`