import { useDataValue } from 'Simple/Data.js'
/** @type {import('Simple/types.js').useDataConfiguration} */
export default function useDataConfiguration(props, data) {
  let patient_id = useDataValue({
    viewPath: props.viewPath,
    context: 'profile_patient',
    path: '_id',
  })
  return {
    variables: { patient_id },
  }
}
