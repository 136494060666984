// this is an autogenerated file from App/Account/Content/Patients/Content/Content/Sections/ActiveTab/TreatmentCard/Content/TreatmentPlans/New/Steps/Content/Contracts/Content/Payor/Content/Upload/Content/Modal/mutation.graphql
import { gql } from 'Data/Api'

export default gql`
mutation app__App_Account_Content_Patients_Content_Content_Sections_ActiveTab_TreatmentCard_Content_TreatmentPlans_New_Steps_Content_Contracts_Content_Payor_Content_Upload_Content_Modal($id: numeric!) {
  update_vaxiom_tx_contracts_by_pk(
    pk_columns: { id: $id }
    _set: { generated_date: "now()", signed_date: "now()" }
  ) {
    id
    generated_date
    signed_date
  }
}

`