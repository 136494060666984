import { useMemo } from 'react'

/** @type {import('Simple/types.js').useDataTransform} */
export default function useDataTransform(props, data) {
  return useMemo(() => {
    if (!data) return data

    // There will be only one recievable attached to a payment account with multiple transactions.
    //
    // Transactions with type
    //  * Transfer Charge is the total share of that payer excluding discounts.
    //  * Payment plan is the installment setup of that payer. This doesn't include last installment and duration. need to get that dynamically.
    //
    // To get the downpayment amount for other payers:
    //  * Transfer Charge - Discounts - Payment Plan
    // For primary payer it is in payment plan transaction?

    let payers = data.tx_payers.map(payer => {
      let total = 0
      let length = 0
      let frequency = 0
      let installment = 0
      let last_installment = 0
      let installment_total = 0
      let downpayment = 0
      let payment_plan_id = null
      let receivable_id = null

      let transactions = payer.account.receivables.flatMap(
        item => item.transactions
      )

      let discounts = transactions
        .filter(
          r =>
            r.dtype === 'DiscountAdjustment' &&
            r.discount_adjustment.discount_adjustment_type ===
              'PersonPayerDiscount'
        )
        .map(d => ({
          name: d.discount_adjustment.discount.name,
          amount: Math.abs(d.amount),
        }))

      let total_discounts = discounts.reduce(
        (res, item) => res + item.amount,
        0
      )

      let payment_plan_receivable = payer.account.receivables.find(item =>
        item.transactions.some(item => item.dtype === 'PaymentPlan')
      )
      if (payment_plan_receivable) {
        receivable_id = payment_plan_receivable.id

        let transaction = payment_plan_receivable.transactions.find(
          item => item.dtype === 'PaymentPlan'
        )

        downpayment = transaction.payment_plan.requested_downpayment_amount

        installment = transaction.payment_plan.installment_amount
        frequency = transaction.payment_plan.installment_interval
        payment_plan_id = transaction.payment_plan.id

        installment_total = Math.abs(transaction.amount)
        total = installment_total + downpayment + total_discounts
        length = Math.ceil(
          Math.abs(transaction.amount) /
            transaction.payment_plan.installment_amount
        )
        last_installment =
          Math.abs(transaction.amount) % installment === 0
            ? installment
            : Number((Math.abs(transaction.amount) % installment).toFixed(2))
      }

      return {
        id: payer.account.id,
        name: `${payer.person.first_name} ${payer.person.last_name}`,
        patient_id: data.tx_card.patient._id,
        person_id: payer.person._id,
        payer_person: payer.person,
        organization_id: data.organization._id,
        completed: false,
        downpayment_token_id: null,
        downpayment_complete: false,
        installment_complete: false,
        downpayment_metadata: {
          type: 'card',
          cc: {
            save: false,
          },
          check: {
            payer: null,
            number: null,
          },
        },
        installment_metadata: {
          type: 'card',
        },
        data: {
          receivable_id,
          total,
          length,
          frequency,
          downpayment,
          installment,
          last_installment,
          installment_total,
          total_discounts: Math.abs(total_discounts),
          discounts,
          payment_plan_id,
        },
      }
    })

    // all treatment related transcations get created on primary payer
    let primary_payer = data.tx_payers.find(payer => payer.primary_payer)

    let treatment_charges = primary_payer.account.receivables
      .flatMap(item => item.transactions)
      .filter(t => t.dtype === 'MiscFeeCharge')
      .map(c => ({
        name: c.misc_fee_charge.name,
        amount: c.amount,
      }))

    let treatment_fee = primary_payer.account.receivables
      .flatMap(item => item.transactions)
      .find(t => t.dtype === 'TxFeeCharge').amount

    let treatment_discounts = primary_payer.account.receivables
      .flatMap(item => item.transactions)
      .filter(
        t =>
          t.dtype === 'DiscountAdjustment' &&
          t.discount_adjustment.discount_adjustment_type === 'TxFeeDiscount'
      )
      .map(d => ({
        name: d.discount_adjustment.discount.name,
        amount: Math.abs(d.amount),
      }))

    let treatment = {
      name: data.tx_plans?.[0]?.name,
      total: treatment_fee,
      charges: treatment_charges,
      total_charges: treatment_charges.reduce(
        (res, item) => res + item.amount,
        0
      ),
      discounts: treatment_discounts,
      total_discounts: treatment_discounts.reduce(
        (res, item) => res + item.amount,
        0
      ),
    }

    let activeTab = {
      id: 0,
      name: 'Notes',
      view: 'Notes',
    }

    // skip the notes tab if only a single payer
    if (payers.length === 1) {
      activeTab = {
        ...payers[0],
        view:
          payers[0].data.downpayment > 0
            ? 'DownpaymentSetup'
            : 'AutopaymentSetup',
      }
    }
    treatment.total_to_be_paid =
      treatment.total - treatment.total_discounts + treatment.total_charges

    let showDialog = payers.some(
      p => p.data.downpayment > 0 || p.data.installment_total > 0
    )

    if (!showDialog) {
      // check if no payment is to be made and skip this dialouge completely
      return null
    }

    return { completed: false, payers, notes: null, activeTab, treatment }
  }, [data?.id]) // eslint-disable-line
  // needed to avoid resetting the state if anyoff the data changes
}
