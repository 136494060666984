import { useDataChange } from 'Simple/Data.js'
export function useOnClick(props) {
  let change = useDataChange({
    context: 'search',
    viewPath: props.viewPath,
    path: 'view_details',
  })
  return function onClick() {
    change(null)
  }
}
