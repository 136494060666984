import { useDataValue, useDataChange } from 'Simple/Data.js'

export function useOnChange(props) {
  let data = useDataValue({
    context: 'template_select',
    viewPath: props.viewPath,
  })
  let change = useDataChange({
    context: 'appointment_overlay',
    viewPath: props.viewPath,
  })

  return function onChange(value) {
    let selected = value ? data.find(item => item.id === value) : null
    if (selected) {
      change(next => {
        next.appointment_type_id = selected.id
        next.template_id = selected.template_id
        next.duration = selected.duration || null
      })
    }
  }
}
