import { useDataValue } from 'Simple/Data.js'
import { useMutation } from 'Data/Api.js'
import {
  getPrimaryInsuranceEstimatedReimbursement,
  getSecondaryInsuranceEstimatedReimbursement,
} from 'Data/payment-plan.js'
import debounce from 'lodash/debounce.js'

import mutation from './mutation-save.graphql.js'

/** @type {import('Simple/types.js').useDataOnChange} */
export default function useDataOnChange(props, data) {
  let current_location_id = useDataValue({
    viewPath: props.viewPath,
    context: 'global',
    path: 'current_location.id',
  })
  let treatment_plan = useDataValue({
    context: 'treatment_plan',
    viewPath: props.viewPath,
  })
  let [, executeMutation] = useMutation(mutation)

  return debounce(onChange, 500)

  async function onChange(value, change) {
    if (
      value.payors.length === 0 ||
      value.payors.some(payor => !payor.person_id) ||
      value.insurances.some(insurance => !insurance.insured_id)
    ) {
      return
    }

    await executeMutation({
      current_location_id,
      payment_plan: {
        ...value,
        insurances: getPaymentPlanInsurances(value, treatment_plan),
      },
    })
  }
}

function getPaymentPlanInsurances(payment_plan, treatment_plan) {
  return payment_plan.insurances.map(insurance => {
    let primary_insurance = payment_plan.insurances.find(
      item => item.is_primary
    )
    let { downpayment_amount, installment_amount } = insurance.is_primary
      ? getPrimaryInsuranceEstimatedReimbursement(
          insurance,
          payment_plan,
          treatment_plan
        )
      : getSecondaryInsuranceEstimatedReimbursement(
          insurance,
          primary_insurance,
          payment_plan,
          treatment_plan
        )
    return {
      insured_id: insurance.insured_id,
      estimated_reimbursement_amount: insurance.estimated_reimbursement_amount,
      is_primary: insurance.is_primary,
      // TODO: ideally these fields should be re-clalculated on the backend as all depend on the insured (subscription)
      // passing it from UI for now since it's too much work and duplicated logic in SQL
      downpayment_amount,
      installment_amount,
    }
  })
}
