import { useDataValue } from 'Simple/Data.js'
import { useMutation } from 'Data/Api'
import { normalizePath, useSetFlowTo } from 'Simple/Flow.js'
import {
  notifyError,
  useNotifications,
  notifySuccess,
} from 'Logic/Notifications.js'
import {
  textInput,
  street,
  zip,
  state,
  city,
  phoneNumber,
  email as validateEmail,
} from 'Data/validate.js'

import mutation from './mutation.graphql.js'

function isInvalid(new_professional, notify) {
  let { person, external_office } = new_professional.other
  if (
    !textInput(person.first_name) ||
    !textInput(person.last_name) ||
    !textInput(external_office.name)
  ) {
    return true
  }

  if (
    new_professional.email.address &&
    !validateEmail(new_professional.email.address)
  ) {
    notify(notifyError('Invalid email address.'))
    return true
  }

  if (
    (new_professional.phone.number || new_professional.phone.type) &&
    (!phoneNumber(new_professional.phone.number) ||
      !textInput(new_professional.phone.type))
  ) {
    notify(notifyError('Invalid phone number or type.'))
    return true
  }
}
export default function useDataOnSubmit(props, data) {
  let patient_id = useDataValue({
    context: 'profile_patient',
    viewPath: props.viewPath,
    path: 'id',
  })

  let organization_id = useDataValue({
    context: 'global',
    path: 'current_location.parent_company.id',
    viewPath: props.viewPath,
  })

  let location_id = useDataValue({
    context: 'global',
    path: 'current_location.id',
    viewPath: props.viewPath,
  })

  let [, notify] = useNotifications()
  let [, executeMutation] = useMutation(mutation)

  let setFlowTo = useSetFlowTo(props.viewPath)
  return async function onSubmit({ value: new_professional, args }) {
    let { person, external_office, employee_type } = new_professional.other
    if (isInvalid(new_professional, notify)) {
      return true
    }

    let contact_methods = []
    if (new_professional.email?.address) {
      contact_methods.push({
        dtype: 'email',
        description: new_professional.email.description,
        data: {
          address: new_professional.email.address,
        },
      })
    }
    if (new_professional.phone?.number) {
      contact_methods.push({
        dtype: 'phone',
        description: new_professional.phone.description,
        data: {
          number: new_professional.phone.number,
          type: new_professional.phone.type,
        },
      })
    }
    let mutationResponse = await executeMutation({
      person_details: { ...person, organization_id, location_id },
      contact_methods,
      office_details: external_office,
      employee_type_id: employee_type.id,
      patient_id,
      addresses: new_professional.addresses,
    })

    if (mutationResponse.error) {
      notify(
        notifyError(
          'Something went wrong. Please, try again or contact support if the problem persists.'
        )
      )
      return
    }

    notify(notifySuccess('Professional relationship added!'))

    setFlowTo(normalizePath(props.viewPath, '../No'))
  }
}
