// this is an autogenerated file from App/Account/Content/Patients/Content/Content/Sections/ActiveTab/TreatmentCard/Content/TreatmentPlans/New/Steps/Content/TreatmentPlanTemplates/Content/Content/mutation.graphql
import { gql } from 'Data/Api'

export default gql`
mutation app__App_Account_Content_Patients_Content_Content_Sections_ActiveTab_TreatmentCard_Content_TreatmentPlans_New_Steps_Content_TreatmentPlanTemplates_Content_Content(
  $treatment_id: uuid!
  $current_location_id: numeric!
  $treatment_plan_template_ids: _numeric!
) {
  treatments_insert_treatment_plans(
    args: {
      treatment_id: $treatment_id
      current_location_id: $current_location_id
      treatment_plan_template_ids: $treatment_plan_template_ids
    }
  ) {
    id
  }
}

`