// this is an autogenerated file from App/Account/Content/Patients/Content/Content/Sections/ActiveTab/Profile/Content/NewProfile/Content/SchedulingPreferences/Content/Edit/Content/schedulingPrefsNewMutation.graphql
import { gql } from 'Data/Api'

export default gql`
mutation app__App_Account_Content_Patients_Content_Content_Sections_ActiveTab_Profile_Content_NewProfile_Content_SchedulingPreferences_Content_Edit_Content(
  $input: vaxiom_scheduling_preferences_insert_input!
) {
  insert_vaxiom_scheduling_preferences_one(object: $input) {
    id
  }
}

`