import React from 'react'
import View from './view.js'
import { useDataValue, useDataIsSubmitting } from 'Simple/Data.js'
import { textInput } from 'Data/validate.js'

export default function Logic(props) {
  let { other } = useDataValue({
    context: 'new_professional',
    viewPath: props.viewPath,
  })

  let isSubmitting = useDataIsSubmitting({
    context: 'new_professional',
    viewPath: props.viewPath,
  })

  let disabled =
    isSubmitting ||
    !textInput(other.person.first_name) ||
    !textInput(other.person.last_name) ||
    !textInput(other.external_office.name)

  return <View viewPath={props.viewPath} isDisabled={disabled} />
}
