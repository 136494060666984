import { format } from 'date-fns'
import { phoneNumberUS } from 'Data/format.js'

export function getText(request) {
  if (!request.contact_method_association) {
    return `Plan has been sent to the payor ${request.person.first_name} ${
      request.person.last_name
    } on ${format(new Date(request.created_at), 'MM/dd/yyyy')}`
  } else {
    return `Plan has been sent to the payor ${request.person.first_name} ${
      request.person.last_name
    }'s ${
      request.contact_method_association.contact_method.dtype
    } - ${getContactMethod(
      request.contact_method_association.contact_method
    )} on ${format(new Date(request.created_at), 'MM/dd/yyyy')}.`
  }
}

function getContactMethod(contact_method) {
  return contact_method.dtype === 'email'
    ? contact_method.email?.address
    : phoneNumberUS(contact_method.phone?.number)
}
