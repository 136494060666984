import { useMemo } from 'react'

export default function useDataTransform(props, data) {
  return useMemo(() => {
    if (!data) return data
    let { employee_type, external_office } =
      data.other_professional_relationships?.[0] || {}
    let city, state
    if (external_office?.postal_address) {
      city = external_office.postal_address.city
      state = external_office.postal_address.state
    }
    let display = [
      data.first_name,
      data.last_name,
      employee_type?.name,
      external_office?.name,
      city,
      state,
    ]
      .filter(Boolean)
      .join(' ')
    return { ...data, display }
  })
}
