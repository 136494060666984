import { useDataValue, useDataSubmit } from 'Simple/Data.js'

export function useOnClick(props) {
  let submit = useDataSubmit({
    context: 'appointment_overlay',
    viewPath: props.viewPath,
  })
  let patient_id = useDataValue({
    context: 'person',
    path: 'patient._id',
    viewPath: props.viewPath,
  })

  return function onClick() {
    submit({
      type: 'updatePatient',
      id: patient_id,
    })
  }
}
