import { useDataSubmit } from 'Simple/Data.js'

export function useOnChange(props) {
  let submit = useDataSubmit({
    context: 'appointment',
    viewPath: props.viewPath,
  })
  return function onChange(value) {
    submit({
      type: 'set-interval-to-next',
      value,
    })
  }
}
