import { useMemo } from 'react'
import { useDataChange } from 'Simple/Data.js'

function formatRelative(relative) {
  let { from_person_record: person, id, to_person_record } = relative
  let { contact_method_associations } = person

  return contact_method_associations
    .map(cma => {
      let { email, contact_method_id, id: cma_id } = cma

      return {
        id,
        person,
        email,
        contact_method_id,
        type: relative.type,
        cma_id,
      }
    })
    .filter(obj => {
      return !to_person_record.contact_method_associations
        .map(cma => cma.contact_method_id)
        .includes(obj.contact_method_id)
    })
}
export default function useDataTransform(props, data) {
  let toggleShowType = useDataChange({
    context: 'new_email',
    viewPath: props.viewPath,
    path: 'show_choose_type',
  })
  return useMemo(() => {
    if (!data) return data

    let emails = data
      .filter(
        relative =>
          relative?.from_person_record?.contact_method_associations.length > 0
      )
      .flatMap(formatRelative)
    toggleShowType(emails.length !== 0)
    return emails
  }, [data])
}
