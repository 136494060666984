// this is an autogenerated file from App/Account/Content/Patients/Content/Content/Sections/ActiveTab/TreatmentCard/Content/TreatmentCard/Images/ActiveTab/ToothChart/Content/Preview/data.graphql
import { gql } from 'Data/Api'

export default gql`
query app__App_Account_Content_Patients_Content_Content_Sections_ActiveTab_TreatmentCard_Content_TreatmentCard_Images_ActiveTab_ToothChart_Content_Preview($id: uuid!) {
  vaxiom_toothchart_snapshot(where: { _id: { _eq: $id } }) {
    id
    _id
    display_data
    mime_type
    appointment {
      id
      _id
      booking {
        id
        _id
      }
    }
  }
}

`