// this is an autogenerated file from App/Account/Content/Patients/Content/Content/Sections/ActiveTab/TreatmentCard/Content/TreatmentPlans/New/Steps/Content/Contracts/Content/Payor/Content/SignAction/Empty/Content/mutation.graphql
import { gql } from 'Data/Api'

export default gql`
mutation app__App_Account_Content_Patients_Content_Content_Sections_ActiveTab_TreatmentCard_Content_TreatmentPlans_New_Steps_Content_Contracts_Content_Payor_Content_SignAction_Empty_Content(
  $signature_request_id: uuid!
  $contract_id: numeric!
  $document_template_id: numeric!
  $template_file_key: String!
  $initial_document_data: jsonb!
  $allowed_birth_dates: jsonb!
  $resource_metadata: jsonb!
  $signed_document_data: jsonb!
  $required_document_data: jsonb!
  $signatory_person_id: uuid!
) {
  insert_documents_signature_requests_one(
    object: {
      id: $signature_request_id
      document_template_id: $document_template_id
      template_file_key: $template_file_key
      initial_document_data: $initial_document_data
      allowed_birth_dates: $allowed_birth_dates
      resource_metadata: $resource_metadata
      signed_document_data: $signed_document_data
      required_document_data: $required_document_data
      signatory_person_id: $signatory_person_id
      status: pending
    }
    on_conflict: {
      constraint: signature_requests_pkey
      update_columns: [initial_document_data, allowed_birth_dates, status]
    }
  ) {
    id
    status
  }
  update_vaxiom_tx_contracts_by_pk(
    pk_columns: { id: $contract_id }
    _set: { signature_request_id: $signature_request_id }
  ) {
    id
    signature_request_id
  }
}

`