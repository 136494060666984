import { useMutation } from 'Data/Api.js'
import {
  useNotifications,
  notifyError,
  notifySuccess,
} from 'Logic/Notifications.js'
import { useDataValue } from 'Simple/Data.js'
import mutation_send_medical_form from './sendMedicalFormMutation.graphql.js'
import mutation_send_hub_invite from './sendHubInviteMutation.graphql.js'

/** @type {import('Simple/types.js').useDataOnSubmit} */
export default function useDataOnSubmit(props, data) {
  let [, notify] = useNotifications()
  let patient = useDataValue({
    viewPath: props.viewPath,
    context: 'profile_patient',
  })

  let [, executeSendMedicalFormMutation] = useMutation(
    mutation_send_medical_form
  )

  let [, executeSendHubInviteMutation] = useMutation(mutation_send_hub_invite)

  // send hub invite requires numeric location id
  let location_id = useDataValue({
    viewPath: props.viewPath,
    context: 'global',
    path: 'current_location.id',
  })

  return async function onSubmit({ value, originalValue, args, change }) {
    switch (args.type) {
      case 'MEDICAL_FORM': {
        let mutationSendMedicalFormResponse =
          await executeSendMedicalFormMutation({
            patient_id: patient._id,
            contact_method_association_id: args.email.id,
          })

        if (mutationSendMedicalFormResponse.error) {
          notify(notifyError('Something went wrong. Please, try again.'))
          return
        }

        notify(notifySuccess('Medical Form Sent!'))
        break
      }
      case 'SEND_HUB_INVITE': {
        let mutationSendHubInviteResponse = await executeSendHubInviteMutation({
          email: args.email.email.address,
          location_id,
        })

        if (
          mutationSendHubInviteResponse.error ||
          !mutationSendHubInviteResponse.data.auth_patient_invite.ok
        ) {
          notify(
            notifyError(
              'Make sure that the patient is at least 18 years old or that they have a responsible person.'
            )
          )
          return
        }

        notify(notifySuccess('Hub Invite Sent!'))
        break
      }
      default: {
      }
    }
  }
}
