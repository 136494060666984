import { useDataValue } from 'Simple/Data.js'

/** @type {import('Simple/types.js').useDataConfiguration} */
export default function useDataConfiguration(props) {
  let person_id = useDataValue({
    viewPath: props.viewPath,
    context: 'profile_patient',
    path: 'person._id',
  })

  return {
    variables: {
      person_id,
    },
    requestPolicy: 'cache-and-network',
  }
}
