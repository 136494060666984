// this is an autogenerated file from DesignSystem/DataVaxiomRelationshipsItem/Edit/NonPatient/Phone/NewPhone/Content/updatePersonRelationshipsMutation.graphql
import { gql } from 'Data/Api'

export default gql`
mutation app__DesignSystem_DataVaxiomRelationshipsItem_Edit_NonPatient_Phone_NewPhone_Content($relationships: json) {
  update_person_relationships(
    args: { _relationships: $relationships, add_contact_method_to_self: false }
  ) {
    id
  }
}

`