import { useMemo } from 'react'
import { useDataValue } from 'Simple/Data.js'

let DEFAULT_SETTINGS = {
  slot_interval: 5,
  hipaa: false,
  selected_appointment_templates: [],
}

/** @type {import('Simple/types.js').useDataTransform} */
export default function useDataTransform(props, data) {
  let tab_appointment_overlay_open = useDataValue({
    viewPath: props.viewPath,
    context: 'tab',
    path: 'appointment_overlay.open',
  })
  let global_appointment_overlay_open = useDataValue({
    viewPath: props.viewPath,
    context: 'global',
    path: 'appointment_overlay.open',
  })
  let appointment_overlay_open =
    tab_appointment_overlay_open || global_appointment_overlay_open

  return useMemo(() => {
    let settings = data?.[0]

    if (!settings) {
      return DEFAULT_SETTINGS
    }

    // prevent full day view when scheduling, but still allows switching between 5/10
    if (appointment_overlay_open && settings.slot_interval === 30) {
      return {
        ...settings,
        slot_interval: DEFAULT_SETTINGS.slot_interval,
      }
    }

    return settings
  }, [data, appointment_overlay_open])
}
