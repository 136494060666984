// this is an autogenerated file from DesignSystem/DataVaxiomRelationshipsItem/Edit/NonPatient/Phone/NewPhone/Content/query.graphql
import { gql } from 'Data/Api'

export default gql`
query app__DesignSystem_DataVaxiomRelationshipsItem_Edit_NonPatient_Phone_NewPhone_Content($number: String!) {
  vaxiom_contact_phones(where: { number: { _eq: $number } }) {
    id
  }
}

`