import { dateISOIn, dateISOToDateAndTime } from 'Data/format.js'
import { date } from 'Data/validate.js'
import React, { useMemo } from 'react'

let columns = [
  {
    header: 'Date',
    accessorKey: 'created_at',
    cell: cell => {
      let cellValue = cell.getValue()
        ? dateISOToDateAndTime(cell.getValue().toISOString())
        : '-'
      if (cell.row.index === 0) {
        return <b>{cellValue}</b>
      }
      return cellValue
    },
  },
  {
    header: 'User',
    accessorKey: 'user',
    cell: cell => {
      if (cell.row.index === 0) {
        return <b>{cell.getValue()}</b>
      }
      return cell.getValue()
    },
  },
  {
    header: 'Answer',
    accessorKey: 'answer',
    cell: cell => {
      let value = cell.getValue()
      let cellValue
      if (typeof value === 'boolean') {
        cellValue = value ? 'Yes' : 'No'
      } else if (date(value)) {
        cellValue = value.toISOString
          ? dateISOIn(value.toISOString())
          : dateISOIn(value)
      } else if (Array.isArray(value)) {
        cellValue = value.join(', ')
      } else {
        cellValue = value?.toString() || ''
      }

      if (cell.row.index === 0) {
        return <b>{cellValue}</b>
      }

      return cellValue
    },
  },
]
export function useTableData(data) {
  return useMemo(() => ({ columns, data }), [data])
}
