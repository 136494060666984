import { useDataChange, useDataValue } from 'Simple/Data.js'
export function useOnChange(props) {
  let change = useDataChange({
    context: 'new_relation',
    viewPath: props.viewPath,
  })
  let addresses = useDataValue({
    context: 'addresses',
    viewPath: props.viewPath,
  })

  return function onChange(value) {
    if (!value) return
    let address = addresses.find(a => a.id === value)
    change(next => {
      next.selected_address = value
      next.address = address.postal_address
    })
  }
}
