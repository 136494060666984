import { normalizePath, useSetFlowTo } from 'Simple/Flow.js'
import { useDataChange, useDataValue } from 'Simple/Data.js'

export function useOnClick(props) {
  let setFlowTo = useSetFlowTo(props.viewPath)
  let change = useDataChange({
    viewPath: props.viewPath,
    context: 'tab',
    path: 'selected.create_treatment_plan',
  })
  let treatment_id = useDataValue({
    viewPath: props.viewPath,
    context: 'treatment_plan',
    path: 'id',
  })

  return function onClick() {
    change({ treatment_id })
    setFlowTo(
      normalizePath(props.viewPath, '../../../../../../../TreatmentPlans')
    )
  }
}
