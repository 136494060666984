// this is an autogenerated file from App/Account/Content/Patients/Content/Content/Sections/ActiveTab/Profile/Content/NewProfile/Content/ContactInfo/Phone/Phones/data.graphql
import { gql } from 'Data/Api'

export default gql`
query app__App_Account_Content_Patients_Content_Content_Sections_ActiveTab_Profile_Content_NewProfile_Content_ContactInfo_Phone_Phones($patient_id: numeric!) {
  patient_contact_method_associations(
    args: { _patient_id: $patient_id, _type: "phone" }
  ) {
    id
    person_id
    preference
    contact_method_id
    description
    phone {
      id
      type
      number
    }
    communication_preferences(where: { patient_id: { _eq: $patient_id } }) {
      id
      patient_id
      type
    }
  }
}

`