import { useDataSubmit, useDataValue } from 'Simple/Data.js'
import { patientName } from 'Data/format.js'

export function useOnClick(props) {
  let submit = useDataSubmit({
    context: 'global',
    viewPath: props.viewPath,
  })

  let patient = useDataValue({
    context: 'event',
    path: 'appointment.patient',
    viewPath: props.viewPath,
  })

  let dues = useDataValue({
    context: 'info_dialog',
    viewPath: props.viewPath,
    path: 'payment_overdue',
  })

  return function onClick() {
    let payor_with_maximum_due = dues.reduce((a, b) => {
      if (b.due_now > a.due_now) return b
      else return a
    }, dues[0])

    let selected_payor_id
    if (
      payor_with_maximum_due.payment_account.account_type ===
      'PersonPaymentAccount'
    ) {
      selected_payor_id =
        payor_with_maximum_due.payment_account.person_payment_account.id
    } else {
      selected_payor_id =
        payor_with_maximum_due.payment_account.insurance_payment_accounts[0]?.id
    }

    submit({
      type: 'tabs/add',
      tab: {
        id: patient._id,
        type: 'Patient',
        name: patientName(patient.person),
        tab: 'ledger',
        viewPath: '/App/Account/Content/Patients',
        patient_id: patient._id,
        data: { selected_payor_id },
      },
    })
  }
}
