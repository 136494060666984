import { useDataSubmit } from 'Simple/Data.js'
import { normalizePath, useSetFlowTo } from 'Simple/Flow.js'
export function useOnClickSave(props) {
  let submit = useDataSubmit({
    context: 'carrier',
    viewPath: props.viewPath,
  })
  return function onClick() {
    submit({ type: 'edit' })
  }
}

export function useOnClickNew(props) {
  let setFlowTo = useSetFlowTo(props.viewPath)
  return function onClick() {
    setFlowTo(normalizePath(props.viewPath, '../../../../../../Create/Content'))
    setFlowTo(normalizePath(props.viewPath, '../../../../../../Edit/No'))
    setFlowTo(normalizePath(props.viewPath, './No'))
  }
}
