import { useMutation } from 'Data/Api'
import { useDataSubmit } from 'Simple/Data.js'
import { notifyError, useNotifications } from 'Logic/Notifications'

import mutation from './mutation.graphql.js'

/** @type {import('Simple/types.js').useDataOnSubmit} */
export default function useDataOnSubmit(props, data) {
  let submit = useDataSubmit({
    context: 'wizard',
    viewPath: props.viewPath,
  })

  let [, notify] = useNotifications()

  let [, executeAcceptTreatmentMutation] = useMutation(mutation)

  return async function onSubmit({ value, originalValue, args, change }) {
    let mutationResponse = await executeAcceptTreatmentMutation({
      treatment_plan_id: value.treatment_plan_id,
    })

    if (mutationResponse.error) {
      notify(
        notifyError(
          'Something went wrong. Please, try again or contact support if the problem persists.'
        )
      )
      return
    }

    submit({ type: 'done' })
  }
}
