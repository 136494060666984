// this is an autogenerated file from App/Account/Content/Settings/AdminContent/SettingsContent/TreatmentCaseCloser/Configuration/DefaultPaymentPlans/Rulesets/Content/Ruleset/DefaultPaymentPlans/DefaultPaymentPlan/Discounts/Discount/Discounts/data.graphql
import { gql } from 'Data/Api'

export default gql`
query app__App_Account_Content_Settings_AdminContent_SettingsContent_TreatmentCaseCloser_Configuration_DefaultPaymentPlans_Rulesets_Content_Ruleset_DefaultPaymentPlans_DefaultPaymentPlan_Discounts_Discount_Discounts($location_id: uuid) {
  vaxiom_discounts(
    where: {
      organization: { _id: { _eq: $location_id } }
      deleted: { _eq: false }
    }
  ) {
    _id
    name
  }
}

`