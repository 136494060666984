import { useDataSubmit, useDataValue } from 'Simple/Data'
import { normalizePath, useSetFlowTo } from 'Simple/Flow.js'

export function useOnClick(props) {
  let setFlowTo = useSetFlowTo(props.viewPath)

  let phone = useDataValue({
    viewPath: props.viewPath,
    context: 'phone',
  })

  let action = useDataValue({
    context: 'option',
    viewPath: props.viewPath,
    path: 'id',
  })

  let submit = useDataSubmit({
    context: 'phones',
    viewPath: props.viewPath,
  })

  return function onClick() {
    props.closePopover()
    switch (action) {
      case 'DELETE':
        return deleteAddress()
      case 'EDIT':
        return editAddress()
      default: {
        submit({
          type: action,
          phone,
        })
      }
    }
  }

  function deleteAddress() {
    setFlowTo(normalizePath(props.viewPath, '../../../Delete/Content'))
  }

  function editAddress() {
    setFlowTo(normalizePath(props.viewPath, '../../../Edit/Content'))
  }
}
