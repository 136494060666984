import { useDataChange } from 'Simple/Data.js'
import View from './view.js'

export default function Logic(props) {
  let change = useDataChange({
    viewPath: props.viewPath,
    context: 'new_address',
  })
  let changeCitySearch = useDataChange({
    viewPath: props.viewPath,
    context: 'city_search',
    path: 'term',
  })

  let onChooseAddress = ({ street, city, state, zip }) => {
    change(next => {
      next.address_line1 = street || ''
      next.city = city || ''
      next.state = state || ''
      next.zip = zip || ''
    })
    changeCitySearch(city || '')
  }

  return <View {...props} onChooseAddress={onChooseAddress} />
}
