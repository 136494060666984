import { useMutation } from 'Data/Api.js'
import { useDataValue } from 'Simple/Data.js'
import { useSetFlowTo, normalizePath } from 'Simple/Flow.js'
import {
  notifyError,
  notifySuccess,
  useNotifications,
} from 'Logic/Notifications.js'

import mutationSetIntervalToNext from './mutation-set-interval-to-next.graphql.js'
import mutationDeleteAppointment from './mutation-delete-appointment.graphql.js'
import mutationInsertAppointment from './mutation-insert-appointment.graphql.js'
import mutationSetAppointmentTypeId from './mutation-set-appointment-type-id.graphql.js'

/** @type {import('Simple/types.js').useDataOnSubmit} */
export default function useDataOnSubmit(props) {
  let onSetIntervalToNext = useOnSetIntervalToNext(props)
  let onDelete = useOnDelete(props)
  let onEdit = useOnEdit(props)
  let onAdd = useOnAdd(props)

  return async function onSubmit({ value, args, change }) {
    switch (args.type) {
      case 'set-interval-to-next':
        return onSetIntervalToNext({ value, args, change })

      case 'remove':
        return onDelete({ value, args, change })

      case 'edit':
        return onEdit({ value, args, change })

      case 'add':
        return onAdd({ value, args, change })

      default: {
        return
      }
    }
  }
}

function useOnSetIntervalToNext(props) {
  let [, notify] = useNotifications()
  let [, executeMutationSetIntervalToNext] = useMutation(
    mutationSetIntervalToNext
  )

  return async function onSubmit({ value, args, change }) {
    if (typeof args.value !== 'number') return

    let mutationResponse = await executeMutationSetIntervalToNext({
      id: value.id,
      interval_to_next: args.value,
    })

    if (mutationResponse.error) {
      notify(
        notifyError(
          'Something went wrong. Please, try again or contact support if the problem persists.'
        )
      )

      return
    }

    notify(notifySuccess('Interval to next appointment updated successfully'))
  }
}

function useOnDelete(props) {
  let [, notify] = useNotifications()
  let [, executeMutationDeleteAppointment] = useMutation(
    mutationDeleteAppointment
  )

  return async function onSubmit({ value, args, change }) {
    let mutationResponse = await executeMutationDeleteAppointment({
      id: value.id,
    })
    if (mutationResponse.error) {
      notify(
        notifyError(
          'Something went wrong. Please, try again or contact support if the problem persists.'
        )
      )
      return
    }

    notify(notifySuccess('Appointment removed successfully'))
  }
}

function useOnEdit(props) {
  let [, notify] = useNotifications()
  let setFlowTo = useSetFlowTo(props.viewPath)
  let [, executeMutationSetAppointmentTypeId] = useMutation(
    mutationSetAppointmentTypeId
  )

  return async function onSubmit({ value, args, change }) {
    // ignore if the same appointment type selected
    if (value.type_id !== args.type_id) {
      let mutationResponse = await executeMutationSetAppointmentTypeId({
        id: value.id,
        type_id: args.type_id,
      })
      if (mutationResponse.error) {
        notify(
          notifyError(
            'Something went wrong. Please, try again or contact support if the problem persists.'
          )
        )

        return
      }

      notify(notifySuccess('Appointment type updated successfully'))
    }

    setFlowTo(normalizePath(props.viewPath, 'Appointment/Read'))
  }
}

function useOnAdd(props) {
  let treatment_plan_id = useDataValue({
    context: 'treatment_plan',
    path: 'id',
    viewPath: props.viewPath,
  })
  let location_id = useDataValue({
    context: 'global',
    path: 'current_location.id',
    viewPath: props.viewPath,
  })
  let [, notify] = useNotifications()
  let setFlowTo = useSetFlowTo(props.viewPath)
  let [, executeMutationInsertAppointment] = useMutation(
    mutationInsertAppointment
  )

  return async function onSubmit({ value, args, change }) {
    if (!args.type_id || !args.interval_to_next) return true

    let mutationResponse = await executeMutationInsertAppointment({
      treatment_plan_id,
      type_id: args.type_id,
      interval_to_next: args.interval_to_next,
      previous_appointment_id: value.id,
      location_id,
    })
    if (mutationResponse.error) {
      notify(
        notifyError(
          'Something went wrong. Please, try again or contact support if the problem persists.'
        )
      )
      return
    }

    notify(notifySuccess('Appointment created successfully'))

    setFlowTo(normalizePath(props.viewPath, 'Add/No'))
  }
}
