// this is an autogenerated file from DesignSystem/TimeGrid/mutation-update-schedule-note.graphql
import { gql } from 'Data/Api'

export default gql`
mutation app__DesignSystem_TimeGrid(
  $id: uuid!
  $data: vaxiom_schedule_notes_set_input!
) {
  update_vaxiom_schedule_notes(where: { _id: { _eq: $id } }, _set: $data) {
    affected_rows
    returning {
      id
      duration
    }
  }
}

`