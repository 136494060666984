import { useDataValue } from 'Simple/Data'
import { normalizePath, useSetFlowTo } from 'Simple/Flow.js'
import { useMutation } from 'Data/Api'
import {
  notifyError,
  notifySuccess,
  useNotifications,
} from 'Logic/Notifications.js'
import mutation_edit_preferences from './editPreferencesMutation.graphql.js'
import mutation_send_hub_invite from './sendHubInviteMutation.graphql.js'

export function useOnClick(props) {
  let setFlowTo = useSetFlowTo(props.viewPath)
  let [, notify] = useNotifications()

  let [, executeEditPreferencesMutation] = useMutation(
    mutation_edit_preferences
  )
  let [, executeSendHubInviteMutation] = useMutation(mutation_send_hub_invite)

  // send hub invite requires numeric location id
  let location_id = useDataValue({
    viewPath: props.viewPath,
    context: 'global',
    path: 'current_location.id',
  })

  let person_id = useDataValue({
    viewPath: props.viewPath,
    context: 'edit_relation',
    path: 'from_person',
  })

  let email = useDataValue({
    viewPath: props.viewPath,
    context: 'email',
  })

  return function onClick(action) {
    switch (action) {
      case 'DELETE':
        return deleteAddress()
      case 'EDIT':
        return editAddress()
      case 'SET_PREFERRED':
        return setPreferred()
      case 'SEND_HUB_INVITE':
        return sendHubInvite()
      default:
        return
    }
  }

  function deleteAddress() {
    setFlowTo(normalizePath(props.viewPath, '../Delete/Content'))
  }

  function editAddress() {
    setFlowTo(normalizePath(props.viewPath, '../Edit/Content'))
  }

  async function sendHubInvite() {
    let mutationSendHubInviteResponse = await executeSendHubInviteMutation({
      email: email.email.address,
      location_id,
    })

    if (
      mutationSendHubInviteResponse.error ||
      !mutationSendHubInviteResponse.data.auth_patient_invite.ok
    ) {
      notify(
        notifyError(
          'Make sure that the patient is at least 18 years old or that they have a responsible person.'
        )
      )
      return
    }

    notify(notifySuccess('Hub Invite Sent!'))
  }

  async function setPreferred() {
    let mutationEditPreferencesResponse = await executeEditPreferencesMutation({
      id: email.id,
      preference: 'ANY',
      person_id,
    })
    if (mutationEditPreferencesResponse.error) {
      notify(notifyError('Something went wrong. Please, try again.'))
      return
    }
  }
}
