import { useMemo } from 'react'
import { useDataValue } from 'Simple/Data'

export default function useDataTransform(props, data) {
  let sp = useDataValue({
    viewPath: props.viewPath,
    context: 'scheduling_preferences',
  })
  return useMemo(() => {
    if (!data) return data

    if (sp.id) {
      return {
        id: sp.id,
        start_hour: sp.start_hour,
        end_hour: sp.end_hour,
        providers: sp.providers.map(p => p.employee_id),
        assistants: sp.assistants.map(a => a.employee_id),
        days: sp.days.map(d => d.week_days),
      }
    }
    return data
  }, [data])
}
