// this is an autogenerated file from App/Account/Content/Patients/Content/Content/Sections/ActiveTab/Profile/Content/NewProfile/Content/Main/ReferralsSection/Referrals/Referral/Kind/Person/Professional/data.graphql
import { gql } from 'Data/Api'

export default gql`
query app__App_Account_Content_Patients_Content_Content_Sections_ActiveTab_Profile_Content_NewProfile_Content_Main_ReferralsSection_Referrals_Referral_Kind_Person_Professional($referral_professional_id: numeric!) {
  vaxiom_persons_by_pk(id: $referral_professional_id) {
    id
    first_name
    last_name
    other_professional_relationships {
      id
      employee_type {
        id
        name
      }
      external_office {
        name
        postal_address {
          city
          state
        }
      }
    }
  }
}

`