import { useDataChange, useDataValue } from 'Simple/Data.js'
import { useSetFlowTo, normalizePath } from 'Simple/Flow.js'

export function useOnClick(props) {
  let changeSearchTerm = useDataChange({
    viewPath: props.viewPath,
    context: 'city_search',
    path: 'term',
  })
  let selectedCity = useDataValue({
    viewPath: props.viewPath,
    context: 'city',
  })
  let changeAddressCity = useDataChange({
    context: 'new_professional',
    viewPath: props.viewPath,
    path: 'other.external_office.postal_address.city',
  })

  let setFlowTo = useSetFlowTo(props.viewPath)

  return function onClick(value) {
    changeSearchTerm(selectedCity.value)
    changeAddressCity(selectedCity.value)
    setFlowTo(normalizePath(props.viewPath, '../../No'))
  }
}
