// this is an autogenerated file from App/Account/Content/Patients/Content/Content/Sections/ActiveTab/Profile/Content/NewProfile/Content/Professionals/AddAction/New/Content/Details/Person/Profession/data.graphql
import { gql } from 'Data/Api'

export default gql`
query app__App_Account_Content_Patients_Content_Content_Sections_ActiveTab_Profile_Content_NewProfile_Content_Professionals_AddAction_New_Content_Details_Person_Profession{
  vaxiom_resource_types(
    where: { dtype: { _eq: "employeeType" } }
    order_by: { name: asc }
  ) {
    id
    text: name
  }
}

`