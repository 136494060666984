import { useSetFlowTo, normalizePath } from 'Simple/Flow.js'
import { useMutation, useClient } from 'Data/Api'
import { useDataValue } from 'Simple/Data'
import {
  notifyError,
  useNotifications,
  notifySuccess,
} from 'Logic/Notifications.js'
import { phoneNumber } from 'Data/validate.js'
import { RELATIONSHIPS } from 'Data/constants.js'
import mutation_new_contact_method_association from './newContactMethodAssociationMutation.graphql.js'
import mutation_add_person_contact_methods from './addPersonContactMethodsMutation.graphql.js'
import mutation_update_person_relationships from './updatePersonRelationshipsMutation.graphql.js'
import query from './query.graphql.js'

export default function useDataOnSubmit(props, data) {
  let onActionAddNewRelations = useDataOnActionAddNewRelations(props)
  let onActionNewPhoneForPatient = useDataOnActionNewPhoneForPatient(props)
  let onActionPhoneForPatient = useDataOnActionPhoneForPatient(props)
  let onActionPhoneFromRelative = useDataOnActionPhoneFromRelative(props)
  let onActionFormSubmit = useDataOnActionFormSubmit(props)

  return async function onSubmit({ value, args }) {
    switch (args?.type) {
      case 'add_new_relations': {
        return onActionAddNewRelations({ value, args })
      }
      case 'new_phone_for_patient': {
        return onActionNewPhoneForPatient({ value, args })
      }
      case 'phone_for_patient': {
        return onActionPhoneForPatient({ value, args })
      }
      case 'form_submit': {
        return onActionFormSubmit({ value, args })
      }
      default: {
        onActionPhoneFromRelative({ value, args })
      }
    }
  }
}

function useDataOnActionFormSubmit(props) {
  let setFlowTo = useSetFlowTo(props.viewPath)
  let [, notify] = useNotifications()
  let client = useClient()
  let [, executeMutationAddPersonContactMethods] = useMutation(
    mutation_add_person_contact_methods
  )

  let person_id = useDataValue({
    viewPath: props.viewPath,
    context: 'edit_relation',
    path: 'from_person',
  })
  return async function onAction({ value: phone, args }) {
    if (!phoneNumber(phone.phone.number)) {
      return true
    }

    let { data } = await client
      .query(query, {
        number: phone.phone.number,
      })
      .toPromise()

    if (data?.vaxiom_contact_phones.length === 0) {
      let mutationAddPersonContactMethods =
        await executeMutationAddPersonContactMethods({
          person_id,
          contact_methods: [
            {
              dtype: 'phone',
              description: phone.description,
              data: {
                number: phone.phone.number,
                type: phone.phone.type,
              },
            },
          ],
        })

      if (mutationAddPersonContactMethods.error) {
        notify(
          notifyError(
            'Something went wrong. Please, try again or contact support if the problem persists.'
          )
        )
        return
      }

      notify(notifySuccess('Phone saved!'))

      setFlowTo(normalizePath(props.viewPath, '../No'))
      return
    }
    setFlowTo(normalizePath(props.viewPath, '../../../SearchForPhone/Content'))
  }
}

function useDataOnActionAddNewRelations(props) {
  let setFlowTo = useSetFlowTo(props.viewPath)
  let [, notify] = useNotifications()
  let [, executeMutationUpdatePersonRelationships] = useMutation(
    mutation_update_person_relationships
  )
  let person_id = useDataValue({
    viewPath: props.viewPath,
    context: 'profile_patient',
    path: 'person.id',
  })
  let person_gender = useDataValue({
    viewPath: props.viewPath,
    context: 'profile_patient',
    path: 'person.gender',
  })
  return async function onAction({ value: phone, args }) {
    let relations = args.relations.map(rel => ({
      from_person: {
        id: rel.id,
        type: rel.type,
        role: rel.role,
        permitted_to_see_info: rel.role === 'RESPONSIBLE',
        contact_methods: [],
      },
      to_person: {
        id: person_id,
        type: RELATIONSHIPS.find(r => r.id === rel.type)[person_gender],
        role:
          rel.role === 'RESPONSIBLE'
            ? 'DEPENDENT'
            : rel.role === 'DEPENDENT'
            ? 'RESPONSIBLE'
            : 'OTHER',
        permitted_to_see_info: rel.role === 'DEPENDENT',
        contact_methods: [
          {
            dtype: 'phone',
            description: phone.description,
            data: {
              number: phone.phone.number,
              type: phone.phone.type,
            },
          },
        ],
      },
    }))

    let mutationUpdatePersonRelationshipsResponse =
      await executeMutationUpdatePersonRelationships({
        relationships: relations,
      })
    if (mutationUpdatePersonRelationshipsResponse.error) {
      notify(
        notifyError(
          'Something went wrong. Please, try again or contact support if the problem persists.'
        )
      )
      return
    }

    notify(notifySuccess('Phone saved!'))

    setFlowTo(normalizePath(props.viewPath, '../No'))
    return
  }
}

function useDataOnActionNewPhoneForPatient(props) {
  let setFlowTo = useSetFlowTo(props.viewPath)
  let [, notify] = useNotifications()

  let [, executeMutationAddPersonContactMethods] = useMutation(
    mutation_add_person_contact_methods
  )

  let person_id = useDataValue({
    viewPath: props.viewPath,
    context: 'edit_relation',
    path: 'from_person',
  })
  return async function onAction({ value: phone }) {
    // if the phone doesn't exist,
    // and we don't want to add a relation,
    // we just save the new phone and add
    // a new association
    let mutationAddPersonContactMethods =
      await executeMutationAddPersonContactMethods({
        person_id,
        contact_methods: [
          {
            dtype: 'phone',
            description: phone.description,
            data: {
              number: phone.phone.number,
              type: phone.phone.type,
            },
          },
        ],
      })

    if (mutationAddPersonContactMethods.error) {
      notify(
        notifyError(
          'Something went wrong. Please, try again or contact support if the problem persists.'
        )
      )
      return
    }

    notify(notifySuccess('Phone saved!'))

    setFlowTo(normalizePath(props.viewPath, '../No'))
    return
  }
}

function useDataOnActionPhoneForPatient(props) {
  let setFlowTo = useSetFlowTo(props.viewPath)
  let [, notify] = useNotifications()

  let [, executeMutationNewContactMethodAssociation] = useMutation(
    mutation_new_contact_method_association
  )
  let person_id = useDataValue({
    viewPath: props.viewPath,
    context: 'edit_relation',
    path: 'from_person',
  })
  return async function onAction({ args }) {
    // if we found that the phone already exists,
    // but we don't want to add a realtionship,
    // we just link the patient to the existing contact_method
    let mutationNewContactMethodAssociationRespone =
      await executeMutationNewContactMethodAssociation({
        contact_method_id: args.contact_method_id,
        person_id,
      })

    if (mutationNewContactMethodAssociationRespone.error) {
      notify(
        notifyError(
          'Something went wrong. Please, try again or contact support if the problem persists.'
        )
      )
      return
    }

    notify(notifySuccess('Phone saved!'))

    setFlowTo(normalizePath(props.viewPath, '../No'))
    return
  }
}

function useDataOnActionPhoneFromRelative(props) {
  let setFlowTo = useSetFlowTo(props.viewPath)
  let [, notify] = useNotifications()

  let [, executeMutationNewContactMethodAssociation] = useMutation(
    mutation_new_contact_method_association
  )

  let person_id = useDataValue({
    viewPath: props.viewPath,
    context: 'edit_relation',
    path: 'from_person',
  })
  return async function onAction({ value: phone }) {
    // if we select a relative's phone we save it
    let mutationNewContactMethodAssociationRespone =
      await executeMutationNewContactMethodAssociation({
        contact_method_id: phone.contact_method_id,
        person_id,
      })

    if (mutationNewContactMethodAssociationRespone.error) {
      notify(
        notifyError(
          'Something went wrong. Please, try again or contact support if the problem persists.'
        )
      )
      return
    }

    notify(notifySuccess('Phone saved!'))

    setFlowTo(normalizePath(props.viewPath, '../No'))
    return
  }
}
