// this is an autogenerated file from App/Account/Content/Patients/Content/Content/Sections/ActiveTab/TreatmentCard/PaymentsTreatmentPlan/Content/TreatmentPayments/data.graphql
import { gql } from 'Data/Api'

export default gql`
query app__App_Account_Content_Patients_Content_Content_Sections_ActiveTab_TreatmentCard_PaymentsTreatmentPlan_Content_TreatmentPayments($treatment_id: numeric!) {
  vaxiom_txs_by_pk(id: $treatment_id) {
    id
    organization {
      id
      _id
    }
    tx_payers(
      where: { account: { account_type: { _eq: PersonPaymentAccount } } }
    ) {
      id
      primary_payer
      person {
        id
        _id
        first_name
        last_name
        patient {
          id
          _id
        }
      }
      account {
        id
        account_type
        receivables(where: { treatment_id: { _eq: $treatment_id } }) {
          id
          transactions(
            where: {
              dtype: {
                _in: [
                  PaymentPlan
                  TransferCharge
                  DiscountAdjustment
                  MiscFeeCharge
                  TxFeeCharge
                ]
              }
            }
          ) {
            id
            amount
            dtype
            misc_fee_charge {
              id
              name
            }
            discount_adjustment {
              id
              discount_adjustment_type
              discount {
                id
                name
              }
            }
            payment_plan {
              id
              installment_amount
              installment_interval
              requested_downpayment_amount
            }
          }
        }
      }
    }
    tx_plans {
      id
      name
      fee
    }
    tx_card {
      id
      patient {
        id
        _id
      }
    }
  }
}

`