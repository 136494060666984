import { useMemo } from 'react'

export default function useDataTransform(props, data) {
  function tranformCommPreferences(pref) {
    return {
      ...pref,
      type: pref.type === 'APPOINTMENT_MESSAGES' ? true : false,
    }
  }
  return useMemo(() => {
    if (!data) return data

    let insertedNumbers = new Set()
    // this is to ensure if there are two duplicated numbers one with comm preference set other with not
    // only with one with set gets inserted
    let filteredData = data.filter(d => {
      if (
        d.phone &&
        d.communication_preferences.map(tranformCommPreferences)?.[0] &&
        !insertedNumbers.has(d.phone.number)
      ) {
        insertedNumbers.add(d.phone.number)
        return true
      } else {
        return false
      }
    })

    data.forEach(d => {
      if (d.phone && !insertedNumbers.has(d.phone.number)) {
        filteredData.push(d)
        insertedNumbers.add(d.phone.number)
      }
    })

    return filteredData
      .map(obj => ({
        ...obj,
        preference: !!obj?.preference,
        communication_preferences: obj.communication_preferences.map(
          tranformCommPreferences
        )?.[0],
      }))
      .sort((a, b) => a.id - b.id)
  }, [data])
}
