// this is an autogenerated file from App/Account/Content/Patients/Content/Content/Sections/ActiveTab/TreatmentCard/PaymentsTreatmentPlan/Content/TreatmentPayments/mutation_ach.graphql
import { gql } from 'Data/Api'

export default gql`
mutation app__App_Account_Content_Patients_Content_Content_Sections_ActiveTab_TreatmentCard_PaymentsTreatmentPlan_Content_TreatmentPayments(
  $amount: numeric!
  $notes: String
  $location_id: uuid!
  $payment_account_id: numeric!
  $patient_id: uuid!
  $token: jsonb!
  $receivable_id: numeric
) {
  payments_post_payment_bank_account(
    token: $token
    amount: $amount
    location_id: $location_id
    notes: $notes
    patient_id: $patient_id
    payment_account_id: $payment_account_id
    auto_apply_to_unpaid_receivables: false
    receivable_id: $receivable_id
  ) {
    payment_id
    payment {
      id
      payment_transaction {
        id
        token_id
      }
    }
  }
}

`