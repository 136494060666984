/** @type {import('Simple/types.js').useDataOnSubmit} */
import { useMutation } from 'Data/Api'

import { notifyError, useNotifications } from 'Logic/Notifications'

import mutationDeleteTreatmentPlan from './mutation-delete-treatment-plan.graphql.js'

export default function useDataOnSubmit(props, data, reExecuteQuery) {
  let [, notify] = useNotifications()

  let [, executeDeleteTreatmentPlanMutation] = useMutation(
    mutationDeleteTreatmentPlan
  )

  return async function onSubmit({ args }) {
    switch (args.type) {
      case 'remove': {
        let mutationResponse = await executeDeleteTreatmentPlanMutation({
          treatment_plan_id: args.treatment_plan_id,
        })

        if (mutationResponse.error) {
          notify(
            notifyError(
              'Something went wrong. Please, try again or contact support if the problem persists.'
            )
          )

          return
        }

        reExecuteQuery({ requestPolicy: 'cache-and-network' })

        return
      }
      default: {
        return
      }
    }
  }
}
