// this is an autogenerated file from DesignSystem/DataVaxiomRelationshipsItem/Edit/Patient/updatePersonRelationshipsMutation.graphql
import { gql } from 'Data/Api'

export default gql`
mutation app__DesignSystem_DataVaxiomRelationshipsItem_Edit_Patient(
  $patient_person_id: numeric!
  $relative_person_id: numeric!
  $type: String!
  $role: String!
  $permitted_to_see_info: Boolean
  $inv_type: String!
  $inv_role: String!
  $inv_permitted_to_see_info: Boolean
) {
  update_vaxiom_relationships_many(
    updates: [
      {
        where: {
          from_person: { _eq: $relative_person_id }
          to_person: { _eq: $patient_person_id }
        }
        _set: {
          role: $role
          type: $type
          permitted_to_see_info: $permitted_to_see_info
        }
      }
      {
        where: {
          from_person: { _eq: $patient_person_id }
          to_person: { _eq: $relative_person_id }
        }
        _set: {
          role: $inv_role
          type: $inv_type
          permitted_to_see_info: $inv_permitted_to_see_info
        }
      }
    ]
  ) {
    affected_rows
  }
}

`