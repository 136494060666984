import { useDataValue } from 'Simple/Data.js'

export default function useDataConfiguration(props) {
  let id = useDataValue({
    viewPath: props.viewPath,
    context: 'selected',
    path: 'payor_id',
  })

  return {
    variables: {
      id,
    },
    pause: !id,
    requestPolicy: 'cache-and-network',
  }
}
