import { useMemo } from 'react'

export default function useDataTransform(props, data) {
  return useMemo(() => {
    if (!data) return data

    return JSON.parse(data).map(item => ({
      ...item,
      selected: false,
      id: item.external.id,
    }))
  }, [data])
}
