// this is an autogenerated file from App/Account/Content/Patients/Content/Content/Sections/ActiveTab/Profile/Content/NewProfile/Content/Main/ReferralsSection/Referrals/Referral/Delete/Content/Actions/DeleteAction/personReferralsDeleteMutation.graphql
import { gql } from 'Data/Api'

export default gql`
mutation app__App_Account_Content_Patients_Content_Content_Sections_ActiveTab_Profile_Content_NewProfile_Content_Main_ReferralsSection_Referrals_Referral_Delete_Content_Actions_DeleteAction($person_referrals_ids: [uuid!]!) {
  delete_vaxiom_patient_person_referrals(
    where: { _id: { _in: $person_referrals_ids } }
  ) {
    affected_rows
  }
}

`