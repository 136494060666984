import { useDataSubmit } from 'Simple/Data.js'
import {
  formatTimegridAppointmentTime,
  minutesToThreeDigitsTime,
} from 'Data/format.js'

/**
 * @param {{ date: string; start_min: number; end_min: number }} untemplated_slot
 * @returns {string}
 */
export function formatTime(untemplated_slot) {
  let { date, start_min, end_min } = untemplated_slot
  return formatTimegridAppointmentTime({
    start: `${date}T${minutesToThreeDigitsTime(start_min)}`,
    end: `${date}T${minutesToThreeDigitsTime(end_min)}`,
  })
}

export function useOnClick(props) {
  let submit = useDataSubmit({
    viewPath: props.viewPath,
    context: 'tab',
  })

  return function onClick() {
    submit({
      type: 'scheduling/deselectAnySlot',
    })
  }
}
