// this is an autogenerated file from App/Account/Content/Patients/Content/Content/Sections/ActiveTab/Profile/Content/NewProfile/Content/Insurance/AssignedInsurance/data.graphql
import { gql } from 'Data/Api'

export default gql`
query app__App_Account_Content_Patients_Content_Content_Sections_ActiveTab_Profile_Content_NewProfile_Content_Insurance_AssignedInsurance($patient_id: uuid!) {
  vaxiom_insured(
    where: { patient: { _id: { _eq: $patient_id } }, deleted: { _eq: false } }
  ) {
    id
    _id
    tx_category_insured {
      id
      _id
    }
    receivables {
      id
      payment_account_id
    }
    insurance_subscription {
      id
      enrollment_date
      member_id
      active
      sys_created
      sys_created_by
      reactivated
      reactivated_by
      deactivated_reason
      deactivated
      deactivated_by
      type_last_changed_by {
        id
        person {
          id
          first_name
          last_name
        }
      }
      person {
        id
        first_name
        last_name
      }
      insurance_plan {
        _id
        id
        number
        insurance_company {
          id
          carrier_name
        }
      }
      employer {
        id
        name
      }
    }
  }
}

`