// this is an autogenerated file from App/Account/Content/Patients/Content/Content/Sections/ActiveTab/Profile/Content/NewProfile/Content/Insurance/AssignedInsurance/Content/AssignedInsuranceItem/Subscription/Content/Subscription/mutation.graphql
import { gql } from 'Data/Api'

export default gql`
mutation app__App_Account_Content_Patients_Content_Content_Sections_ActiveTab_Profile_Content_NewProfile_Content_Insurance_AssignedInsurance_Content_AssignedInsuranceItem_Subscription_Content_Subscription(
  $id: uuid!
  $used_lifetime_coverage: numeric!
  $year_deductible_paid_last: Int
  $last_verified: date
  $insurance_subscription_id: numeric!
  $employer_id: numeric
  $enrollment_date: date
  $member_id: String
  $person_id: numeric!
  $notes: String
) {
  update_vaxiom_ortho_insured(
    where: { _id: { _eq: $id } }
    _set: {
      used_lifetime_coverage: $used_lifetime_coverage
      year_deductible_paid_last: $year_deductible_paid_last
      last_verified: $last_verified
    }
  ) {
    returning {
      id
    }
  }
  update_vaxiom_insurance_subscriptions_by_pk(
    pk_columns: { id: $insurance_subscription_id }
    _set: {
      employer_id: $employer_id
      enrollment_date: $enrollment_date
      member_id: $member_id
      notes: $notes
      person_id: $person_id
    }
  ) {
    id
  }
}

`