// this is an autogenerated file from App/Account/Content/Patients/Content/Content/Sections/ActiveTab/Profile/Content/NewProfile/Content/ContactInfo/Postal/Addresses/data.graphql
import { gql } from 'Data/Api'

export default gql`
query app__App_Account_Content_Patients_Content_Content_Sections_ActiveTab_Profile_Content_NewProfile_Content_ContactInfo_Postal_Addresses($person_id: numeric!) {
  vaxiom_contact_method_associations(
    where: {
      person_id: { _eq: $person_id }
      contact_method: { dtype: { _eq: "postal" } }
    }
  ) {
    id
    person_id
    contact_order
    contact_method_id
    postal_address {
      id
      address_line1
      city
      state
      zip
    }
  }
}

`