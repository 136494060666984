// this is an autogenerated file from App/Account/Content/Patients/Content/Content/Sections/ActiveTab/Profile/Content/NewProfile/Content/ContactInfo/Postal/Addresses/Content/Address/Edit/Content/mutation-update-other-contact-method-associations.graphql
import { gql } from 'Data/Api'

export default gql`
mutation app__App_Account_Content_Patients_Content_Content_Sections_ActiveTab_Profile_Content_NewProfile_Content_ContactInfo_Postal_Addresses_Content_Address_Edit_Content(
  $ids: [numeric!]!
  $contact_method_id: numeric!
) {
  update_vaxiom_contact_method_associations(
    where: { id: { _in: $ids } }
    _set: { contact_method_id: $contact_method_id }
  ) {
    affected_rows
  }
}

`