import { useEffect } from 'react'
import useFetch from 'Data/useFetch.js'
import {
  useDataChange,
  useDataValue,
  DataProvider,
  useSetFlowToBasedOnData,
} from 'Simple/Data.js'
import { getS3ObjectUrl, useGetSignedUrl, withAwsCredentials } from 'Data/s3.js'

import View from './view.js'

// ensure AWS credentials are available at this stage
export default withAwsCredentials(function Logic(props) {
  let credentials = useDataValue({
    context: 'aws_credentials',
    viewPath: props.viewPath,
  })
  let key = useDataValue({
    context: 'payor',
    path: 'contract.html_file_uuid',
    viewPath: props.viewPath,
  })
  let presignedUrl = useGetSignedUrl({
    // TODO: once core doesn't reference the contracts anymore,
    // it will be refactored to get the S3 URL directly from backend
    url: getS3ObjectUrl({
      bucket: credentials.legacy_storage_bucket_name,
      key: `patientdoc/${key}`,
      region: credentials.region,
    }),
    download: false,
    viewPath: props.viewPath,
  })
  let change = useDataChange({
    context: 'editor',
    path: 'content',
    viewPath: props.viewPath,
  })
  let refresh = useDataValue({
    context: 'preview',
    path: 'refresh',
    viewPath: props.viewPath,
  })
  let setRefresh = useDataChange({
    context: 'preview',
    path: 'refresh',
    viewPath: props.viewPath,
  })

  let [{ data, fetching, error }, reExecute] = useFetch(presignedUrl, {
    cache: 'reload',
  })

  useSetFlowToBasedOnData({
    data,
    fetching,
    error,
    pause: false,
    viewPath: props.viewPath,
  })

  useEffect(() => {
    if (!refresh) return

    // re-fetch the content when the file changed
    reExecute()
    setRefresh(null)
  }, [refresh])

  return (
    <DataProvider
      context="content"
      value={data}
      onChange={onChange}
      viewPath={props.viewPath}
    >
      <View {...props} />
    </DataProvider>
  )

  function onChange(value) {
    change(value)
  }
})
