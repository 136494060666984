import React, { useEffect, useRef } from 'react'
import { createPortal } from 'react-dom'
import load from 'load-script2'
import { useAuthRef } from 'Data/Auth.js'
import get from 'lodash/get.js'
import { notifyError, useNotifications } from 'Logic/Notifications.js'

let queue = {}
async function loadScript(url) {
  if (!queue[url]) {
    queue[url] = load(url)
  }
  return queue[url]
}

export default function AppComponent({
  value,
  onClose = () => {},
  viewPath,
  innerRef = null,
}) {
  let element = useRef(innerRef)
  let authRef = useAuthRef({
    viewPath,
  })
  let isRunning = useRef(false)
  let [, notify] = useNotifications()

  useEffect(() => {
    if (isRunning.current) return

    async function run() {
      try {
        isRunning.current = true

        switch (value.type) {
          // other types to handle, module, iframe
          case 'MODULE': {
            // Dynamically import the ES module
            let module = await import(
              /* webpackIgnore: true */ /* @vite-ignore */ value.url
            )
            executeFunction(getFunction(module))
            break
          }

          case 'SCRIPT': {
            await loadScript(value.url)
            executeFunction(getFunction(window))
            break
          }

          default: {
            onClose()
            break
          }
        }
      } catch (error) {
        console.error(`Failed to load component: ${value.url}`, error)
        notify(notifyError(`Failed to load component: ${value.url}`))
        onClose()
      }

      isRunning.current = false
    }
    run()

    function executeFunction(fn) {
      // Execute the function
      if (typeof fn === 'function') {
        let args = getArgs()
        console.log(`Executing function: ${value.entryPoint.path}`, {
          compiledArgs: args,
          sourceArgs: value.entryPoint.args,
        })
        fn(...args)
      } else {
        console.error(`Entry point is not a function: ${value.entryPoint.path}`)
      }
    }

    function getFunction(root) {
      // Access the function using the path
      let entryPointPath = value.entryPoint.path.split('.')
      let fn = root

      for (let i = 0; i < entryPointPath.length; i++) {
        fn = fn[entryPointPath[i]]
        if (!fn) {
          console.error(`Function not found: ${value.entryPoint.path}`)
          return
        }
      }

      return fn
    }

    function getArgs() {
      return value.entryPoint.args.map(source =>
        mapJsonObjectDefinitionToObject({
          source,
          context: {
            component: {
              onClose,
              getAccessToken: () => authRef.current.access_token,
              rootElement: element.current,
            },
          },
        })
      )
    }
  }, [value])

  return createPortal(<div ref={element}></div>, document.body)
}

/** @type {(input: { value: string, context?: object }) => any} */
function getValueInContext({ value, context = null }) {
  if (context === null) return value
  if (!/^({{.+?}})$/g.test(value)) return value

  return get(context, value.slice(2, -2).trim())
}

/**
 * @source api/public/apps/schemas/0/jsonObject.json
 * @type {(
 *  input: {
 *    source: import('../../api/public/apps/schemas/0/jsonObject.ts').Schema
 *    context?: object
 *  }) => string | number | boolean | object | (string | number | boolean | object)[] }}
 */
function mapJsonObjectDefinitionToObject({ source, context }) {
  switch (source.type) {
    case 'FUNCTION':
    case 'DOM':
    case 'STRING': {
      return getValueInContext({ value: source.value, context })
    }

    case 'NUMBER': {
      return typeof source.value === 'number'
        ? source.value
        : Number(getValueInContext({ value: source.value, context }))
    }

    case 'BOOLEAN': {
      return typeof source.value === 'boolean'
        ? source.value
        : getValueInContext({ value: source.value, context }) === 'true'
    }

    case 'OBJECT': {
      return Object.fromEntries(
        source.value.map(item => [
          item.key,
          mapJsonObjectDefinitionToObject({
            source: item.value,
            context,
          }),
        ])
      )
    }

    case 'ARRAY': {
      return source.value.map(item =>
        mapJsonObjectDefinitionToObject({
          source: item,
          context,
        })
      )
    }

    default: {
      return source.value
    }
  }
}
