import { useDataValue, useDataSubmit } from 'Simple/Data.js'

export function useOnClick(props) {
  let appointment = useDataValue({
    context: 'selected',
    viewPath: props.viewPath,
  })
  let submit = useDataSubmit({
    context: 'appointment',
    viewPath: props.viewPath,
  })

  return function onClick() {
    submit({
      type: 'add',
      type_id: appointment.type_id,
      interval_to_next: appointment.interval_to_next,
    })
  }
}
