// this is an autogenerated file from App/Account/Content/Patients/Content/Content/Sections/ActiveTab/Profile/Content/NewProfile/personMutation.graphql
import { gql } from 'Data/Api'

export default gql`
mutation app__App_Account_Content_Patients_Content_Content_Sections_ActiveTab_Profile_Content_NewProfile(
  $person_id: uuid!
  $birth_date: date
  $first_name: String
  $last_name: String
  $middle_name: String
  $title: String
  $greeting: String
  $gender: String
  $ssn: bpchar
  $school: String
  $heard_about_us_from: String
) {
  update_vaxiom_persons(
    where: { _id: { _eq: $person_id } }
    _set: {
      birth_date: $birth_date
      first_name: $first_name
      last_name: $last_name
      middle_name: $middle_name
      title: $title
      greeting: $greeting
      gender: $gender
      ssn: $ssn
      school: $school
      heard_about_us_from: $heard_about_us_from
    }
  ) {
    returning {
      id
    }
  }
}

`