import React from 'react'
import { useDataValue } from 'Simple/Data.js'
import { getS3ObjectUrl, useGetSignedUrl, withAwsCredentials } from 'Data/s3.js'

import View from './view.js'

export default withAwsCredentials(function Logic(props) {
  let credentials = useDataValue({
    context: 'aws_credentials',
    viewPath: props.viewPath,
  })
  let key = useDataValue({
    context: 'payor',
    path: 'contract.file',
    viewPath: props.viewPath,
  })
  let presignedUrl = useGetSignedUrl({
    url: getS3ObjectUrl({
      bucket: credentials.legacy_storage_bucket_name,
      key: `patientdoc/${key}`,
      region: credentials.region,
    }),
    download: false,
    viewPath: props.viewPath,
  })

  return <View {...props} presignedUrl={presignedUrl} />
})
