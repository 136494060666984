import { useDataChange, useDataValue } from 'Simple/Data.js'

export function useOnClick(props) {
  let treatment_plan_id = useDataValue({
    context: 'treatment_plan',
    path: '_id',
    viewPath: props.viewPath,
  })
  let change = useDataChange({
    context: 'selected',
    path: 'treatment_plan_id',
    viewPath: props.viewPath,
  })

  return function onClick() {
    change(treatment_plan_id)
  }
}
