import { useMutation } from 'Data/Api'
import {
  notifyError,
  notifySuccess,
  useNotifications,
} from 'Logic/Notifications.js'
import { useDataSubmit, useDataValue } from 'Simple/Data.js'

import mutation from './mutation.graphql.js'

/** @type {import('Simple/types.js').useDataOnSubmit} */
export default function useDataOnSubmit(props, data, reExecuteQuery) {
  let onApplyPaymentPlan = useOnApplyPaymentPlan(props)

  return async function onSubmit({ value, originalValue, args, change }) {
    switch (args.type) {
      case 'reFetch':
        return reExecuteQuery({ requestPolicy: 'network-only' })

      case 'apply-payment-plan':
        return onApplyPaymentPlan({ value, originalValue, args, change })

      default:
        return
    }
  }
}

function useOnApplyPaymentPlan(props) {
  let [, executeMutation] = useMutation(mutation)
  let [, notify] = useNotifications()
  let current_location_id = useDataValue({
    viewPath: props.viewPath,
    context: 'global',
    path: 'current_location.id',
  })
  let submit = useDataSubmit({
    context: 'wizard',
    viewPath: props.viewPath,
  })
  let submitPayments = useDataSubmit({
    context: 'payments',
    viewPath: props.viewPath,
  })

  return async function onSubmit({ value, originalValue, args, change }) {
    let mutationResponse = await executeMutation({
      treatment_plan_id: value[0].treatment_plan_id,
      current_location_id,
    })

    if (mutationResponse.error) {
      notify(
        notifyError(
          'Something went wrong. Please, try again or contact support if the problem persists.'
        )
      )
      return
    }

    notify(notifySuccess('Payment plan applied successfully'))

    submitPayments({
      type: 'capture-payments',
      params: {
        treatment_id: value[0].treatment_plan.tx_id,
      },
    })
    submit({ type: 'done' })
  }
}
