import { useDataSubmit } from 'Simple/Data.js'

export function useOnClick(props) {
  let submit = useDataSubmit({
    context: 'bottom_action',
    viewPath: props.viewPath,
  })

  return function onClick() {
    submit({ type: 'checkOut' })
  }
}
