// this is an autogenerated file from App/Account/Content/Patients/Content/Content/Sections/ActiveTab/TreatmentCard/Content/TreatmentPlans/New/Steps/Content/Contracts/data.graphql
import { gql } from 'Data/Api'

export default gql`
query app__App_Account_Content_Patients_Content_Content_Sections_ActiveTab_TreatmentCard_Content_TreatmentPlans_New_Steps_Content_Contracts($treatment_plan_id: numeric!) {
  treatments_payment_plan_payors(
    where: { treatment_plan_id: { _eq: $treatment_plan_id } }
    order_by: { is_primary: desc }
  ) {
    id
    is_primary
    person_id
    treatment_plan_id
    person {
      id
      first_name
      last_name
    }
    treatment_plan {
      id
      tx_id
    }
  }
}

`