import React, { useMemo } from 'react'
import {
  useReactTable,
  flexRender,
  getExpandedRowModel,
  getCoreRowModel,
} from '@tanstack/react-table'
import TysiaIconKeyboardArrowUpFilled from 'DesignSystem/Svgs/TysiaIconKeyboardArrowUpFilled'
import TysiaIconKeyboardArrowDownFilled from 'DesignSystem/Svgs/TysiaIconKeyboardArrowDownFilled'
import TysiaCheckboxInput from 'design-system/TysiaCheckbox/TysiaCheckboxInput'
import TysiaCheckboxText from 'design-system/TysiaCheckbox/TysiaCheckboxText'
import TysiaCheckbox from 'design-system/TysiaCheckbox'
import { useDataChange, useDataValue } from 'Simple/Data'

import { convertTxPlanWeeksToMonths, dataValueFormat } from 'Data/format'

function TreatmentTable(props) {
  let rowSelection = useDataValue({
    context: 'selected',
    viewPath: props.viewPath,
  })
  let setRowSelection = useDataChange({
    context: 'selected',
    viewPath: props.viewPath,
  })

  let columns = useMemo(
    () => [
      {
        header: 'Treatment Name',
        accessorKey: 'name',
        cell: cell => {
          if (cell.row.getCanExpand()) {
            return (
              <div
                style={{
                  fontSize: 11,
                  fontWeight: 500,
                  display: 'flex',
                  flex: 'row',
                  alignItems: 'center',
                }}
              >
                {cell.row.original.name || cell.row.original.type}
                {cell.row.getIsExpanded() ? (
                  <TysiaIconKeyboardArrowUpFilled
                    fill={props.collapseIconColor}
                    viewPath={`${props.viewPath}/Tysiav2ChevronUp`}
                  />
                ) : (
                  <TysiaIconKeyboardArrowDownFilled
                    fill={props.collapseIconColor}
                    viewPath={`${props.viewPath}/Tysiav2ChevronDown`}
                  />
                )}
              </div>
            )
          } else {
            return (
              <TysiaCheckbox
                viewPath={`${props.viewPath}/TysiaCheckbox`}
                value={cell.row.getIsSelected()}
                disabled={
                  !cell.row.getIsSelected() &&
                  cell.table.getSelectedRowModel().flatRows.length >= 4
                }
                onChange={cell.row.getToggleSelectedHandler()}
                size="small"
              >
                {childProps => (
                  <React.Fragment>
                    <TysiaCheckboxInput
                      {...childProps}
                      viewPath={`${props.viewPath}/TysiaCheckboxInput`}
                    />
                    <TysiaCheckboxText
                      {...childProps}
                      viewPath={`${props.viewPath}/TysiaCheckboxText`}
                      text={cell.row.original.name}
                    />
                  </React.Fragment>
                )}
              </TysiaCheckbox>
            )
          }
        },
      },
      {
        header: 'Treatment Length',
        accessorKey: 'length_in_weeks',
        cell: cell => {
          if (cell.row.getCanExpand()) return null

          return (
            <span
              style={{
                opacity:
                  cell.row.getIsSelected() ||
                  cell.table.getSelectedRowModel().flatRows.length < 4
                    ? 1
                    : 0.5,
              }}
            >
              {`${convertTxPlanWeeksToMonths(
                cell.row.original.length_in_weeks
              )} Months`}
            </span>
          )
        },
      },
      {
        header: 'Treatment Fee',
        accessorKey: 'fee',
        cell: cell => {
          if (cell.row.getCanExpand()) return null

          return (
            <span
              style={{
                opacity:
                  cell.row.getIsSelected() ||
                  cell.table.getSelectedRowModel().flatRows.length < 4
                    ? 1
                    : 0.5,
              }}
            >
              {dataValueFormat(cell.getValue(), { isCurrency: true })}
            </span>
          )
        },
      },
    ],
    [props.collapseIconColor, props.viewPath]
  )

  let table = useReactTable({
    columns,
    data: props.value,
    initialState: {
      expanded: true,
    },
    state: {
      rowSelection,
    },
    getRowId: row => row.id,
    getSubRows: row => row.plans || [],
    getCoreRowModel: getCoreRowModel(),
    getExpandedRowModel: getExpandedRowModel(),
    enableMultiRowSelection: true,
    onRowSelectionChange: setRowSelection,
  })

  return (
    <div
      style={{
        width: 'calc(100% - 32px)',
        overflowY: 'auto',
        marginLeft: props.marginLeft,
        marginRight: props.marginRight,
        marginBottom: props.marginBottom,
        alignSelf: 'center',
      }}
    >
      <table
        style={{
          fontFamily: props.fontFamily,
          fontWeight: props.fontWeight,
          fontSize: props.fontSize,
          color: props.color,
          borderSpacing: 0,
          borderRadius: props.borderRadius,
          borderWidth: props.borderWidth,
          borderStyle: props.borderStyle,
          borderColor: props.borderColor,
          overflow: props.overflow,
          width: '100%',
        }}
      >
        <thead>
          {table.getHeaderGroups().map(headerGroup => (
            <tr key={headerGroup.id}>
              {headerGroup.headers.map(header => (
                <th
                  key={header.id}
                  style={{
                    cursor: props.cursorHeader,
                    backgroundColor: props.backgroundColorHeader,
                    borderBottomWidth: props.borderBottomWidthHeader,
                    borderBottomStyle: props.borderBottomStyleHeader,
                    borderBottomColor: header.column
                      ? 'transparent'
                      : props.borderBottomColorHeader,
                    borderLeftWidth: props.borderLeftWidthHeader,
                    borderLeftStyle: props.borderLeftStyleHeader,
                    borderLeftColor: props.borderLeftColorHeader,
                    height: props.heightHeader,
                    fontWeight: props.fontWeightHeader,
                    paddingLeft: props.paddingLeftHeader,
                    paddingRight: props.paddingRightHeader,
                  }}
                >
                  {flexRender(
                    header.column.columnDef.header,
                    header.getContext()
                  )}
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody>
          {table.getRowModel().rows.map((row, i, list) => {
            let isLast = i === list.length - 1
            return (
              <tr
                key={row.id}
                onClick={() => {
                  if (row.getCanExpand()) {
                    row.toggleExpanded()
                  } else if (
                    row.getIsSelected() ||
                    table.getSelectedRowModel().flatRows.length < 4
                  ) {
                    row.toggleSelected()
                  }
                }}
                style={{
                  height: props.heightRow,
                  color: props.colorRow,
                  cursor: 'pointer',
                  backgroundColor: row.getIsSelected()
                    ? props.backgroundColorRowSelected
                    : !!props.onGetBackgroundRowColor &&
                      typeof props.onGetBackgroundRowColor === 'function'
                    ? props.onGetBackgroundRowColor(row)
                    : props.backgroundColorRow,
                }}
              >
                {row.getVisibleCells().map(cell => {
                  return (
                    <td
                      style={{
                        borderBottomWidth: isLast
                          ? 0
                          : props.borderBottomWidthCell,
                        borderBottomStyle: isLast
                          ? 'none'
                          : props.borderBottomStyleCell,
                        borderBottomColor: isLast
                          ? 'transparent'
                          : props.borderBottomColorCell,
                        paddingLeft: props.paddingLeftCell,
                        paddingRight: props.paddingRightCell,

                        borderRightWidth: props.rightBorder
                          ? props.borderBottomWidthCell
                          : 0,
                        borderRightStyle: props.rightBorder
                          ? props.borderBottomStyleCell
                          : 'none',
                        borderRightColor: props.rightBorder
                          ? props.borderBottomColorCell
                          : 'transparent',
                      }}
                    >
                      {flexRender(
                        cell.column.columnDef.cell,
                        cell.getContext()
                      )}
                    </td>
                  )
                })}
              </tr>
            )
          })}
        </tbody>
      </table>
    </div>
  )
}

export default function Logic({
  backgroundColorHeader = '#F5F7FA',
  backgroundColorRow = 'transparent',
  backgroundColorRowSelected = '#F5F7FA',
  borderBottomWidthCell = 1,
  borderBottomWidthHeader = 1,
  borderBottomStyleCell = 'solid',
  borderBottomStyleHeader = 'solid',
  borderBottomColorCell = '#E4E7EB',
  borderBottomColorHeader = '#E4E7EB',
  borderLeftWidthHeader = 1,
  borderLeftStyleHeader = 'solid',
  borderLeftColorHeader = '#F5F7FA',
  borderColor = '#E4E7EB',
  borderRadius = 8,
  borderStyle = 'solid',
  borderWidth = 1,
  color = '#152935',
  colorRow = '#152935',
  cursorHeader = 'pointer',
  fontFamily = 'Inter',
  fontSize = 12,
  fontWeight = 400,
  fontWeightHeader = 500,
  heightHeader = 36,
  heightRow = 44,
  marginBottom = 0,
  marginLeft = 4,
  marginRight = 4,
  marginTop = 16,
  overflow = 'hidden',
  paddingLeftCell = 16,
  paddingLeftHeader = 16,
  paddingRightCell = 16,
  paddingRightHeader = 16,
  onGetBackgroundRowColor = null,
  collapseIconColor = '#9AA5B1',
  disableColor = '#E4E7EB',
  ...props
}) {
  return (
    <TreatmentTable
      backgroundColorHeader={backgroundColorHeader}
      backgroundColorRow={backgroundColorRow}
      backgroundColorRowSelected={backgroundColorRowSelected}
      borderBottomWidthCell={borderBottomWidthCell}
      borderBottomWidthHeader={borderBottomWidthHeader}
      borderBottomStyleCell={borderBottomStyleCell}
      borderBottomStyleHeader={borderBottomStyleHeader}
      borderBottomColorCell={borderBottomColorCell}
      borderBottomColorHeader={borderBottomColorHeader}
      borderLeftWidthHeader={borderLeftWidthHeader}
      borderLeftStyleHeader={borderLeftStyleHeader}
      borderLeftColorHeader={borderLeftColorHeader}
      borderColor={borderColor}
      borderRadius={borderRadius}
      borderStyle={borderStyle}
      borderWidth={borderWidth}
      color={color}
      colorRow={colorRow}
      cursorHeader={cursorHeader}
      fontFamily={fontFamily}
      fontSize={fontSize}
      fontWeight={fontWeight}
      fontWeightHeader={fontWeightHeader}
      heightHeader={heightHeader}
      heightRow={heightRow}
      marginBottom={marginBottom}
      marginLeft={marginLeft}
      marginRight={marginRight}
      marginTop={marginTop}
      overflow={overflow}
      paddingLeftCell={paddingLeftCell}
      paddingLeftHeader={paddingLeftHeader}
      paddingRightCell={paddingRightCell}
      paddingRightHeader={paddingRightHeader}
      onGetBackgroundRowColor={onGetBackgroundRowColor}
      collapseIconColor={collapseIconColor}
      disableColor={disableColor}
      {...props}
    />
  )
}
