import { useMemo } from 'react'
import { useDataValue } from 'Simple/Data.js'

/** @type {import('Simple/types.js').useDataTransform} */
export default function useDataTransform(props, data) {
  let selectedSlotInterval = useDataValue({
    context: 'settings',
    path: 'slot_interval',
    viewPath: props.viewPath,
  })

  return useMemo(() => {
    return Object.fromEntries(
      Object.keys(data).map(interval => [
        interval,
        {
          is_selected: selectedSlotInterval === parseInt(interval),
        },
      ])
    )
  }, [selectedSlotInterval, data])
}
