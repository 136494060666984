// this is an autogenerated file from DesignSystem/DataVaxiomProfessionalView/Content/Email/Emails/data.graphql
import { gql } from 'Data/Api'

export default gql`
query app__DesignSystem_DataVaxiomProfessionalView_Content_Email_Emails($person_id: numeric!) {
  vaxiom_contact_method_associations(
    where: {
      person_id: { _eq: $person_id }
      contact_method: { dtype: { _eq: "email" } }
    }
    order_by: { email: { address: asc } }
  ) {
    id
    preference
    contact_method_id
    description
    email {
      id
      address
    }
  }
}

`