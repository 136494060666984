import { useDataChange, useOnChangeLastValueGuard } from 'Simple/Data.js'

export default function useListItemDataOnChange(props) {
  let change = useDataChange({
    context: 'profile_patient',
    viewPath: props.viewPath,
  })

  return useOnChangeLastValueGuard(function onChange(value) {
    change(next => {
      next.txs[props.index] = value
    })
  })
}
