import { useMemo } from 'react'
import { useDataValue } from 'Simple/Data.js'

export default function useDataTransform(props, data) {
  let city = useDataValue({
    context: 'contact_method_association',
    viewPath: props.viewPath,
    path: 'postal_address.city',
  })

  return useMemo(() => {
    if (!data) return data

    return { term: city }
  }, [data])
}
