export function getView(connections) {
  let statuses = new Set(connections.map(item => item.status))

  if (statuses.has('active')) {
    return 'Connected'
  } else if (
    statuses.has('expired') ||
    statuses.has('revoked') ||
    statuses.has('inactive')
  ) {
    return 'Disconnected'
  } else {
    return 'No'
  }
}
