// this is an autogenerated file from App/Account/Content/Settings/AdminContent/SettingsContent/TreatmentCaseCloser/Configuration/CustomPlanRulesets/Rulesets/data.graphql
import { gql } from 'Data/Api'

export default gql`
query app__App_Account_Content_Settings_AdminContent_SettingsContent_TreatmentCaseCloser_Configuration_CustomPlanRulesets_Rulesets($parent_company_id: numeric!) {
  treatments_custom_payment_plan_ruleset(
    where: {
      tx_plan_group: {
        pc_id: { _eq: $parent_company_id }
        deleted: { _eq: false }
      }
    }
    order_by: { tx_plan_group: { name: asc } }
  ) {
    id
    tx_plan_group_id
    min_downpayment
    min_downpayment_type
    max_length
    max_length_type
  }
}

`