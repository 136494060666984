// this is an autogenerated file from App/Account/Content/Settings/AdminContent/SettingsContent/TreatmentCaseCloser/Configuration/PaymentMethods/Content/PaymentMethodSelector/data.graphql
import { gql } from 'Data/Api'

export default gql`
query app__App_Account_Content_Settings_AdminContent_SettingsContent_TreatmentCaseCloser_Configuration_PaymentMethods_Content_PaymentMethodSelector($organization_id: numeric!, $feature_flag: String!) {
  vaxiom_application_properties(
    where: {
      organization_id: { _eq: $organization_id }
      message_key: { _eq: $feature_flag }
    }
  ) {
    id: organization_id
    message_value
  }
}

`