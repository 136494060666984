// this is an autogenerated file from DesignSystem/TimelineEvent/Appointment/Expanded/Content/Notes/Notes/data.graphql
import { gql } from 'Data/Api'

export default gql`
query app__DesignSystem_TimelineEvent_Appointment_Expanded_Content_Notes_Notes($appointment_id: numeric!) {
  notes: vaxiom_notes(
    order_by: { sys_created: asc }
    where: {
      target_type: {
        _eq: "com.axpm.treatments.core.internal.domain.Appointment"
      }
      target_id: { _eq: $appointment_id }
    }
  ) {
    id
    note
    target_type
    sys_created
    author
    visible_in_schedule
    author_user {
      id
      person {
        id
        first_name
        last_name
      }
    }
  }
}

`