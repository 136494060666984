import { useDataChange } from 'Simple/Data'

export function useOnClick(props) {
  let setDisabled = useDataChange({
    viewPath: props.viewPath,
    context: 'editor',
    path: 'disabled',
  })
  let setRefresh = useDataChange({
    context: 'preview',
    path: 'refresh',
    viewPath: props.viewPath,
  })

  return function onClick() {
    setDisabled(true)
    // refetch to discard changes
    setRefresh(Date.now())
  }
}
