// this is an autogenerated file from DesignSystem/PatientOverview/Content/Contact/Content/Phones/Content/Phone/Apps/Content/App/mutation.graphql
import { gql } from 'Data/Api'

export default gql`
mutation app__DesignSystem_PatientOverview_Content_Contact_Content_Phones_Content_Phone_Apps_Content_App(
  $id: String!
  $resource_id: uuid!
  $location_id: uuid!
) {
  apps_launchers_resolve(
    id: $id
    resource_id: $resource_id
    location_id: $location_id
  ) {
    ok
    url
    component
    message
  }
}

`