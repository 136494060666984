// this is an autogenerated file from App/Account/Content/Patients/Content/Content/Sections/ActiveTab/Profile/Content/NewProfile/Content/Main/ReferralsSection/Add/Content/Templates/data.graphql
import { gql } from 'Data/Api'

export default gql`
query app__App_Account_Content_Patients_Content_Content_Sections_ActiveTab_Profile_Content_NewProfile_Content_Main_ReferralsSection_Add_Content_Templates($primary_location_id: uuid!) {
  vaxiom_referral_templates(
    where: {
      _and: [
        { location: { _id: { _eq: $primary_location_id } } }
        { deleted: { _eq: false } }
        { free_type: { _nin: ["Patient", "Professional"] } }
      ]
    }
  ) {
    id
    type
    free_type
    referral_list_values {
      referral_template_id
      pos
      value
    }
  }
}

`