export function getView(
  connection_resource_status,
  connection_status,
  connection_app_tags
) {
  // only allow setting up automations for iTero and if the connection is active and enabled for the parent compant/location
  return (
    Array.isArray(connection_app_tags) &&
    connection_app_tags.includes('itero') &&
    connection_resource_status === 'enabled' &&
    connection_status === 'active'
  )
}
