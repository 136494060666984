import { useDataValue } from 'Simple/Data.js'
/** @type {import('Simple/types.js').useDataConfiguration} */
export default function useDataConfiguration(props, data) {
  let id = useDataValue({
    context: 'employer',
    viewPath: props.viewPath,
    path: 'id',
  })
  return {
    variables: { id },
  }
}
