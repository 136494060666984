import React from 'react'
import View from './view.js'
import { useGetUrl } from './helpers.js'
import { useDataFormat } from 'Simple/Data.js'

export default function Logic(props) {
  let getUrl = useGetUrl(props)

  let url = useDataFormat({
    viewPath: props.viewPath,
    context: 'launcher',
    path: 'data.url',
    format: getUrl,
  })

  return <View {...props} url={url} />
}
