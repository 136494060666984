import useStableValue from 'Logic/useStableValue.js'
import { useDataValue } from 'Simple/Data.js'
/** @type {import('Simple/types.js').useDataConfiguration} */
export default function useDataConfiguration(props, data) {
  let organization_id = useDataValue({
    context: 'patient',
    path: 'person.organization_id',
    viewPath: props.viewPath,
  })
  let search = useDataValue({
    context: 'selected',
    path: 'search',
    viewPath: props.viewPath,
  })

  let selected_employer = useDataValue({
    context: 'employer',
    path: 'id',
    viewPath: props.viewPath,
  })

  let search_term = useStableValue(`%${search}%`)

  return {
    variables: {
      organization_id,
      search_term,
      selected_employer,
      search_selected_employer: !isNaN(selected_employer),
    },
  }
}
