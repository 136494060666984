// this is an autogenerated file from App/Account/Content/Patients/Content/Content/Sections/ActiveTab/TreatmentCard/Content/TreatmentCard/Treatment/Content/Actions/Content/Questionnaires/Content/Content/QuestionsAnswersModal/Content/data.graphql
import { gql } from 'Data/Api'

export default gql`
query app__App_Account_Content_Patients_Content_Content_Sections_ActiveTab_TreatmentCard_Content_TreatmentCard_Treatment_Content_Actions_Content_Questionnaires_Content_Content_QuestionsAnswersModal_Content($questionnaire_id: numeric!) {
  vaxiom_questionnaire_answers(
    where: { questionnaire_id: { _eq: $questionnaire_id } }
  ) {
    id
    question {
      id
      question
    }
    answer {
      id
      answer
    }
  }
}

`