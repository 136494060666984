// this is an autogenerated file from App/Account/Content/Patients/Content/Content/Sections/ActiveTab/TreatmentCard/Content/TreatmentCard/TxPlanNotes/Content/data.graphql
import { gql } from 'Data/Api'

export default gql`
query app__App_Account_Content_Patients_Content_Content_Sections_ActiveTab_TreatmentCard_Content_TreatmentCard_TxPlanNotes_Content($tx_card_id: uuid!) {
  vaxiom_tx_plan_notes(
    where: { tx_card: { _id: { _eq: $tx_card_id } } }
    order_by: { sys_created: desc_nulls_last }
  ) {
    id
    note
    sys_created
    created_by {
      id
      person {
        id
        _id
        first_name
        last_name
      }
    }
    tx_card {
      id
      _id
    }
  }
}

`