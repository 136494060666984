import { useDataValue } from 'Simple/Data.js'
import { useMemo } from 'react'

/** @type {import('Simple/types.js').useDataTransform} */
export default function useDataTransform(props, data) {
  let automations = useDataValue({
    context: 'tab',
    path: 'selected.automations',
    viewPath: props.viewPath,
  })
  let automation_hub_enabled = useDataValue({
    context: 'tab',
    path: 'selected.automation_hub_enabled',
    viewPath: props.viewPath,
  })

  return useMemo(() => {
    if (!data?.length) return data

    // Only show events which doesn't have a notification action
    return data
      .filter(({ actions }) =>
        actions.every(({ type }) => type !== 'notification')
      )
      .map(item => ({
        ...item,
        selected: automations.includes(item.id),
        status:
          item.app_id && !automation_hub_enabled ? 'disabled' : item.status,
      }))
  }, [data, automations, automation_hub_enabled])
}
