// this is an autogenerated file from App/Account/Content/Patients/Content/Content/Sections/ActiveTab/TreatmentCard/Content/TreatmentPlans/New/Steps/Content/PaymentPlans/Content/Content/PaymentPlan/Content/Content/mutation-send-request.graphql
import { gql } from 'Data/Api'

export default gql`
mutation app__App_Account_Content_Patients_Content_Content_Sections_ActiveTab_TreatmentCard_Content_TreatmentPlans_New_Steps_Content_PaymentPlans_Content_Content_PaymentPlan_Content_Content(
  $treatment_plan_id: uuid!
  $patient_id: uuid!
  $person_id: uuid!
  $current_location_id: numeric!
  $payment_plan: jsonb!
) {
  treatments_save_payment_plan(
    args: {
      current_location_id: $current_location_id
      payment_plan: $payment_plan
    }
  ) {
    id
  }
  treatments_request_create(
    tx_plan_id: $treatment_plan_id
    patient_id: $patient_id
    person_id: $person_id
  ) {
    id
  }
}

`