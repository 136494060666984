// this is an autogenerated file from DesignSystem/DataVaxiomOtherProfessionalsRelationshipsItem/Menu/Option/setPastMutation.graphql
import { gql } from 'Data/Api'

export default gql`
mutation app__DesignSystem_DataVaxiomOtherProfessionalsRelationshipsItem_Menu_Option($id: numeric!, $past: Boolean!) {
  update_vaxiom_professional_relationships_by_pk(
    pk_columns: { id: $id }
    _set: { past: $past }
  ) {
    id
  }
}

`