import { useMemo } from 'react'

export default function useDataTransform(props, data) {
  return useMemo(() => {
    if (!data) return data
    let { id, first_name, last_name, contact_method_associations } = data
    let contact_method_association = contact_method_associations?.[0]
    let city = contact_method_association?.contact_method?.city
    let state = contact_method_association?.contact_method?.state
    let display = `${first_name} ${last_name} ${city ? ',' + city : ''} ${
      state ? ',' + state : ''
    }`
    return { ...data, display }
  })
}
