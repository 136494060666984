import React from 'react'
import View from './view.js'
import { useDataChange } from 'Simple/Data.js'

/*
 this was needed was simple was taking adding onChange prop on main component but taking the value from the data onChange only
 ex: 

 View
 data selected.search
 onFilterChange <onChange
 onChange helpers.js useOnChange

 would transte to 

 // the onChange in args is unneccessary since it'd take it from searchOnChange instead
 
 function Component({onChange}) {

  let searchOnChange = useDataChange({...})
  let onChange = useOnChange(...) // error here since it is redefining onChange

  return 
  <View onFilterChange={searchOnChange} onChange={onChange}>
    ...
  </View>

 }

*/

export default function Logic(props) {
  let change = useDataChange({
    viewPath: props.viewPath,
    context: 'selected',
    path: 'search_term',
  })
  return <View {...props} onChangeFilter={change} />
}
