import { useDataSubmit } from 'Simple/Data'

export function useOnClick(props) {
  let submit = useDataSubmit({
    viewPath: props.viewPath,
    context: 'new_address',
  })
  return function useOnClick(value) {
    submit({ type: 'new_address_for_patient' })
  }
}
