// this is an autogenerated file from App/Account/PatientOverlay/Content/Content/Appointments/TabContent/AppointmentsList/Content/Appointment/BottomAction/Content/mutation-unseat.graphql
import { gql } from 'Data/Api'

export default gql`
mutation app__App_Account_PatientOverlay_Content_Content_Appointments_TabContent_AppointmentsList_Content_Appointment_BottomAction_Content($id: uuid!, $skip_required_fields: Boolean!) {
  vaxiom_appointment_booking_unseat(
    id: $id
    skip_required_fields: $skip_required_fields
  ) {
    status
  }
}

`