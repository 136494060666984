// this is an autogenerated file from App/Account/Content/Patients/Content/Content/Sections/ActiveTab/TreatmentCard/Content/TreatmentPlans/New/Steps/Content/Contracts/Content/Payor/Content/SignAction/data.graphql
import { gql } from 'Data/Api'

export default gql`
subscription app__App_Account_Content_Patients_Content_Content_Sections_ActiveTab_TreatmentCard_Content_TreatmentPlans_New_Steps_Content_Contracts_Content_Payor_Content_SignAction($id: uuid!) {
  documents_signature_requests(
    where: { id: { _eq: $id }, status: { _neq: cancelled } }
  ) {
    id
    status
    created_at
    resource_id: resource_metadata(path: "id")
    allowed_birth_dates
    signatory_person {
      id
      first_name
      last_name
    }
  }
}

`