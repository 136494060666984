// this is an autogenerated file from DesignSystem/TimeGrid/FullCalendarTimegrid/Note/mutation-delete-schedule-note.graphql
import { gql } from 'Data/Api'

export default gql`
mutation app__DesignSystem_TimeGrid_FullCalendarTimegrid_Note($id: uuid!) {
  delete_vaxiom_schedule_notes(where: { _id: { _eq: $id } }) {
    affected_rows
    returning {
      id
      _id
    }
  }
}

`