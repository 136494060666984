import { useDataSubmit } from 'Simple/Data'

export function useOnClick(props) {
  let submit = useDataSubmit({
    viewPath: props.viewPath,
    context: 'edit_email',
  })
  return async function onClick() {
    submit({ type: 'new_email_for_patient' })
  }
}
