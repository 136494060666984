import { normalizePath, useSetFlowTo } from 'Simple/Flow.js'
import { useDataChange } from 'Simple/Data.js'

/** @type {import('Simple/types.js').useDataOnSubmit} */
export default function useDataOnSubmit(props, data) {
  let changeTabs = useDataChange({
    context: 'tab',
    viewPath: props.viewPath,
  })
  let setFlowTo = useSetFlowTo(props.viewPath)

  return async function onSubmit({ value, originalValue, args, change }) {
    switch (args.type) {
      case 'capture-payments': {
        changeTabs(next => {
          next.selected.payments_treatment_plan = args.params
        })
        setFlowTo(
          normalizePath(props.viewPath, 'PaymentsTreatmentPlan/Content')
        )
        break
      }
      case 'cancel':
        setFlowTo([
          normalizePath(props.viewPath, 'PaymentsTreatmentPlan/No'),
          normalizePath(props.viewPath, 'Content/TreatmentCard'),
        ])
        break
      default:
        return
    }
  }
}
