// this is an autogenerated file from App/Account/Content/Patients/Content/Content/Sections/ActiveTab/Profile/Content/NewProfile/Content/SchedulingPreferences/Content/Delete/Content/Actions/DeleteAction/deleteSchedulingPreferencesMutation.graphql
import { gql } from 'Data/Api'

export default gql`
mutation app__App_Account_Content_Patients_Content_Content_Sections_ActiveTab_Profile_Content_NewProfile_Content_SchedulingPreferences_Content_Delete_Content_Actions_DeleteAction($sp_id: numeric!) {
  delete_vaxiom_scheduling_preferred_employees(
    where: { preference_id: { _eq: $sp_id } }
  ) {
    affected_rows
  }
  delete_vaxiom_scheduling_preferences_week_days(
    where: { scheduling_preferences_id: { _eq: $sp_id } }
  ) {
    affected_rows
  }
  delete_vaxiom_scheduling_preferences_by_pk(id: $sp_id) {
    id
  }
}

`