// this is an autogenerated file from App/Account/Content/Settings/AdminContent/SettingsContent/IntegrationsApplications/Content/Connection/Content/SetupAutomations/Content/data.graphql
import { gql } from 'Data/Api'

export default gql`
query app__App_Account_Content_Settings_AdminContent_SettingsContent_IntegrationsApplications_Content_Connection_Content_SetupAutomations_Content(
  $location_id: uuid!
  $app_id: uuid!
  $connection_level: connection_levels!
  $type: apps_connection_resource_types_enum!
  $connection_id: uuid!
  $resource_id: uuid!
) {
  apps_automations(
    location_id: $location_id
    app_id: $app_id
    connection_level: $connection_level
  ) {
    id
    automations
  }
  apps_connection_resource_properties(
    where: {
      type: { _eq: $type }
      connection_id: { _eq: $connection_id }
      resource_id: { _eq: $resource_id }
    }
  ) {
    id
    connection_id
    resource_id
    type
    properties
  }
}

`