import { useSetFlowTo, normalizePath } from 'Simple/Flow.js'
import { useMutation } from 'Data/Api'
import { useDataValue } from 'Simple/Data'
import {
  notifyError,
  useNotifications,
  notifySuccess,
} from 'Logic/Notifications.js'
import { RELATIONSHIPS } from 'Data/constants.js'
import mutation_update_person_relationships from './updatePersonRelationshipsMutation.graphql.js'
import { birthday } from 'Data/validate.js'

export default function useDataOnSubmit(props, data) {
  let setFlowTo = useSetFlowTo(props.viewPath)
  let [, notify] = useNotifications()

  let [, executeMutationUpdatePersonRelationships] = useMutation(
    mutation_update_person_relationships
  )

  let patient_person_id = useDataValue({
    viewPath: props.viewPath,
    context: 'profile_patient',
    path: 'person.id',
  })
  let patient_gender = useDataValue({
    viewPath: props.viewPath,
    context: 'profile_patient',
    path: 'person.gender',
  })

  return async function onSubmit({ value: edit_relation }) {
    let { type, role, permitted_to_see_info, from_person_record } =
      edit_relation
    if (
      !type ||
      !role ||
      !from_person_record.first_name ||
      !from_person_record.last_name ||
      !from_person_record.gender
    ) {
      return true
    }

    if (
      from_person_record.birth_date &&
      !birthday(from_person_record.birth_date)
    ) {
      return true
    }

    let inv_type =
      RELATIONSHIPS.find(r => r.id === type)?.[patient_gender] ||
      RELATIONSHIPS.find(r => r.UNSPECIFIED === type).id
    let inv_role =
      role === 'RESPONSIBLE'
        ? 'DEPENDENT'
        : role === 'DEPENDENT'
        ? 'RESPONSIBLE'
        : 'OTHER'
    let inv_permitted_to_see_info = false

    let mutationUpdatePersonRelationshipsResponse =
      await executeMutationUpdatePersonRelationships({
        patient_person_id,
        relative_person_id: edit_relation.from_person,
        type,
        role,
        permitted_to_see_info,
        inv_type,
        inv_role,
        inv_permitted_to_see_info,
        person: {
          first_name: from_person_record.first_name,
          middle_name: from_person_record.middle_name,
          last_name: from_person_record.last_name,
          gender: from_person_record.gender,
          birth_date: from_person_record.birth_date,
          title: from_person_record.title,
          greeting: from_person_record.greeting,
          ssn: from_person_record.ssn,
        },
      })
    if (mutationUpdatePersonRelationshipsResponse.error) {
      notify(
        notifyError(
          'Something went wrong. Please, try again or contact support if the problem persists.'
        )
      )
      return
    }

    notify(notifySuccess('Relationship Updated!'))

    setFlowTo(normalizePath(props.viewPath, '../No'))
  }
}
