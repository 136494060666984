import { useSetFlowTo, normalizePath } from 'Simple/Flow.js'
import { useDataValue } from 'Simple/Data.js'
import { useMutation } from 'Data/Api.js'
import {
  notifyError,
  useNotifications,
  notifySuccess,
} from 'Logic/Notifications.js'
import mutation from './mutation.graphql.js'

export function useOnClick(props) {
  let subscription_id = useDataValue({
    context: 'assigned_insurance',
    viewPath: props.viewPath,
    path: 'id',
  })

  let [, notify] = useNotifications()
  let setFlowTo = useSetFlowTo(props.viewPath)

  let [, executeMutation] = useMutation(mutation)
  return async function onClick() {
    let mutationResponse = await executeMutation({
      subscription_id,
    })
    if (mutationResponse.error) {
      notify(
        notifyError(
          `There was a problem removing the coverage. Please try again.`
        )
      )
      return
    }
    notify(notifySuccess(`Coverage removed succesfully.`))
    setFlowTo(normalizePath(props.viewPath, '../../No'))
  }
}
