// this is an autogenerated file from App/Account/Content/Patients/Content/Content/Sections/ActiveTab/Profile/Content/NewProfile/Content/ContactInfo/Postal/Addresses/Content/Address/Edit/Content/EditOrAdd/Content/Content/Profiles/data.graphql
import { gql } from 'Data/Api'

export default gql`
query app__App_Account_Content_Patients_Content_Content_Sections_ActiveTab_Profile_Content_NewProfile_Content_ContactInfo_Postal_Addresses_Content_Address_Edit_Content_EditOrAdd_Content_Content_Profiles($contact_method_id: numeric!, $person_id: numeric) {
  vaxiom_contact_method_associations(
    where: {
      contact_method_id: { _eq: $contact_method_id }
      person_id: { _neq: $person_id }
    }
    limit: 10
  ) {
    id
    person_id
    contact_method_id
    description
    preference
    person {
      id
      _id
      first_name
      last_name
      greeting
      gender
      birth_date
      profile_pic_url
      patient {
        id
        _id
        human_readable_id
        legacy_id
      }
      from_person_relationships {
        id
        role
        to_person_record {
          id
          first_name
          last_name
          profile_pic_url
          gender
          birth_date
          patient {
            id
            _id
          }
        }
      }
      employment_contracts(
        where: {
          employment_end_date: { _is_null: true }
          deleted: { _eq: false }
        }
      ) {
        id
      }
      other_professional_relationships {
        id
      }
    }
  }
}

`