// this is an autogenerated file from App/Account/Content/Patients/Content/Content/Sections/ActiveTab/Profile/Content/NewProfile/Content/Insurance/AddAction/Content/Content/Filter/RegisterAction/Content/mutation.graphql
import { gql } from 'Data/Api'

export default gql`
mutation app__App_Account_Content_Patients_Content_Content_Sections_ActiveTab_Profile_Content_NewProfile_Content_Insurance_AddAction_Content_Content_Filter_RegisterAction_Content(
  $insurance_plan: json!
  $ortho: Boolean!
  $ortho_coverage: json!
  $tx_category_id: numeric!
) {
  insert_patient_insurance_plan(
    args: {
      ortho: $ortho
      insurance_plan: $insurance_plan
      ortho_coverage: $ortho_coverage
      tx_category_id: $tx_category_id
    }
  ) {
    id
    _id
  }
}

`