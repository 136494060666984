// this is an autogenerated file from DesignSystem/DataVaxiomPatientInsuranceForm/Carrier/data.graphql
import { gql } from 'Data/Api'

export default gql`
query app__DesignSystem_DataVaxiomPatientInsuranceForm_Carrier(
  $selected_carrier_id: numeric
  $search_selected_carrier_id: Boolean!
  $organization_id: numeric!
  $search_term: String!
) {
  options: vaxiom_insurance_companies(
    where: {
      organization_id: { _eq: $organization_id }
      carrier_name: { _ilike: $search_term }
    }
    distinct_on: carrier_name
    limit: 250
    order_by: { carrier_name: asc }
  ) {
    id
    text: carrier_name
    installment_interval
    continuation_claims_required
    postal_address {
      address_line1
      zip
      state
      city
    }
  }

  selected_insurance_company: vaxiom_insurance_companies(
    where: { id: { _eq: $selected_carrier_id } }
  ) @include(if: $search_selected_carrier_id) {
    id
    text: carrier_name
    installment_interval
    continuation_claims_required
    postal_address {
      address_line1
      zip
      state
      city
    }
  }
}

`