export function getWeekdayText(weekdays) {
  return weekdays.map(w => days.find(d => d.id === w.week_days).text).join(', ')
}

let days = [
  {
    id: 1,
    text: 'Monday',
  },
  {
    id: 2,
    text: 'Tuesday',
  },
  {
    id: 3,
    text: 'Wednesday',
  },
  {
    id: 4,
    text: 'Thursday',
  },
  {
    id: 5,
    text: 'Friday',
  },
  {
    id: 6,
    text: 'Saturday',
  },
  {
    id: 7,
    text: 'Sunday',
  },
]
