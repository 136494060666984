import React from 'react'
import { useDataValue } from 'Simple/Data.js'

import View from './view.js'

export default function Logic(props) {
  let number_of_people_with_email = useDataValue({
    viewPath: props.viewPath,
    context: 'number_of_people_with_email',
  })
  let email = useDataValue({
    viewPath: props.viewPath,
    context: 'email',
    path: 'email.address',
  })

  return (
    <View
      {...props}
      text={
        number_of_people_with_email === 1
          ? `There is one other person that has this email ${email}. Do you want to update it with this email or just this profile?`
          : `There are ${number_of_people_with_email} people that have this email ${email}. Do you want to update all the selected profiles with this email or just this profile?`
      }
    />
  )
}
