import { useDataChange, useDataSubmit, useDataValue } from 'Simple/Data.js'
export function useOnChange(props) {
  let email = useDataValue({
    context: 'email',
    viewPath: props.viewPath,
    path: 'id',
  })

  let change = useDataChange({
    context: 'profile_patient',
    viewPath: props.viewPath,
  })

  return function onChange(value) {
    change(next => {
      next.appointment_reminders[email] = value
    })
  }
}

export function isAppointmentReminder(
  contact_method_id,
  appointment_reminders
) {
  return !!appointment_reminders?.[contact_method_id]
}
