// this is an autogenerated file from App/Account/Content/Patients/Content/Content/Sections/ActiveTab/Profile/Content/NewProfile/Content/ContactInfo/Postal/NewAddress/Content/Content/Form/City/Cities/data.graphql
import { gql } from 'Data/Api'

export default gql`
query app__App_Account_Content_Patients_Content_Content_Sections_ActiveTab_Profile_Content_NewProfile_Content_ContactInfo_Postal_NewAddress_Content_Content_Form_City_Cities($search_term: String) {
  cities(
    where: { city: { _ilike: $search_term } }
    limit: 20
    order_by: { city: asc }
    distinct_on: city
  ) {
    id: city
    value: city
  }
}

`