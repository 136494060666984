import { useDataValue } from 'Simple/Data.js'

export default function useDataConfiguration(props) {
  let person_id = useDataValue({
    viewPath: props.viewPath,
    context: 'profile_patient',
    path: 'person.id',
  })
  let contact_method_id = useDataValue({
    viewPath: props.viewPath,
    context: 'phone',
    path: 'contact_method_id',
  })
  return {
    variables: { person_id, contact_method_id },
    pause: !person_id,
  }
}
