import { useMutation } from 'Data/Api.js'
import mutation from './mutation.graphql.js'
import { normalizePath, useSetFlowTo } from 'Simple/Flow.js'
import {
  notifyError,
  notifySuccess,
  useNotifications,
} from 'Logic/Notifications.js'
import { useDataValue } from 'Simple/Data.js'

export default function useListItemDataOnSubmit(props) {
  let [, executeMutation] = useMutation(mutation)
  let setFlowTo = useSetFlowTo(props.viewPath)
  let [, notify] = useNotifications()
  let location_id = useDataValue({
    context: 'global',
    path: 'current_location._id',
    viewPath: props.viewPath,
  })

  return async function onSubmit({ value }) {
    // TODO: implement through backend
    if (value.type === 'ACTION_QR') {
      setFlowTo(normalizePath(props.viewPath, 'ActionQr/Content'))
      return
    }

    let mutationResponse = await executeMutation({
      id: value.id,
      location_id,
    })

    if (mutationResponse.error) {
      return notify(notifyError(`There was a problem launching the app.`))
    }

    if (!mutationResponse.data.apps_launchers_resolve.ok) {
      return notify(
        notifyError(mutationResponse.data.apps_launchers_resolve.message)
      )
    }

    switch (value.type) {
      case 'URL_STATIC': {
        window.open(mutationResponse.data.apps_launchers_resolve.url)
        setFlowTo(normalizePath(props.viewPath, '../../../../../No'))
        break
      }

      case 'URL_HTTP': {
        window.open(mutationResponse.data.apps_launchers_resolve.url)
        setFlowTo(normalizePath(props.viewPath, '../../../../../No'))
        break
      }

      case 'ACTION_QR': {
        setFlowTo(normalizePath(props.viewPath, 'ActionQr/Content'))
        break
      }

      case 'ACTION_COMPONENT': {
        break
      }

      default: {
        break
      }
    }

    if (mutationResponse.data.apps_launchers_resolve.message) {
      return notify(
        notifySuccess(mutationResponse.data.apps_launchers_resolve.message)
      )
    }
  }
}
