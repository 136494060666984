import { useDataValue, useDataSubmit, useDataChange } from 'Simple/Data.js'
import { timeToMinutes } from 'Data/format.js'

export function useOnClick(props) {
  let submit = useDataSubmit({
    viewPath: props.viewPath,
    context: 'appointment_overlay',
  })
  let appointment = useDataValue({
    context: 'appointment',
    viewPath: props.viewPath,
  })
  let change = useDataChange({
    viewPath: props.viewPath,
    context: 'appointment_overlay',
  })

  return () => {
    if (appointment.id === 'new') {
      change(next => {
        next.appointment_id = null
        next.is_external_appointment_id = false
      })
    } else {
      submit({
        type: 'setExistingAppointment',
        id: appointment._id,
        type_id: appointment.type._id,
        treatment_id: appointment.tx._id,
        duration: appointment.type.appointment_templates?.[0]?.duration ?? null,
        ...(appointment.booking
          ? {
              preselect_scheduling_slot_config: {
                start_min: timeToMinutes(appointment.booking.local_start_time),
                date: appointment.booking.local_start_date,
                chair_id: appointment.booking.chair._id,
              },
            }
          : {}),
      })
    }
    props.closePopover?.()
  }
}
