import { useEffect, useState } from 'react'

let initialState = {
  fetching: false,
  data: null,
  error: null,
  headers: null,
  status: null,
  statusText: null,
}

export default function useFetch(url, init = undefined) {
  let [state, setState] = useState(initialState)

  useEffect(() => {
    if (!url) return

    execute()
  }, [url]) // eslint-disable-line react-hooks/exhaustive-deps
  // ignore execute

  return [state, execute]

  function stringifyHeaders(headers) {
    return JSON.stringify(Object.fromEntries(headers.entries()))
  }

  async function execute() {
    try {
      setState(prev => ({ ...initialState, fetching: true }))
      let response = await fetch(url, init)
      let data = await response.text()

      if (response.ok) {
        setState(prev => ({
          ...prev,
          fetching: false,
          data,
          status: response.status,
          headers: stringifyHeaders(response.headers),
          statusText: response.statusText,
        }))
      } else {
        setState(prev => ({
          ...prev,
          fetching: false,
          data: null,
          error: data,
          status: response.status,
          headers: stringifyHeaders(response.headers),
          statusText: response.statusText,
        }))
      }
    } catch (error) {
      setState(prev => ({ ...prev, fetching: false, error }))
    }
  }
}
