import { useMemo } from 'react'
import { formatAddress } from 'Data/format.js'

/** @type {import('Simple/types.js').useDataTransform} */
export default function useDataTransform(props, data) {
  return useMemo(() => {
    if (!data) return []

    let selected_billing_center = []
    if (
      data?.selected_billing_center &&
      !data?.options.some(d => d.id === data?.selected_billing_center?.id)
    ) {
      selected_billing_center = data?.selected_billing_center
    }

    return data.options.concat(selected_billing_center).map(d => ({
      id: d.id,
      text: `${d.name} (${d.payer_id}); ${formatAddress(
        d.contact_postal_address
      )}`,
    }))
  }, [data])
}
