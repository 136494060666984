import useStableValue from 'Logic/useStableValue.js'
import { useDataValue } from 'Simple/Data.js'
/** @type {import('Simple/types.js').useDataConfiguration} */
export default function useDataConfiguration(props, data) {
  let organization_id = useDataValue({
    viewPath: props.viewPath,
    context: 'global',
    path: 'current_location.parent_company._id',
  })
  let search = useDataValue({
    context: 'selected',
    path: 'search',
    viewPath: props.viewPath,
  })
  let selected_employer = useDataValue({
    context: 'tx_category_insured',
    viewPath: props.viewPath,
    path: 'insured.insurance_subscription.employer_id',
  })

  let search_term = useStableValue(`%${search}%`)
  return {
    variables: {
      organization_id,
      search_term,
      selected_employer,
      search_selected_employer: !!selected_employer,
    },
  }
}
