import { useMemo } from 'react'

/** @type {import('Simple/types.js').useDataTransform} */
export default function useDataTransform(props, data) {
  return useMemo(() => {
    // Events for calendar: this query and data context retrieves
    // the main events we need for the timegrid, appointment bookings
    // and shedule notes (that has blocked times and calendar notes)
    // This is the query that reacts to event updates when necessary
    return {
      appointment_bookings:
        data?.vaxiom_appointment_bookings?.map(item => ({
          ...item,
          duration:
            item.duration > 0
              ? item.duration
              : Math.max(
                  ...item.appointment.type.appointment_templates.map(
                    at => at.duration
                  )
                ),
        })) || [],
      schedule_notes: data?.vaxiom_schedule_notes || [],
    }
  }, [data])
}
