// this is an autogenerated file from DesignSystem/DataVaxiomRelationshipsItem/Edit/NonPatient/Postal/Addresses/Content/Address/Related/Content/Popup/Content/Persons/Person/Relation/data.graphql
import { gql } from 'Data/Api'

export default gql`
query app__DesignSystem_DataVaxiomRelationshipsItem_Edit_NonPatient_Postal_Addresses_Content_Address_Related_Content_Popup_Content_Persons_Person_Relation($from_person_id: numeric!, $to_person_id: numeric!) {
  vaxiom_relationships(
    where: {
      from_person: { _eq: $from_person_id }
      to_person: { _eq: $to_person_id }
    }
  ) {
    id
    type
  }
}

`