import React from 'react'
import { useDataValue } from 'Simple/Data.js'
import { phoneNumberUS } from 'Data/format.js'

import View from './view.js'

export default function Logic(props) {
  let number_of_people_with_phone = useDataValue({
    viewPath: props.viewPath,
    context: 'number_of_people_with_phone',
  })
  let phone = useDataValue({
    viewPath: props.viewPath,
    context: 'phone',
    path: 'phone.number',
  })

  return (
    <View
      {...props}
      text={
        number_of_people_with_phone === 1
          ? `There is one other person that has this phone number ${phoneNumberUS(
              phone
            )}. Do you want to update it with this phone number or just this profile?`
          : `There are ${number_of_people_with_phone} people that have this phone number ${phoneNumberUS(
              phone
            )}. Do you want to update all the selected profiles with this phone number or just this profile?`
      }
    />
  )
}
