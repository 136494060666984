import { useDataChange } from 'Simple/Data.js'
export function useOnChooseAddress(props) {
  let change = useDataChange({
    viewPath: props.viewPath,
    context: 'billing_center',
  })
  return function onChange({ street, city, state, zip }) {
    change(next => {
      next.contact_postal_address.address_line1 = street || ''
      next.contact_postal_address.city = city || ''
      next.contact_postal_address.state = state || ''
      next.contact_postal_address.zip = zip || ''
    })
  }
}
