// this is an autogenerated file from App/Account/Content/Calendar/New/Content/Content/CollapsibleSidebar/MonthlyCalendar/data.graphql
import { gql } from 'Data/Api'

export default gql`
query app__App_Account_Content_Calendar_New_Content_Content_CollapsibleSidebar_MonthlyCalendar(
  $location_id: uuid!
  $start_date: date!
  $end_date: date!
) {
  metrics: calendar_get_daily_calendar_metrics(
    args: {
      _location_id: $location_id
      _start_date: $start_date
      _end_date: $end_date
    }
  ) {
    id
    office_day_date
    appointments
    patients
    exams
    starts
    percentage_of_occupancy
    free_appointment_slots
    appointment_templates
    office_day {
      id
      template {
        id
        name
        color
      }
    }
  }
  allocations: vaxiom_chair_allocations(
    where: {
      ca_date: { _gte: $start_date, _lte: $end_date }
      resource: {
        organization: { _id: { _eq: $location_id } }
        resource_type: {
          _or: [{ is_assistant: { _eq: true } }, { is_provider: { _eq: true } }]
        }
      }
    }
    order_by: { ca_date: asc }
  ) {
    id
    ca_date
    resource {
      id
      employee_resources {
        id
        employment_contract {
          id
          person {
            id
            greeting
            first_name
            last_name
          }
        }
      }
      resource_type {
        id
        is_assistant
        is_provider
      }
    }
  }
}

`