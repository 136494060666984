// this is an autogenerated file from App/Account/PatientOverlay/Content/Content/Apps/Orders/data.graphql
import { gql } from 'Data/Api'

export default gql`
query app__App_Account_PatientOverlay_Content_Content_Apps_Orders($patient_id: uuid!) {
  orders_orders(
    where: { patient_id: { _eq: $patient_id } }
    limit: 1
    order_by: [{ updated_at: desc }]
  ) {
    id
    title
    status
    treatment_id
  }
}

`