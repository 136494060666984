// this is an autogenerated file from DesignSystem/DataVaxiomPatientInsuranceForm/BillingCenter/data.graphql
import { gql } from 'Data/Api'

export default gql`
query app__DesignSystem_DataVaxiomPatientInsuranceForm_BillingCenter(
  $organization_id: numeric!
  $search_term: String!
  $selected_billing_center: numeric!
  $search_selected_billing_center: Boolean!
) {
  options: vaxiom_insurance_billing_centers(
    where: {
      organization_id: { _eq: $organization_id }
      name: { _ilike: $search_term }
    }
    distinct_on: name
    limit: 250
    order_by: { name: asc }
  ) {
    id
    name
    payer_id
    contact_postal_address {
      address_line1
      zip
      city
      state
    }
  }
  selected_billing_center: vaxiom_insurance_billing_centers_by_pk(
    id: $selected_billing_center
  ) @include(if: $search_selected_billing_center) {
    id
    name
    payer_id
    contact_postal_address {
      address_line1
      zip
      city
      state
    }
  }
}

`