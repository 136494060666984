// this is an autogenerated file from DesignSystem/DataVaxiomRelationshipsItem/Edit/NonPatient/Phone/NewPhone/Content/addPersonContactMethodsMutation.graphql
import { gql } from 'Data/Api'

export default gql`
mutation app__DesignSystem_DataVaxiomRelationshipsItem_Edit_NonPatient_Phone_NewPhone_Content($person_id: numeric!, $contact_methods: json!) {
  add_person_contact_methods(
    args: { _person_id: $person_id, _contact_methods: $contact_methods }
  ) {
    id
  }
}

`