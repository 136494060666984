import { useDataChange, useDataValue } from 'Simple/Data.js'

export function useOnChange(props) {
  let person = useDataValue({
    context: 'person',
    viewPath: props.viewPath,
  })
  let change = useDataChange({
    context: 'selected',
    viewPath: props.viewPath,
  })

  return function onChange(value) {
    if (value) {
      change(next => {
        next.contact_method_association_ids.push(
          person.contact_method_association_id
        )
      })
    } else {
      change(next => {
        next.contact_method_association_ids =
          next.contact_method_association_ids.filter(
            id => id !== person.contact_method_association_id
          )
      })
    }
  }
}
