// this is an autogenerated file from App/Account/Content/Patients/Content/Content/Sections/ActiveTab/Profile/Content/NewProfile/Content/ContactInfo/Phone/Phones/Content/Phone/Edit/Content/mutation-insert-contact-method-association.graphql
import { gql } from 'Data/Api'

export default gql`
mutation app__App_Account_Content_Patients_Content_Content_Sections_ActiveTab_Profile_Content_NewProfile_Content_ContactInfo_Phone_Phones_Content_Phone_Edit_Content($objects: json!) {
  insert_contact_method_associations(args: { objects: $objects }) {
    id
    contact_method_id
  }
}

`