import { useSetFlowTo, normalizePath } from 'Simple/Flow.js'
import { useDataValue, useDataChange } from 'Simple/Data.js'
import mutation from './mutation.graphql.js'
import {
  notifySuccess,
  notifyError,
  useNotifications,
} from 'Logic/Notifications.js'
import { useMutation } from 'Data/Api'
import { phoneNumber, textInput, email } from 'Data/validate.js'

function validate(value) {
  if (
    !textInput(value.name) ||
    (value.contact_email.address !== '' &&
      !email(value.contact_email.address)) ||
    (value.contact_phone.number !== '' &&
      !phoneNumber(value.contact_phone.number))
  ) {
    return true
  }
  return false
}

function useOnSubmit(props) {
  let [, executeMutation] = useMutation(mutation)
  let [, notify] = useNotifications()
  let setFlowTo = useSetFlowTo(props.viewPath)
  let change = useDataChange({
    context: 'tx_category_insured',
    viewPath: props.viewPath,
  })
  let organization_id = useDataValue({
    context: 'patient',
    path: 'person.organization_id',
    viewPath: props.viewPath,
  })
  return async function onSave(value) {
    if (validate(value)) {
      return true
    }
    try {
      let mutationResponse = await executeMutation({
        employer_details: {
          ...value,
          organization_id,
        },
      })

      if (mutationResponse.error) {
        notify(notifyError(`Cannot create employer. Please try again.`))
        return
      }
      change(next => {
        next.insured.insurance_subscription.employer_id =
          mutationResponse?.data?.insert_employer?.[0].id
      })
      notify(notifySuccess('Employer created successfully.'))
      setFlowTo(normalizePath(props.viewPath, '../No'))
    } catch (err) {
      notify(notifyError('Cannot create employer. Please try again.'))
    }
  }
}

/** @type {import('Simple/types.js').useDataOnSubmit} */
export default function useDataOnSubmit(props, data) {
  let setFlowTo = useSetFlowTo(props.viewPath)
  let onSave = useOnSubmit(props)
  return async function onSubmit({ value, originalValue, args, change }) {
    switch (args?.type) {
      case 'cancel': {
        setFlowTo(normalizePath(props.viewPath, '../No'))
        return
      }
      case 'submit': {
        return onSave(value)
      }
      default: {
        return
      }
    }
  }
}
