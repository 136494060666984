// this is an autogenerated file from App/Account/Content/Patients/Content/Content/Sections/ActiveTab/TreatmentCard/Content/TreatmentCard/Summary/Content/Content/Progress/Content/data.graphql
import { gql } from 'Data/Api'

export default gql`
query app__App_Account_Content_Patients_Content_Content_Sections_ActiveTab_TreatmentCard_Content_TreatmentCard_Summary_Content_Content_Progress_Content(
  $treatment_id: numeric!
  $treatment_status: String!
  $parent_company_id: numeric!
) {
  treatment_planned_length: vaxiom_appointments_aggregate(
    where: {
      tx_id: { _eq: $treatment_id }
      type: { not_tx: { _eq: false } }
      unplanned: { _eq: false }
      next_appointment: {
        unplanned: { _eq: false }
        type: { not_tx: { _eq: false } }
      }
    }
  ) {
    aggregate {
      sum {
        interval_to_next
      }
    }
  }
  status: vaxiom_tx_statuses(
    where: {
      type: { _eq: $treatment_status }
      parent_company_id: { _eq: $parent_company_id }
      hidden: { _eq: false }
      is_active: { _eq: true }
    }
  ) {
    id
    name
  }
}

`