import { useDataValue, useDataSubmit } from 'Simple/Data'

export function useOnClick(props) {
  let submit = useDataSubmit({
    viewPath: props.viewPath,
    context: 'new_email',
  })
  let persons = useDataValue({
    context: 'same_email_persons',
    viewPath: props.viewPath,
  })
  return function useOnClick(value) {
    submit({
      type: 'email_for_relative',
      contact_method_id: persons?.[0].contact_method_id,
    })
  }
}
