import { useSetFlowTo, normalizePath } from 'Simple/Flow.js'

import mutation from './mutation.graphql.js'
import {
  notifySuccess,
  notifyError,
  useNotifications,
} from 'Logic/Notifications.js'
import { useMutation } from 'Data/Api'
import { phoneNumber } from 'Data/validate.js'

function validate(value) {
  let isInvalid = false
  if (!value.carrier_name || !value.installment_interval) {
    isInvalid = true
  }
  value.insurance_company_phone_associations?.map(p => {
    if (
      p.contact_method.phone.number != '' &&
      !phoneNumber(p.contact_method.phone.number)
    ) {
      isInvalid = true
    }
  })
  return isInvalid
}

function useOnEdit(props) {
  let [, executeMutation] = useMutation(mutation)
  let [, notify] = useNotifications()
  let setFlowTo = useSetFlowTo(props.viewPath)
  return async function onEdit(value, originalValue) {
    // without any ids
    let to_insert = []
    // with ids
    let to_update = []

    // ids in current one
    let current_ids = []
    let original_ids = []
    // in original but not in value
    let to_delete = []
    value.insurance_company_phone_associations.map(p => {
      if (isNaN(p.id)) {
        to_insert.push(p)
      } else {
        current_ids.push(p.id)
        to_update.push(p)
      }
    })

    originalValue.insurance_company_phone_associations.map(p => {
      original_ids.push(p.id)
    })

    to_delete = original_ids.filter(x => !current_ids.includes(x))

    try {
      if (validate(value)) {
        return true
      }
      let mutationResponse = await executeMutation({
        input: {
          carrier_name: value.carrier_name,
          continuation_claims_required: value.continuation_claims_required,
          installment_interval: value.installment_interval,
          medicaid: value.medicaid,
          medicaid_state: value.medicaid_state,
          electronic: value.electronic,
        },
        id: value.id,
        postal_input: {
          id: value.postal_address?.id,
          address_line1: value.postal_address?.address_line1,
          city: value.postal_address?.city,
          zip: value.postal_address?.zip,
          state: value.postal_address?.state,
        },
        phones_to_be_deleted: to_delete,
        phones: to_insert.map(i => ({
          ...i,
          insurance_company_id: value.id,
        })),
        phones_to_be_updated: to_update,
      })

      if (mutationResponse.error) {
        notify(notifyError(`Cannot edit insurance carrier. Please try again.`))
        return
      }
      notify(notifySuccess('Insurance carrier edited successfully.'))
      setFlowTo(normalizePath(props.viewPath, '../../No'))
    } catch (err) {
      notify(notifyError('Cannot edit insurance carrier. Please try again.'))
    }
  }
}

/** @type {import('Simple/types.js').useDataOnSubmit} */
export default function useDataOnSubmit(props, data) {
  let setFlowTo = useSetFlowTo(props.viewPath)
  let onEdit = useOnEdit(props)

  return async function onSubmit({ value, originalValue, args, change }) {
    switch (args?.type) {
      case 'cancel': {
        setFlowTo(normalizePath(props.viewPath, '../../No'))
        return
      }
      case 'submit': {
        if (validate(value)) {
          return true
        }
        setFlowTo(
          normalizePath(props.viewPath, './Content/SaveConfirm/Content')
        )
        return
      }
      case 'edit': {
        return onEdit(value, originalValue)
      }
      default: {
        return
      }
    }
  }
}
