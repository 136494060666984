import { useDataValue } from 'Simple/Data.js'

/** @type {import('Simple/types.js').useDataConfiguration} */
export default function useDataConfiguration(props, data) {
  let to_person = useDataValue({
    context: 'profile_patient',
    path: 'person._id',
    viewPath: props.viewPath,
  })

  return {
    variables: {
      to_person,
    },
    pause: false,
  }
}
