import { useDataValue } from 'Simple/Data.js'
import useStableValue from 'Logic/useStableValue.js'

export default function useDataConfiguration(props) {
  let parent_company_id = useDataValue({
    context: 'global',
    path: 'current_location.parent_company._id',
    viewPath: props.viewPath,
  })
  let search_term = useDataValue({
    context: 'patient_select',
    path: 'search_term',
    viewPath: props.viewPath,
  })
  let search_term_stable = useStableValue(wrapSearchTerm(search_term))

  return {
    variables: {
      search_term: search_term_stable || '',
      parent_company_id,
    },
    pause: search_term_stable.length - wrapSearchTerm('').length < 3,
  }
}

function wrapSearchTerm(search_term) {
  return `%${search_term}%`
}
