import { useDataValue } from 'Simple/Data.js'
import { getType } from './helpers.js'

/** @type {import('Simple/types.js').useDataConfiguration} */
export default function useDataConfiguration(props, data) {
  let organization_id = useDataValue({
    context: 'patient',
    path: 'person.organization_id',
    viewPath: props.viewPath,
  })

  let filters = useDataValue({
    context: 'filter',
    viewPath: props.viewPath,
  })

  let type = getType(filters)
  return {
    variables: {
      organization_id,
      number: `%${filters.plan_number}%`,
      search_term: `%${filters.search_term}%`,
      employer_id: filters.filter_object_id,
      with_employer_id: type === 'EMPLOYER_FILTER',
      carrier_id: filters.filter_object_id,
      with_carrier_id: type === 'CARRIER_FILTER',
      with_filters_only: type === 'NUMBER_FILTER',
    },
    pause:
      filters.plan_number === '' &&
      filters.filter_object_id === '' &&
      filters.search_term === '',
  }
}
