import { useMutation } from 'Data/Api.js'
import { useDataValue } from 'Simple/Data.js'
import {
  notifyError,
  notifySuccess,
  useNotifications,
} from 'Logic/Notifications.js'
import { RELATIONSHIPS } from 'Data/constants.js'

import mutation from './mutation.graphql.js'

/** @type {import('Simple/types.js').useDataOnSubmit} */
export default function useDataOnSubmit(props, data) {
  let [, notify] = useNotifications()
  let [, executeMutation] = useMutation(mutation)
  let from_person_id = useDataValue({
    viewPath: props.viewPath,
    context: 'person',
    path: 'person.id',
  })
  let to_person_id = useDataValue({
    viewPath: props.viewPath,
    context: 'profile_patient',
    path: 'person.id',
  })
  let patient_gender = useDataValue({
    viewPath: props.viewPath,
    context: 'profile_patient',
    path: 'person.gender',
  })

  return async function onSubmit({ value, originalValue, args, change }) {
    switch (args.type) {
      case 'addRelationship':
        let mutationResponse = await executeMutation({
          type: value,
          opposite_type: RELATIONSHIPS.find(r => r.id === value)[
            patient_gender
          ],
          from_person_id,
          to_person_id,
        })
        if (mutationResponse.error) {
          notify(notifyError('Cannot add relationship. Please try again.'))
          return
        }
        notify(notifySuccess('Relationship added.'))
        return
      default:
        return
    }
  }
}
