import { dateISOToDateAndTime } from 'Data/format.js'
import { useGetSignedUrl } from 'Data/s3.js'
import TysiaTextLink from 'design-system/TysiaTextLink/index.js'
import React, { useMemo } from 'react'
import { useDataValue } from 'Simple/Data.js'

import View from './view.js'

export default function Logic(props) {
  let inputs = useDataValue({
    context: 'inputs',
    viewPath: props.viewPath,
  })

  let value = useMemo(() => {
    let columns = [
      {
        header: 'ID',
        accessorKey: 'id',
      },
      {
        header: 'Type',
        accessorKey: 'type',
      },
      {
        header: 'Data',
        accessorKey: 'data_url',
        cell: function DataUrlCell(cell) {
          let signedUrl = useGetSignedUrl({
            url: cell.getValue(),
            download: true,
            viewPath: props.viewPath,
          })
          return (
            <TysiaTextLink
              goTo={signedUrl}
              text="Download"
              viewPath={props.viewPath}
            />
          )
        },
      },
      {
        header: 'Created at',
        accessorKey: 'created_at',
      },
      {
        header: 'Created by',
        accessorKey: 'created_by',
      },
    ]

    if (!inputs) return { columns, data: [] }

    return {
      columns,
      data: inputs.map(item => ({
        ...item,
        created_at: dateISOToDateAndTime(item.created_at),
        created_by: item.created_by_name,
      })),
    }
  }, [inputs, props.viewPath])

  return <View value={value} viewPath={props.viewPath} />
}
