import { useDataSubmit, useDataValue } from 'Simple/Data.js'

export function useOnClick(props) {
  let request = useDataValue({
    context: 'request',
    viewPath: props.viewPath,
  })
  let submit = useDataSubmit({
    context: 'payment_plan',
    viewPath: props.viewPath,
  })

  return function onClick() {
    submit({
      type: 'cancel-request',
      treatment_plan_id: request.tx_plan_id,
      person_id: request.person_id,
    })
  }
}
