import { useDataSubmit } from 'Simple/Data'

/** @type {import('Simple/types.js').useDataOnChange} */
export default function useDataOnChange(props, data) {
  let submit = useDataSubmit({
    context: 'connection',
    viewPath: props.viewPath,
  })

  // TODO: refactor
  return async function onChange(value) {
    if (!value) return

    let { status } = value || {}

    submit({ type: status })
  }
}
