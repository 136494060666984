import { useSetFlowTo, normalizePath } from 'Simple/Flow.js'
import mutation from './mutation.graphql.js'
import { useMutation } from 'Data/Api.js'
import { values } from 'lodash'
import {
  notifySuccess,
  notifyError,
  useNotifications,
} from 'Logic/Notifications.js'
import { textInput, email } from 'Data/validate.js'

function useDataSave(props) {
  let setFlowTo = useSetFlowTo(props.viewPath)
  let [, executeMutation] = useMutation(mutation)
  let [, notify] = useNotifications()

  return async function onSubmit(value) {
    if (
      !textInput(value.name) ||
      (value.contact_email && !email(value.contact_email.address))
    ) {
      return true
    }
    let mutationResponse = await executeMutation({
      employer_id: value.id,
      name: value.name,
      postal_id: value.contact_postal_address?.id,
      address_line1: value.contact_postal_address?.address_line1,
      zip: value.contact_postal_address?.zip,
      city: value.contact_postal_address?.address_line1,
      state: value.contact_postal_address?.state,
      phone_id: value.contact_phone?.id,
      number: value.contact_phone?.number,
      email_id: value.contact_email?.id,
      email: value.contact_email?.address,
    })
    if (mutationResponse.error) {
      notify(
        notifyError(
          `There was a problem updating the employer. Please try again.`
        )
      )
      return
    }
    notify(notifySuccess(`Employer edited.`))
    setFlowTo(normalizePath(props.viewPath, '../No'))
  }
}

/** @type {import('Simple/types.js').useDataOnSubmit} */
export default function useDataOnSubmit(props, data) {
  let setFlowTo = useSetFlowTo(props.viewPath)
  let onSave = useDataSave(props)
  return async function onSubmit({ value, originalValue, args, change }) {
    switch (args?.type) {
      case 'cancel': {
        setFlowTo(normalizePath(props.viewPath, '../No'))
        return
      }
      case 'submit': {
        return onSave(value)
      }
      default: {
        return
      }
    }
  }
}
