import { useMemo } from 'react'

export default function useDataTransform(props, data) {
  return useMemo(() => {
    if (!data) return data
    let template_referrals = data?.[0]?.patient_template_referrals || []
    let person_referrals = data?.[0]?.patient_person_referrals || []
    let referrals = template_referrals.concat(person_referrals)

    return {
      ...data?.[0],
      txs: data?.[0]?.tx_cards.flatMap(tx_card => tx_card.txs),
      referrals: referrals,
      preferred_contact_method: data?.[0]?.communication_preferences?.filter(
        pref => !!pref.contact_method_association?.preference
      )?.[0]?.contact_method_association?.id,
      appointment_reminders: Object.assign(
        {},
        ...(data?.[0]?.communication_preferences.map(c => ({
          [c.contact_method_association_id]: true,
        })) || [])
      ),
    }
  }, [data])
}
