// this is an autogenerated file from DesignSystem/DataVaxiomRelationshipsItem/Edit/NonPatient/Email/Emails/Content/Email/Edit/Content/query.graphql
import { gql } from 'Data/Api'

export default gql`
query app__DesignSystem_DataVaxiomRelationshipsItem_Edit_NonPatient_Email_Emails_Content_Email_Edit_Content(
  $contact_method_id: numeric!
  $person_id: numeric
) {
  vaxiom_contact_method_associations_aggregate(
    where: {
      contact_method_id: { _eq: $contact_method_id }
      person_id: { _neq: $person_id }
    }
  ) {
    aggregate {
      count
    }
  }
}

`