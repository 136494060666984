// this is an autogenerated file from DesignSystem/DataVaxiomPatientInsuranceForm/Carrier/Content/Options/Edit/Content/EditInsurance/mutation.graphql
import { gql } from 'Data/Api'

export default gql`
mutation app__DesignSystem_DataVaxiomPatientInsuranceForm_Carrier_Content_Options_Edit_Content_EditInsurance(
  $id: numeric!
  $input: vaxiom_insurance_companies_set_input!
  $postal_input: json!
  $phones: json!
  $phones_to_be_deleted: [numeric!]
  $phones_to_be_updated: json!
) {
  update_vaxiom_insurance_companies_by_pk(
    pk_columns: { id: $id }
    _set: $input
  ) {
    id
  }

  update_carrier_postal_address(
    args: { insurance_company_id: $id, postal_address: $postal_input }
  ) {
    id
  }

  insert_insurance_carrier_phone_associations(
    args: { phone_associations: $phones }
  ) {
    id
  }

  update_insurance_carrier_phone_associations(
    args: { insurance_company_phone_associations: $phones_to_be_updated }
  ) {
    id
  }

  delete_vaxiom_insurance_company_phone_associations(
    where: { id: { _in: $phones_to_be_deleted } }
  ) {
    affected_rows
  }
}

`