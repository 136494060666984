// this is an autogenerated file from App/Account/Content/Calendar/New/Content/Content/Calendar/data.graphql
import { gql } from 'Data/Api'

export default gql`
query app__App_Account_Content_Calendar_New_Content_Content_Calendar($location_id: uuid!, $date: date!) {
  chairs: vaxiom_chairs(
    where: { resource: { organization: { _id: { _eq: $location_id } } } }
  ) {
    id
    _id
    pos
    full_name
    chair_configurations(where: { configuration_date: { _eq: $date } }) {
      id
      office_and_break_hours
      appointment_slots {
        slots
        appointment_template_id
        day_schedule_id
        appointment_template {
          id
          full_name_computed
          color_id_computed
        }
      }
    }
    chair_allocations(
      where: {
        ca_date: { _eq: $date }
        resource: {
          resource_type: {
            _or: [
              { is_assistant: { _eq: true } }
              { is_provider: { _eq: true } }
            ]
          }
        }
      }
    ) {
      id
      primary_resource
      resource {
        id
        employee_resources {
          id
          employment_contract {
            id
            person {
              id
              title
              first_name
              last_name
            }
          }
        }
        resource_type {
          id
          is_assistant
          is_provider
        }
      }
    }
  }
  open_office_dates: calendar_get_next_and_previous_open_office_dates(
    args: { _current_date: $date, _location_id: $location_id }
  ) {
    date
    location_id
    next_day
    next_month
    previous_day
    previous_month
  }
}

`