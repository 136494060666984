import { useMemo } from 'react'

export default function useDataTransform(props, data) {
  return useMemo(() => {
    if (!data) return data

    let person_referral_types = [
      { id: 'Patient', type: 'Search', free_type: 'Patient' },
      {
        id: 'Professional',
        type: 'Search',
        free_type: 'Professional',
      },
    ]
    return data.concat(person_referral_types).map(d => {
      return {
        ...d,
        referral_list_values: d.referral_list_values?.length
          ? d.referral_list_values?.map(r => ({
              ...r,
              id: `${r.referral_template_id}-${r.pos}`,
            }))
          : [],
      }
    })
  }, [data])
}
