import { useDataChange } from 'Simple/Data.js'

export function useOnClick(props) {
  let setDisabled = useDataChange({
    context: 'editor',
    path: 'disabled',
    viewPath: props.viewPath,
  })

  return function onClick() {
    setDisabled(false)
  }
}

export function isDisabled(contract) {
  return (
    contract.signed_date ||
    (contract.signature_request &&
      contract.signature_request?.status !== 'cancelled')
  )
}
