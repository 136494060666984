import { normalizePath, useSetFlowTo } from 'Simple/Flow.js'
import { useDataValue } from 'Simple/Data.js'
export function useOnClick(props) {
  let setFlowTo = useSetFlowTo(props.viewPath)

  let patient_id = useDataValue({
    viewPath: props.viewPath,
    context: 'relationship',
    path: 'from_person_record.patient._id',
  })
  return function onClick(value) {
    if (patient_id) {
      setFlowTo(normalizePath(props.viewPath, 'Edit/Patient'))
    } else {
      setFlowTo(normalizePath(props.viewPath, 'Edit/NonPatient'))
    }
  }
}
