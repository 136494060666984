import { useDataSubmit, useDataValue } from 'Simple/Data.js'
import { age, dateShortIn } from 'Data/format.js'

export function format(person, hipaa) {
  /** @type {(string | number)[]} */
  let result = [
    [person.first_name, !hipaa && person.last_name].filter(Boolean).join(' '),
  ]

  if (person.gender) result.push(person.gender[0])

  if (person.birth_date) {
    result.push(age(person.birth_date), dateShortIn(person.birth_date))
  }

  return result.filter(Boolean).join(', ')
}

export function useOnClick(props) {
  let submit = useDataSubmit({
    viewPath: props.viewPath,
    context: 'global',
  })

  let patient_id = useDataValue({
    context: 'info_dialog',
    viewPath: props.viewPath,
    path: 'patient._id',
  })

  return function onClick() {
    submit({
      type: 'patientOverlay/open',
      patient_id,
    })
  }
}
