// this is an autogenerated file from App/Account/Content/Patients/Content/Content/Sections/ActiveTab/TreatmentCard/Content/TreatmentPlans/New/Steps/Content/TreatmentPlans/Content/TreatmentPlan/mutation-set-appointment-type-id.graphql
import { gql } from 'Data/Api'

export default gql`
mutation app__App_Account_Content_Patients_Content_Content_Sections_ActiveTab_TreatmentCard_Content_TreatmentPlans_New_Steps_Content_TreatmentPlans_Content_TreatmentPlan($id: numeric!, $type_id: numeric!) {
  update_vaxiom_tx_plan_appointments_by_pk(
    pk_columns: { id: $id }
    _set: { type_id: $type_id }
  ) {
    id
    type_id
    type {
      id
      display_name
      display_color_id
    }
  }
}

`