// this is an autogenerated file from DesignSystem/DataVaxiomPatientInsuranceAssignForm/Content/PolicyHolder/Options/AddRelationship/Content/updatePersonRelationshipsMutation.graphql
import { gql } from 'Data/Api'

export default gql`
mutation app__DesignSystem_DataVaxiomPatientInsuranceAssignForm_Content_PolicyHolder_Options_AddRelationship_Content($relationships: json) {
  update_person_relationships(
    args: { _relationships: $relationships, add_contact_method_to_self: false }
  ) {
    id
  }
}

`