// this is an autogenerated file from App/Account/Content/Calendar/New/Content/Content/AppointmentOverlay/Content/Form/Content/UntemplatedSlot/data.graphql
import { gql } from 'Data/Api'

export default gql`
query app__App_Account_Content_Calendar_New_Content_Content_AppointmentOverlay_Content_Form_Content_UntemplatedSlot($chair_id: uuid!) {
  vaxiom_chairs(where: { _id: { _eq: $chair_id } }) {
    id
    _id
    full_name
  }
}

`