import { useDataValue } from 'Simple/Data.js'
/** @type {import('Simple/types.js').useDataConfiguration} */
export default function useDataConfiguration(props, data) {
  let tx_category_insured_id = useDataValue({
    context: 'assigned_insurance',
    viewPath: props.viewPath,
    path: 'tx_category_insured._id',
  })
  return {
    variables: { tx_category_insured_id },
  }
}
