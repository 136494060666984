// this is an autogenerated file from App/Account/Content/Patients/Content/Content/Sections/ActiveTab/TreatmentCard/Content/TreatmentCard/Treatment/Content/Actions/Content/Rename/Content/mutation.graphql
import { gql } from 'Data/Api'

export default gql`
mutation app__App_Account_Content_Patients_Content_Content_Sections_ActiveTab_TreatmentCard_Content_TreatmentCard_Treatment_Content_Actions_Content_Rename_Content(
  $tx_card_id: uuid!
  $name: String!
  $tx_updates: [vaxiom_txs_updates!]!
) {
  update_vaxiom_tx_cards(
    where: { _id: { _eq: $tx_card_id } }
    _set: { name: $name }
  ) {
    affected_rows
  }
  update_vaxiom_txs_many(updates: $tx_updates) {
    affected_rows
  }
}

`