import { useMemo } from 'react'
import { useDataValue } from 'Simple/Data.js'

export default function useDataTransform(props, data) {
  let city = useDataValue({
    viewPath: props.viewPath,
    context: 'search',
    path: 'view_details.other.external_office.postal_address.city',
  })
  return useMemo(() => {
    if (!data) return data
    return { term: city }
  }, [data])
}
