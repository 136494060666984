// this is an autogenerated file from DesignSystem/DataVaxiomEmployeeProfessionalsRelationshipsItem/Edit/Content/Phone/NewPhone/Content/SearchForPhone/Content/data.graphql
import { gql } from 'Data/Api'

export default gql`
query app__DesignSystem_DataVaxiomEmployeeProfessionalsRelationshipsItem_Edit_Content_Phone_NewPhone_Content_SearchForPhone_Content($number: String!) {
  vaxiom_contact_phones(where: { number: { _eq: $number } }) {
    id
    contact_method {
      id
      association {
        id
        person {
          id
          first_name
          last_name
        }
      }
    }
  }
}

`