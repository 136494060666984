// this is an autogenerated file from App/Account/Content/Patients/Content/Content/Sections/ActiveTab/Profile/Content/NewProfile/Content/Relations/AddRelation/Search/Content/Profiles/data.graphql
import { gql } from 'Data/Api'

export default gql`
query app__App_Account_Content_Patients_Content_Content_Sections_ActiveTab_Profile_Content_NewProfile_Content_Relations_AddRelation_Search_Content_Profiles(
  $search_term: String!
  $parent_company_id: uuid!
  $whole_parent_company: Boolean!
) {
  vaxiom_persons_search(
    search_term: $search_term
    parent_company_id: $parent_company_id
    whole_parent_company: $whole_parent_company
  ) {
    person: vaxiom_person {
      id
      title
      first_name
      middle_name
      last_name
      greeting
      gender
      birth_date
      ssn
      profile_pic_url
      public_user {
        id
      }
      phones: contact_method_associations(
        where: { contact_method: { dtype: { _eq: "phone" } } }
      ) {
        id
        description
        preference
        contact_method {
          id
          phone {
            id
            number
          }
        }
      }
      addresses: contact_method_associations(
        where: { contact_method: { dtype: { _eq: "postal" } } }
      ) {
        id
        description
        contact_method {
          id
          postal_address {
            id
            address_line1
            city
            state
            zip
          }
        }
      }
      emails: contact_method_associations(
        where: { contact_method: { dtype: { _eq: "email" } } }
      ) {
        id
        description
        preference
        contact_method {
          id
          email {
            id
            address
          }
        }
      }
      patient {
        id
        _id
        human_readable_id
        legacy_id
      }
      from_person_relationships {
        id
      }
      employment_contracts(
        where: {
          employment_end_date: { _is_null: true }
          deleted: { _eq: false }
        }
      ) {
        id
      }
      other_professional_relationships {
        id
      }
    }
  }
}

`