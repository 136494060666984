// this is an autogenerated file from App/Account/Content/Calendar/New/EventUpdates/query.graphql
import { gql } from 'Data/Api'

export default gql`
subscription app__App_Account_Content_Calendar_New_EventUpdates($location_id: uuid!) {
  calendar_event_updates(where: { location_id: { _eq: $location_id } }) {
    id
    key
  }
}

`