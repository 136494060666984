import { useDataChange, useDataValue } from 'Simple/Data.js'
import { useSetFlowTo, normalizePath } from 'Simple/Flow.js'
export function useOnClick(props) {
  let selected_person = useDataValue({
    viewPath: props.viewPath,
    context: 'person',
  })

  let change_new_referral = useDataChange({
    context: 'new_referral',
    viewPath: props.viewPath,
  })
  let setFlowTo = useSetFlowTo(props.viewPath)
  return function onClick(value) {
    change_new_referral(next => {
      next.person_id = selected_person.id
      next.person_type = 'Professional'
      next.person_value = selected_person.search_name
    })
    setFlowTo(normalizePath(props.viewPath, '../../../../No'))
  }
}
