// this is an autogenerated file from App/Account/Content/Patients/Content/Content/Sections/ActiveTab/Profile/Content/NewProfile/Content/ContactInfo/Phone/NewPhone/Content/SearchForPhone/Content/data.graphql
import { gql } from 'Data/Api'

export default gql`
query app__App_Account_Content_Patients_Content_Content_Sections_ActiveTab_Profile_Content_NewProfile_Content_ContactInfo_Phone_NewPhone_Content_SearchForPhone_Content($number: String!) {
  vaxiom_contact_method_associations(
    where: { phone: { number: { _eq: $number } } }
  ) {
    id
    contact_method_id
    person {
      id
      first_name
      last_name
    }
  }
}

`