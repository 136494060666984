import { useMemo } from 'react'

export default function useDataTransform(props, data) {
  return useMemo(() => {
    if (!data) return data

    return data.map(({ preference, ...rest }) => ({
      ...rest,
      preference: !!preference,
    }))
  }, [data])
}
