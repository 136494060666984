import { useSetFlowTo, normalizePath } from 'Simple/Flow.js'
import { useDataValue, useDataChange } from 'Simple/Data.js'
import mutation from './mutation.graphql.js'
import {
  notifySuccess,
  notifyError,
  useNotifications,
} from 'Logic/Notifications.js'
import { useMutation } from 'Data/Api'
import { phoneNumber, email } from 'Data/validate.js'

function validate(value) {
  if (!value.name || !value.payer_id) {
    return true
  }
  if (
    value.contact_email.address !== '' &&
    !email(value.contact_email?.address)
  ) {
    return true
  }

  if (
    value.contact_phone.number !== '' &&
    !phoneNumber(value.contact_phone?.number)
  ) {
    return true
  }
  return false
}

function useOnSubmit(props) {
  let [, executeMutation] = useMutation(mutation)
  let [, notify] = useNotifications()
  let setFlowTo = useSetFlowTo(props.viewPath)
  let organization_id = useDataValue({
    context: 'patient',
    path: 'person.organization_id',
    viewPath: props.viewPath,
  })
  let change = useDataChange({
    viewPath: props.viewPath,
    context: 'plan_details',
  })
  return async function onSave(value) {
    if (validate(value)) {
      return true
    }
    try {
      let mutationResponse = await executeMutation({
        object: {
          address: { ...value.contact_postal_address },
          center_name: value.name,
          email: value.contact_email?.address,
          organization_id,
          payer_id: value.payer_id,
          phone: value.contact_phone?.number,
        },
      })

      if (mutationResponse.error) {
        notify(notifyError(`Cannot create billing center. Please try again.`))
        return
      }

      change(next => {
        next.billing_center =
          mutationResponse.data.insert_billing_center?.[0]?.id
      })

      notify(notifySuccess('Billing center created successfully.'))
      setFlowTo(normalizePath(props.viewPath, '../No'))
    } catch (err) {
      console.error(err)
      notify(notifyError('Cannot create billing center. Please try again.'))
    }
  }
}

/** @type {import('Simple/types.js').useDataOnSubmit} */
export default function useDataOnSubmit(props, data) {
  let setFlowTo = useSetFlowTo(props.viewPath)
  let onSave = useOnSubmit(props)
  return async function onSubmit({ value, originalValue, args, change }) {
    switch (args?.type) {
      case 'cancel': {
        setFlowTo(normalizePath(props.viewPath, '../No'))
        return
      }
      case 'submit': {
        return onSave(value)
      }
      default: {
        return
      }
    }
  }
}
