// this is an autogenerated file from DesignSystem/DataVaxiomPatientInsuranceForm/Carrier/Content/Options/Edit/Content/EditInsurance/data.graphql
import { gql } from 'Data/Api'

export default gql`
query app__DesignSystem_DataVaxiomPatientInsuranceForm_Carrier_Content_Options_Edit_Content_EditInsurance($carrier_id: numeric!) {
  vaxiom_insurance_companies_by_pk(id: $carrier_id) {
    id
    carrier_name
    installment_interval
    continuation_claims_required
    medicaid
    medicaid_state
    electronic
    postal_address {
      id
      address_line1
      city
      zip
      state
      contact_method {
        id
        dtype
      }
    }
    insurance_company_phone_associations {
      id
      description
      contact_method {
        id
        dtype
        phone {
          id
          number
          type
        }
      }
    }
  }
}

`