import { useDataValue } from 'Simple/Data.js'
/** @type {import('Simple/types.js').useDataConfiguration} */
export default function useDataConfiguration(props, data) {
  let account_id = useDataValue({
    context: 'assigned_insurance',
    viewPath: props.viewPath,
    path: 'receivables.payment_account_id',
  })
  let patient_id = useDataValue({
    viewPath: props.viewPath,
    context: 'profile_patient',
    path: 'id',
  })
  return {
    variables: { account_id, patient_id },
    // pause: false,
  }
}
