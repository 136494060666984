import { useDataChange, useDataSubmit, useDataValue } from 'Simple/Data.js'
export function useOnClick(props) {
  let phone = useDataValue({
    context: 'phone',
    viewPath: props.viewPath,
    path: 'id',
  })
  let change = useDataChange({
    context: 'profile_patient',
    viewPath: props.viewPath,
    path: 'preferred_contact_method',
  })

  return function onClick(value) {
    change(phone)
  }
}
