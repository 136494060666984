import React from 'react'
import { DataProvider } from 'Simple/Data.js'

import View from './view.js'
import useOnSubmit from './useDataOnEditorSubmit.js'

let value = {
  section_id: null,
  dirty: false,
  saving: false,
  disabled: true,
}

export default function Logic(props) {
  let onSubmit = useOnSubmit(props)
  return (
    <DataProvider
      context="editor"
      value={value}
      onSubmit={onSubmit}
      viewPath={props.viewPath}
    >
      <View {...props} />
    </DataProvider>
  )
}
