import { useDataChange, useDataValue } from 'Simple/Data.js'
import React, { useMemo } from 'react'
import View from './view.js'
import {
  useNotifications,
  notifyError,
  notifySuccess,
} from 'Logic/Notifications'
import TysiaCheckbox from 'DesignSystem/TysiaCheckbox/index.js'
import TysiaCheckboxInput from 'DesignSystem/TysiaCheckbox/TysiaCheckboxInput/index.js'
import { useMutation } from 'Data/Api.js'
import mutation from './mutation.graphql.js'

export default function Logic(props) {
  let feature_flags = useDataValue({
    context: 'feature_flags',
    viewPath: props.viewPath,
  })
  let change = useDataChange({
    context: 'feature_flags',
    viewPath: props.viewPath,
  })
  let [, executeMutation] = useMutation(mutation)
  let [, notify] = useNotifications()

  let value = useMemo(() => {
    return {
      columns: [
        {
          header: 'Name',
          accessorKey: 'name',
        },
        {
          header: 'Level',
          accessorKey: 'level',
        },
        {
          header: 'New reporting',
          columns: [
            {
              header: 'Default',
              accessorKey: 'new_reporting_default_enabled',
              cell: cell => (
                <TysiaCheckbox
                  marginLeft={4}
                  marginRight={4}
                  disabled={isDisabled(cell)}
                  onChange={value => onChange(cell, value)}
                  value={cell.getValue()}
                  viewPath={props.viewPath}
                >
                  {childProps => <TysiaCheckboxInput {...childProps} />}
                </TysiaCheckbox>
              ),
            },
          ],
        },
        {
          header: 'Marketing Analytics',
          columns: [
            {
              header: 'Enabled',
              accessorKey: 'new_marketing-analytics_enabled',
              cell: cell => (
                <TysiaCheckbox
                  marginLeft={4}
                  marginRight={4}
                  disabled={isDisabled(cell)}
                  onChange={value => onChange(cell, value)}
                  value={cell.getValue()}
                  viewPath={props.viewPath}
                >
                  {childProps => <TysiaCheckboxInput {...childProps} />}
                </TysiaCheckbox>
              ),
            },
          ],
        },
        {
          header: 'Beta calendar', // it's the haeders that are not grouping
          columns: [
            {
              header: 'Enabled',
              accessorKey: 'new_calendar_enabled',
              cell: cell => (
                <TysiaCheckbox
                  marginLeft={4}
                  marginRight={4}
                  disabled={isDisabled(cell)}
                  onChange={value => onChange(cell, value)}
                  value={cell.getValue()}
                  viewPath={props.viewPath}
                >
                  {childProps => <TysiaCheckboxInput {...childProps} />}
                </TysiaCheckbox>
              ),
            },
            {
              header: 'Default',
              accessorKey: 'new_calendar_default_enabled',
              cell: cell => (
                <TysiaCheckbox
                  marginLeft={4}
                  marginRight={4}
                  disabled={isDisabled(cell)}
                  onChange={value => onChange(cell, value)}
                  value={cell.getValue()}
                  viewPath={props.viewPath}
                >
                  {childProps => <TysiaCheckboxInput {...childProps} />}
                </TysiaCheckbox>
              ),
            },
          ],
        },
        {
          header: 'Beta Treatment Plan',
          columns: [
            {
              header: 'Enabled',
              accessorKey: 'new_tx_planning_enabled',
              cell: cell => (
                <TysiaCheckbox
                  marginLeft={4}
                  marginRight={4}
                  disabled={isDisabled(cell)}
                  onChange={value => onChange(cell, value)}
                  value={cell.getValue()}
                  viewPath={props.viewPath}
                >
                  {childProps => <TysiaCheckboxInput {...childProps} />}
                </TysiaCheckbox>
              ),
            },
            {
              header: 'Default',
              accessorKey: 'new_tx_planning_default_enabled',
              cell: cell => (
                <TysiaCheckbox
                  marginLeft={4}
                  marginRight={4}
                  disabled={isDisabled(cell)}
                  onChange={value => onChange(cell, value)}
                  value={cell.getValue()}
                  viewPath={props.viewPath}
                >
                  {childProps => <TysiaCheckboxInput {...childProps} />}
                </TysiaCheckbox>
              ),
            },
          ],
        },
      ],
      data: feature_flags || [],
    }

    async function onChange(cell, value) {
      change(next => {
        let item = next.find(item => item.id === cell.row.original.id)
        item[cell.column.id] = value
      })

      let res = await executeMutation({
        organization_id: cell.row.original.id,
        message_key: cell.column.id.replaceAll('_', '.'),
        enabled: value,
      })

      let featureFlagName = cell.column.parent
        ? `${
            cell.column.parent.columnDef.header
          } ${cell.column.columnDef.header.toLowerCase()}`
        : cell.column.columnDef.header

      if (res.error) {
        return notify(
          notifyError(
            `Can't toggle "${featureFlagName}" ${value ? 'on' : 'off'} at ${
              cell.row.original.name
            }. Contact support if the problem persists.`
          )
        )
      }

      notify(
        notifySuccess(
          `Toggled "${featureFlagName}" ${value ? 'on' : 'off'} at ${
            cell.row.original.name
          }`
        )
      )
    }

    function isDisabled(cell) {
      if (cell.row.original.level === 'parent') return false

      return cell.table
        .getRowModel()
        .rows.find(item => item.original.level === 'parent')?.original[
        cell.column.id
      ]
    }
  }, [feature_flags]) // eslint-disable-line
  // ignore executeMutation, change, notify and props.viewPath

  return <View value={value} viewPath={props.viewPath} />
}
