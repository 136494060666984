// this is an autogenerated file from App/Account/Content/Patients/Content/Content/Sections/ActiveTab/Profile/Content/NewProfile/Content/SchedulingPreferences/Content/Edit/Content/Providers/data.graphql
import { gql } from 'Data/Api'

export default gql`
query app__App_Account_Content_Patients_Content_Content_Sections_ActiveTab_Profile_Content_NewProfile_Content_SchedulingPreferences_Content_Edit_Content_Providers($current_location_id: uuid!) {
  vaxiom_employee_resources(
    where: {
      invalid: { _eq: false }
      resource: {
        organization: { _id: { _eq: $current_location_id } }
        resource_type: { is_provider: { _eq: true } }
        deleted: { _eq: false }
      }
      employment_contract: { employment_end_date: { _is_null: true } }
    }
    order_by: {
      employment_contract: { person: { first_name: asc, last_name: asc } }
    }
  ) {
    id
    employment_contract {
      id
      person {
        id
        first_name
        last_name
      }
    }
  }
}

`