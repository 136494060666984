import { useSetFlowTo, normalizePath } from 'Simple/Flow.js'
import { useDataValue, useDataChange } from 'Simple/Data.js'
import mutation from './mutation.graphql.js'
import {
  notifySuccess,
  notifyError,
  useNotifications,
} from 'Logic/Notifications.js'
import { useMutation } from 'Data/Api'
import { phoneNumber } from 'Data/validate.js'

function validate(value) {
  let isInvalid = false
  if (!value.carrier_name || !value.installment_interval) {
    isInvalid = true
  }
  value.insurance_company_phone_associations?.map(p => {
    if (
      p.contact_method.phone.number != '' &&
      !phoneNumber(p.contact_method.phone.number)
    ) {
      isInvalid = true
    }
  })
  return {
    isInvalid,
  }
}

function useOnSave(props) {
  let [, executeMutation] = useMutation(mutation)
  let [, notify] = useNotifications()
  let setFlowTo = useSetFlowTo(props.viewPath)
  let organization_id = useDataValue({
    context: 'patient',
    path: 'person.organization_id',
    viewPath: props.viewPath,
  })
  let change = useDataChange({
    viewPath: props.viewPath,
    context: 'plan_details',
  })

  return async function onSave(value) {
    let validation = validate(value)
    if (validation.isInvalid) {
      return true
    }
    try {
      let mutationResponse = await executeMutation({
        carrier_details: {
          carrier_name: value.carrier_name,
          installment_interval: value.installment_interval,
          continuation_claims_required: value.continuation_claims_required,
          medicaid: value.medicaid,
          medicaid_state: value.medicaid_state,
          electronic: value.electronic,
          organization_id,
        },
        insurance_company_phone_associations:
          value.insurance_company_phone_associations.map(d => ({
            description: d.description,
            contact_method: {
              ...d.contact_method,
              phone: d.contact_method.phone,
            },
          })),
        postal_address: value.postal_address,
      })

      if (mutationResponse.error) {
        notify(
          notifyError(`Cannot create insurance carrier. Please try again.`)
        )
        return
      }

      change(next => {
        next.carrier = mutationResponse.data.insert_insurance_carrier?.[0]?.id
      })
      notify(notifySuccess('Insurance carrier created successfully.'))
      setFlowTo(normalizePath(props.viewPath, '../No'))
    } catch (err) {
      notify(notifyError('Cannot create insurance carrier. Please try again.'))
    }
  }
}

/** @type {import('Simple/types.js').useDataOnSubmit} */
export default function useDataOnSubmit(props, data) {
  let setFlowTo = useSetFlowTo(props.viewPath)
  let onSave = useOnSave(props)
  return async function onSubmit({ value, originalValue, args, change }) {
    switch (args?.type) {
      case 'cancel': {
        setFlowTo(normalizePath(props.viewPath, '../No'))
        return
      }
      case 'submit': {
        return onSave(value)
      }
      default: {
        return
      }
    }
  }
}
