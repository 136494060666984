import { useSetFlowTo, normalizePath } from 'Simple/Flow.js'
import { useMutation } from 'Data/Api'
import { useDataValue } from 'Simple/Data'
import { email } from 'Data/validate.js'
import {
  notifyError,
  useNotifications,
  notifySuccess,
} from 'Logic/Notifications.js'
import { useClient } from 'Data/Api.js'

import query from './query.graphql.js'
import mutationInsertContactMethodAssociation from './mutation-insert-contact-method-association.graphql.js'
import mutationUpdateOtherContactMethodAssociations from './mutation-update-other-contact-method-associations.graphql.js'

export default function useDataOnSubmit(props, data) {
  let onActionUpdateEmailForPatient = useDataOnActionUpdateEmail(props)
  let onActionFormSubmit = useDataOnActionFormSubmit(props)

  return async function onSubmit({ value, originalValue, args }) {
    switch (args?.type) {
      case 'update_email': {
        return onActionUpdateEmailForPatient({ value, originalValue, args })
      }
      default: {
        onActionFormSubmit({ value, originalValue, args })
      }
    }
  }
}

function useDataOnActionUpdateEmail(props) {
  let ids = useDataValue({
    context: 'selected',
    path: 'contact_method_association_ids',
    viewPath: props.viewPath,
  })
  let setFlowTo = useSetFlowTo(props.viewPath)
  let [, notify] = useNotifications()
  let [, executeMutation] = useMutation(mutationInsertContactMethodAssociation)
  let [, executeMutationUpdateOtherContactMethodAssociations] = useMutation(
    mutationUpdateOtherContactMethodAssociations
  )

  return async function onAction({ value, originalValue, args }) {
    if (!email(value.email.address)) return true

    let mutationResponse = await executeMutation({
      objects: [getVariables(value)],
    })
    if (mutationResponse.error) {
      notify(notifyError('Something went wrong. Please, try again.'))
      return
    }

    let contact_method_id =
      mutationResponse.data.insert_contact_method_associations[0]
        .contact_method_id

    let mutationUpdateOtherContactMethodAssociationsResponse =
      await executeMutationUpdateOtherContactMethodAssociations({
        ids,
        contact_method_id,
      })

    if (mutationUpdateOtherContactMethodAssociationsResponse.error) {
      notify(notifyError('Something went wrong. Please, try again.'))
      return
    }

    notify(notifySuccess('Email updated!'))
    setFlowTo(normalizePath(props.viewPath, '../No'))
  }
}

function useDataOnActionFormSubmit(props) {
  let setFlowTo = useSetFlowTo(props.viewPath)
  let [, notify] = useNotifications()
  let client = useClient()
  let [, executeMutation] = useMutation(mutationInsertContactMethodAssociation)

  let person_id = useDataValue({
    viewPath: props.viewPath,
    context: 'profile_patient',
    path: 'person.id',
  })

  return async function onAction({ value, originalValue, args }) {
    if (!email(value.email.address)) return true

    // if the email was edited, check if other people are associated with the old email address
    if (value.email.address !== originalValue.email.address) {
      let { data } = await client
        .query(query, {
          contact_method_id: value.contact_method_id,
          person_id,
        })
        .toPromise()

      if (
        data?.vaxiom_contact_method_associations_aggregate?.aggregate.count > 0
      ) {
        setFlowTo(normalizePath(props.viewPath, 'EditOrAdd/Content'))
        return
      }
    }

    let mutationResponse = await executeMutation({
      objects: [getVariables(value)],
    })
    if (mutationResponse.error) {
      notify(notifyError('Something went wrong. Please, try again.'))
      return
    }
    notify(notifySuccess('Email updated!'))
    setFlowTo(normalizePath(props.viewPath, '../No'))
  }
}

function getVariables(value) {
  return {
    id: value.id,
    person_id: value.person_id,
    description: value.description,
    // TODO: consider keeping the original value to make it easier to update
    // the transformation will be performed where the value is used
    preference: value.preference ? 'ANY' : null,
    contact_method: {
      id: value.email.id,
      dtype: 'email',
      address: value.email.address,
    },
    communication_preference: value.communication_preferences
      ? {
          id: value.communication_preferences.id,
          patient_id: value.communication_preferences.patient_id,
          type: value.communication_preferences.type
            ? 'APPOINTMENT_MESSAGES'
            : null,
        }
      : null,
  }
}
