// this is an autogenerated file from App/Account/Content/Patients/Content/Content/Sections/ActiveTab/Profile/Content/NewProfile/Content/Professionals/AddAction/New/Content/Addresses/AddressItem/City/Cities/data.graphql
import { gql } from 'Data/Api'

export default gql`
query app__App_Account_Content_Patients_Content_Content_Sections_ActiveTab_Profile_Content_NewProfile_Content_Professionals_AddAction_New_Content_Addresses_AddressItem_City_Cities($search_term: String!) {
  vaxiom_contact_postal_addresses(
    where: { city: { _ilike: $search_term } }
    limit: 20
    distinct_on: city
    order_by: { city: asc }
  ) {
    id
    value: city
  }
}

`