import { useMemo } from 'react'
import { useDataValue } from 'Simple/Data.js'

export default function useDataTransform(props, data) {
  let past = useDataValue({
    context: 'professional',
    viewPath: props.viewPath,
    path: 'past',
  })
  return useMemo(() => {
    if (!data) return data

    return [
      ...data,
      past
        ? { id: 'REMOVE_FROM_PAST', text: 'Remove from Past' }
        : { id: 'MARK_AS_PAST', text: 'Mark as Past Relationship' },
    ]
  }, [data, past])
}
