import { useDataValue } from 'Simple/Data.js'

/** @type {import('Simple/types.js').useDataConfiguration} */
export default function useDataConfiguration(props) {
  let person_id = useDataValue({
    viewPath: props.viewPath,
    context: 'edit_relation',
    path: 'from_person',
  })

  return {
    variables: {
      person_id,
    },
    requestPolicy: 'cache-and-network',
  }
}
