import { useDataSubmit, useDataValue } from 'Simple/Data'

let BUTTON_LABEL_HANDLER = {
  create_treatment_plan: 'Create treatment plan',
  resume_treatment_plan: 'Resume treatment plan',
  create_payment_plan: 'Create payment plan',
  resume_payment_plan: 'Resume payment plan',
  view_case_closer_request: 'View request',
  start_contract: 'Start contract',
}

export function getButtonLabel(treatment_plan_action) {
  return BUTTON_LABEL_HANDLER[treatment_plan_action] ?? ''
}

export function useOnSubmit(props) {
  let treatment_plan_action = useDataValue({
    context: 'treatment_card',
    path: 'txs.treatment_plan_action',
    viewPath: props.viewPath,
  })

  let submit = useDataSubmit({
    context: 'treatment_card',
    viewPath: props.viewPath,
  })

  return function onSubmitClick() {
    submit({
      type: `treatment_plan/${treatment_plan_action}`,
    })
  }
}
