// this is an autogenerated file from App/Account/Content/Settings/AdminContent/SettingsContent/IntegrationsApplications/Content/Connection/mutation.graphql
import { gql } from 'Data/Api'

export default gql`
mutation app__App_Account_Content_Settings_AdminContent_SettingsContent_IntegrationsApplications_Content_Connection(
  $id: uuid!
  $connection_id: uuid!
  $resource_id: uuid!
  $type: apps_connection_resource_types_enum!
  $with_insert: Boolean!
  $with_update: Boolean!
) {
  insert_apps_connection_resources_one(
    object: {
      connection_id: $connection_id
      resource_id: $resource_id
      type: $type
    }
  ) @include(if: $with_insert) {
    id
    status
    connection_id
    resource_id
    type
    connection {
      id
      display_name
      status
      app_id
    }
  }
  insert_apps_connection_resource_properties_one(
    object: {
      connection_id: $connection_id
      resource_id: $resource_id
      type: $type
      properties: {}
    }
  ) @include(if: $with_insert) {
    id
    connection_id
    resource_id
    type
    properties
  }
  update_apps_connection_resources_by_pk(
    pk_columns: { id: $id }
    _set: {
      connection_id: $connection_id
      resource_id: $resource_id
      type: $type
    }
  ) @include(if: $with_update) {
    id
    status
    connection_id
    resource_id
    type
    connection {
      id
      display_name
      status
      app_id
    }
  }
}

`