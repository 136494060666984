import { useDataChange, useDataValue } from 'Simple/Data'

export function useOnClick(props) {
  let change = useDataChange({
    context: 'tab',
    viewPath: props.viewPath,
  })
  let previousDay = useDataValue({
    context: 'resources',
    path: 'open_office_dates.previous_day',
    viewPath: props.viewPath,
  })

  return function onClick() {
    change(next => {
      next.selected.date = previousDay
    })
  }
}
