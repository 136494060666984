import { useMemo } from 'react'

export default function useDataTransform(props, data) {
  return useMemo(() => {
    if (!data) return data

    return data.map(provider => {
      return {
        id: provider.id,
        text: `${provider.employment_contract.person.first_name} ${provider.employment_contract.person.last_name}`,
      }
    })
  }, [data])
}
