// this is an autogenerated file from App/Account/Content/Patients/Content/Content/Sections/ActiveTab/Profile/Content/NewProfile/Content/SchedulingPreferences/Content/Edit/Content/schedulingReferencesUpdateMutation.graphql
import { gql } from 'Data/Api'

export default gql`
mutation app__App_Account_Content_Patients_Content_Content_Sections_ActiveTab_Profile_Content_NewProfile_Content_SchedulingPreferences_Content_Edit_Content(
  $id: numeric!
  $start_hour: Int
  $end_hour: Int
  $days_to_insert: [vaxiom_scheduling_preferences_week_days_insert_input!]!
  $days_to_remove: [Int!]
  $employees_to_insert: [vaxiom_scheduling_preferred_employees_insert_input!]!
  $employees_to_remove: [numeric!]
) {
  update_vaxiom_scheduling_preferences_by_pk(
    pk_columns: { id: $id }
    _set: { start_hour: $start_hour, end_hour: $end_hour }
  ) {
    id
  }
  delete_vaxiom_scheduling_preferences_week_days(
    where: {
      scheduling_preferences_id: { _eq: $id }
      week_days: { _in: $days_to_remove }
    }
  ) {
    affected_rows
  }
  insert_vaxiom_scheduling_preferences_week_days(objects: $days_to_insert) {
    affected_rows
  }

  delete_vaxiom_scheduling_preferred_employees(
    where: {
      preference_id: { _eq: $id }
      employee_id: { _in: $employees_to_remove }
    }
  ) {
    affected_rows
  }
  insert_vaxiom_scheduling_preferred_employees(objects: $employees_to_insert) {
    affected_rows
  }
}

`