// this is an autogenerated file from App/Account/Content/Patients/Content/Content/Sections/ActiveTab/Profile/Content/NewProfile/Content/Insurance/AssignedInsurance/Content/AssignedInsuranceItem/Inactivate/Content/Warning/data.graphql
import { gql } from 'Data/Api'

export default gql`
query app__App_Account_Content_Patients_Content_Content_Sections_ActiveTab_Profile_Content_NewProfile_Content_Insurance_AssignedInsurance_Content_AssignedInsuranceItem_Inactivate_Content_Warning($account_id: numeric, $patient_id: numeric!) {
  financial_payment_account_balance_by_patient(
    args: { account_id_input: $account_id }
    where: { patient_id: { _eq: $patient_id }, balance: { _gt: 0 } }
  ) {
    balance
  }
}

`