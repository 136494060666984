import { add } from 'lodash'
import { useMemo } from 'react'

export default function useDataTransform(props, data) {
  return useMemo(() => {
    if (!data) return

    return data
      .filter(item => !!item.person)
      .map(item => {
        let phones = item.person.phones
          .map(item => {
            let number = item.contact_method.phone?.number

            if (!number) return false

            return {
              id: item.id,
              number,
              description: item.description,
              preference: item.preference,
            }
          })
          .filter(Boolean)

        let addresses = item.person.addresses
          .map(item => {
            let address_line1 =
              item.contact_method.postal_address?.address_line1
            let city = item.contact_method.postal_address?.city
            let state = item.contact_method.postal_address?.state
            let zip = item.contact_method.postal_address?.zip

            if (!address_line1 || !city || !state || !zip) return false

            return {
              id: item.id,
              address_line1,
              city,
              state,
              zip,
            }
          })
          .filter(Boolean)

        let emails = item.person.emails
          .map(item => {
            let address = item.contact_method.email?.address

            if (!address) return false

            return {
              id: item.id,
              address,
              preference: item.preference,
            }
          })
          .filter(Boolean)

        let is_patient = !!item.person.patient
        let is_payer = item.person.from_person_relationships?.length > 0
        let is_employee = item.person.employment_contracts?.length > 0
        let is_external_professional =
          item.person.other_professional_relationships?.length > 0

        return {
          ...item.person,
          is_patient,
          is_payer,
          is_employee,
          is_external_professional,
          phones,
          addresses,
          emails,
        }
      })
      .filter(Boolean)
  }, [data]) // eslint-disable-line
  // ignore transform
}
