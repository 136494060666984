import { useMemo } from 'react'

export default function useDataTransform(props, data) {
  return useMemo(
    () =>
      data?.map(item => ({
        id: item.appointment_type_id,
        name: item.name,
        color_id: item.color_id,
      })),
    [data]
  )
}
