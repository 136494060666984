// this is an autogenerated file from App/Account/Content/Patients/Content/Content/Sections/ActiveTab/Profile/Content/NewProfile/Content/Professionals/AddAction/Search/Content/Profiles/Content/Profile/mutation_add_other_professional_relationship.graphql
import { gql } from 'Data/Api'

export default gql`
mutation app__App_Account_Content_Patients_Content_Content_Sections_ActiveTab_Profile_Content_NewProfile_Content_Professionals_AddAction_Search_Content_Profiles_Content_Profile(
  $patient_id: numeric!
  $person_id: numeric!
  $external_office_id: numeric!
  $employee_type_id: numeric!
) {
  insert_vaxiom_professional_relationships_one(
    object: {
      dtype: "otherProfessionalRelationship"
      patient_id: $patient_id
      past: false
      other: {
        data: {
          person_id: $person_id
          external_office_id: $external_office_id
          employee_type_id: $employee_type_id
        }
      }
    }
  ) {
    id
  }
}

`