import { useSetFlowTo, normalizePath } from 'Simple/Flow.js'
import { useMemo } from 'react'

/** @type {import('Simple/types.js').useDataTransform} */
export default function useDataTransform(props, data) {
  let setFlowTo = useSetFlowTo(props.viewPath)
  return useMemo(() => {
    if (!data) return data
    // if no relationships are there on that user. skip this dialog
    if (!data?.length) {
      setFlowTo(normalizePath(props.viewPath, '../../../../../../../../../No'))
      return
    }

    return data
  }, [data])
}
