import { useMemo } from 'react'

export default function useDataTransform(props, data) {
  return useMemo(() => {
    let scheduled =
      data?.scheduled?.map(item => ({
        ...item,
        booking: item.booking?.[0],
        group: 'scheduled',
      })) || []

    let unscheduled =
      data?.unscheduled?.map(item => ({
        ...item,
        group: 'unscheduled',
      })) || []

    return [{ id: 'new' }, ...scheduled, ...unscheduled]
  }, [data])
}
