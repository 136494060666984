// this is an autogenerated file from App/Account/Content/Patients/Content/Content/Sections/ActiveTab/TreatmentCard/Content/TreatmentCard/Images/ActiveTab/Images/data.graphql
import { gql } from 'Data/Api'

export default gql`
query app__App_Account_Content_Patients_Content_Content_Sections_ActiveTab_TreatmentCard_Content_TreatmentCard_Images_ActiveTab_Images($patient_id: uuid!) {
  vaxiom_patient_imageseries(
    where: { deleted: { _eq: false }, patient: { _id: { _eq: $patient_id } } }
    order_by: { series_date: desc_nulls_last }
  ) {
    id
    name
    series_date
    patient {
      id
      _id
    }
  }
}

`