import { DEFAULT_TIMEZONE } from 'Data/constants.js'
import { format, utcToZonedTime } from 'Data/date.js'
import { useMemo } from 'react'

/** @type {import('Simple/types.js').useDataTransform} */
export default function useDataTransform(props, data) {
  return useMemo(() => {
    let time_zone_id = props.location.time_zone_id || DEFAULT_TIMEZONE
    return {
      ...data,
      events: props.events || data.events,
      resources: props.resources || data.resources,
      date: props.date || new Date(),
      location: {
        ...props.location,
        time_zone_id,
      },
      slot_interval: props.slotInterval || data.slot_interval,
      scroll_time: format(utcToZonedTime(new Date(), time_zone_id), 'HH:mm:ss'),
      slot_min_time: props.slotMinTime || data.slot_min_time,
      slot_max_time: props.slotMaxTime || data.slot_max_time,
      hipaa: props.hipaa || data.hipaa,
      open_dialog_near_click: props.resources?.length < 3,
      focused_event_id: props.focusedEventId || data.focused_event_id,
      scheduling_config: props.schedulingConfig || data.scheduling_config,
    }
  }, [
    data,
    props.events,
    props.resources,
    props.date,
    props.location,
    props.slotInterval,
    props.slotMinTime,
    props.slotMaxTime,
    props.hipaa,
    props.focusedEventId,
    props.schedulingConfig,
  ])
}
