// this is an autogenerated file from App/Account/Content/Patients/Content/Content/Sections/ActiveTab/Profile/Content/NewProfile/Content/Main/Treatments/Content/Treatment/Status/data.graphql
import { gql } from 'Data/Api'

export default gql`
query app__App_Account_Content_Patients_Content_Content_Sections_ActiveTab_Profile_Content_NewProfile_Content_Main_Treatments_Content_Treatment_Status($parent_company_id: numeric!, $type: String!) {
  vaxiom_tx_statuses(
    where: {
      parent_company_id: { _eq: $parent_company_id }
      hidden: { _eq: false }
      type: { _eq: $type }
    }
    limit: 1
  ) {
    id
    name
  }
}

`