import { useDataChange } from 'Simple/Data.js'
import { useSetFlowTo, normalizePath } from 'Simple/Flow.js'
import {
  changePayorsShare,
  changeDiscountsAppliedToPayorsShare,
  changePayorsConfiguration,
} from 'Data/payment-plan.js'

export function useOnClick(props) {
  let change = useDataChange({
    context: 'payment_plan',
    viewPath: props.viewPath,
  })
  let setFlowTo = useSetFlowTo(props.viewPath)

  return function onClick() {
    change(next => {
      next.insurances = []

      changePayorsShare(next)
      changeDiscountsAppliedToPayorsShare(next)
      changePayorsConfiguration(next)
    })

    setFlowTo(normalizePath(props.viewPath, '../../No'))
  }
}
