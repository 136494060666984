import { useMemo } from 'react'
import { useDataValue } from 'Simple/Data.js'

export default function useDataTransform(props, data) {
  let appointment_booking = useDataValue({
    context: 'event',
    path: 'appointment.booking.0',
    viewPath: props.viewPath,
  })

  return useMemo(
    () => ({
      provider_id: appointment_booking.provider_id,
      assistant_id: appointment_booking.assistant_id,
      chair_id: appointment_booking.chair_id,
    }),
    [
      appointment_booking.assistant_id,
      appointment_booking.chair_id,
      appointment_booking.provider_id,
    ]
  )
}
