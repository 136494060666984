// this is an autogenerated file from DesignSystem/DataVaxiomPatientInsuranceAssignForm/Content/Employer/data.graphql
import { gql } from 'Data/Api'

export default gql`
query app__DesignSystem_DataVaxiomPatientInsuranceAssignForm_Content_Employer(
  $organization_id: uuid!
  $search_term: String!
  $selected_employer: numeric
  $search_selected_employer: Boolean!
) {
  employers: vaxiom_employers(
    where: {
      organization: { _id: { _eq: $organization_id } }
      name: { _ilike: $search_term }
    }
    order_by: { name: asc }
    limit: 250
  ) {
    id
    text: name
  }

  selected_employer: vaxiom_employers(
    where: { id: { _eq: $selected_employer } }
  ) @include(if: $search_selected_employer) {
    id
    text: name
  }
}

`