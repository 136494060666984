import { useDataChange, useDataValue } from 'Simple/Data.js'

export function useOnClick(props) {
  let docked_to_left = useDataValue({
    context: 'appointment_overlay',
    path: 'docked_to_left',
    viewPath: props.viewPath,
  })
  let change = useDataChange({
    context: 'appointment_overlay',
    viewPath: props.viewPath,
  })

  return function onClick() {
    change(next => {
      next.docked_to_left = !docked_to_left
    })
  }
}
