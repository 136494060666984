import { useMemo } from 'react'
import { useDataValue } from 'Simple/Data.js'

/** @type {import('Simple/types.js').useDataTransform} */
export default function useDataTransform(props, data) {
  let resources = useDataValue({
    context: 'timegrid',
    path: 'resources',
    viewPath: props.viewPath,
  })
  let location_id = useDataValue({
    context: 'timegrid',
    path: 'location.vaxiom_id',
    viewPath: props.viewPath,
  })

  return useMemo(() => {
    return {
      ...data,
      schedule_note: {
        ...data.schedule_note,
        ...props.event,
        location_id,
        resources,
      },
    }
  }, [data, props.event, resources, location_id])
}
