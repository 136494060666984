// this is an autogenerated file from App/Account/Content/Patients/Content/Content/Sections/ActiveTab/Profile/Content/NewProfile/data.graphql
import { gql } from 'Data/Api'

export default gql`
query app__App_Account_Content_Patients_Content_Content_Sections_ActiveTab_Profile_Content_NewProfile($selected_patient_id: uuid!) {
  vaxiom_patients(where: { _id: { _eq: $selected_patient_id } }) {
    _id
    id
    human_id
    legacy_id
    human_readable_id
    notes
    organization {
      id
      _id
    }
    primary_location_id
    primary_location {
      id
      _id
    }
    communication_preferences {
      id
      contact_method_association_id
      contact_method_association {
        id
        preference
      }
    }
    person {
      id
      _id
      preferred_contact_method: contact_method_associations(
        where: { preference: { _is_null: false } }
      ) {
        id
        contact_method_id
      }
      title
      first_name
      middle_name
      last_name
      birth_date
      gender
      ssn
      school
      greeting
      profile_pic_url
      heard_about_us_from
    }
    tx_cards(where: { deleted: { _eq: false } }) {
      id
      txs(where: { deleted: { _eq: false } }) {
        id
        organization_id
        name
        status
        did_not_start
        organization {
          id
          _id
          name
          parent_company {
            id
          }
        }
      }
    }
    patient_template_referrals {
      id
      _id
      text_value
      referral_template_id
    }
    patient_person_referrals {
      id
      _id
      person_type
      person_id
      person {
        id
        _id
      }
    }
  }
}

`