// this is an autogenerated file from DesignSystem/DataVaxiomRelationshipsItem/Delete/Content/DeleteAction/mutation.graphql
import { gql } from 'Data/Api'

export default gql`
mutation app__DesignSystem_DataVaxiomRelationshipsItem_Delete_Content_DeleteAction(
  $patient_person_id: numeric!
  $relative_person_id: numeric!
) {
  delete_vaxiom_relationships(
    where: {
      _or: [
        {
          from_person: { _eq: $patient_person_id }
          to_person: { _eq: $relative_person_id }
        }
        {
          from_person: { _eq: $relative_person_id }
          to_person: { _eq: $patient_person_id }
        }
      ]
    }
  ) {
    affected_rows
  }
}

`