import { useDataChange, useDataValue } from 'Simple/Data'

export function useOnChange(props) {
  let carrier_employer_options = useDataValue({
    context: 'carriers',
    viewPath: props.viewPath,
  })

  let change = useDataChange({
    context: 'selected',
    viewPath: props.viewPath,
  })

  return function onChange(filter_id) {
    change(next => {
      let [filter] = carrier_employer_options.filter(c => c.id == filter_id)
      next.filter_id = filter_id
      next.plan_number = next.plan_number
      next.is_carrier = filter?.is_carrier ?? false
      next.filter_object_id = filter?.object_id ?? ''
    })
  }
}
