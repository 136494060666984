// this is an autogenerated file from App/Account/Content/Patients/Content/Content/Sections/ActiveTab/Profile/Content/NewProfile/Content/Professionals/AddAction/New/Content/Details/Office/Search/Content/Content/Offices/data.graphql
import { gql } from 'Data/Api'

export default gql`
query app__App_Account_Content_Patients_Content_Content_Sections_ActiveTab_Profile_Content_NewProfile_Content_Professionals_AddAction_New_Content_Details_Office_Search_Content_Content_Offices($search_term: String!, $organizations: [uuid!]!) {
  vaxiom_external_offices(
    where: {
      name: { _ilike: $search_term }
      other_professional_relationships: {
        person: { organization: { _id: { _in: $organizations } } }
      }
    }
  ) {
    id
    name
    postal_address {
      id
      address_line1
      city
      state
      zip
    }
  }
}

`