// this is an autogenerated file from DesignSystem/DataVaxiomRelationshipsItem/Edit/NonPatient/Email/NewEmail/Content/newContactMethodAssociationMutation.graphql
import { gql } from 'Data/Api'

export default gql`
mutation app__DesignSystem_DataVaxiomRelationshipsItem_Edit_NonPatient_Email_NewEmail_Content(
  $contact_method_id: numeric!
  $person_id: numeric!
) {
  insert_vaxiom_contact_method_associations_one(
    object: { person_id: $person_id, contact_method_id: $contact_method_id }
  ) {
    id
    contact_method_id
  }
}

`