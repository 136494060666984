import { useDataValue } from 'Simple/Data.js'

/** @type {import('Simple/types.js').useDataConfiguration} */
export default function useDataConfiguration(props, data) {
  let treatment_plan_id = useDataValue({
    context: 'treatment_plan',
    path: 'id',
    viewPath: props.viewPath,
  })

  return {
    variables: {
      treatment_plan_id,
    },
    pause: !treatment_plan_id,
    // refetch for now to capture the changes on old UI
    requestPolicy: 'cache-and-network',
  }
}
