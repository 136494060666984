// this is an autogenerated file from App/Account/Content/Patients/Content/Content/Sections/ActiveTab/TreatmentCard/Content/TreatmentPlans/New/Steps/Content/TreatmentPlans/Content/TreatmentPlan/mutation-insert-appointment.graphql
import { gql } from 'Data/Api'

export default gql`
mutation app__App_Account_Content_Patients_Content_Content_Sections_ActiveTab_TreatmentCard_Content_TreatmentPlans_New_Steps_Content_TreatmentPlans_Content_TreatmentPlan(
  $treatment_plan_id: numeric!
  $type_id: numeric!
  $interval_to_next: Int!
  $previous_appointment_id: numeric!
  $location_id: numeric!
) {
  treatments_insert_treatment_plan_appointment(
    args: {
      treatment_plan_id: $treatment_plan_id
      type_id: $type_id
      interval_to_next: $interval_to_next
      previous_appointment_id: $previous_appointment_id
      location_id: $location_id
    }
  ) {
    id
  }
}

`