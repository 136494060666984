import { useDataSubmit } from 'Simple/Data.js'
import { normalizePath, useSetFlowTo } from 'Simple/Flow.js'

export function useOnClick(props) {
  let submit = useDataSubmit({
    context: 'app',
    viewPath: props.viewPath,
  })
  let setFlowTo = useSetFlowTo(props.viewPath)

  return function onClick() {
    submit({ type: 'cancel' })
    setFlowTo(normalizePath(props.viewPath, '../../No'))
  }
}
