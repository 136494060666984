import { useDataSubmit } from 'Simple/Data'

export function useOnClick(props) {
  let submit = useDataSubmit({
    viewPath: props.viewPath,
    context: 'new_address',
  })

  return async function onClick(value) {
    submit({ type: 'form_submit' })
  }
}
