// this is an autogenerated file from DesignSystem/DataVaxiomPatientInsuranceForm/Carrier/Content/Options/Create/Content/mutation.graphql
import { gql } from 'Data/Api'

export default gql`
mutation app__DesignSystem_DataVaxiomPatientInsuranceForm_Carrier_Content_Options_Create_Content(
  $carrier_details: json!
  $insurance_company_phone_associations: json!
  $postal_address: json!
) {
  insert_insurance_carrier(
    args: {
      carrier_details: $carrier_details
      insurance_company_phone_associations: $insurance_company_phone_associations
      postal_address: $postal_address
    }
  ) {
    id
  }
}

`