import { useDataChange, useDataValue } from 'Simple/Data.js'

/** @type {import('Simple/types.js').useDataOnChange} */
export default function useDataOnChange(props, data) {
  let change = useDataChange({
    viewPath: props.viewPath,
    context: 'appointment_overlay',
  })
  let is_external_any = useDataValue({
    viewPath: props.viewPath,
    context: 'appointment_overlay',
    path: 'is_external_any',
  })

  return async function onChange(next) {
    if (is_external_any) return

    if (next?.length === 0) {
      change(next => {
        next.existing_patient_no_treatments = true
      })
      return
    }

    let preferred = next?.find(i => i.status === 'ACTIVE')
    let selected = preferred || next?.[0]

    if (selected) {
      change(next => {
        next.treatment_id = selected._id
        next.appointment_id = null
      })
    }
  }
}
