import { useDataChange, useDataValue } from 'Simple/Data.js'

/** @type {import('Simple/types.js').useDataOnChange} */
export default function useDataOnChange(props, data) {
  let provider_employee_resource_id = useDataValue({
    viewPath: props.viewPath,
    context: 'appointment_overlay',
    path: 'patient_new.provider_employee_resource_id',
  })
  let change = useDataChange({
    viewPath: props.viewPath,
    context: 'appointment_overlay',
    path: 'patient_new.provider_employee_resource_id',
  })

  return async function onChange(value) {
    if (provider_employee_resource_id) return
    let first_id = value?.[0]?.id
    if (!first_id) return
    if (first_id === provider_employee_resource_id) return
    change(first_id)
  }
}
