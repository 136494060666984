// this is an autogenerated file from App/Account/Content/Settings/AdminContent/SettingsContent/TreatmentCaseCloser/Configuration/DefaultPaymentPlans/Rulesets/data.graphql
import { gql } from 'Data/Api'

export default gql`
query app__App_Account_Content_Settings_AdminContent_SettingsContent_TreatmentCaseCloser_Configuration_DefaultPaymentPlans_Rulesets{
  vaxiom_sys_organizations(
    where: {
      level: { _eq: "location" }
      deleted: { _eq: false }
      default_payment_plans: {}
    }
    order_by: { name: asc }
  ) {
    id
    _id
    name
    parent {
      _id
    }
    default_payment_plans(
      where: {
        name: {
          _nin: [
            "Pay over time with Wisetack"
            "Apply to pay over time with HFD Financing"
          ]
        }
      }
      order_by: { order: asc }
    ) {
      id
      name
      description
      downpayment
      downpayment_type
      length
      length_type
      frequency
      order
      organization_id
      discounts {
        id
        discount_id
        value
        type
        stack_with_other_discounts
      }
    }
  }
}

`