import { useSetFlowTo, normalizePath } from 'Simple/Flow.js'
import mutation_new_scheduling_prefs from './schedulingPrefsNewMutation.graphql.js'
import mutation_update_scheduling_prefs from './schedulingReferencesUpdateMutation.graphql.js'
import {
  notifyError,
  useNotifications,
  notifySuccess,
} from 'Logic/Notifications.js'
import { useMutation } from 'Data/Api'
import { useDataValue } from 'Simple/Data'
import difference from 'lodash/difference.js'

export default function useDataOnSubmit(props, data, reExecuteQuery) {
  let setFlowTo = useSetFlowTo(props.viewPath)
  let patient_id = useDataValue({
    viewPath: props.viewPath,
    context: 'profile_patient',
    path: 'id',
  })
  let [, notify] = useNotifications()

  let [, executeMutationNewPrefs] = useMutation(mutation_new_scheduling_prefs)
  let [, executeMutationUpdatePrefs] = useMutation(
    mutation_update_scheduling_prefs
  )
  return async function onSubmit({ value: preferences, args, originalValue }) {
    if (preferences.id) {
      let employees = preferences.providers.concat(preferences.assistants)
      let original_employees = originalValue.providers.concat(
        originalValue.assistants
      )

      let mutationUpdatePrefsResponse = await executeMutationUpdatePrefs({
        id: preferences.id,
        start_hour: preferences.start_hour,
        end_hour: preferences.end_hour,
        days_to_insert: difference(preferences.days, originalValue.days).map(
          d => ({ week_days: d, scheduling_preferences_id: preferences.id })
        ),
        days_to_remove: difference(originalValue.days, preferences.days),
        employees_to_insert: difference(employees, original_employees).map(
          e => ({ employee_id: e, preference_id: preferences.id })
        ),
        employees_to_remove: difference(original_employees, employees),
      })
      if (mutationUpdatePrefsResponse.error) {
        notify(
          notifyError(
            'Something went wrong. Please, try again or contact support if the problem persists.'
          )
        )
        return
      }

      setFlowTo(normalizePath(props.viewPath, '../No'))
      notify(notifySuccess('Scheduling preferences saved!'))
      return
    } else {
      let mutationNewPrefsResponse = await executeMutationNewPrefs({
        input: {
          patient_id,
          start_hour: preferences.start_hour,
          end_hour: preferences.end_hour,
          scheduling_preferences_week_days: {
            data: preferences.days.map(d => ({ week_days: d })),
          },
          scheduling_preferred_employees: {
            data: preferences.providers
              .concat(preferences.assistants)
              .map(e => ({ employee_id: e })),
          },
        },
      })
      if (mutationNewPrefsResponse.error) {
        notify(
          notifyError(
            'Something went wrong. Please, try again or contact support if the problem persists.'
          )
        )
        return
      }

      setFlowTo(normalizePath(props.viewPath, '../No'))
      notify(notifySuccess('Scheduling preferences saved!'))
      return
    }
  }
}
