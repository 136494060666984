// this is an autogenerated file from App/Account/Content/Patients/Content/Content/Sections/ActiveTab/Profile/Content/NewProfile/Content/Insurance/AddAction/Content/Content/Assign/Content/mutation.graphql
import { gql } from 'Data/Api'

export default gql`
mutation app__App_Account_Content_Patients_Content_Content_Sections_ActiveTab_Profile_Content_NewProfile_Content_Insurance_AddAction_Content_Content_Assign_Content(
  $insurance_subscription: json!
  $insured_patient_id: numeric!
  $ortho_insured: json!
  $tx_category_id: numeric!
) {
  insert_patient_insurance_subscription(
    args: {
      insurance_subscription: $insurance_subscription
      insured_patient_id: $insured_patient_id
      ortho_insured: $ortho_insured
      tx_category_id: $tx_category_id
    }
  ) {
    id
  }
}

`