import { useMemo } from 'react'

/** @type {import('Simple/types.js').useDataTransform} */
export default function useDataTransform(props, data) {
  return useMemo(() => {
    if (!data) return data

    let location_ids = Array.isArray(data.comms_user_preferences?.location_ids)
      ? data.comms_user_preferences.location_ids
      : []

    return {
      ...data,
      comms_user_preferences: {
        ...data.comms_user_preferences,
        location_ids,
      },
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data])
}
