import { useMemo } from 'react'
import { useDataValue } from 'Simple/Data.js'

export default function useDataTransform(props, data) {
  let id = useDataValue({
    context: 'payor',
    path: 'contract.signature_request_id',
    viewPath: props.viewPath,
  })
  // null is needed since otherwise the morpher redirects to Loading
  // adding the check on id since otherwise it shows the previous payor's request
  return useMemo(() => (id ? data?.[0] || null : null), [id, data])
}
