// this is an autogenerated file from DesignSystem/DataVaxiomEmployeeProfessionalsRelationshipsItem/Edit/Content/Email/NewEmail/Content/addPersonContactMethodsMutation.graphql
import { gql } from 'Data/Api'

export default gql`
mutation app__DesignSystem_DataVaxiomEmployeeProfessionalsRelationshipsItem_Edit_Content_Email_NewEmail_Content($person_id: numeric!, $contact_methods: json!) {
  add_person_contact_methods(
    args: { _person_id: $person_id, _contact_methods: $contact_methods }
  ) {
    id
  }
}

`