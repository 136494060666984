import React from 'react'
import useFetch from 'Data/useFetch.js'
import {
  useDataValue,
  DataProvider,
  useSetFlowToBasedOnData,
} from 'Simple/Data.js'
import { getS3ObjectUrl, useGetSignedUrl, withAwsCredentials } from 'Data/s3.js'

import View from './view.js'

// ensure AWS credentials are available at this stage
export default withAwsCredentials(function Logic(props) {
  let credentials = useDataValue({
    context: 'aws_credentials',
    viewPath: props.viewPath,
  })
  let key = useDataValue({
    context: 'contract',
    path: 'html_file_uuid',
    viewPath: props.viewPath,
  })
  let presignedUrl = useGetSignedUrl({
    // TODO: once core doesn't reference the contracts anymore,
    // it will be refactored to get the S3 URL directly from backend
    url: getS3ObjectUrl({
      bucket: credentials.legacy_storage_bucket_name,
      key: `patientdoc/${key}`,
      region: credentials.region,
    }),
    download: false,
    viewPath: props.viewPath,
  })

  let [{ data, fetching, error }] = useFetch(presignedUrl, { cache: 'reload' })

  useSetFlowToBasedOnData({
    data,
    fetching,
    error,
    pause: false,
    viewPath: props.viewPath,
  })

  return (
    <DataProvider context="content" value={data} viewPath={props.viewPath}>
      <View {...props} />
    </DataProvider>
  )
})
