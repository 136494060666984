// this is an autogenerated file from App/Account/Content/Patients/Content/Content/Sections/ActiveTab/TreatmentCard/Content/TreatmentCard/Treatment/Content/Actions/data.graphql
import { gql } from 'Data/Api'

export default gql`
query app__App_Account_Content_Patients_Content_Content_Sections_ActiveTab_TreatmentCard_Content_TreatmentCard_Treatment_Content_Actions($treatment_card_id: uuid!) {
  vaxiom_tx_cards(where: { _id: { _eq: $treatment_card_id } }) {
    id
    _id
    name
    tx_category {
      id
      name
    }
    txs(where: { deleted: { _eq: false } }) {
      id
      _id
      has_unrealized_invoices
      name
      status
      is_active
      start_date
      estimated_start_date
      end_date
      estimated_end_date
      colors
      notes
      organization {
        id
        _id
        computed_parent_company_id
      }
    }
  }
}

`