import { useMemo } from 'react'
import { useDataValue } from 'Simple/Data.js'

export default function useDataTransform(props, data) {
  let appointments = useDataValue({
    viewPath: props.viewPath,
    context: 'appointments',
  })
  let appointment_id = useDataValue({
    viewPath: props.viewPath,
    context: 'appointment_overlay',
    path: 'appointment_id',
  })

  return useMemo(() => {
    return appointments?.find(v => v._id === appointment_id) ?? null
  }, [appointments, appointment_id])
}
