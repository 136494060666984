// this is an autogenerated file from App/Account/Content/Patients/Content/Content/Sections/ActiveTab/TreatmentCard/Content/TreatmentCard/Treatment/Content/Actions/Content/AssignColors/Content/mutation.graphql
import { gql } from 'Data/Api'

export default gql`
mutation app__App_Account_Content_Patients_Content_Content_Sections_ActiveTab_TreatmentCard_Content_TreatmentCard_Treatment_Content_Actions_Content_AssignColors_Content($tx_id: uuid!, $colors: String!) {
  update_vaxiom_txs(
    where: { _id: { _eq: $tx_id } }
    _set: { colors: $colors }
  ) {
    affected_rows
  }
  update_vaxiom_appointments(
    where: { tx: { _id: { _eq: $tx_id } } }
    _set: { colors: $colors }
  ) {
    affected_rows
  }
}

`