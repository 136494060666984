import { useMemo } from 'react'

export default function useDataTransform(props, data) {
  return useMemo(() => {
    if (!data) return data
    return data.map(tx_card => ({
      ...tx_card,
      txs: tx_card?.txs?.[0],
      case_closer_request: getFirstCaseCloserRequest(tx_card?.txs?.[0]),
    }))[0]
  }, [data])
}

function getFirstCaseCloserRequest(treatment) {
  return treatment.tx_plans.flatMap(
    treatment_plan => treatment_plan.treatment_requests
  )?.[0]
}
