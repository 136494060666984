// this is an autogenerated file from App/Account/Content/Patients/Content/Content/Sections/ActiveTab/TreatmentCard/Content/TreatmentPlans/New/Steps/Content/Contracts/Content/Payor/Content/SignAction/Content/Content/CancelAction/Content/SubmitAction/mutation.graphql
import { gql } from 'Data/Api'

export default gql`
mutation app__App_Account_Content_Patients_Content_Content_Sections_ActiveTab_TreatmentCard_Content_TreatmentPlans_New_Steps_Content_Contracts_Content_Payor_Content_SignAction_Content_Content_CancelAction_Content_SubmitAction($id: uuid!) {
  update_documents_signature_requests_by_pk(
    pk_columns: { id: $id }
    _set: { status: cancelled }
  ) {
    id
    status
  }
}

`