import { booleanToYesNo, formatDateDisplay } from 'Data/format'
import { useMemo } from 'react'

export default function useDataTransform(props, data) {
  return useMemo(() => {
    if (!data) return data

    return data
      .filter(
        question =>
          (question.category !== 'habits' && question.category !== 'general') ||
          question.type === 'boolean'
      )
      .map(question => {
        let answer = question.answers[0]
        let questionType = question?.type
        let response = answer?.value

        return {
          id: question.id,
          name: question.name,
          value: getValue(response, questionType, answer?.alert),
          alert: getAlertValue({
            answer_alert: answer?.alert,
            answer_value: response,
            alert_type: question?.alert_type,
            alert_conditions: question?.alert_conditions,
          }),
        }
      })
      .filter(alertDTO => alertDTO.alert === true)
  }, [data])
}

function getValue(response, questionType, answer_alert) {
  switch (questionType) {
    case 'boolean':
      return response ? 'Yes' : 'No'
    case 'number':
    case 'choice':
    case 'text':
      return response
    case 'date':
      return formatDateDisplay(response)
    case 'multichoice':
      return Array.isArray(response)
        ? response
            .filter(
              item =>
                answer_alert === true ||
                (Array.isArray(answer_alert) && answer_alert?.includes(item))
            )
            .join(', ')
        : response
    default:
      return JSON.stringify(response)
  }
}

function getAlertValue({
  answer_alert,
  answer_value,
  alert_type,
  alert_conditions,
}) {
  if (
    answer_alert === false ||
    answer_alert === null ||
    answer_alert === undefined ||
    alert_type === 'none'
  ) {
    return false
  } else {
    if (alert_type === 'any') {
      return true
    }

    if (alert_type === 'values') {
      if (Array.isArray(alert_conditions)) {
        return alert_conditions.includes(answer_value)
      }

      return alert_conditions === answer_value
    }
  }

  return typeof answer_value === 'boolean' && typeof answer_alert === 'string'
    ? answer_alert.toLowerCase() === booleanToYesNo(answer_value).toLowerCase()
    : answer_alert === answer_value
}
