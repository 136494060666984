import inactivatemutation from './mutation_inactivate.graphql.js'
import reactivatemutation from './mutation_reactivate.graphql.js'
import { useDataValue } from 'Simple/Data.js'
import { useMutation } from 'Data/Api'
import { useSetFlowTo, normalizePath } from 'Simple/Flow.js'
import {
  notifyError,
  notifySuccess,
  useNotifications,
} from 'Logic/Notifications.js'

/** @type {import('Simple/types.js').useDataOnSubmit} */
export default function useDataOnSubmit(props, data) {
  let [, executeInactivateMutation] = useMutation(inactivatemutation)
  let [, executeReactivateMutation] = useMutation(reactivatemutation)
  let logged_in_user = useDataValue({
    context: 'auth',
    path: 'access_token_data.vaxiom_user_id',
    viewPath: props.viewPath,
  })

  let insured_id = useDataValue({
    context: 'assigned_insurance',
    viewPath: props.viewPath,
    path: 'id',
  })

  let setFlowTo = useSetFlowTo(props.viewPath)
  let [, notify] = useNotifications()

  return async function onSubmit({ value, originalValue, args, change }) {
    switch (args.type) {
      case 'inactivate':
        if (!value.deactivated_reason) {
          return true
        }
        let mutationResponse = await executeInactivateMutation({
          id: value.id,
          reason: value.deactivated_reason,
          deactivated_by: logged_in_user,
          deactivated: new Date(),
          insured_id,
        })

        if (mutationResponse.error) {
          notify(notifyError('Something went wrong. Please try again.'))
          return
        }
        notify(notifySuccess('Insurance subscription inactivated succesfully.'))
        setFlowTo(normalizePath(props.viewPath, 'Inactivate/No'))
        return
      case 'reactivate':
        let reactivateMutationResponse = await executeReactivateMutation({
          id: value.id,
          reactivated_by: logged_in_user,
          reactivated: new Date(),
          insured_id,
        })

        if (reactivateMutationResponse.error) {
          notify(notifyError('Something went wrong. Please try again.'))
          return
        }
        notify(notifySuccess('Insurance subscription activated succesfully.'))
        setFlowTo(normalizePath(props.viewPath, 'Reactivate/No'))

        return
      default:
        return
    }
  }
}
