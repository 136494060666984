import { useDataValue } from 'Simple/Data.js'

export default function useDataConfiguration(props) {
  let address = useDataValue({
    viewPath: props.viewPath,
    context: 'new_address',
  })
  return {
    variables: {
      address_line1: address.address_line1,
      city: address.city,
      state: address.state,
      zip: address.zip,
    },
  }
}
