import { useSetFlowTo, normalizePath } from 'Simple/Flow.js'
import { useMutation, useClient } from 'Data/Api'
import { useDataValue } from 'Simple/Data'
import {
  notifyError,
  useNotifications,
  notifySuccess,
} from 'Logic/Notifications.js'
import { street, state, city, zip } from 'Data/validate.js'
import mutationInsertContactMethodAssociation from './mutation-insert-contact-method-association.graphql.js'
import mutationUpdateOtherContactMethodAssociations from './mutation-update-other-contact-method-associations.graphql.js'
import query from './query.graphql.js'

export default function useDataOnSubmit(props, data) {
  let onActionUpdateAddressForPatient =
    useDataOnActionUpdateAddressForPatient(props)
  let onActionFormSubmit = useDataOnActionFormSubmit(props)

  return async function onSubmit({ value, args }) {
    switch (args?.type) {
      case 'update_address': {
        return onActionUpdateAddressForPatient({ value, args })
      }
      default: {
        return onActionFormSubmit({ value, args })
      }
    }
  }
}

function useDataOnActionFormSubmit(props) {
  let setFlowTo = useSetFlowTo(props.viewPath)
  let client = useClient()
  let [, notify] = useNotifications()

  let [, executeMutationInsertContactMethodAssociation] = useMutation(
    mutationInsertContactMethodAssociation
  )

  let person_id = useDataValue({
    viewPath: props.viewPath,
    context: 'profile_patient',
    path: 'person.id',
  })

  return async function onAction({ value, args }) {
    if (
      !street(value.postal_address.address_line1) ||
      !city(value.postal_address.city) ||
      !state(value.postal_address.state) ||
      !zip(value.postal_address.zip)
    ) {
      return true
    }

    let { data } = await client
      .query(query, {
        contact_method_id: value.contact_method_id,
        person_id,
      })
      .toPromise()

    if (
      data?.vaxiom_contact_method_associations_aggregate?.aggregate.count > 0
    ) {
      setFlowTo(normalizePath(props.viewPath, './EditOrAdd/Content'))
      return
    }

    let mutationEditPersonContactMehtodResponse =
      await executeMutationInsertContactMethodAssociation({
        objects: [getVariables(value)],
      })

    if (mutationEditPersonContactMehtodResponse.error) {
      notify(notifyError('Something went wrong. Please, try again.'))
      return
    }

    notify(notifySuccess('Address updated!'))
    setFlowTo(normalizePath(props.viewPath, '../No'))
  }
}

function useDataOnActionUpdateAddressForPatient(props) {
  let ids = useDataValue({
    context: 'selected',
    path: 'contact_method_association_ids',
    viewPath: props.viewPath,
  })

  let setFlowTo = useSetFlowTo(props.viewPath)
  let [, notify] = useNotifications()

  let [, executeMutationInsertContactMethodAssociation] = useMutation(
    mutationInsertContactMethodAssociation
  )
  let [, executeMutationUpdateOtherContactMethodAssociations] = useMutation(
    mutationUpdateOtherContactMethodAssociations
  )

  return async function onAction({ value }) {
    let mutationResponseInsertContactMethodAssociation =
      await executeMutationInsertContactMethodAssociation({
        objects: [getVariables(value)],
      })

    if (mutationResponseInsertContactMethodAssociation.error) {
      notify(
        notifyError(
          'Something went wrong. Please, try again or contact support if the problem persists.'
        )
      )
      return
    }

    let contact_method_id =
      mutationResponseInsertContactMethodAssociation.data
        .insert_contact_method_associations[0].contact_method_id

    let mutationUpdateOtherContactMethodAssociationsResponse =
      await executeMutationUpdateOtherContactMethodAssociations({
        ids,
        contact_method_id,
      })

    if (mutationUpdateOtherContactMethodAssociationsResponse.error) {
      notify(notifyError('Something went wrong. Please, try again.'))
      return
    }

    notify(notifySuccess('Address saved!'))

    setFlowTo(normalizePath(props.viewPath, '../No'))
    return
  }
}

function getVariables(value) {
  return {
    id: value.id,
    description: value.description,
    contact_method: {
      id: value.postal_address.id,
      dtype: 'postal',
      address_line1: value.postal_address.address_line1,
      city: value.postal_address.city,
      state: value.postal_address.state,
      zip: value.postal_address.zip,
    },
  }
}
