import { useSetFlowTo, normalizePath } from 'Simple/Flow.js'
import { useMutation, useClient } from 'Data/Api'
import { useDataValue } from 'Simple/Data'
import {
  notifyError,
  useNotifications,
  notifySuccess,
} from 'Logic/Notifications.js'
import { email } from 'Data/validate.js'
import query from './query.graphql.js'
import mutation_add_person_contact_methods from './addPersonContactMethodsMutation.graphql.js'
import mutation_edit_person_contact_method from './editPersonContactMethodMutation.graphql.js'

export default function useDataOnSubmit(props, data) {
  let onActionNewEmail = useDataOnActionNewEmail(props)
  let onActionFormSubmit = useDataOnActionFormSubmit(props)
  let onActionUpdateEmail = useDataOnActionUpdateEmail(props)

  return async function onSubmit({ value, args }) {
    switch (args?.type) {
      case 'new_email_for_patient': {
        return onActionNewEmail({ value, args })
      }
      case 'update_email': {
        return onActionUpdateEmail({ value, args })
      }
      default: {
        onActionFormSubmit({ value })
      }
    }
  }
}

function useDataOnActionUpdateEmail(props) {
  let setFlowTo = useSetFlowTo(props.viewPath)
  let [, notify] = useNotifications()
  let [, executeMutationEditPersonContactMehtod] = useMutation(
    mutation_edit_person_contact_method
  )

  let person_id = useDataValue({
    viewPath: props.viewPath,
    context: 'edit_relation',
    path: 'from_person',
  })
  return async function onAction({ value: edit_email }) {
    let mutationEditPersonContactMehtodResponse =
      await executeMutationEditPersonContactMehtod({
        person_id,
        contact_method: {
          contact_method_association_id: edit_email.id,
          id: edit_email.email.id,
          description: edit_email.description,
          dtype: 'email',
          data: {
            id: edit_email.email.id,
            address: edit_email.email.address,
          },
        },
      })
    if (mutationEditPersonContactMehtodResponse.error) {
      notify(notifyError('Something went wrong. Please, try again.'))
      return
    }
    notify(notifySuccess('Email updated!'))
    setFlowTo(normalizePath(props.viewPath, '../No'))
  }
}

function useDataOnActionFormSubmit(props) {
  let setFlowTo = useSetFlowTo(props.viewPath)
  let [, notify] = useNotifications()

  let [, executeMutationAddPersonContactMethods] = useMutation(
    mutation_add_person_contact_methods
  )

  let person_id = useDataValue({
    viewPath: props.viewPath,
    context: 'edit_relation',
    path: 'from_person',
  })

  let client = useClient()
  return async function onAction({ value: edit_email }) {
    if (!email(edit_email.email.address)) {
      return true
    }

    let { data } = await client
      .query(query, {
        contact_method_id: edit_email.contact_method_id,
        person_id,
      })
      .toPromise()

    if (
      data?.vaxiom_contact_method_associations_aggregate?.aggregate.count > 0
    ) {
      setFlowTo(normalizePath(props.viewPath, 'EditOrAdd/Content'))
      return
    }

    let mutationAddPersonContactMethods =
      await executeMutationAddPersonContactMethods({
        person_id,
        contact_method_id: edit_email.contact_method_id,
        contact_methods: [
          {
            dtype: 'email',
            description: edit_email.description,
            data: {
              ...edit_email.email,
            },
          },
        ],
      })

    if (mutationAddPersonContactMethods.error) {
      notify(
        notifyError(
          'Something went wrong. Please, try again or contact support if the problem persists.'
        )
      )
      return
    }
    notify(notifySuccess('Email updated!'))
    setFlowTo(normalizePath(props.viewPath, '../No'))
  }
}

function useDataOnActionNewEmail(props) {
  let setFlowTo = useSetFlowTo(props.viewPath)
  let [, notify] = useNotifications()

  let [, executeMutationAddPersonContactMethods] = useMutation(
    mutation_add_person_contact_methods
  )

  let person_id = useDataValue({
    viewPath: props.viewPath,
    context: 'edit_relation',
    path: 'from_person',
  })
  return async function onAction({ value: edit_email }) {
    // if the email doesn't exist,
    // and we don't want to add a relation,
    // we just save the new email and add
    // a new association

    let mutationAddPersonContactMethods =
      await executeMutationAddPersonContactMethods({
        person_id,
        contact_method_id: edit_email.contact_method_id,
        contact_methods: [
          {
            dtype: 'email',
            description: edit_email.description,
            data: {
              ...edit_email.email,
            },
          },
        ],
      })

    if (mutationAddPersonContactMethods.error) {
      notify(
        notifyError(
          'Something went wrong. Please, try again or contact support if the problem persists.'
        )
      )
      return
    }

    notify(notifySuccess('Email saved!'))

    setFlowTo(normalizePath(props.viewPath, '../No'))
    return
  }
}
