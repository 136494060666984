import { useMemo } from 'react'
import { formatAddress } from 'Data/format.js'

/** @type {import('Simple/types.js').useDataTransform} */
export default function useDataTransform(props, data) {
  return useMemo(() => {
    if (!data) return []

    let selected_option = []
    if (
      data?.selected_insurance_company &&
      !data.options.some(o => o.id === data?.selected_insurance_company?.id)
    ) {
      selected_option = data?.selected_insurance_company
    }

    return data.options.concat(selected_option).map(d => ({
      id: d.id,
      text: `${d.text} ${
        d.installment_interval === null ? `` : `(${d.installment_interval})`
      } ${
        d.continuation_claims_required ? 'Continuation Claims' : ''
      } ${formatAddress(d.postal_address)}`,
    }))
  }, [data])
}
