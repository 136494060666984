import { useDataValue } from 'Simple/Data.js'

export function useOnClick(props) {
  let patientUrl = useDataValue({
    context: 'match',
    viewPath: props.viewPath,
    path: 'external.patientUrl',
  })

  return function onClick() {
    window.open(patientUrl, '_blank')
  }
}
