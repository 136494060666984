import { useDataChange, useDataValue } from 'Simple/Data'

export function useOnClick(props) {
  let change = useDataChange({
    context: 'tab',
    viewPath: props.viewPath,
  })
  let nextMonth = useDataValue({
    context: 'resources',
    path: 'open_office_dates.next_month',
    viewPath: props.viewPath,
  })

  return function onClick() {
    change(next => {
      next.selected.date = nextMonth
    })
  }
}
