// this is an autogenerated file from App/Account/Content/Patients/Content/Content/Sections/ActiveTab/TreatmentCard/Content/TreatmentCard/Images/ActiveTab/ToothChart/data.graphql
import { gql } from 'Data/Api'

export default gql`
query app__App_Account_Content_Patients_Content_Content_Sections_ActiveTab_TreatmentCard_Content_TreatmentCard_Images_ActiveTab_ToothChart($patient_id: uuid!) {
  patients_tooth_chart_snapshots(args: { _patient_id: $patient_id }) {
    id
    _id
    display_data
    mime_type
    appointment {
      id
      _id
      type {
        id
        _id
        display_name
      }
      patient {
        id
        _id
      }
      booking {
        id
        _id
        local_start_date
      }
    }
  }
}

`