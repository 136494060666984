// this is an autogenerated file from DesignSystem/TimegridScheduleNote/mutation-edit-schedule-note.graphql
import { gql } from 'Data/Api'

export default gql`
mutation app__DesignSystem_TimegridScheduleNote(
  $id: uuid!
  $edit_input: vaxiom_schedule_notes_set_input!
  $insert_objects: [vaxiom_schedule_notes_insert_input!]!
) {
  update_vaxiom_schedule_notes(
    where: { _id: { _eq: $id } }
    _set: $edit_input
  ) {
    affected_rows
    returning {
      id
      start_time
      duration
      content
      alert_this_day
      is_blocking_time
    }
  }

  insert_vaxiom_schedule_notes(objects: $insert_objects) {
    affected_rows
    returning {
      id
    }
  }
}

`