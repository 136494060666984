import { range, formatNumber } from './format.js'
import parseISO from 'date-fns/parseISO'
import _formatDate from 'date-fns/format'
import isValidDate from 'date-fns/isValid'

export function rangeWithText(start, end, increment = 1) {
  return range(start, end, increment).map(value => ({ id: value, text: value }))
}

export function steppedRangeWithText(
  start,
  end,
  initialIncrement = 1,
  step = 7
) {
  return [
    ...new Set([
      ...range(start, start - 1 + step, initialIncrement),
      ...range(start + step - 1, end, step),
    ]),
  ].map(value => ({ id: value, text: value }))
}

export function timeRange(start, end, increment = 1) {
  return range(start, end, increment).map(value => ({
    id: value,
    text: formatNumber(value, '0#'),
  }))
}

export function containsItemWithId(list, item) {
  return Array.isArray(list) && list.some(litem => litem.id === item.id)
}

export function listEquals(a, b) {
  if (Array.isArray(a) && Array.isArray(b) && a.length === b.length) {
    return a.every(element => {
      if (b.includes(element)) {
        return true
      }

      return false
    })
  }

  return false
}

export function not(value, valueCompare) {
  return value !== valueCompare
}

export function join(...values) {
  return values.join(' ')
}

export function joinNoSpace(...values) {
  return values.join('')
}

export function any(...values) {
  return values.find(value => !!value || value === 0 || value === '')
}

export function none(...values) {
  return !any(...values)
}

export function attachmentsPrefix(parentCompanyId, threadId) {
  return `companies/${parentCompanyId}/threads/${threadId}/attachments`
}

export function fallbackIfSame(a, b, fallback) {
  return a === b ? fallback : a
}

export function equals(a, b) {
  if (!a || !b || (typeof a !== 'object' && typeof b !== 'object'))
    return a === b

  if (a instanceof Date && b instanceof Date) return a.getTime() === b.getTime()

  if (a.prototype !== b.prototype) return false

  if (
    (Array.isArray(a) && !Array.isArray(b)) ||
    (Array.isArray(b) && !Array.isArray(a))
  ) {
    return false
  }

  let keys = Object.keys(a)
  if (keys.length !== Object.keys(b).length) return false

  return keys.every(k => equals(a[k], b[k]))
}

export function equalLength(a, b) {
  return a?.length === b?.length
}

export function includes(list, value) {
  return Array.isArray(list) && list.includes(value)
}

export function notIncludes(list, value) {
  return Array.isArray(list) && !list.includes(value)
}

export function includesAll(list, values) {
  return (
    Array.isArray(list) &&
    Array.isArray(values) &&
    values.every(value => list.includes(value))
  )
}

export function slice(list, start, end) {
  return list.slice(start, end)
}

export function lengthGreaterThan(list, a) {
  return list.length > a
}

export function lengthEquals(list, a) {
  return list.length === a
}

export function truncate(str, limit = 30, ending = '...') {
  if (!str) return str
  if (str.length <= limit) return str
  let lastSpace = str.slice(0, limit - ending.length + 1).lastIndexOf(' ')
  return (
    str.slice(0, lastSpace > 0 ? lastSpace : limit - ending.length) + ending
  )
}

export function and(a, b) {
  return a && b
}

export function getValueOrDefault(value, defaultValue = '') {
  return value || defaultValue
}

export function firstN(list, n) {
  return slice(list, 0, n)
}
export function afterN(list, n) {
  return slice(list, n, list.length)
}

export function find(list, value, key = 'id') {
  return list?.find(item => item[key] === value)
}

export function localStartTimeWithFormat(value, format = 'h:mm a') {
  let date = parseISO('1970-01-01T' + value)

  return isValidDate(date) ? _formatDate(date, format) : value
}

export function subtract(a, b) {
  return a - b
}

export function add(a, b) {
  return a + b
}

/** @type {(a: any, b: any) => boolean} */
export function matchEquals(a, b) {
  if (typeof a !== 'string' || typeof b !== 'string') return false
  return a.trim().toLowerCase() === b.trim().toLowerCase()
}
