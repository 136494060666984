// this is an autogenerated file from DesignSystem/DataVaxiomRelationshipsItem/Edit/NonPatient/Postal/NewAddress/Content/Related/data.graphql
import { gql } from 'Data/Api'

export default gql`
query app__DesignSystem_DataVaxiomRelationshipsItem_Edit_NonPatient_Postal_NewAddress_Content_Related($person_id: numeric!) {
  vaxiom_relationships(
    where: { to_person_record: { id: { _eq: $person_id } } }
  ) {
    id
    type
    role
    to_person_record {
      id
      contact_method_associations {
        id
        contact_method_id
      }
    }
    from_person_record {
      id
      first_name
      middle_name
      last_name
      contact_method_associations(
        where: { contact_method: { dtype: { _eq: "postal" } } }
        order_by: { contact_order: asc_nulls_first }
      ) {
        id
        contact_method_id
        postal_address {
          id
          address_line1
          city
          state
          zip
        }
      }
    }
  }
}

`