// this is an autogenerated file from App/Account/Content/Patients/Content/Content/Sections/ActiveTab/Profile/Content/NewProfile/Content/Insurance/AssignedInsurance/Content/AssignedInsuranceItem/Subscription/Content/Subscription/data.graphql
import { gql } from 'Data/Api'

export default gql`
query app__App_Account_Content_Patients_Content_Content_Sections_ActiveTab_Profile_Content_NewProfile_Content_Insurance_AssignedInsurance_Content_AssignedInsuranceItem_Subscription_Content_Subscription($tx_category_insured_id: uuid!) {
  vaxiom_tx_category_insured(where: { _id: { _eq: $tx_category_insured_id } }) {
    id
    ortho_insured {
      _id
      id
      used_lifetime_coverage
      year_deductible_paid_last
      last_verified
    }
    insured {
      id
      insurance_subscription {
        id
        active
        employer_id
        enrollment_date
        insurance_plan_id
        member_id
        notes
        person_id
      }
      patient_id
    }
  }
}

`