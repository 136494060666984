import { useMemo } from 'react'
import { MEDICAL_HISTORY_CATEGORY_NAME } from 'Data/constants.js'
import { useDataValue } from 'Simple/Data.js'

let CATEGORY_ORDER = {
  general: 0,
  medical_conditions: 1,
  habits: 2,
  medications: 3,
  drugs_allergies_and_sensitivities: 4,
}

export default function useDataTransform(props, data) {
  let patient = useDataValue({
    viewPath: props.viewPath,
    context: 'patient',
    path: 'person',
  })

  return useMemo(() => {
    if (!data) return data

    return data
      .filter(item => item.questions.length > 0)
      .map(item => ({
        ...item,
        questions: formatQuestions(item.questions, patient),
      }))
      .sort((a, b) => CATEGORY_ORDER[a.id] - CATEGORY_ORDER[b.id])
  }, [data, patient])
}

export function formatQuestions(questions, patient) {
  let formattedQuestions = questions.flatMap(({ answers, ...rest }) => {
    let question_type = rest.type
    let answer = getAnswerValue(answers, question_type)
    let created_at = Array.isArray(answers) ? answers[0]?.created_at : null
    let user = Array.isArray(answers)
      ? getCreatedBy(answers[0]?.user?.vaxiom_person ?? patient)
      : null
    let alert = Array.isArray(answers) ? answers[0]?.alert : null
    if (
      MEDICAL_HISTORY_CATEGORY_NAME.habits === rest.name ||
      MEDICAL_HISTORY_CATEGORY_NAME.medical_conditions === rest.name
    ) {
      let options =
        typeof rest.options === 'string'
          ? rest.options.split(',').filter(Boolean)
          : rest.options

      if (!Array.isArray(options)) return []
      return options.map((option, idx) => {
        return {
          ...rest,
          parent_id: rest.id,
          parent_type: rest.type,
          parent_name: rest.name,
          parent_answer: answer,
          parent_alert: alert,
          id: rest.id + idx,
          name: option,
          type: 'boolean',
          answer: !!answer?.includes(option),
          original_answer: !!answer?.includes(option),
          alert: getAlertValueForSubtype(rest, option, alert),
          original_alert: getAlertValueForSubtype(rest, option, alert),
          created_at: created_at ? new Date(created_at) : null,
          user,
          historical_data: getHistoricalData({ answers, patient, option }),
        }
      })
    }

    return {
      ...rest,
      type: /(Chief concerns|Additional information)/.test(rest.name)
        ? 'textMultiLine'
        : rest.type,
      answer,
      original_answer: answer,
      alert: getAlertValue(rest, answer, alert),
      original_alert: getAlertValue(rest, answer, alert),
      created_at: created_at ? new Date(created_at) : null,
      user,
      historical_data: getHistoricalData({ answers, patient }),
    }
  })

  formattedQuestions.sort((a, b) => {
    if (a.type === 'multichoice' && b.type !== 'multichoice') {
      return 1
    }

    if (a.type !== 'multichoice' && b.type === 'multichoice') {
      return -1
    }

    return 0
  })

  return formattedQuestions
}

function getAnswerValue(answers, question_type) {
  if (Array.isArray(answers)) {
    let answer = answers[0]?.value

    if (answer !== null && answer !== undefined) {
      return answer
    } else if (['multichoice', 'list'].includes(question_type)) {
      return []
    }
  }

  return null
}

function getAlertValue({ alert_type, alert_conditions }, answer, alert) {
  if (alert !== undefined && alert !== null) {
    return alert
  }
  switch (alert_type) {
    case 'any':
      return true
    case 'values':
      return Array.isArray(alert_conditions)
        ? alert_conditions.includes(answer)
        : false
    default:
      return false
  }
}

function getAlertValueForSubtype(
  { alert_type, alert_conditions },
  answer,
  alert
) {
  if ([true, false].includes(alert)) {
    return alert
  }
  switch (alert_type) {
    case 'any':
      return true
    case 'values':
      return Array.isArray(alert)
        ? alert.includes(answer)
        : Array.isArray(alert_conditions)
        ? alert_conditions.includes(answer)
        : false
    default:
      return false
  }
}

function getCreatedBy(person) {
  return `${person.first_name} ${person.last_name}`
}

function getHistoricalData({ answers, patient, option }) {
  return answers
    .filter(answer => {
      let answerValue = answer.value
      return answerValue != null && answer.created_at != null
    })
    .map(answer => {
      let answerValue = answer.value
      return {
        answer: option ? answerValue.includes(option) : answerValue,
        created_at: new Date(answer?.created_at),
        user: getCreatedBy(answer?.user?.vaxiom_person ?? patient),
      }
    })
}
