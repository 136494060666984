import { useDataChange, useDataValue, useDataSubmit } from 'Simple/Data.js'
import { useClient } from 'Data/Api.js'
import { normalizePath, useSetFlowTo } from 'Simple/Flow.js'
import differenceInMinutes from 'date-fns/differenceInMinutes'
import { utcToZonedTime } from 'date-fns-tz'

import query_check_in_settings from './query_check_in_settings.graphql.js'
import query_patient_due_amount from './query_patient_due_amount.graphql.js'

export function useOnClick(props) {
  let patient_id = useDataValue({
    viewPath: props.viewPath,
    context: 'patient',
    path: '_id',
  })
  let appointment_booking = useDataValue({
    context: 'event',
    path: 'appointment.booking.0',
    viewPath: props.viewPath,
  })
  let time_zone_id = useDataValue({
    context: 'event',
    path: 'appointment.booking.0.location.time_zone_id',
    viewPath: props.viewPath,
  })

  let change = useDataChange({
    context: 'check_in',
    viewPath: props.viewPath,
  })

  let submit = useDataSubmit({
    context: 'bottom_action',
    viewPath: props.viewPath,
  })

  let client = useClient()
  let setFlowTo = useSetFlowTo(props.viewPath)

  return async function onClick() {
    let querySettingsResponse = await client
      .query(query_check_in_settings, {
        current_location_id: appointment_booking.location._id,
      })
      .toPromise()

    let settings =
      querySettingsResponse?.data?.vaxiom_location_access_keys?.[0]?.settings

    if (settings) {
      let minutes_late = differenceInMinutes(
        utcToZonedTime(new Date(), time_zone_id),
        new Date(`${appointment_booking.start}`)
      )
      let is_patient_too_late =
        settings.max_minutes_late && minutes_late > settings.max_minutes_late

      let queryDueAmountResponse = await client
        .query(query_patient_due_amount, {
          patient_id,
        })
        .toPromise()

      let has_billing_past_due = hasBillingPastDue(
        queryDueAmountResponse.data.financial_patient_due_amount,
        settings
      )

      if (is_patient_too_late || has_billing_past_due) {
        change(next => {
          next.is_patient_too_late = is_patient_too_late
          next.has_billing_past_due = has_billing_past_due
        })
        setFlowTo(normalizePath(props.viewPath, 'ConfirmationDialog/Content'))
        return
      }
    }

    submit({ type: 'checkIn' })
  }
}

function hasBillingPastDue(due_amounts, settings) {
  if (settings.due_period === 'ANY') return false

  let due_amounts_by_private_pay = getSum(
    due_amounts.filter(
      value => value.payment_account.account_type === 'PersonPaymentAccount'
    )
  )
  let due_amounts_by_insurance = getSum(
    due_amounts.filter(
      value => value.payment_account.account_type === 'InsurancePaymentAccount'
    )
  )
  let has_bills_past_due_for_private_pay =
    getDueAmountByType(due_amounts_by_private_pay, settings.due_period) >
    settings.max_due_amount
  let has_bills_past_due_for_insurance =
    getDueAmountByType(
      due_amounts_by_insurance,
      settings.due_period_insurance
    ) > settings.max_due_amount_insurance

  return has_bills_past_due_for_private_pay || has_bills_past_due_for_insurance
}

function getDueAmountByType(value, type) {
  switch (type) {
    case 'UNDER_30':
      return value.due_now
    case 'OVER_30':
      return value.due_30_days
    case 'OVER_60':
      return value.due_60_days
    case 'OVER_90':
      return value.due_90_days
    case 'OVER_120':
      return value.due_120_days
    default:
      return 0
  }
}

function getSum(amounts) {
  return amounts.reduce(
    (acc, value) => ({
      due_now: acc.due_now + value.due_now,
      due_30_days: acc.due_30_days + value.due_30_days,
      due_60_days: acc.due_60_days + value.due_60_days,
      due_90_days: acc.due_90_days + value.due_90_days,
      due_120_days: acc.due_120_days + value.due_120_days,
    }),
    {
      due_now: 0,
      due_30_days: 0,
      due_60_days: 0,
      due_90_days: 0,
      due_120_days: 0,
    }
  )
}
