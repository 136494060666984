import { useSetFlowTo, normalizePath } from 'Simple/Flow.js'
import mutation from './mutation.graphql.js'
import { useMutation } from 'Data/Api.js'
import {
  notifySuccess,
  notifyError,
  useNotifications,
} from 'Logic/Notifications.js'
import { email, phoneNumber } from 'Data/validate.js'

function validate(value) {
  let isInvalid = false
  if (!value.name || !value.payer_id) {
    isInvalid = true
  }
  if (value.contact_email?.address && !email(value.contact_email?.address)) {
    isInvalid = true
  }

  if (
    value.contact_phone?.number &&
    !phoneNumber(value.contact_phone?.number)
  ) {
    isInvalid = true
  }
  return isInvalid
}

function useDataSave(props) {
  let setFlowTo = useSetFlowTo(props.viewPath)
  let [, executeMutation] = useMutation(mutation)
  let [, notify] = useNotifications()

  return async function onSubmit(value) {
    if (validate(value)) {
      return true
    }
    let mutationResponse = await executeMutation({
      billing_center_id: value.id,
      name: value.name,
      payer_id: value.payer_id,
      postal_id: value.contact_postal_address?.id,
      postal_address: {
        address_line1: value.contact_postal_address?.address_line1,
        zip: value.contact_postal_address?.zip,
        city: value.contact_postal_address?.city,
        state: value.contact_postal_address?.state,
      },
      phone_id: value.contact_phone?.id,
      number: value.contact_phone?.number,
      email_id: value.contact_email?.id,
      email: value.contact_email?.address,
    })
    if (mutationResponse.error) {
      notify(
        notifyError(
          `There was a problem updating the billing center. Please try again.`
        )
      )
      return
    }
    notify(notifySuccess(`Billing center edited.`))
    setFlowTo(normalizePath(props.viewPath, '../No'))
  }
}

/** @type {import('Simple/types.js').useDataOnSubmit} */
export default function useDataOnSubmit(props, data) {
  let setFlowTo = useSetFlowTo(props.viewPath)
  let onSave = useDataSave(props)
  return async function onSubmit({ value, originalValue, args, change }) {
    switch (args?.type) {
      case 'cancel': {
        setFlowTo(normalizePath(props.viewPath, '../No'))
        return
      }
      case 'submit': {
        return onSave(value)
      }
      default: {
        return
      }
    }
  }
}
