import { useSetFlowTo, normalizePath } from 'Simple/Flow.js'
import { useMutation, useClient } from 'Data/Api'
import { useDataValue } from 'Simple/Data'
import { street, state, city, zip } from 'Data/validate.js'
import {
  notifyError,
  useNotifications,
  notifySuccess,
} from 'Logic/Notifications.js'
import mutation_add_person_contact_methods from './addPersonContactMethodsMutation.graphql.js'
import mutation_edit_person_contact_method from './editPersonContactMethodMutation.graphql.js'
import query from './query.graphql.js'

export default function useDataOnSubmit(props, data) {
  let onActionNewAddressForPatient = useDataOnActionNewAddressForPatient(props)
  let onActionEditAddress = useDataOnActionEditAddress(props)
  let onActionFormSubmit = useDataOnActionFormSubmit(props)

  return async function onSubmit({ value, args }) {
    switch (args?.type) {
      case 'new_address_for_patient': {
        return onActionNewAddressForPatient({ value, args })
      }
      case 'form_submit': {
        return onActionFormSubmit({ value, args })
      }
      default: {
        onActionEditAddress({ value })
      }
    }
  }
}

function useDataOnActionFormSubmit(props) {
  let setFlowTo = useSetFlowTo(props.viewPath)
  let client = useClient()
  let [, notify] = useNotifications()

  let [, executeMutationEditPersonContactMehtod] = useMutation(
    mutation_edit_person_contact_method
  )
  let person_id = useDataValue({
    viewPath: props.viewPath,
    context: 'edit_relation',
    path: 'from_person',
  })

  return async function onAction({ value: address, args }) {
    if (
      !street(address.postal_address.address_line1) ||
      !city(address.postal_address.city) ||
      !state(address.postal_address.state) ||
      !zip(address.postal_address.zip)
    ) {
      return true
    }

    let { data } = await client
      .query(query, {
        contact_method_id: address.contact_method_id,
        person_id,
      })
      .toPromise()

    if (
      data?.vaxiom_contact_method_associations_aggregate?.aggregate.count === 0
    ) {
      let mutationEditPersonContactMehtodResponse =
        await executeMutationEditPersonContactMehtod({
          person_id,
          contact_method: {
            contact_method_association_id: address.id,
            id: address.postal_address.id,
            description: address.description,
            dtype: 'postal',
            data: {
              id: address.postal_address.id,
              address_line1: address.postal_address.address_line1,
              city: address.postal_address.city,
              state: address.postal_address.state,
              zip: address.postal_address.zip,
            },
          },
        })
      if (mutationEditPersonContactMehtodResponse.error) {
        notify(notifyError('Something went wrong. Please, try again.'))
        return
      }
      notify(notifySuccess('Address updated!'))
      setFlowTo(normalizePath(props.viewPath, '../No'))
    } else {
      setFlowTo(normalizePath(props.viewPath, './EditOrAdd/Content'))
    }
  }
}

function useDataOnActionEditAddress(props) {
  let setFlowTo = useSetFlowTo(props.viewPath)
  let [, notify] = useNotifications()

  let [, executeMutationEditPersonContactMehtod] = useMutation(
    mutation_edit_person_contact_method
  )

  let person_id = useDataValue({
    viewPath: props.viewPath,
    context: 'edit_relation',
    path: 'from_person',
  })

  return async function onAction({ value: address, args }) {
    let mutationEditPersonContactMehtodResponse =
      await executeMutationEditPersonContactMehtod({
        person_id,
        contact_method: {
          contact_method_association_id: address.id,
          id: address.postal_address.id,
          description: address.description,
          dtype: 'postal',
          data: {
            id: address.postal_address.id,
            address_line1: address.postal_address.address_line1,
            city: address.postal_address.city,
            state: address.postal_address.state,
            zip: address.postal_address.zip,
          },
        },
      })
    if (mutationEditPersonContactMehtodResponse.error) {
      notify(notifyError('Something went wrong. Please, try again.'))
      return
    }
    notify(notifySuccess('Address updated!'))
    setFlowTo(normalizePath(props.viewPath, '../No'))
  }
}

function useDataOnActionNewAddressForPatient(props) {
  let setFlowTo = useSetFlowTo(props.viewPath)
  let [, notify] = useNotifications()

  let [, executeMutationAddPersonContactMethods] = useMutation(
    mutation_add_person_contact_methods
  )

  let person_id = useDataValue({
    viewPath: props.viewPath,
    context: 'edit_relation',
    path: 'from_person',
  })
  return async function onAction({ value: address }) {
    // if the address doesn't exist,
    // and we don't want to add a relation,
    // we just save the new address and add
    // a new association
    let mutationAddPersonContactMethods =
      await executeMutationAddPersonContactMethods({
        person_id,
        contact_method_id: address.contact_method_id,
        contact_methods: [
          {
            dtype: 'postal',
            data: {
              ...address.postal_address,
            },
          },
        ],
      })

    if (mutationAddPersonContactMethods.error) {
      notify(
        notifyError(
          'Something went wrong. Please, try again or contact support if the problem persists.'
        )
      )
      return
    }

    notify(notifySuccess('Address saved!'))

    setFlowTo(normalizePath(props.viewPath, '../No'))
    return
  }
}
