import { useDataValue } from 'Simple/Data.js'
import { dateShortOut } from 'Data/format.js'

export default function useDataConfiguration(props) {
  let date = useDataValue({
    context: 'tab',
    path: 'selected.date',
    viewPath: props.viewPath,
  })
  let chairs = useDataValue({
    context: 'resources',
    path: 'chairs',
    viewPath: props.viewPath,
  })
  let shortDate = dateShortOut(date)

  return {
    variables: {
      chair_ids: chairs.map(item => item.id),
      date: shortDate,
      start_min: `${shortDate}T00:00:00`,
      start_max: `${shortDate}T23:59:59`,
    },
    pause: !date || !chairs.length,
  }
}
