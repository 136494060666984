import { useDataValue } from 'Simple/Data.js'
import { useMutation } from 'Data/Api.js'
import { notifyError, useNotifications } from 'Logic/Notifications.js'
import { useSetFlowTo, normalizePath } from 'Simple/Flow.js'

import mutation from './mutation.graphql.js'

/** @type {import('Simple/types.js').useDataOnSubmit} */
export default function useDataOnSubmit(props, data) {
  let treatment_plan = useDataValue({
    context: 'treatment_plan',
    viewPath: props.viewPath,
  })
  let person_id = useDataValue({
    context: 'request',
    path: 'person.id',
    viewPath: props.viewPath,
  })
  let setFlowTo = useSetFlowTo(props.viewPath)
  let [, notify] = useNotifications()
  let [, executeMutation] = useMutation(mutation)

  return async function onSubmit({ value, args, change }) {
    let mutationResponse = await executeMutation({
      treatment_plan_id: treatment_plan.id,
      person_id,
    })
    if (mutationResponse?.error) {
      notify(
        notifyError(
          'There was a problem creating the contract. Please try again.'
        )
      )
      return
    }

    setFlowTo(normalizePath(props.viewPath, '../Contract/Content'))
  }
}
