import { useDataValue } from 'Simple/Data.js'
import { useMemo } from 'react'

/** @type {import('Simple/types.js').useDataTransform} */
export default function useDataTransform(props, data) {
  let location_id = useDataValue({
    viewPath: props.viewPath,
    context: 'selected',
    path: 'location_id',
  })

  return useMemo(() => {
    if (!data) return data

    return (
      data[0] || {
        id: null,
        location_id,
        data: {
          default_view: 'timeline',
          compact_view_notes: 'compact',
          is_financials_managed_externally: false,
          minimum_downpayment_amount: 0,
          default_downpayment_amount: 0,
          maximum_payment_plan_length: 60,
        },
      }
    )
  }, [data, location_id])
}
