import { useDataValue, useDataChange } from 'Simple/Data.js'

export function useOnClick(props, type) {
  let downpayment_type = useDataValue({
    viewPath: props.viewPath,
    context: 'plan_details',
    path: 'downpayment_type',
  })

  let change = useDataChange({
    viewPath: props.viewPath,
    context: 'plan_details',
  })

  return function onClick() {
    change(next => {
      next.downpayment_type =
        downpayment_type === 'FIXED' ? 'PERCENTAGE' : 'FIXED'
    })
  }
}
