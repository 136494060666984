// this is an autogenerated file from App/Account/Content/Patients/Content/Content/Sections/ActiveTab/Profile/Content/NewProfile/addCommsPreferencesMutation.graphql
import { gql } from 'Data/Api'

export default gql`
mutation app__App_Account_Content_Patients_Content_Content_Sections_ActiveTab_Profile_Content_NewProfile($patient_id: numeric!, $association_id: numeric!) {
  insert_vaxiom_communication_preferences_one(
    object: {
      patient_id: $patient_id
      contact_method_association_id: $association_id
      type: "APPOINTMENT_MESSAGES"
    }
  ) {
    id
  }
}

`