// this is an autogenerated file from App/Account/Content/Patients/Content/Content/Sections/ActiveTab/TreatmentCard/Content/TreatmentPlans/New/Steps/Content/Contracts/Content/Payor/Content/ContractTemplates/data.graphql
import { gql } from 'Data/Api'

export default gql`
query app__App_Account_Content_Patients_Content_Content_Sections_ActiveTab_TreatmentCard_Content_TreatmentPlans_New_Steps_Content_Contracts_Content_Payor_Content_ContractTemplates($organization_id: uuid!) {
  vaxiom_document_templates(
    where: {
      organization: { _id: { _eq: $organization_id } }
      is_contract: { _eq: true }
    }
    order_by: { name: asc_nulls_last }
  ) {
    id
    text: name
  }
}

`