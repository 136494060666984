import { useDataValue, useDataSubmit } from 'Simple/Data.js'

export function useOnClick(props) {
  let slotInterval = useDataValue({
    context: 'slot_interval',
    path: 'id',
    viewPath: props.viewPath,
  })
  let selectedSlotInterval = useDataValue({
    context: 'settings',
    path: 'slot_interval',
    viewPath: props.viewPath,
  })
  let submit = useDataSubmit({
    context: 'settings',
    viewPath: props.viewPath,
  })

  return function onClick() {
    if (selectedSlotInterval === slotInterval || typeof props.id !== 'number')
      return
    return submit({
      key: 'slot_interval',
      value: props.id,
    })
  }
}
