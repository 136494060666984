// this is an autogenerated file from DesignSystem/DataVaxiomEmployeeProfessionalsRelationshipsItem/Edit/Content/Phone/NewPhone/Content/newContactMethodAssociationMutation.graphql
import { gql } from 'Data/Api'

export default gql`
mutation app__DesignSystem_DataVaxiomEmployeeProfessionalsRelationshipsItem_Edit_Content_Phone_NewPhone_Content(
  $contact_method_id: numeric!
  $person_id: numeric!
) {
  insert_vaxiom_contact_method_associations_one(
    object: { person_id: $person_id, contact_method_id: $contact_method_id }
  ) {
    id
    contact_method_id
  }
}

`