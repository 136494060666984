export function getView(state) {
  switch (state) {
    case 'SCHEDULED':
      return 'Scheduled'
    case 'OFFICE_CANCELLED':
    case 'PATIENT_CANCELLED':
      return 'Cancelled'
    case 'NO_SHOW':
      return 'NoShow'
    case 'UNSEATED':
      return 'Unseated'
    case 'CHECKED_OUT':
      return 'CheckedOut'
    case 'CHECKED_IN':
      return 'CheckedIn'
    case undefined:
      return 'Unscheduled'
    default:
      return 'No'
  }
}
