// this is an autogenerated file from App/Account/Content/Patients/Content/Content/Sections/ActiveTab/Images/Content/Content/Content/Content/ImportFromInvisalignPracticeApp/Content/data.graphql
import { gql } from 'Data/Api'

export default gql`
query app__App_Account_Content_Patients_Content_Content_Sections_ActiveTab_Images_Content_Content_Content_Content_ImportFromInvisalignPracticeApp_Content($patient_id: uuid!) {
  apps_connection_patients(
    where: {
      patient_id: { _eq: $patient_id }
      # TODO: ideally it should also check the app_id
      connection: {
        app: { metadata: { _contains: { tags: ["ids"] } } }
        status: { _eq: active }
      }
    }
  ) {
    id
    connection {
      id
      app_id
    }
  }
}

`