import { useDataValue, useDataSubmit } from 'Simple/Data.js'

export function useOnClick(props) {
  let event = useDataValue({
    viewPath: props.viewPath,
    context: 'event',
  })
  let submit = useDataSubmit({
    viewPath: props.viewPath,
    context: 'tab',
  })

  return () => {
    if (event.selected) {
      submit({ type: 'scheduling/deselectAnySlot' })
    } else {
      submit({
        type: 'scheduling/selectTemplatedSlot',
        id: event.id,
        focus: false,
      })
    }
  }
}
