import { useMutation } from 'Data/Api.js'
import { notifyError, useNotifications } from 'Logic/Notifications.js'

import mutationCreateContract from './mutation-create-contract.graphql.js'

/** @type {import('Simple/types.js').useDataOnSubmit} */
export default function useDataOnSubmit(props, data, reExecuteQuery) {
  let onChangeContractTemplate = useOnChangeContractTemplate(props)

  return async function onSubmit({ value, originalValue, args, change }) {
    switch (args.type) {
      case 'reFetch':
        return reExecuteQuery({ requestPolicy: 'network-only' })

      case 'change-contract-template':
        return onChangeContractTemplate({
          value,
          originalValue,
          args,
          change,
          reExecuteQuery,
        })

      default:
        return
    }
  }
}

function useOnChangeContractTemplate(props) {
  let [, notify] = useNotifications()
  let [, executeMutationCreateContract] = useMutation(mutationCreateContract)

  return async function onSubmit({ value, args, change, reExecuteQuery }) {
    if (value.contract.document_template_id === args.document_template_id) {
      return
    }

    let mutationResponse = await executeMutationCreateContract({
      treatment_plan_id: value.treatment_plan_id,
      person_id: value.person_id,
      document_template_id: args.document_template_id,
    })

    if (mutationResponse.error) {
      notify(
        notifyError(
          'There was a problem regenerating the contract. Please try again.'
        )
      )
      return
    }

    reExecuteQuery({ requestPolicy: 'network-only' })
  }
}
