import { normalizePath, useSetFlowTo } from 'Simple/Flow.js'
import { useDataValue } from 'Simple/Data.js'
export function useOnClick(props) {
  let setFlowTo = useSetFlowTo(props.viewPath)
  let billing_center = useDataValue({
    context: 'plan_details',
    viewPath: props.viewPath,
    path: 'billing_center',
  })
  return function onClick(action) {
    switch (action) {
      case 'EDIT':
        if (billing_center !== null) {
          return editBillingCenter()
        }
        return
      case 'ADD':
        return addBillingCenter()
      default:
        return
    }
  }

  function editBillingCenter() {
    setFlowTo(normalizePath(props.viewPath, './Edit/Content'))
  }
  function addBillingCenter() {
    setFlowTo(normalizePath(props.viewPath, './Create/Content'))
  }
}
