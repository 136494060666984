// this is an autogenerated file from DesignSystem/TimelineEvent/CustomResource/data.graphql
import { gql } from 'Data/Api'

export default gql`
query app__DesignSystem_TimelineEvent_CustomResource($id: uuid!) {
  apps_custom_resources_by_pk(id: $id) {
    id
    type
    data
    created_at
    updated_at
    connection {
      id
      app_id
    }
  }
  apps_displays(type: ADMIN_PATIENT_TREATMENT_TIMELINE) {
    id
    displays
  }
}

`