import { useDataSubmit } from 'Simple/Data.js'

export function useOnClick(props) {
  let submit = useDataSubmit({
    context: 'match',
    viewPath: props.viewPath,
  })

  return function onClick(id) {
    switch (id) {
      case 'REMOVE_MATCH': {
        submit({ type: 'REMOVE_MATCH' })
        break
      }

      default: {
        break
      }
    }
  }
}
