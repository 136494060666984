// this is an autogenerated file from App/Account/Content/Patients/Content/Content/Sections/ActiveTab/TreatmentCard/Content/TreatmentPlans/New/Steps/Content/PaymentPlans/Content/Content/PaymentPlan/Content/Content/Send/Content/Url/data.graphql
import { gql } from 'Data/Api'

export default gql`
query app__App_Account_Content_Patients_Content_Content_Sections_ActiveTab_TreatmentCard_Content_TreatmentPlans_New_Steps_Content_PaymentPlans_Content_Content_PaymentPlan_Content_Content_Send_Content_Url($treatment_plan_id: uuid!) {
  treatments_requests(
    where: {
      tx_plan_id: { _eq: $treatment_plan_id }
      status: { _neq: cancelled }
    }
  ) {
    id
    url
  }
}

`