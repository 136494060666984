import { useMemo } from 'react'
import { STATES } from 'Data/constants.js'

export default function useDataTransform(props, data) {
  return useMemo(() => {
    if (!data) return data

    return STATES.sort((a, b) => a.text.localeCompare(b.text))
  }, [data])
}
