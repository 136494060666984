import { useDataSubmit, useDataValue } from 'Simple/Data.js'
import { timeToMinutes } from 'Data/format.js'

/** @type {import('Simple/types.js').useDataOnChange} */
export default function useDataOnChange(props, data) {
  let submit = useDataSubmit({
    viewPath: props.viewPath,
    context: 'appointment_overlay',
  })
  let is_external_any = useDataValue({
    viewPath: props.viewPath,
    context: 'appointment_overlay',
    path: 'is_external_any',
  })

  return async function onChange(next) {
    if (is_external_any) return

    let preferred = next?.filter(v => v.group === 'unscheduled')?.[0]

    if (preferred) {
      submit({
        type: 'setExistingAppointment',
        id: preferred._id,
        type_id: preferred.type._id,
        treatment_id: preferred.tx._id,
        duration: preferred.type.appointment_templates?.[0]?.duration ?? null,
        ...(preferred.booking
          ? {
              preselect_scheduling_slot: {
                start_min: timeToMinutes(preferred.booking.local_start_time),
                date: preferred.booking.local_start_date,
                chair_id: preferred.booking.chair._id,
              },
            }
          : {}),
      })
    }
  }
}
