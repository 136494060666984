import { useDataValue } from 'Simple/Data.js'

export default function useDataConfiguration(props) {
  let patient_id = useDataValue({
    context: 'selected',
    path: 'patient_id',
    viewPath: props.viewPath,
  })
  let include_professionals = useDataValue({
    context: 'selected',
    path: 'include_professionals',
    viewPath: props.viewPath,
  })
  let include_external_professionals = useDataValue({
    context: 'selected',
    path: 'include_external_professionals',
    viewPath: props.viewPath,
  })

  return {
    variables: {
      id: patient_id,
      include_professionals,
      include_external_professionals,
    },
    pause: !patient_id,
  }
}
