import { useDataValue, useDataSubmit } from 'Simple/Data'
import { normalizePath, useSetFlowTo } from 'Simple/Flow.js'

export function useOnClick(props) {
  let setFlowTo = useSetFlowTo(props.viewPath)

  let action = useDataValue({
    context: 'option',
    viewPath: props.viewPath,
    path: 'id',
  })

  let email = useDataValue({
    context: 'email',
    viewPath: props.viewPath,
  })

  let submit = useDataSubmit({
    context: 'emails',
    viewPath: props.viewPath,
  })

  return function onClick() {
    props.closePopover()
    switch (action) {
      case 'DELETE':
        return deleteAddress()
      case 'EDIT':
        return editAddress()
      default: {
        submit({
          type: action,
          email,
        })
      }
    }
  }

  function deleteAddress() {
    setFlowTo(normalizePath(props.viewPath, '../../../Delete/Content'))
  }

  function editAddress() {
    setFlowTo(normalizePath(props.viewPath, '../../../Edit/Content'))
  }
}
