import { useSetFlowTo, normalizePath } from 'Simple/Flow.js'
import { useDataSubmit } from 'Simple/Data'

export default function useDataOnSubmit(props) {
  let submit = useDataSubmit({
    context: 'bottom_action',
    viewPath: props.viewPath,
  })

  let setFlowTo = useSetFlowTo(props.viewPath)

  return async function onSubmit({ value }) {
    submit({ type: 'seat', ...value })
    setFlowTo(normalizePath(props.viewPath, '../No'))
  }
}
