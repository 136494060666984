// this is an autogenerated file from App/Account/Content/Settings/AdminContent/SettingsContent/TreatmentCaseCloser/Configuration/PaymentMethods/mutation.graphql
import { gql } from 'Data/Api'

export default gql`
mutation app__App_Account_Content_Settings_AdminContent_SettingsContent_TreatmentCaseCloser_Configuration_PaymentMethods(
  $organization_id: numeric!
  $upsert_feature_flag: vaxiom_application_properties_insert_input!
  $payment_plan: treatments_default_payment_plans_insert_input!
  $with_payment_plan: Boolean!
) {
  insert_vaxiom_application_properties_one(
    object: $upsert_feature_flag
    on_conflict: {
      constraint: idx_364469_primary
      update_columns: [message_value]
    }
  ) {
    organization_id
    message_key
    message_value
  }
  delete_treatments_default_payment_plans(
    where: {
      organization: { id: { _eq: $organization_id } }
      name: {
        _in: [
          "Pay over time with Wisetack"
          "Apply to pay over time with HFD Financing"
        ]
      }
    }
  ) {
    affected_rows
    returning {
      id
    }
  }

  insert_treatments_default_payment_plans_one(object: $payment_plan)
    @include(if: $with_payment_plan) {
    id
  }
}

`