import { useDataValue } from 'Simple/Data.js'

export function useOnClick(props) {
  let id = useDataValue({
    context: 'match',
    viewPath: props.viewPath,
    path: 'internal.id',
  })

  return function onClick() {
    window.open(
      `${window.location.protocol}//${window.location.host}/patients/${id}`,
      '_blank'
    )
  }
}
