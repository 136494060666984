import { useDataSubmit } from 'Simple/Data'

export function useOnClick(props) {
  let submit = useDataSubmit({
    viewPath: props.viewPath,
    context: 'new_phone',
  })
  return function useOnClick(value) {
    submit({ type: 'new_phone_for_patient' })
  }
}
