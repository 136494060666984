export function showMarkAsEmergency(appointment_overlay) {
  return (
    appointment_overlay.patient_option === 'existing' &&
    !appointment_overlay.appointment_id
  )
}

export function showFields(appointment_overlay) {
  return (
    appointment_overlay.patient_option === 'new' ||
    appointment_overlay.existing_patient_no_treatments === false
  )
}

export function showCreateTreatmentAction(appointment_overlay) {
  return (
    appointment_overlay.patient_option === 'existing' &&
    appointment_overlay.existing_patient_no_treatments === true
  )
}

export function showAppointmentSelect(appointment_overlay) {
  return (
    appointment_overlay.treatment_id &&
    !appointment_overlay.is_external_appointment_id &&
    !appointment_overlay.is_external_treatment_id
  )
}
