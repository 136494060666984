import { useDataValue, useDataChange } from 'Simple/Data.js'

export function useOnClickOutsideClose(props) {
  let change = useDataChange({
    context: 'tab',
    path: 'selected.topbar_appointment_templates',
    viewPath: props.viewPath,
  })
  let settings = useDataValue({
    context: 'settings',
    viewPath: props.viewPath,
  })

  return function onClickOutsideClose() {
    change(settings.selected_appointment_templates)
  }
}
