// this is an autogenerated file from App/Account/Content/Settings/AdminContent/SettingsContent/TreatmentCaseCloser/Configuration/Autodraft/AddRuleset/mutation.graphql
import { gql } from 'Data/Api'

export default gql`
mutation app__App_Account_Content_Settings_AdminContent_SettingsContent_TreatmentCaseCloser_Configuration_Autodraft_AddRuleset(
  $organization_id: uuid!
  $payment_methods: jsonb!
) {
  insert_treatments_autodraft_payment_methods_one(
    object: {
      organization_id: $organization_id
      payment_methods: $payment_methods
    }
  ) {
    id
  }
}

`