import { useMemo } from 'react'
import { useDataValue } from 'Simple/Data.js'
import { FILTERS, getType } from './helpers.js'

/** @type {import('Simple/types.js').useDataTransform} */
export default function useDataTransform(props, data) {
  let filters = useDataValue({
    context: 'filter',
    viewPath: props.viewPath,
  })
  let related_plans = useDataValue({
    context: 'related_insurance_plans',
    viewPath: props.viewPath,
  })

  return useMemo(() => {
    // return related plans in first render
    if (!data) return related_plans.length > 0 ? related_plans : null

    switch (getType(filters)) {
      case FILTERS.EMPLOYEE:
        return data.plans_with_employer_id
      case FILTERS.CARRIER:
        return data.plans_with_carrier_id
      case FILTERS.NUMBER:
        return data.plans_by_filters
    }
  }, [data])
}
