import { useDataChange, useDataValue } from 'Simple/Data.js'
export function getValue(options, id) {
  return options.some(item => item.id === id) ? id : ''
}

export function useOnChange(props) {
  let change = useDataChange({
    context: 'employer',
    viewPath: props.viewPath,
  })

  let employers = useDataValue({
    context: 'employers',
    viewPath: props.viewPath,
  })

  return function onChange(value) {
    let emp = employers?.find(e => e.id === value)
    change(next => {
      next.name = emp?.text
      next.id = value
    })
  }
}
