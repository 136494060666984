import { useDataValue, useDataChange } from 'Simple/Data.js'
import { dateISOIn, phoneNumberUS } from 'Data/format.js'

export function getName(person) {
  return `${person.first_name} ${person.last_name}, ${
    person.gender
  }, ${dateISOIn(person.birth_date)}`
}

/** @type {(person: any) => string | null} */
export function maybeGetPhone(person) {
  let phone = person?.contact_method_associations.find(item => item.phone)
    ?.phone?.number

  return phone ? phoneNumberUS(phone) : null
}
/** @type {(person: any) => string | null} */
export function maybeGetEmail(person) {
  return (
    person?.contact_method_associations.find(item => item.email)?.email
      ?.address || null
  )
}
/** @type {(person: any) => string | null} */
export function maybeGetZip(person) {
  return (
    person?.contact_method_associations.find(item => item.postal_address)
      ?.postal_address?.zip || null
  )
}

export function useOnClick(props) {
  let value = useDataValue({
    context: 'person',
    viewPath: props.viewPath,
  })
  let change = useDataChange({
    context: 'replace',
    viewPath: props.viewPath,
    path: 'selected_patient_id',
  })

  return function onClick() {
    change(next => {
      if (next.selected_patient?.id === value.patient._id) {
        next.selected_patient = null
      } else {
        next.selected_patient = {
          id: value.patient._id,
          firstName: value.first_name,
          lastName: value.last_name,
          gender: value.gender,
          birthDate: value.birth_date,
          phone: maybeGetPhone(value),
          email: maybeGetEmail(value),
          zip: maybeGetZip(value),
        }
      }
    })
  }
}
