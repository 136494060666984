import { useMemo } from 'react'
import { useDataValue } from 'Simple/Data'

/** @type {import('Simple/types.js').useDataTransform} */
export default function useDataTransform(props, data) {
  let automation_type = useDataValue({
    context: 'automation',
    path: 'automation_type',
    viewPath: props.viewPath,
  })
  let resources = useDataValue({
    context: 'resources',
    path: 'resources',
    viewPath: props.viewPath,
  })

  return useMemo(() => {
    return data
      .filter(({ supported_automation_types }) =>
        supported_automation_types.includes(automation_type)
      )
      .filter(
        item => item.type !== 'custom_resource_insert' || resources?.length > 0
      )
  }, [data, automation_type, resources])
}
