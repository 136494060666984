/**
 *
 * @param {number} free_slots
 * @returns
 */
export function getText(free_slots) {
  return free_slots === 0
    ? 'No slots available'
    : free_slots === 1
    ? '1 slot available'
    : free_slots.toString() + ' slots available'
}
