import { useDataSubmit } from 'Simple/Data.js'
export function useOnClick(props) {
  let submit = useDataSubmit({
    context: 'relationship',
    viewPath: props.viewPath,
  })
  return function onClick() {
    return submit({ type: 'addRelationship' })
  }
}
