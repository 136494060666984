// this is an autogenerated file from App/Account/Content/Patients/Content/Content/Sections/ActiveTab/TreatmentCard/Content/TreatmentCard/TxPlanNotes/data.graphql
import { gql } from 'Data/Api'

export default gql`
query app__App_Account_Content_Patients_Content_Content_Sections_ActiveTab_TreatmentCard_Content_TreatmentCard_TxPlanNotes($treatment_card_id: uuid!) {
  vaxiom_tx_cards(where: { _id: { _eq: $treatment_card_id } }) {
    id
    _id
  }
}

`