export function getText(payment_plan) {
  if (payment_plan.insurances.some(insurance => !insurance.insured_id)) {
    return 'Please select an insurance subscription'
  } else if (payment_plan.payors.length !== 1) {
    return 'Treatment plan requests could only be sent for a single payor'
  } else if (payment_plan.payors.some(payor => !payor.person_id)) {
    return 'Please select a payor'
  } else {
    return 'Creating treatment plan request...'
  }
}
