// this is an autogenerated file from App/Account/Content/Settings/AdminContent/SettingsContent/TreatmentCaseCloser/Configuration/Frequencies/AddRuleset/Frequencies/Frequency/Details/Content/DocumentTemplate/data.graphql
import { gql } from 'Data/Api'

export default gql`
query app__App_Account_Content_Settings_AdminContent_SettingsContent_TreatmentCaseCloser_Configuration_Frequencies_AddRuleset_Frequencies_Frequency_Details_Content_DocumentTemplate($organization_ids: [uuid!]!) {
  vaxiom_document_templates(
    where: {
      organization: { _id: { _in: $organization_ids } }
      is_contract: { _eq: true }
    }
    order_by: { name: asc }
  ) {
    _id
    name
    organization {
      _id
      name
      level
    }
  }
}

`