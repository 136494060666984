import React from 'react'
import View from './view.js'
import { useDataChange } from 'Simple/Data.js'

export default function Logic(props) {
  let change = useDataChange({
    viewPath: props.viewPath,
    context: 'selected',
    path: 'search',
  })
  return <View {...props} onChangeFilter={change} />
}
