// this is an autogenerated file from App/Account/Content/Patients/Content/Content/Sections/ActiveTab/Profile/Content/NewProfile/Content/Relations/AddRelation/Search/Content/Profiles/Content/Profile/AddPersonRelation/Content/CopyRelationships/Content/Relationships/data.graphql
import { gql } from 'Data/Api'

export default gql`
query app__App_Account_Content_Patients_Content_Content_Sections_ActiveTab_Profile_Content_NewProfile_Content_Relations_AddRelation_Search_Content_Profiles_Content_Profile_AddPersonRelation_Content_CopyRelationships_Content_Relationships($to_person: numeric!, $original_patient_id: uuid!) {
  vaxiom_relationships(
    where: {
      to_person: { _eq: $to_person }
      from_person_record: { _id: { _neq: $original_patient_id } }
    }
  ) {
    id
    from_person_record {
      _id
      id
      title
      first_name
      middle_name
      last_name
    }
  }
}

`