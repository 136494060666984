import { useMemo } from 'react'
import { useDataValue } from 'Simple/Data.js'

/** @type {import('Simple/types.js').useDataTransform} */
export default function useDataTransform(props, data) {
  let account_id = useDataValue({
    context: 'connection_resource_properties',
    path: 'properties.accountId',
    viewPath: props.viewPath,
  })
  let doctor_id = useDataValue({
    context: 'connection_resource_properties',
    path: 'properties.doctorId',
    viewPath: props.viewPath,
  })
  return useMemo(() => {
    return {
      account_id,
      doctor_id,
      originalValue: {
        account_id,
        doctor_id,
      },
    }
  }, [account_id, doctor_id])
}
