import { useDataIsSubmitting, useDataValue } from 'Simple/Data.js'

export function useIsDisabled(props) {
  let isSubmitting = useDataIsSubmitting({
    context: 'treatment_plan_request',
    viewPath: props.viewPath,
  })
  let contact_method_type = useDataValue({
    context: 'treatment_plan_request',
    path: 'contact_method_type',
    viewPath: props.viewPath,
  })
  let email_id = useDataValue({
    context: 'treatment_plan_request',
    path: 'email_id',
    viewPath: props.viewPath,
  })
  let phone_id = useDataValue({
    context: 'treatment_plan_request',
    path: 'phone_id',
    viewPath: props.viewPath,
  })

  return (
    isSubmitting ||
    (contact_method_type === 'email' && !email_id) ||
    (contact_method_type === 'phone' && !phone_id)
  )
}
