import formatDuration from 'date-fns/formatDuration/index.js'
import addWeeks from 'date-fns/addWeeks/index.js'
import intervalToDuration from 'date-fns/intervalToDuration/index.js'

export function getSubtitle(appointments) {
  let weeks = appointments.reduce((acc, item) => acc + item.interval_to_next, 0)
  let now = new Date()
  let futureDate = addWeeks(now, weeks)
  let duration = intervalToDuration({ start: now, end: futureDate })

  return `${formatDuration(duration, {
    format: ['years', 'months', 'weeks'],
  })}`
}

export function getWidth(plans) {
  switch (plans.length) {
    case 2:
      return '50%'
    case 3:
      return '33%'
    case 4:
      return '25%'
    default:
      return '840px'
  }
}
