import React, { useEffect } from 'react'
import { useDataSubmit, useDataValue } from 'Simple/Data.js'

import View from './view.js'

export default function Logic(props) {
  let submit = useDataSubmit({
    context: 'day_slots',
    viewPath: props.viewPath,
  })
  let appointment_id = useDataValue({
    context: 'appointment_overlay',
    path: 'appointment_id',
    viewPath: props.viewPath,
  })

  useEffect(() => {
    submit({ type: 'reFetch' })
  }, [appointment_id]) // eslint-disable-line react-hooks/exhaustive-deps

  return <View {...props} />
}
