import { useDataChange } from 'Simple/Data.js'

export default function useDataOnChange(props) {
  let change = useDataChange({
    context: 'schedule_via',
    viewPath: props.viewPath,
  })

  return function onChange(value) {
    if (value.length > 0) {
      change(next => {
        next.selected_contact_method = value[0]
      })
    }
  }
}
