import { useMemo } from 'react'
import { useDataFormat } from 'Simple/Data.js'

/** @type {import('Simple/types.js').useDataTransform} */
export default function useDataTransform(props, data) {
  let payor_id = useDataFormat({
    context: 'payors',
    format: value => value[0].id,
    viewPath: props.viewPath,
  })

  // select the first payor by default
  return useMemo(
    () => ({
      payor_id,
    }),
    [payor_id]
  )
}
