import { normalizePath, useSetFlowTo } from 'Simple/Flow.js'

export function useOnClickNew(props) {
  let setFlowTo = useSetFlowTo(props.viewPath)
  return function onClick() {
    setFlowTo([
      normalizePath(
        props.viewPath,
        '../../../../../../../../../../../AddAction/Content/Filter/RegisterAction/Content'
      ),
      normalizePath(props.viewPath, '../../../../../../../No'),
    ])
  }
}
