import { useSignedPreviewUrl } from 'Data/s3'
import React from 'react'
import { useDataValue } from 'Simple/Data'

import View from './view.js'

export default function Logic(props) {
  let url = useDataValue({
    context: 'relation',
    path: 'from_person_record.profile_pic_url',
    viewPath: props.viewPath,
  })
  let presignedUrl = useSignedPreviewUrl({
    url,
    size: 64,
    viewPath: props.viewPath,
  })

  return <View {...props} presignedUrl={presignedUrl} />
}
