// this is an autogenerated file from App/Account/Content/Settings/AdminContent/SettingsContent/TreatmentCaseCloser/Configuration/CustomPlanRulesets/Rulesets/Content/Ruleset/TxPlanGroup/data.graphql
import { gql } from 'Data/Api'

export default gql`
query app__App_Account_Content_Settings_AdminContent_SettingsContent_TreatmentCaseCloser_Configuration_CustomPlanRulesets_Rulesets_Content_Ruleset_TxPlanGroup($parent_company_id: numeric!, $tx_plan_group_id: uuid!) {
  vaxiom_tx_plan_groups(
    where: {
      pc_id: { _eq: $parent_company_id }
      deleted: { _eq: false }
      _or: [
        { _id: { _eq: $tx_plan_group_id } }
        { _not: { custom_payment_plan_ruleset: {} } }
      ]
    }
    order_by: { name: asc }
  ) {
    _id
    name
  }
}

`