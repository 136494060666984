import { useDataChange } from 'Simple/Data.js'

export function useOnChangeEmail(props) {
  let change = useDataChange({
    context: 'treatment_plan_request',
    path: 'contact_method_type',
    viewPath: props.viewPath,
  })

  return function onChange() {
    change('email')
  }
}

export function useOnChangePhone(props) {
  let change = useDataChange({
    context: 'treatment_plan_request',
    path: 'contact_method_type',
    viewPath: props.viewPath,
  })

  return function onChange() {
    change('phone')
  }
}
