import { useDataSubmit, useDataValue } from 'Simple/Data.js'

export function useOnClick(props) {
  let global = useDataValue({
    viewPath: props.viewPath,
    context: 'global',
  })
  let submit = useDataSubmit({
    viewPath: props.viewPath,
    context: 'global',
  })
  let patient_id = useDataValue({
    viewPath: props.viewPath,
    context: 'patient',
    path: '_id',
  })
  let treatment_id = useDataValue({
    viewPath: props.viewPath,
    context: 'appointments',
    path: 'tx_uuid',
  })

  return async function onClick() {
    if (global.tab_id !== 'Calendar') {
      await submit({
        type: global.tabs.includes('Calendar') ? 'tabs/select' : 'tabs/add',
        tab: {
          id: 'Calendar',
          name: 'Calendar',
          type: 'Calendar',
          viewPath: '/App/Account/Content/Calendar',
        },
      })
    }
    submit({ type: 'appointmentOverlay/open', patient_id, treatment_id })
  }
}
