// this is an autogenerated file from DesignSystem/EditAutomation/EventType/Content/data.graphql
import { gql } from 'Data/Api'

export default gql`
query app__DesignSystem_EditAutomation_EventType_Content{
  apps_resources {
    id
    resources
  }
}

`