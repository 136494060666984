import { createPlugin } from '@fullcalendar/core'
import { Interaction, identity } from '@fullcalendar/core/internal'
import { throttle } from 'lodash'

let THROTTLE_MS = 50

/**
 * @description FullCalendar does not have proper structure
 *  Current version of FullCalendar TimeGrid view/layout uses multiple tables that are absolutely positioned on top of each other
 *  Rows (slot lanes) do not have reference to the column (resources) and vice versa
 *  If you click on something with a mouse, you only get row (time), but in order to resolve which column (resource/chair) it was
 *  We also have to check all other elements intersecting that point
 *  As a helper, we also make a note of some known elements on calendar that are intersecting (such as blocked times, non business hours, events)
 *
 *  This plugin might be completely removed in future versions of FullCalendar
 *
 *  Another idea would be to implement a completely custom view (custom timegrid), but that brings complexitities by itself
 */
class PointerMoveInteraction extends Interaction {
  /** @type {import('@fullcalendar/core/internal').InteractionSettings} */
  _settings

  /** @type {HTMLElement | undefined} */
  previousRow

  /** @type {HTMLElement | undefined} */
  previousColumn

  /** @type {PointerMoveInteraction['handlePointerLeave']} */
  boundHandlePointerLeave

  /** @type {PointerMoveInteraction['handlePointerMove']} */
  boundHandlePointerMove

  constructor(
    /** @type {import('@fullcalendar/core/internal').InteractionSettings} */
    settings
  ) {
    super(settings)
    this._settings = settings

    // prettier-ignore
    this.boundHandlePointerMove = throttle(this.handlePointerMove, THROTTLE_MS).bind(this)
    this.boundHandlePointerLeave = this.handlePointerLeave.bind(this)

    this._settings.el?.addEventListener(
      'pointermove',
      this.boundHandlePointerMove
    )

    this._settings.el?.addEventListener(
      'pointerleave',
      this.boundHandlePointerLeave
    )
  }

  handlePointerLeave(
    /** @type {PointerEvent} */
    event
  ) {
    setTimeout(() => {
      this.component.context.emitter.trigger(
        // @ts-expect-error custom untyped
        'pointerMoveCancel',
        {}
      )
    }, THROTTLE_MS + 1)
  }

  handlePointerMove(
    /** @type {PointerEvent} */
    event
  ) {
    if (!(event.target instanceof HTMLElement)) return

    /** @type {HTMLElement | undefined} */
    let row
    /** @type {HTMLElement | undefined} */
    let column

    // eslint-disable-next-line compat/compat
    let elements = document.elementsFromPoint(event.pageX, event.pageY)

    /** @type {import('./pointerMovePluginTypes.d.ts').PointerMoveData['conflicts']} */
    let conflicts = {
      appointmentCandidate: false,
      appointmentCandidateUntemplated: false,
      event: false,
      nonBusinessHours: false,
    }

    for (let element of elements) {
      if (
        element instanceof HTMLElement &&
        element.classList.contains('fc-timegrid-slot-lane')
      ) {
        row = element
      }
      if (
        element instanceof HTMLElement &&
        element.classList.contains('fc-resource')
      ) {
        column = element
      }
      if (element.classList.contains('fc-non-business')) {
        conflicts.nonBusinessHours = true
      }
      if (element.classList.contains('fc-event-main')) {
        conflicts.event = true
      }
      if (element.classList.contains('candidate')) {
        conflicts.appointmentCandidate = true
      }
      if (element.classList.contains('candidate-untemplated')) {
        conflicts.appointmentCandidateUntemplated = true
      }
    }

    if (!row || !column) return
    if (row === this.previousRow && column === this.previousColumn) return

    this.previousRow = row
    this.previousColumn = column

    let startTime = row.dataset['time']
    let resourceId = column.dataset['resourceId']
    let date = column.dataset['date']

    if (!startTime || !resourceId || !date) return

    /** @type {import('./pointerMovePluginTypes.d.ts').PointerMoveData} */
    let data = {
      startTime,
      resourceId,
      date,
      conflicts,
    }

    this.component.context.emitter.trigger(
      // @ts-expect-error custom untyped
      'pointerMove',
      {
        data,
      }
    )
  }

  destroy() {
    this._settings.el?.removeEventListener(
      'pointerleave',
      this.boundHandlePointerLeave
    )
    this._settings.el?.removeEventListener(
      'pointermove',
      this.boundHandlePointerMove
    )
  }
}

export default createPlugin({
  name: 'pointerMove',
  componentInteractions: [PointerMoveInteraction],
  listenerRefiners: {
    pointerMove: identity,
    pointerMoveCancel: identity,
  },
})
