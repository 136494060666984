export function getView(event) {
  let booking = event.appointment.booking
  if (booking.length === 0) return 'Schedule'

  switch (booking[0].state) {
    case 'SCHEDULED':
      return 'CheckIn'
    case 'NO_SHOW':
      return 'Reschedule'
    case 'SEATED':
      return 'Unseat'
    case 'UNSEATED':
      return 'Checkout'
    case 'CHECKED_IN':
      return 'Seat'
    case 'CHECKED_OUT':
      return 'CancelCheckout'
    default:
      return 'No'
  }
}
