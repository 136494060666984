// this is an autogenerated file from App/Account/Content/Patients/Content/Content/Sections/ActiveTab/TreatmentCard/Content/TreatmentPlans/New/Steps/Content/PaymentPlans/Content/Content/PaymentPlan/Content/Content/mutation-create-contract.graphql
import { gql } from 'Data/Api'

export default gql`
mutation app__App_Account_Content_Patients_Content_Content_Sections_ActiveTab_TreatmentCard_Content_TreatmentPlans_New_Steps_Content_PaymentPlans_Content_Content_PaymentPlan_Content_Content($treatment_plan_id: numeric!, $person_id: numeric!) {
  vaxiom_contracts_create(
    treatment_plan_id: $treatment_plan_id
    person_id: $person_id
  ) {
    id
  }
}

`