import { useDataSubmit } from 'Simple/Data.js'

export function useOnSubmit(props) {
  let submit = useDataSubmit({
    context: 'payment_plan',
    viewPath: props.viewPath,
  })

  return function onSubmit() {
    submit({ type: 'send-request' })
  }
}
