import { useDataValue } from 'Simple/Data.js'

export default function useDataConfiguration(props) {
  let treatment_plan_id = useDataValue({
    viewPath: props.viewPath,
    context: 'wizard',
    path: 'selected.treatment_plan_id',
  })

  return {
    variables: {
      treatment_plan_id,
    },
    pause: !treatment_plan_id,
    requestPolicy: 'cache-and-network',
  }
}
