// this is an autogenerated file from DesignSystem/PatientOverview/Content/Contact/Content/Phones/Content/Phone/Apps/data.graphql
import { gql } from 'Data/Api'

export default gql`
query app__DesignSystem_PatientOverview_Content_Contact_Content_Phones_Content_Phone_Apps($location_id: uuid!) {
  apps_launchers(slot: ADMIN_PATIENT_PHONE, location_id: $location_id) {
    id
    shortName
    launchers {
      id
      app_id
      icon
      name
      active
      connected
      type
      description
    }
  }
}

`