import { useMemo } from 'react'
import { useDataValue } from 'Simple/Data.js'

/** @type {import('Simple/types.js').useDataTransform} */
export default function useDataTransform(props, data) {
  let active = useDataValue({
    context: 'assigned_insurance',
    viewPath: props.viewPath,
    path: 'insurance_subscription.active',
  })
  let has_edit_permissions = useDataValue({
    context: 'global',
    viewPath: props.viewPath,
    path: 'current_location.permissions.has_patient_insurance_edit',
  })

  return useMemo(() => {
    if (!data) return data
    return [
      ...data,
      ...(has_edit_permissions
        ? [
            getActivateDeactivateChoice(),
            { id: 'REMOVE_COVERAGE', text: 'Remove Coverage' },
          ]
        : []),
    ]
  }, [data, active])

  function getActivateDeactivateChoice() {
    if (active) {
      return {
        id: 'INACTIVATE_INSURANCE',
        text: 'Inactivate insurance',
      }
    } else {
      return {
        id: 'ACTIVATE_INSURANCE',
        text: 'Reactivate insurance',
      }
    }
  }
}
