import { useDataChange, useDataValue } from 'Simple/Data.js'
import { changePayorsConfiguration } from 'Data/payment-plan.js'

export function useOnChange(props) {
  let index = useDataValue({
    context: 'payor_item',
    path: 'index',
    viewPath: props.viewPath,
  })
  let change = useDataChange({
    context: 'payment_plan',
    viewPath: props.viewPath,
  })
  return function onChange(value) {
    change(next => {
      let payor = next.payors[index]
      let other_payor = next.payors.find((_, i) => i !== index)
      payor.is_primary = value
      other_payor.is_primary = !value

      let primary_payor = value ? payor : other_payor

      // update all the discounts that were applied by default to the primary payor
      // to be recalculated against the new primary payor's share
      next.discounts
        .filter(
          discount => discount.is_applied_to_payor && !discount.payor_person_id
        )
        .forEach(discount => {
          discount.payor_person_id = primary_payor.person_id
          discount.amount = parseFloat(
            ((primary_payor.share.amount * discount.percentage) / 100).toFixed(
              2
            )
          )
        })

      changePayorsConfiguration(next)
    })
  }
}
