// this is an autogenerated file from App/Account/Content/Patients/Content/Content/Sections/ActiveTab/Profile/Content/NewProfile/Content/Professionals/AddAction/Search/Content/Profiles/data.graphql
import { gql } from 'Data/Api'

export default gql`
query app__App_Account_Content_Patients_Content_Content_Sections_ActiveTab_Profile_Content_NewProfile_Content_Professionals_AddAction_Search_Content_Profiles(
  $search_term: String!
  $parent_company_id: uuid!
  $whole_parent_company: Boolean!
  $current_location_id: uuid!
) {
  vaxiom_persons_search(
    search_term: $search_term
    parent_company_id: $parent_company_id
    whole_parent_company: $whole_parent_company
    professional_search: true
  ) {
    person: vaxiom_person {
      id
      first_name
      middle_name
      last_name
      greeting
      title
      gender
      birth_date
      public_user {
        id
      }
      phones: contact_method_associations(
        where: { contact_method: { dtype: { _eq: "phone" } } }
      ) {
        id
        description
        contact_method {
          id
          phone {
            id
            number
          }
        }
      }
      employment_contracts(
        where: {
          employment_end_date: { _is_null: true }
          deleted: { _eq: false }
        }
      ) {
        id
        organization {
          id
          name
          postal_address {
            id
            address_line1
            city
            state
            zip
          }
        }
        employee_resources(
          where: {
            resource: { organization: { _id: { _eq: $current_location_id } } }
          }
        ) {
          id
          resource {
            id
            organization {
              id
              name
              postal_address {
                id
                address_line1
                city
                state
                zip
              }
            }
            resource_type {
              id
              name
            }
          }
        }
      }
      other_professional_relationships {
        id
        external_office {
          id
          name
          postal_address {
            id
            city
            state
          }
        }
        employee_type {
          id
          name
        }
      }
    }
  }
}

`