import { useMemo } from 'react'

export default function useDataTransform(props, data) {
  return useMemo(() => {
    if (!data) return data

    let custom_resource = data.apps_custom_resources_by_pk

    // find the data mapping configuration to be used for the custom resource
    let display = data.apps_displays.displays.find(
      item =>
        item.appId === custom_resource.connection.app_id &&
        item.resourceType === custom_resource.type
    )
    if (!display) return data

    return {
      id: custom_resource.id,
      created_at: custom_resource.created_at,
      updated_at: custom_resource.updated_at,
      ...getValues(custom_resource.data, display.props),
    }
  }, [data])
}

function getValues(data, props) {
  let result = {}
  for (let key of Object.keys(props)) {
    if (typeof props[key] === 'object') {
      result[key] = getValues(data, props[key])
    } else if (key in props) {
      result[key] = getValue(data, props[key])
    }
  }
  return result
}

function getValue(data, placeholder) {
  if (placeholder.startsWith('{{')) {
    return data[placeholder.replace('{{', '').replace('}}', '')]
  } else {
    return placeholder
  }
}
