import { useDataSubmit } from 'Simple/Data.js'

export function useOnClick(props) {
  let submit = useDataSubmit({
    context: 'app',
    viewPath: props.viewPath,
  })

  return function onClick() {
    submit({ type: 'connect' })
  }
}
