import { useDataSubmit } from 'Simple/Data.js'

export function useOnClick(props) {
  let submit = useDataSubmit({
    context: 'profile_patient',
    viewPath: props.viewPath,
  })

  return function onSubmit() {
    submit({ type: 'cancel' })
  }
}
