import { useDataValue, useDataSubmit } from 'Simple/Data.js'

export function useOnClick(props) {
  let id = useDataValue({
    viewPath: props.viewPath,
    context: 'groupMember',
    path: 'id',
  })
  let submit = useDataSubmit({
    viewPath: props.viewPath,
    context: 'tab',
  })

  return function onClick() {
    submit({
      type: 'scheduling/selectTemplatedSlot',
      id,
      focus: false,
    })
  }
}
