import { normalizePath, useSetFlowTo } from 'Simple/Flow.js'
import { useDataValue } from 'Simple/Data.js'

export function useOnClick(props) {
  let setFlowTo = useSetFlowTo(props.viewPath)

  let action = useDataValue({
    viewPath: props.viewPath,
    context: 'option',
    path: 'id',
  })

  return function onClick() {
    props.closePopover()
    switch (action) {
      case 'DELETE':
        return deleteAddress()
      case 'EDIT':
        return editAddress()
      default:
        return
    }
  }

  function deleteAddress() {
    setFlowTo(normalizePath(props.viewPath, '../../../Delete/Content'))
  }

  async function editAddress() {
    setFlowTo(normalizePath(props.viewPath, '../../../Edit/Content'))
  }
}
