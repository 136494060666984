// this is an autogenerated file from App/Account/Content/Patients/Content/Content/Sections/ActiveTab/TreatmentCard/Content/TreatmentCard/TxPlanNotes/Content/Content/insert_tx_plan_notes.graphql
import { gql } from 'Data/Api'

export default gql`
mutation app__App_Account_Content_Patients_Content_Content_Sections_ActiveTab_TreatmentCard_Content_TreatmentCard_TxPlanNotes_Content_Content($notes: String, $tx_card_id: numeric!) {
  insert_vaxiom_tx_plan_notes_one(
    object: { note: $notes, tx_card_id: $tx_card_id }
  ) {
    id
    note
  }
}

`