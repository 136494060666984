import { useMemo } from 'react'
import { useDataValue } from 'Simple/Data'

/** @type {import('Simple/types.js').useDataTransform} */
export default function useDataTransform(props, data) {
  let image_series_id = useDataValue({
    context: 'tab',
    path: 'selected.images.image_series_id',
    viewPath: props.viewPath,
  })

  return useMemo(() => {
    if (!data?.apps_connection_resources.length) return null

    let {
      apps_connection_resources: [connection_resource],
      apps_connection_resource_properties: [connection_resource_properties],
    } = data

    return {
      id: connection_resource.connection.id,
      app_id: connection_resource.connection.app_id,
      step: 'account',
      account_id: connection_resource_properties?.properties?.accountId,
      doctor_id: connection_resource_properties?.properties?.doctorId,
      image_series_id,
      set_preferred: true,
    }
  }, [data, image_series_id])
}
