import { useMemo } from 'react'

export default function useDataTransform(props, data) {
  return useMemo(() => {
    if (!data) return data

    let res = data
      .map(obj => ({
        ...obj.contact_method?.association?.person,
        contact_method_id: obj.id,
      }))
      .filter(obj => obj.contact_method_id)
    return res
  }, [data])
}
