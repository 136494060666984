import { useMemo } from 'react'
import { useDataValue } from 'Simple/Data.js'

/** @type {import('Simple/types.js').useDataTransform} */
export default function useDataTransform(props, data) {
  let person = useDataValue({
    viewPath: props.viewPath,
    context: 'profile_patient',
    path: 'person',
  })
  return useMemo(() => {
    return [
      {
        id: person.id,
        text: `${person.first_name} ${person.last_name}`,
      },
      ...(data || []).map(d => ({
        id: d.from_person,
        text: `${d.from_person_record.first_name} ${d.from_person_record.last_name}`,
      })),
    ]
  }, [data])
}
