// this is an autogenerated file from DesignSystem/AppointmentActions/mutation-send-link.graphql
import { gql } from 'Data/Api'

export default gql`
mutation app__DesignSystem_AppointmentActions(
  $appointment_id: uuid!
  $phone: String
  $email: String
  $custom_instructions: String
) {
  appointments_scheduling_link_send(
    appointment_id: $appointment_id
    phone: $phone
    email: $email
    custom_instructions: $custom_instructions
  ) {
    ok
  }
}

`