// this is an autogenerated file from App/Account/Content/Patients/Content/Content/Sections/ActiveTab/Profile/Content/NewProfile/Content/ContactInfo/Postal/NewAddress/Content/SearchForAddress/Content/data.graphql
import { gql } from 'Data/Api'

export default gql`
query app__App_Account_Content_Patients_Content_Content_Sections_ActiveTab_Profile_Content_NewProfile_Content_ContactInfo_Postal_NewAddress_Content_SearchForAddress_Content(
  $address_line1: String!
  $city: String!
  $state: String!
  $zip: String!
) {
  vaxiom_contact_postal_addresses(
    where: {
      address_line1: { _eq: $address_line1 }
      city: { _eq: $city }
      state: { _eq: $state }
      zip: { _eq: $zip }
    }
  ) {
    id
    contact_method {
      id
      association {
        id
        person {
          id
          first_name
          last_name
        }
      }
    }
  }
}

`