import { useDataChange } from 'Simple/Data.js'
import { v4 as uuid } from 'uuid'
export function useOnClick(props) {
  let change = useDataChange({
    context: 'carrier',
    viewPath: props.viewPath,
  })
  return function onClick() {
    change(next => {
      next.insurance_company_phone_associations = [
        ...(next.insurance_company_phone_associations || []),
        {
          id: uuid(),
          description: '',
          contact_method: {
            dtype: 'PHONE',
            phone: {
              number: '',
              type: '',
            },
          },
        },
      ]
    })
  }
}
