import React from 'react'
import View from './view.js'
import {
  useFloating,
  flip,
  offset,
  shift,
  autoUpdate,
} from '@floating-ui/react-dom'
import { useDataValue } from 'Simple/Data.js'

export default function Logic(props) {
  let is_dialog_open = useDataValue({
    context: 'slot_dialog',
    viewPath: props.viewPath,
    path: 'is_dialog_open',
  })

  let { x, y, refs } = useFloating({
    open: is_dialog_open,
    strategy: 'fixed',
    elements: {
      reference: {
        getBoundingClientRect: () => props.position,
      },
    },
    middleware: [
      offset({ mainAxis: 8, alignmentAxis: 72 }),
      flip({ fallbackPlacements: ['bottom-end', 'top-start', 'top-end'] }),
      shift({ padding: 5 }),
    ],
    placement: 'bottom-start',
    whileElementsMounted: autoUpdate,
  })

  return (
    <View
      {...props}
      contentRef={refs.setFloating}
      isOpen={is_dialog_open}
      top={y}
      left={x}
    />
  )
}
