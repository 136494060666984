import { useMemo } from 'react'
import { useDataValue } from 'Simple/Data.js'

/** @type {import('Simple/types.js').useDataTransform} */
export default function useDataTransform(props, data) {
  let resources = useDataValue({
    context: 'timegrid',
    path: 'resources',
    viewPath: props.viewPath,
  })
  let location_id = useDataValue({
    context: 'timegrid',
    path: 'location.vaxiom_id',
    viewPath: props.viewPath,
  })

  return useMemo(
    () => ({
      ...data,
      is_dialog_open: props.showDialog,
      schedule_note: {
        ...data.schedule_note,
        ...props.schedule_note,
        resources,
        location_id,
      },
    }),
    [data, props.schedule_note, props.showDialog, location_id, resources]
  )
}
