import { useDataValueOnce } from 'Logic/FlowShortcuts'
import { useDataValue } from 'Simple/Data.js'

export default function useEnsureDefaultTab(props, data) {
  let patient_id = useDataValueOnce({
    context: 'flow_shortcuts',
    path: 'patientId',
    viewPath: props.viewPath,
  })
  let patientTab = useDataValueOnce({
    context: 'flow_shortcuts',
    path: 'patientTab',
    viewPath: props.viewPath,
  })
  let is_new_profile_default_enabled = true
  let is_new_tx_planning_default_enabled = useDataValue({
    context: 'global',
    path: 'feature_flags.is_new_tx_planning_default_enabled',
    viewPath: props.viewPath,
  })

  return function ensureDefaultTab(maybeTab, id) {
    if (maybeTab?.selected) return maybeTab

    let tab = maybeTab?.tab || PATIENT_TABS[patientTab] || 'profile'

    return {
      ...data,
      id: id || patient_id,
      // TODO: move to selected
      patient_id: id || patient_id,
      tab,
      selected: {
        ...data.selected,
        profile: {
          ...data.selected.profile,
          new: is_new_profile_default_enabled,
        },
        treatment: {
          ...data.selected.treatment,
          treatment_plans: {
            ...data.selected.treatment.treatment_plans,
            new: is_new_tx_planning_default_enabled,
          },
        },
        ledger: {
          payor: maybeTab?.data?.selected_payor_id,
        },
      },
    }
  }
}

let PATIENT_TABS = {
  medical: 'medical',
  documents: 'documents',
  images: 'images',
  ledger: 'ledger',
  notes: 'notes',
  treatment: 'treatment_card',
  'dental-monitoring': 'dental-monitoring',
}
