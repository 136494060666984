import { normalizePath, useSetFlowTo } from 'Simple/Flow.js'
import { useDataValue } from 'Simple/Data.js'

export function useOnClick(props) {
  let setFlowTo = useSetFlowTo(props.viewPath)

  let action = useDataValue({
    context: 'option',
    viewPath: props.viewPath,
    path: 'id',
  })

  return function onClick() {
    switch (action) {
      case 'DELETE':
        return deletePreferences()
      case 'UPDATE':
        return editPrefs()
      default:
        return
    }
  }

  function deletePreferences() {
    setFlowTo(normalizePath(props.viewPath, './../Delete/Content'))
  }

  async function editPrefs() {
    setFlowTo(normalizePath(props.viewPath, './../Edit/Content'))
  }
}
