import { useDataValue, useDataChange } from 'Simple/Data.js'

export function useOnClick(props) {
  let change = useDataChange({
    viewPath: props.viewPath,
    context: 'appointment_overlay',
  })
  let treatment = useDataValue({
    context: 'treatment',
    viewPath: props.viewPath,
  })

  return () => {
    change(next => {
      next.treatment_id = treatment._id
      next.appointment_id = null
    })
    props.closePopover?.()
  }
}
