// this is an autogenerated file from App/Account/Content/Patients/Content/Content/Sections/ActiveTab/Profile/Content/NewProfile/Content/Insurance/AssignedInsurance/Content/AssignedInsuranceItem/mutation_reactivate.graphql
import { gql } from 'Data/Api'

export default gql`
mutation app__App_Account_Content_Patients_Content_Content_Sections_ActiveTab_Profile_Content_NewProfile_Content_Insurance_AssignedInsurance_Content_AssignedInsuranceItem(
  $id: numeric!
  $reactivated: date!
  $reactivated_by: numeric!
) {
  update_vaxiom_insurance_subscriptions_by_pk(
    _set: {
      active: true
      reactivated: $reactivated
      reactivated_by: $reactivated_by
      deactivated_reason: ""
    }
    pk_columns: { id: $id }
  ) {
    id
  }
}

`