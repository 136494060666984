import { numberMoney } from 'Data/format.js'
import {
  getLastInstallmentAmount,
  getPayorShareAfterDiscounts,
} from 'Data/payment-plan.js'

export function getLastInstallmentAmountFormatted(payor, payment_plan) {
  return numberMoney(getLastInstallmentAmount(payor, payment_plan))
}

export function getPayorShareAfterDiscountsFormatted(payor, payment_plan) {
  return numberMoney(getPayorShareAfterDiscounts(payor, payment_plan))
}
