import React, { useEffect, useMemo, useState } from 'react'
import { useClient } from 'Data/Api'
import { DataProvider, useDataChange, useDataValue } from 'Simple/Data.js'
import TysiaText from 'DesignSystem/TysiaText/index.js'
import query from './txStatusQuery.graphql.js'

import PatientInternalCard from './PatientInternalCard/index.js'
import PatientExternalCard from './PatientExternalCard/index.js'
import PatientNotFoundCard from './PatientNotFoundCard/index.js'
import View from './view.js'

let MATCH_TYPES = /** @type {const} */ ({
  NO_MATCH: 'NO_MATCH',
  PARTIAL: 'PARTIAL',
  MATCH: 'MATCH',
})

export default function Logic(props) {
  let patients = useDataValue({
    context: 'patients',
    viewPath: props.viewPath,
  })
  let filters = useDataValue({
    context: 'filters',
    viewPath: props.viewPath,
  })

  let client = useClient()

  let [filteredPatients, setFilteredPatients] = useState(patients)

  useEffect(() => {
    async function run() {
      let fpatients =
        filters.type === 'ALL'
          ? patients
          : patients.filter(item => filters.type === item.type)

      if (filters.status.length > 0) {
        let response = await client.query(query, {
          patient_ids: patients.filter(p => p.internal).map(p => p.internal.id),
          statuses: filters.status,
        })
        if (response.error) return

        let filteredPatientIds = new Set(
          response.data.vaxiom_patients.map(p => p._id)
        )
        fpatients = patients.filter(
          item => item.internal && filteredPatientIds.has(item.internal.id)
        )
      }

      setFilteredPatients(fpatients)
    }
    run()
  }, [filters, patients])

  let changePatients = useDataChange({
    context: 'patients',
    viewPath: props.viewPath,
  })

  let value = useMemo(() => {
    let columns = [
      {
        header: 'External',
        accessorKey: 'external',
        enableSorting: false,
        cell: cell => {
          if (cell.row.original.type === 'SECTION') {
            return (
              <TysiaText
                marginLeft={16}
                text={cell.row.original.text}
                viewPath={`${props.viewPath}/TysiaText(${cell.row.original.id})`}
              />
            )
          }

          return (
            <DataProvider
              context="match"
              value={cell.row.original}
              viewPath={`${props.viewPath}/PatientExternalCard(${cell.row.original.id})`}
            >
              <PatientExternalCard
                viewPath={`${props.viewPath}/PatientExternalCard(${cell.row.original.id})`}
              />
            </DataProvider>
          )
        },
      },
      {
        header: 'Greyfinch',
        accessorKey: 'internal',
        enableSorting: false,
        cell: cell => {
          if (cell.row.original.type === 'SECTION') {
            return null
          }

          return (
            <DataProvider
              context="match"
              value={cell.row.original}
              onSubmit={({ args, value }) => {
                switch (args.type) {
                  case 'TOGGLE_SELECTED': {
                    changePatients(next => {
                      let match = next.find(item => item.id === value.id)
                      if (match) {
                        match.selected = !match.selected
                      }
                    })
                    break
                  }

                  case 'REPLACE_MATCH': {
                    changePatients(next => {
                      // TODO: extend so that it allows replacing a match even if it is assigned to someone else
                      // debugger
                      // let previousMatch = next.find(item => item.internal?.id === args.internal.id)
                      // if (previousMatch) {
                      //   previousMatch.internal = null
                      //   previousMatch.type = MATCH_TYPES.NO_MATCH
                      // }
                      let match = next.find(item => item.id === value.id)
                      if (match) {
                        match.internal = args.internal
                        match.type = MATCH_TYPES.MATCH
                        match.selected = true
                      }
                    })
                    break
                  }

                  case 'REMOVE_MATCH': {
                    changePatients(next => {
                      let match = next.find(item => item.id === value.id)
                      if (match) {
                        match.internal = null
                        match.type = MATCH_TYPES.NO_MATCH
                      }
                    })
                    break
                  }

                  default: {
                    break
                  }
                }
              }}
              viewPath={`${props.viewPath}/PatientInternalCard(${cell.row.original.id})`}
            >
              {cell.getValue() === null ? (
                <PatientNotFoundCard
                  viewPath={`${props.viewPath}/PatientInternalCard(${cell.row.original.id})`}
                />
              ) : (
                <PatientInternalCard
                  viewPath={`${props.viewPath}/PatientInternalCard(${cell.row.original.id})`}
                />
              )}
            </DataProvider>
          )
        },
      },
    ]

    if (!Array.isArray(filteredPatients)) return { columns, data: [] }

    return {
      columns,
      data: [
        { type: 'SECTION', text: 'MATCHES', color: 'green' },
        ...filteredPatients.filter(item => item.type === MATCH_TYPES.MATCH),
        { type: 'SECTION', text: 'PARTIAL MATCHES', color: 'blue' },
        ...filteredPatients.filter(item => item.type === MATCH_TYPES.PARTIAL),
        { type: 'SECTION', text: 'NO MATCH', color: 'red' },
        ...filteredPatients.filter(item => item.type === MATCH_TYPES.NO_MATCH),
      ],
    }
  }, [filteredPatients])

  return <View value={value} viewPath={props.viewPath} />
}
