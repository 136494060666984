import { useMutation } from 'Data/Api.js'
import { notifyError, useNotifications } from 'Logic/Notifications.js'

import mutation from './mutation.graphql.js'

/** @type {import('Simple/types.js').useDataOnSubmit} */
export default function useDataOnSubmit(props, data, reExecuteQuery) {
  let [, executeMutation] = useMutation(mutation)
  let [, notify] = useNotifications()

  return async function onSubmit({ value, originalValue, args, change }) {
    switch (args.type) {
      case 'refetch': {
        reExecuteQuery({ requestPolicy: 'network-only' })
        return
      }

      case 'save': {
        let mutationResponse = await executeMutation({
          id: value?.id || crypto.randomUUID(),
          type: value.type,
          connection_id: args.connection_id,
          resource_id: value.resource_id,
          with_insert: !value?.id,
          with_update: !!value?.id,
        })
        if (mutationResponse.error) {
          return notify(
            notifyError(
              'An error occurred trying to enable the connection. Please refresh the page and try again.'
            )
          )
        }
        return
      }

      default: {
        return
      }
    }
  }
}
