import { APP_ENV, LOCAL_DEV_IP } from './constants'
import { DataProvider, useDataSubmit, useDataValue } from 'Simple/Data.js'
import { GetObjectCommand } from '@aws-sdk/client-s3'
import { getSignedUrl as _getSignedUrl } from '@aws-sdk/s3-request-presigner'
import { gql, useClient } from 'Data/Api.js'
import { S3Client } from '@aws-sdk/client-s3'
import AmazonS3URI from 'amazon-s3-uri'
import React, { useEffect, useMemo, useRef, useState } from 'react'
import addSeconds from 'date-fns/addSeconds'
import isPast from 'date-fns/isPast'
import isFuture from 'date-fns/isFuture'
import parseISO from 'date-fns/parseISO'
import subMilliseconds from 'date-fns/subMilliseconds'
import isValid from 'date-fns/isValid'
import subMinutes from 'date-fns/subMinutes'

let query = gql`
  query aws_credentials {
    aws_token_vending_machine {
      access_key_id
      secret_access_key
      session_token
      expires_in
      expiration
      region
      storage_bucket_name
      legacy_storage_bucket_name
      preview_access_point_arn
    }
  }
`

export function AwsCredentials(props) {
  let onSubmit = useDataOnSubmit(props)
  let onChange = useDataOnChange(props)
  let data = useDataTransform(props, null)

  useEffect(
    () => () => {
      cache = {}
    },
    []
  )

  return (
    <DataProvider
      context="aws_credentials"
      onSubmit={onSubmit}
      onChange={onChange}
      value={data}
      viewPath={props.viewPath}
    >
      {props.children}
    </DataProvider>
  )
}

function useDataTransform(props, data) {
  return useMemo(() => {
    let value = localStorage.getItem('aws_credentials')
    return value ? JSON.parse(value) : data
  }, [data])
}

function useDataOnChange(props, data) {
  return function onChange(next) {
    localStorage.setItem('aws_credentials', JSON.stringify(next))
  }
}

function useDataOnSubmit(props) {
  // Using tempValue to avoid rendering concurrency in all this hackery
  // from requesting a token twice once the app starts.
  // The reason it happens is that `change` runs in the next cycle after
  // `onSubmit` finished processing. Tbh, the use of onSubmit's result
  // and locking mechanism (through its internal isSubmitting ref)
  // is probably abusing it a bit but it seems to do the trick here, so let's
  // try it out and see what happens!
  let tempValue = useRef(null)

  let client = useClient()

  return async function onSubmit({ args, value, originalValue, change }) {
    if (value !== null && isFuture(subMinutes(parseISO(value.expiration), 1))) {
      // we want to revert tempValue as soon as we understand we have a value
      tempValue.current = null
      return value
    }
    if (
      tempValue.current !== null &&
      isFuture(subMinutes(parseISO(tempValue.current.expiration), 1))
    ) {
      return tempValue.current
    }

    let response = await client
      .query(
        query,
        {},
        {
          requestPolicy: 'network-only',
        }
      )
      .toPromise()

    let nextValue = response.data?.aws_token_vending_machine || null
    tempValue.current = nextValue
    change(nextValue)
    return nextValue
  }
}

export function useAwsCredentials(props) {
  let submit = useDataSubmit({
    context: 'aws_credentials',
    viewPath: props.viewPath,
  })

  /**
   * @returns {Promise<null |import('./s3.types.d.ts').aws_token_vending_machine_response>}
   */
  return async function getAwsCredentials() {
    let value = await submit()
    let tries = 1_000_000

    // in theory this could enter a loop but in practice I doubt it does it?
    // DataProvider#onSubmit returns undefined if an onSubmit function is running
    // and we return either null or a value, so it should work alright
    // anyway I added an artificial cutoff point at 1m runs
    while (typeof value === 'undefined' && tries-- > 0) {
      await wait()
      value = await submit()
    }
    return value
  }
}

async function wait(time = Math.random() * 10) {
  return new Promise(resolve => setTimeout(resolve, time))
}

export function useAwsS3Client(props) {
  let submit = useAwsCredentials(props)

  return async function getS3Client() {
    let value = await submit()
    if (!value) return null

    let configuration = {
      credentials: {
        accessKeyId: value.access_key_id,
        secretAccessKey: value.secret_access_key,
        sessionToken: value.session_token,
        expiration: value.expiration,
      },
      region: value.region,
    }

    if (APP_ENV === 'development') {
      configuration.endpoint = {
        protocol: 'http:',
        hostname: LOCAL_DEV_IP,
        path: '/',
        port: 3003,
      }
      configuration.s3ForcePathStyle = true // needed with minio?
      configuration.forcePathStyle = true
      configuration.signatureVersion = 's3v4'
    }

    let client = new S3Client(configuration)
    maybeFixClientForMinio(client)
    // A presigned URL is only valid as long as the session key that was used when generating it.
    // Our tokens last an hour, so one hour it is https://stackoverflow.com/a/50398133
    client.signingDate = subMilliseconds(
      parseISO(value.expiration),
      value.expires_in
    )
    client.expiresIn = Math.floor(value.expires_in / 1_000)
    if (!isValid(client.signingDate)) {
      client.signingDate = new Date()
    }
    return client
  }
}

function maybeFixClientForMinio(client) {
  if (APP_ENV !== 'development') return

  // https://github.com/minio/minio/discussions/14709
  function presignHeaderMiddleware(next) {
    return async function presignHeader(args) {
      let request = args.request
      request.headers['host'] = `${request.hostname}:3003`

      return await next(args)
    }
  }

  client.middlewareStack.addRelativeTo(presignHeaderMiddleware, {
    name: 'presignHeaderMiddleware',
    relation: 'before',
    toMiddleware: 'hostHeaderMiddleware', // 'presignInterceptMiddleware',
    override: true,
  })
}

export function getAmazonS3URI(rurl) {
  let url = cleanParams(rurl)
  try {
    return AmazonS3URI(url)
  } catch (error) {
    if (APP_ENV === 'development') {
      let bucketKey = url.replace(/http:\/\/\d+.\d+.\d+.\d+(:3003)?\//, '')
      let bucket = bucketKey.substr(0, bucketKey.indexOf('/'))
      let key = bucketKey.substr(bucketKey.indexOf('/') + 1)
      return { bucket, key }
    }

    throw error
  }
}

function cleanParams(url = '') {
  let [newUrl] = url.split(`?`)
  return newUrl
}

let S3_INVALID_CHARS_REGEX = /[^0-9a-zA-Z!\-_.*'() ]/g

/**
 * This functions allows to clean s3 invalid chars from a string. It is used to prevent the InvalidArgument issue.
 *
 * Ref:
 * https://stackoverflow.com/questions/10108506/special-characters-in-amazon-s3-file-name
 * https://docs.aws.amazon.com/AmazonS3/latest/userguide/object-keys.html
 * https://docs.aws.amazon.com/AmazonS3/latest/userguide/UsingMetadata.html#object-keys
 *
 * @param {string} value
 *
 * @return {string}
 * */
export function cleanS3Characters(value) {
  return value?.toString().replace(S3_INVALID_CHARS_REGEX, '')
}

export async function generateSignedUrl(s3, url, options = {}) {
  let { bucket, key } = getAmazonS3URI(url)

  return await getSignedUrl(
    s3,
    new GetObjectCommand({
      Bucket: bucket,
      Key: key,
      ResponseContentDisposition: [
        options.download ? 'attachment' : 'inline',
        options.filename
          ? `filename="${cleanS3Characters(options.filename)}"`
          : null,
      ]
        .filter(Boolean)
        .join(';'),
      ResponseContentType: options.content_type ?? undefined,
    })
  )
}

export function useGetSignedUrl(props) {
  let [signedUrl, setSignedUrl] = useState(() =>
    maybeGetCachedUrl(getCacheKey())
  )

  let lastSourceUrl = useRef(null)
  let s3 = useAwsS3Client(props)

  useEffect(() => {
    if (!props.url) return
    if (props.once && lastSourceUrl.current === props.url) return

    let cancel = false

    async function run() {
      let cachedUrl = maybeGetCachedUrl(getCacheKey())
      if (cachedUrl) {
        lastSourceUrl.current = props.url
        setSignedUrl(cachedUrl)
        return
      }

      let url = await generateSignedUrl(s3, props.url, {
        download: props.download,
        filename: props.filename,
        content_type: props.content_type,
      })
      cache[getCacheKey()] = url

      if (cancel) return

      lastSourceUrl.current = props.url
      setSignedUrl(url)
    }

    run()

    return () => {
      cancel = true
    }
  }, [props.url, props.download, props.filename, props.once]) // eslint-disable-line
  // ignore s3 and submit

  return signedUrl

  function getCacheKey() {
    return `${props.url}+${props.download}+${props.filename}`
  }
}

// Cache generated URLs to prevent going through the signature process again
// and avoid things like placeholders/empty images
let cache = {}
function maybeGetCachedUrl(cacheKey, props) {
  if (!(cacheKey in cache)) return null

  let url = cache[cacheKey]
  if (isUrlExpired(url)) {
    delete cache[cacheKey]
    return null
  }

  return url
}

let URL_EXPIRED_BUFFER_SECONDS = 5
function isUrlExpired(signedUrl) {
  let parsedUrl = new URL(signedUrl)
  let amzDate = parsedUrl.searchParams.get('X-Amz-Date')
  let amzExpires = parseInt(parsedUrl.searchParams.get('X-Amz-Expires'), 10)

  if (amzDate === null || amzExpires === null) return true

  return isPast(
    addSeconds(parseISO(amzDate), amzExpires - URL_EXPIRED_BUFFER_SECONDS)
  )
}

export function useSignedPreviewUrl(props) {
  let [signedUrl, setSignedUrl] = useState(() =>
    maybeGetCachedUrl(getCacheKey())
  )
  let submit = useAwsCredentials(props)
  let s3 = useAwsS3Client(props)

  useEffect(() => {
    let cancel = false

    async function run() {
      let size = getSize()
      let cachedUrl = maybeGetCachedUrl(getCacheKey())
      if (cachedUrl) {
        setSignedUrl(cachedUrl)
        return
      }

      let { bucket, key } = getAmazonS3URI(props.url)
      let value = await submit()
      if (!value?.preview_access_point_arn) return

      let url = await getSignedUrl(
        s3,
        new GetObjectCommand({
          Bucket:
            APP_ENV === 'development' ? bucket : value.preview_access_point_arn,
          Key: APP_ENV === 'development' ? key : `${key}/w_${size}`, // keep aspect ratio
        })
      )

      cache[getCacheKey()] = url
      if (cancel) return
      setSignedUrl(url)
    }

    // reset signed URL to prevent caching previous one
    setSignedUrl(null)

    if (props.url) {
      run()
    }

    return () => {
      cancel = true
    }
  }, [props.url, props.size]) // eslint-disable-line
  // ignore s3 and submit

  return signedUrl

  function getSize() {
    return props.size || 500
  }
  function getCacheKey() {
    return `${props.url}+${getSize()}`
  }
}

// Custom wrapper to avoid issue with Client who has not synced the clock.
async function getSignedUrl(rs3, command) {
  let s3 = await rs3()
  if (!s3) {
    console.error({ type: 'missing-s3-client', command })
    throw new Error('Missing S3 client')
  }

  // set signingDate in case the client's time is off
  return _getSignedUrl(s3, command, {
    signingDate: s3.signingDate,
    expiresIn: s3.expiresIn,
  })
}

export function getS3ObjectUrl({ bucket, key, region }) {
  if (APP_ENV === 'development') {
    return `http://${LOCAL_DEV_IP}:3003/${bucket}/${key}`
  }

  return `https://${bucket}.s3.${region}.amazonaws.com/${
    key.startsWith('/') ? key.substring(1) : key
  }`
}

export function withAwsCredentials(Component) {
  return function WithAwsCredentials(props) {
    let value = useDataValue({
      context: 'aws_credentials',
      viewPath: props.viewPath,
    })
    let getCredentials = useAwsCredentials(props)

    useEffect(() => {
      if (value) return

      async function run() {
        try {
          await getCredentials()
        } catch (_) {}
      }
      run()
    }, [value]) // eslint-disable-line
    // ignore getCredentials

    return value ? <Component {...props} /> : null
  }
}
