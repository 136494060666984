import { useDataSubmit } from 'Simple/Data.js'
export function useOnClick(props) {
  let submit = useDataSubmit({
    context: 'tx_category_insured',
    viewPath: props.viewPath,
  })

  return function onSubmit() {
    submit({ type: 'cancel' })
  }
}
