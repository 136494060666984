import { useSetFlowTo, normalizePath } from 'Simple/Flow.js'
import { useMutation, useClient } from 'Data/Api'
import { useDataValue } from 'Simple/Data'
import {
  notifyError,
  useNotifications,
  notifySuccess,
} from 'Logic/Notifications.js'
import mutation_add_person_contact_methods from './mutation.graphql.js'

export default function useDataOnSubmit(props, data) {
  let setFlowTo = useSetFlowTo(props.viewPath)
  let [, notify] = useNotifications()

  let [, executeMutationAddPersonContactMethods] = useMutation(
    mutation_add_person_contact_methods
  )

  let person_id = useDataValue({
    viewPath: props.viewPath,
    context: 'edit_professional',
    path: 'employee.provider.employee_resource.employment_contract.person.id',
  })

  return async function onSubmit({ value, args }) {
    let { address_line1, city, state, zip } = value
    if (!address_line1 || !city || !state || !zip) {
      return true
    }
    let mutationAddPersonContactMethods =
      await executeMutationAddPersonContactMethods({
        person_id,
        contact_methods: [
          {
            dtype: 'postal',
            data: {
              address_line1,
              city,
              state,
              zip,
            },
          },
        ],
      })

    if (mutationAddPersonContactMethods.error) {
      notify(
        notifyError(
          'Something went wrong. Please, try again or contact support if the problem persists.'
        )
      )
      return
    }

    notify(notifySuccess('Address saved!'))

    setFlowTo(normalizePath(props.viewPath, '../No'))
    return
  }
}
