import { useDataValue } from 'Simple/Data.js'

export default function useDataConfiguration(props) {
  let person_id = useDataValue({
    viewPath: props.viewPath,
    context: 'search',
    path: 'view_details.id',
  })
  return {
    variables: { person_id },
    pause: !person_id,
  }
}
