import { useDataChange } from 'Simple/Data.js'
/** @type {import('Simple/types.js').useDataOnSubmit} */
export default function useDataOnSubmit(props, data) {
  let changeSelected = useDataChange({
    context: 'filter',
    viewPath: props.viewPath,
  })
  return async function onSubmit({ value, originalValue, args, change }) {
    changeSelected(next => {
      next.filter_id = value.filter_id
      next.filter_object_id = value.filter_object_id
      next.is_carrier = value.is_carrier
      next.plan_number = value.plan_number
      next.search_term = value.search_term
    })
  }
}
