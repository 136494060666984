import React, { useRef } from 'react'
import {
  useReactTable,
  getCoreRowModel,
  getSortedRowModel,
  flexRender,
} from '@tanstack/react-table'
import TysiaButton from 'design-system/TysiaButton/index.js'
import TysiaButtonIcon from 'design-system/TysiaButton/TysiaButtonIcon/index.js'
import ArrowLeft from 'design-system/Svgs/ArrowLeft/index.js'
import ArrowRight from 'design-system/Svgs/ArrowRight/index.js'
import TysiaText from 'design-system/TysiaText/index.js'
import { useVirtualizer } from '@tanstack/react-virtual'

function TableXPagination({
  borderColor,
  limit,
  count,
  offset,
  limitOptions,
  onLimitChange,
  onPreviousPage,
  onNextPage,
}) {
  let previousButtonProps = {
    onClick: offset === 0 ? () => null : onPreviousPage,
    opacity: offset === 0 ? 0.5 : 1,
  }

  let nextButtonProps = {
    onClick: offset + limit >= count ? () => null : onNextPage,
    opacity: offset + limit >= count ? 0.5 : 1,
  }

  return (
    <tfoot
      style={{
        position: 'relative',
        height: 44,
      }}
    >
      <tr
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          width: '100%',
          position: 'absolute',
          borderTopStyle: 'solid',
          borderTopWidth: 1,
          borderTopColor: borderColor,
          paddingTop: 8,
          paddingBottom: 8,
        }}
      >
        <td
          style={{
            display: 'contents',
          }}
        >
          <select
            value={limit}
            onChange={e => onLimitChange(parseInt(e.target.value))}
            style={{
              borderStyle: 'solid',
              borderWidth: 1,
              borderColor: borderColor,
              padding: 4,
              borderRadius: 4,
              marginLeft: 16,
              color: '#606060',
              outline: 'none',
              alignSelf: 'center',
            }}
          >
            {limitOptions.map(item => (
              <option key={item.id} id={item.id}>
                {item.label}
              </option>
            ))}
          </select>
          <TysiaText
            size="medium"
            text={`Showing ${offset + 1} to ${Math.min(
              offset + limit,
              count
            )} of ${count} results`}
            viewPath="/App"
          />
          <div
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
              marginRight: 4,
            }}
          >
            <TysiaButton
              {...previousButtonProps}
              type="ghost"
              size="small"
              viewPath="/App"
            >
              {childProps => (
                <TysiaButtonIcon {...childProps}>
                  {childProps => <ArrowLeft {...childProps} fill="none" />}
                </TysiaButtonIcon>
              )}
            </TysiaButton>
            <TysiaButton
              {...nextButtonProps}
              type="ghost"
              size="small"
              viewPath="/App"
              marginRight={8}
            >
              {childProps => (
                <TysiaButtonIcon {...childProps}>
                  {childProps => <ArrowRight {...childProps} fill="none" />}
                </TysiaButtonIcon>
              )}
            </TysiaButton>
          </div>
        </td>
      </tr>
    </tfoot>
  )
}

function TableX(props) {
  let table = useReactTable({
    columns: props.value.columns,
    data: props.value.data,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
  })

  let useRowClick = typeof props.onRowClick === 'function'

  return (
    <table
      style={{
        fontFamily: props.fontFamily,
        fontWeight: props.fontWeight,
        fontSize: props.fontSize,
        color: props.color,
        borderSpacing: 0,
        marginTop: props.marginTop,
        marginLeft: props.marginLeft,
        marginRight: props.marginRight,
        marginBottom: props.marginBottom,
        borderRadius: props.borderRadius,
        borderWidth: props.borderWidth,
        borderStyle: props.borderStyle,
        borderColor: props.borderColor,
        overflow: props.overflow,
      }}
    >
      <thead>
        {table.getHeaderGroups().map(headerGroup => (
          <tr key={headerGroup.id}>
            {headerGroup.headers.map(header => (
              <th
                key={header.id}
                colSpan={header.colSpan}
                onClick={header.column.getToggleSortingHandler()}
                style={{
                  cursor: props.cursorHeader,
                  backgroundColor: props.backgroundColorHeader,
                  borderBottomWidth: props.borderBottomWidthHeader,
                  borderBottomStyle: props.borderBottomStyleHeader,
                  borderBottomColor: header.column
                    ? 'transparent'
                    : props.borderBottomColorHeader,
                  borderLeftWidth: props.borderLeftWidthHeader,
                  borderLeftStyle: props.borderLeftStyleHeader,
                  borderLeftColor: props.borderLeftColorHeader,
                  height: props.heightHeader,
                  fontWeight: props.fontWeightHeader,
                  paddingLeft: props.paddingLeftHeader,
                  paddingRight: props.paddingRightHeader,
                }}
              >
                {header.isPlaceholder
                  ? null
                  : flexRender(
                      header.column.columnDef.header,
                      header.getContext()
                    )}
                {header.isPlaceholder
                  ? null
                  : {
                      asc: ' ↑',
                      desc: ' ↓',
                    }[header.column.getIsSorted()] ?? null}
              </th>
            ))}
          </tr>
        ))}
      </thead>
      <tbody>
        {table.getRowModel().rows.map((row, i, list) => {
          let isLast = i === list.length - 1
          return (
            <tr
              key={row.id}
              onClick={useRowClick ? () => props.onRowClick(row) : null}
              style={{
                height: props.heightRow,
                color: props.colorRow,
                cursor: useRowClick ? 'pointer' : 'default',
                backgroundColor:
                  typeof props.isSelected === 'function' &&
                  props.isSelected(row)
                    ? props.backgroundColorRowSelected
                    : !!props.onGetBackgroundRowColor &&
                      typeof props.onGetBackgroundRowColor === 'function'
                    ? props.onGetBackgroundRowColor(row)
                    : props.backgroundColorRow,
              }}
            >
              {row.getVisibleCells().map(cell => {
                return (
                  <td
                    style={{
                      borderBottomWidth: isLast
                        ? 0
                        : props.borderBottomWidthCell,
                      borderBottomStyle: isLast
                        ? 'none'
                        : props.borderBottomStyleCell,
                      borderBottomColor: isLast
                        ? 'transparent'
                        : props.borderBottomColorCell,
                      paddingLeft: props.paddingLeftCell,
                      paddingRight: props.paddingRightCell,

                      borderRightWidth: props.rightBorder
                        ? props.borderBottomWidthCell
                        : 0,
                      borderRightStyle: props.rightBorder
                        ? props.borderBottomStyleCell
                        : 'none',
                      borderRightColor: props.rightBorder
                        ? props.borderBottomColorCell
                        : 'transparent',
                    }}
                  >
                    {flexRender(cell.column.columnDef.cell, cell.getContext())}
                  </td>
                )
              })}
            </tr>
          )
        })}
      </tbody>
      {props.showPagination && (
        <TableXPagination borderColor={props.borderColor} {...props} />
      )}
    </table>
  )
}

function TableXVirtualized(props) {
  let table = useReactTable({
    columns: props.value.columns,
    data: props.value.data,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
  })

  let tableContainerRef = useRef(null)
  let rowVirtualizer = useVirtualizer({
    count: table.getRowModel().rows.length,
    getScrollElement: () => tableContainerRef.current,
    estimateSize: () => 35, // average row height
  })

  let useRowClick = typeof props.onRowClick === 'function'

  return (
    <div
      ref={tableContainerRef}
      style={{
        overflowY: 'auto',
        width: '100%',
        height: props.height || 900,
        borderRadius: props.borderRadius,
        borderWidth: props.borderWidth,
        borderStyle: props.borderStyle,
        borderColor: props.borderColor,
        borderSpacing: 0,
      }}
    >
      <div
        style={{
          height: `${rowVirtualizer.getTotalSize()}px`,
          fontFamily: props.fontFamily,
          fontWeight: props.fontWeight,
          fontSize: props.fontSize,
          color: props.color,
        }}
      >
        <table
          style={{
            tableLayout: 'fixed',
            width: '100%',
            marginTop: props.marginTop,
            marginLeft: props.marginLeft,
            marginRight: props.marginRight,
            marginBottom: props.marginBottom,
            borderSpacing: 0,
          }}
        >
          <thead>
            {table.getHeaderGroups().map(headerGroup => (
              <tr key={headerGroup.id}>
                {headerGroup.headers.map(header => (
                  <th
                    key={header.id}
                    onClick={header.column.getToggleSortingHandler()}
                    style={{
                      cursor: props.cursorHeader,
                      backgroundColor: props.backgroundColorHeader,
                      borderBottomWidth: props.borderBottomWidthHeader,
                      borderBottomStyle: props.borderBottomStyleHeader,
                      borderBottomColor: header.column
                        ? 'transparent'
                        : props.borderBottomColorHeader,
                      borderLeftWidth: props.borderLeftWidthHeader,
                      borderLeftStyle: props.borderLeftStyleHeader,
                      borderLeftColor: props.borderLeftColorHeader,
                      height: props.heightHeader,
                      fontWeight: props.fontWeightHeader,
                      paddingLeft: props.paddingLeftHeader,
                      paddingRight: props.paddingRightHeader,
                    }}
                  >
                    {flexRender(
                      header.column.columnDef.header,
                      header.getContext()
                    )}
                    {header.column.getIsSorted() ? (
                      <span>
                        {header.column.getNextSortingOrder() === 'asc'
                          ? ' ↓'
                          : ' ↑'}
                      </span>
                    ) : null}
                  </th>
                ))}
              </tr>
            ))}
          </thead>
          <tbody style={{ width: '100%' }}>
            {rowVirtualizer.getVirtualItems().map((virtualRow, index) => {
              let rows = table.getRowModel().rows
              let row = rows[virtualRow.index]
              let isLast = index === rows.length - 1

              return (
                <tr
                  key={row.id}
                  onClick={useRowClick ? () => props.onRowClick(row) : null}
                  style={{
                    height: `${virtualRow.size}px`,
                    width: '100%',
                    transform: `translateY(${
                      virtualRow.start - index * virtualRow.size
                    }px)`,
                    color: props.colorRow,
                    cursor: useRowClick ? 'pointer' : 'default',
                    backgroundColor:
                      typeof props.isSelected === 'function' &&
                      props.isSelected(row)
                        ? props.backgroundColorRowSelected
                        : !!props.onGetBackgroundRowColor &&
                          typeof props.onGetBackgroundRowColor === 'function'
                        ? props.onGetBackgroundRowColor(row)
                        : props.backgroundColorRow,
                  }}
                >
                  {row.getVisibleCells().map(cell => {
                    return (
                      <td
                        style={{
                          width: '50%',
                          borderBottomWidth: isLast
                            ? 0
                            : props.borderBottomWidthCell,
                          borderBottomStyle: isLast
                            ? 'none'
                            : props.borderBottomStyleCell,
                          borderBottomColor: isLast
                            ? 'transparent'
                            : props.borderBottomColorCell,
                          paddingLeft: props.paddingLeftCell,
                          paddingRight: props.paddingRightCell,

                          borderRightWidth: props.rightBorder
                            ? props.borderBottomWidthCell
                            : 0,
                          borderRightStyle: props.rightBorder
                            ? props.borderBottomStyleCell
                            : 'none',
                          borderRightColor: props.rightBorder
                            ? props.borderBottomColorCell
                            : 'transparent',
                        }}
                      >
                        {flexRender(
                          cell.column.columnDef.cell,
                          cell.getContext()
                        )}
                      </td>
                    )
                  })}
                </tr>
              )
            })}
          </tbody>
        </table>
      </div>
    </div>
  )
}

Table.defaultProps = {
  backgroundColorHeader: '#F5F7FA',
  backgroundColorRow: 'transparent',
  backgroundColorRowSelected: '#F5F7FA',
  borderBottomWidthCell: 1,
  borderBottomWidthHeader: 1,
  borderBottomStyleCell: 'solid',
  borderBottomStyleHeader: 'solid',
  borderBottomColorCell: '#E4E7EB',
  borderBottomColorHeader: '#E4E7EB',
  borderLeftWidthHeader: 1,
  borderLeftStyleHeader: 'solid',
  borderLeftColorHeader: '#F5F7FA',
  borderColor: '#E4E7EB',
  borderRadius: 8,
  borderStyle: 'solid',
  borderWidth: 1,
  color: '#152935',
  colorRow: '#152935',
  cursorHeader: 'pointer',
  fontFamily: 'Inter',
  fontSize: 12,
  fontWeight: 400,
  fontWeightHeader: 500,
  heightHeader: 36,
  heightRow: 44,
  marginBottom: 0,
  marginLeft: 4,
  marginRight: 4,
  marginTop: 16,
  overflow: 'hidden',
  paddingLeftCell: 16,
  paddingLeftHeader: 16,
  paddingRightCell: 16,
  paddingRightHeader: 16,
  onGetBackgroundRowColor: null,
}

export default function Table(props) {
  return !props.value ||
    !props.value?.columns ||
    !props.value?.data ? null : props.virtual ? (
    <TableXVirtualized {...props}></TableXVirtualized>
  ) : (
    <TableX {...props}></TableX>
  )
}
