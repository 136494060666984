import { useDataChange, useDataValue } from 'Simple/Data.js'

export function useOnClick(props) {
  let change = useDataChange({
    context: 'new_professional',
    viewPath: props.viewPath,
  })

  let address_id = useDataValue({
    context: 'address',
    viewPath: props.viewPath,
    path: 'id',
  })

  return function onClick() {
    change(next => {
      next.addresses = next.addresses.filter(item => {
        return item.id !== address_id
      })
    })
  }
}
