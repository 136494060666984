import { useMemo } from 'react'

/** @type {import('Simple/types.js').useDataTransform} */
export default function useDataTransform(props, data) {
  return useMemo(() => {
    if (!data) return data

    return data.map(item => ({
      id: item.id,
      text: `${item.email.address} (${item.person.first_name} ${item.person.last_name})`,
    }))
  }, [data])
}
