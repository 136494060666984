// this is an autogenerated file from App/Account/Content/Patients/Content/Content/Sections/ActiveTab/TreatmentCard/Content/TreatmentPlans/New/Steps/Content/TreatmentPlans/mutation-delete-treatment-plan.graphql
import { gql } from 'Data/Api'

export default gql`
mutation app__App_Account_Content_Patients_Content_Content_Sections_ActiveTab_TreatmentCard_Content_TreatmentPlans_New_Steps_Content_TreatmentPlans($treatment_plan_id: uuid!) {
  treatments_remove_treatment_plan(
    args: { treatment_plan_id: $treatment_plan_id }
  ) {
    id
  }
}

`