import { useDataSubmit } from 'Simple/Data.js'

export function useOnClick(props, data) {
  let submit = useDataSubmit({
    context: 'appointment_actions',
    viewPath: props.viewPath,
  })

  return async function onSubmit() {
    submit({ type: 'deleteAppointment' })
  }
}
