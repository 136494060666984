import { useDataChange } from 'Simple/Data.js'
export function useOnClick(props) {
  let change = useDataChange({
    context: 'plan_details',
    viewPath: props.viewPath,
  })
  return function onClick() {
    change(next => {
      next.ortho_coverage = false
    })
  }
}
