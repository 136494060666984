import { useMemo } from 'react'

/** @type {import('Simple/types.js').useDataTransform} */
export default function useDataTransform(props, data) {
  return useMemo(() => {
    return data?.map(d => ({
      ...d,
      text: `${d.phone.number} - ${d.description}`,
    }))
  }, [data])
}
