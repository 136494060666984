import { useSetFlowTo, normalizePath } from 'Simple/Flow.js'
import { useMutation } from 'Data/Api'
import { useDataValue } from 'Simple/Data'
import {
  notifyError,
  useNotifications,
  notifySuccess,
} from 'Logic/Notifications.js'
import { RELATIONSHIPS } from 'Data/constants.js'
import mutation_update_person_relationships from './updatePersonRelationshipsMutation.graphql.js'
import { birthday } from 'Data/validate.js'

export default function useDataOnSubmit(props, data) {
  let setFlowTo = useSetFlowTo(props.viewPath)
  let [, notify] = useNotifications()

  let [, executeMutationUpdatePersonRelationships] = useMutation(
    mutation_update_person_relationships
  )

  let patient_person_id = useDataValue({
    viewPath: props.viewPath,
    context: 'profile_patient',
    path: 'person.id',
  })
  let patient_gender = useDataValue({
    viewPath: props.viewPath,
    context: 'profile_patient',
    path: 'person.gender',
  })

  let parent_company_id = useDataValue({
    viewPath: props.viewPath,
    context: 'global',
    path: 'current_location.parent_company.id',
  })
  return async function onSubmit({ value: new_relation }) {
    if (
      !new_relation.relationship.role ||
      !new_relation.relationship.type ||
      !new_relation.person.first_name ||
      !new_relation.person.last_name ||
      !new_relation.person.gender
    ) {
      return true
    }

    if (
      new_relation.person.birth_date &&
      !birthday(new_relation.person.birth_date)
    ) {
      return true
    }

    let patient_contact_methods = []

    if (new_relation.email?.address) {
      patient_contact_methods.push({
        dtype: 'email',
        description: new_relation.email.description,
        data: {
          address: new_relation.email.address,
        },
      })
    }
    if (new_relation.address?.address_line1) {
      patient_contact_methods.push({
        dtype: 'postal',
        data: {
          ...new_relation.address,
        },
      })
    }
    if (new_relation.phone?.number) {
      patient_contact_methods.push({
        dtype: 'phone',
        description: new_relation.phone.description,
        data: {
          number: new_relation.phone.number,
          type: new_relation.phone.type,
        },
      })
    }

    let relative_contact_methods = []

    if (new_relation.email?.address) {
      relative_contact_methods.push({
        dtype: 'email',
        data: {
          address: new_relation.email.address,
        },
      })
    }
    if (new_relation.address?.address_line1) {
      relative_contact_methods.push({
        dtype: 'postal',
        data: {
          ...new_relation.address,
        },
      })
    }
    if (new_relation.phone?.number) {
      relative_contact_methods.push({
        dtype: 'phone',
        data: {
          number: new_relation.phone.number,
          type: new_relation.phone.type,
        },
      })
    }

    let { relationship } = new_relation

    let relationships = [
      {
        from_person: {
          ...new_relation.person,
          organization_id: parent_company_id,
          type: relationship.type,
          role: relationship.role,
          permitted_to_see_info: relationship.permitted_to_see_info || false,
          contact_methods: relative_contact_methods,
        },
        to_person: {
          id: patient_person_id,
          type: RELATIONSHIPS.find(r => r.id === relationship.type)[
            patient_gender
          ],
          role:
            relationship.role === 'RESPONSIBLE'
              ? 'DEPENDENT'
              : relationship.role === 'DEPENDENT'
              ? 'RESPONSIBLE'
              : 'OTHER',
          permitted_to_see_info: false,
          contact_methods: patient_contact_methods,
        },
      },
    ]

    let mutationUpdatePersonRelationshipsResponse =
      await executeMutationUpdatePersonRelationships({
        relationships: relationships,
      })
    if (mutationUpdatePersonRelationshipsResponse.error) {
      notify(
        notifyError(
          'Something went wrong. Please, try again or contact support if the problem persists.'
        )
      )
      return
    }

    notify(notifySuccess('Relationship saved!'))

    setFlowTo(normalizePath(props.viewPath, '../No'))
    return
  }
}
