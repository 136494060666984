import { useDataChange } from 'Simple/Data.js'

export function useOnClickNew(props) {
  let change = useDataChange({
    context: 'new_phone',
    viewPath: props.viewPath,
    path: 'type',
  })
  return function onClick() {
    change('form_submit')
  }
}

export function useOnClickExisting(props) {
  let change = useDataChange({
    context: 'new_phone',
    viewPath: props.viewPath,
    path: 'type',
  })
  return function onClick() {
    change('existing')
  }
}
