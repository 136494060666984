import { useMemo } from 'react'

export default function useDataTransform(props, data) {
  return useMemo(() => {
    if (!data) return data

    return data.map(assistant => {
      return {
        id: assistant.id,
        text: `${assistant.employment_contract.person.first_name} ${assistant.employment_contract.person.last_name}`,
      }
    })
  }, [data])
}
