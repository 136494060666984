import React, { useEffect, useRef } from 'react'
import { useDataValue } from 'Simple/Data'
import { useSetFlowTo, normalizePath } from 'Simple/Flow'
import { dateShortOut } from 'Data/format.js'
import View from './view.js'

export default function Logic(props) {
  let setFlowTo = useSetFlowTo(props.viewPath)
  let date = useDataValue({
    context: 'tab',
    path: 'selected.date',
    viewPath: props.viewPath,
  })

  let prevDateRef = useRef(null)

  useEffect(() => {
    let shortDate = dateShortOut(date)
    if (prevDateRef.current !== shortDate)
      setFlowTo(normalizePath(props.viewPath, 'Content'))
    prevDateRef.current = shortDate
  }, [date]) // eslint-disable-line react-hooks/exhaustive-deps

  return <View {...props} />
}
