import { useDataValue } from 'Simple/Data.js'
import { useMutation } from 'Data/Api'
import { useSetFlowTo, normalizePath } from 'Simple/Flow.js'
import { notifyError, useNotifications } from 'Logic/Notifications.js'
import mutation_add_employee_relationship from './mutation_add_employee_professional_relationship.graphql.js'
import mutation_add_other_relationship from './mutation_add_other_professional_relationship.graphql.js'

export function useOnClick(props) {
  let [, notify] = useNotifications()
  let setFlowTo = useSetFlowTo(props.viewPath)
  let person = useDataValue({
    context: 'person',
    viewPath: props.viewPath,
  })

  let patient_id = useDataValue({
    viewPath: props.viewPath,
    context: 'profile_patient',
    path: 'id',
  })

  let [, executeMutationAddEmployeeRelationship] = useMutation(
    mutation_add_employee_relationship
  )
  let [, executeMutationAddOtherRelationship] = useMutation(
    mutation_add_other_relationship
  )

  return async function onClick() {
    if (person.is_employee) {
      let mutationAddEmployeeRelationshipResponse =
        await executeMutationAddEmployeeRelationship({
          patient_id,
          provider_id:
            person.employment_contracts?.[0].employee_resources?.[0]?.resource
              .id,
        })
      if (mutationAddEmployeeRelationshipResponse.error) {
        notify(
          notifyError(
            'Something went wrong. Please, try again or contact support if the problem persists.'
          )
        )
      }
      setFlowTo(normalizePath(props.viewPath, '../../../../../No'))
      return
    }
    if (person.is_external_professional) {
      let mutationAddOtherRelationshipResponse =
        await executeMutationAddOtherRelationship({
          patient_id,
          person_id: person.id,
          external_office_id:
            person.other_professional_relationships?.[0].external_office.id,
          employee_type_id:
            person.other_professional_relationships?.[0].employee_type.id,
        })
      if (mutationAddOtherRelationshipResponse.error) {
        notify(
          notifyError(
            'Something went wrong. Please, try again or contact support if the problem persists.'
          )
        )
      }
      setFlowTo(normalizePath(props.viewPath, '../../../../../No'))
      return
    }
  }
}
