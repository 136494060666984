// this is an autogenerated file from App/Account/Content/Settings/AdminContent/SettingsContent/TreatmentCaseCloser/Configuration/Steps/AddRuleset/mutation.graphql
import { gql } from 'Data/Api'

export default gql`
mutation app__App_Account_Content_Settings_AdminContent_SettingsContent_TreatmentCaseCloser_Configuration_Steps_AddRuleset($organization_id: uuid!, $steps: jsonb!) {
  insert_treatments_allowed_request_steps_one(
    object: { organization_id: $organization_id, steps: $steps }
  ) {
    id
  }
}

`