// this is an autogenerated file from App/Account/Content/Patients/Content/Content/Sections/ActiveTab/Profile/Content/NewProfile/editPreferencesMutation.graphql
import { gql } from 'Data/Api'

export default gql`
mutation app__App_Account_Content_Patients_Content_Content_Sections_ActiveTab_Profile_Content_NewProfile(
  $id: numeric!
  $preference: String!
  $person_id: numeric!
) {
  update_vaxiom_contact_method_associations(
    where: { person_id: { _eq: $person_id } }
    _set: { preference: null }
  ) {
    affected_rows
  }

  update_vaxiom_contact_method_associations_by_pk(
    pk_columns: { id: $id }
    _set: { preference: $preference }
  ) {
    id
  }
}

`