import View from './view.js'
import React, { useEffect } from 'react'
import { useDataValue, useDataChange } from 'Simple/Data'

// This is needed to ensure
// that if there are no results
// the city will be set to the values
// of the search_term;

export default function Logic(props) {
  let changeAddressCity = useDataChange({
    context: 'new_address',
    viewPath: props.viewPath,
    path: 'city',
  })
  let search_term = useDataValue({
    viewPath: props.viewPath,
    context: 'city_search',
    path: 'term',
  })
  useEffect(() => {
    changeAddressCity(search_term)
  }, [search_term])
  return <View {...props} />
}
