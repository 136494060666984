// this is an autogenerated file from App/Account/Content/Patients/Content/Content/Sections/ActiveTab/Profile/Content/NewProfile/Content/Insurance/AddAction/Content/data.graphql
import { gql } from 'Data/Api'

export default gql`
query app__App_Account_Content_Patients_Content_Content_Sections_ActiveTab_Profile_Content_NewProfile_Content_Insurance_AddAction_Content($to_person: uuid!) {
  vaxiom_relationships(
    where: { to_person_record: { _id: { _eq: $to_person } } }
  ) {
    id
    from_person_record {
      id
      insurance_subscriptions {
        id
        insurance_plan {
          _id
          id
          number
          insurance_company {
            id
            carrier_name
          }
          insurance_plan_employers {
            employer_id
            employer {
              name
            }
          }
          insurance_billing_center {
            id
            name
          }
        }
      }
    }
  }
}

`