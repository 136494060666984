// this is an autogenerated file from App/Account/PatientOverlay/Content/Content/Apps/CustomResource/data.graphql
import { gql } from 'Data/Api'

export default gql`
query app__App_Account_PatientOverlay_Content_Content_Apps_CustomResource($patient_id: uuid!) {
  apps_custom_resources(
    where: {
      patient_id: { _eq: $patient_id }
      display_slots: { _contains: ["ADMIN_PATIENT_HEADER_STATUS"] }
    }
    order_by: { updated_at: desc_nulls_last }
    limit: 1
  ) {
    id
    type
    data
    created_at
    updated_at
    connection {
      id
      app_id
    }
  }
  apps_displays(type: ADMIN_PATIENT_HEADER_STATUS) {
    id
    displays
  }
}

`