/**
 * @param {Array<Object>} resources
 * @return {String}
 */
export function getProviders(resources) {
  let providers = resources.filter(item => item.is_provider)
  return providers.length ? providers.map(item => item.name).join(', ') : '-'
}

/**
 * @param {Array<Object>} resources
 * @return {String}
 */
export function getAssistants(resources) {
  let assistants = resources.filter(item => item.is_assistant)
  return assistants.length ? assistants.map(item => item.name).join(', ') : '-'
}
