import { useDataChange, useDataValue } from 'Simple/Data.js'
import {
  changeInsurancesEstimatedReimbursementAmount,
  changePayorsShare,
  changeDiscountsAppliedToPayorsShare,
  changePayorsConfiguration,
} from 'Data/payment-plan.js'
import { useSetFlowTo, normalizePath } from 'Simple/Flow.js'

export function useOnDelete(props) {
  let treatment_plan = useDataValue({
    context: 'treatment_plan',
    viewPath: props.viewPath,
  })
  let index = useDataValue({
    context: 'insurance_item',
    path: 'index',
    viewPath: props.viewPath,
  })
  let change = useDataChange({
    context: 'payment_plan',
    viewPath: props.viewPath,
  })
  let setFlowTo = useSetFlowTo(props.viewPath)

  return function onDelete() {
    change(next => {
      if (next.insurances.length === 1) {
        // removing the last insurance, ask for confirmation as the discounts and changes should be updated
        setFlowTo(
          normalizePath(props.viewPath, '../../RemoveInsurance/Content')
        )
      } else {
        next.insurances.splice(index, 1)
        // the remaining insurance will be marked as primary
        next.insurances[0].is_primary = true

        changeInsurancesEstimatedReimbursementAmount(next, treatment_plan)
        changePayorsShare(next)
        changeDiscountsAppliedToPayorsShare(next)
        changePayorsConfiguration(next)
      }
    })
  }
}
