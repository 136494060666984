export function getContactInfo(value) {
  if (value.length === 0) {
    return '-'
  }

  // return email if there otherwise fallback to phone
  let email = value.find(v => v.email !== null)
  if (email) return email.email.address

  let phone = value.find(v => v.phone !== null)
  if (phone) return phone.phone.number

  // no phone or email. only address is there.
  return '-'
}
