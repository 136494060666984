import { personName } from 'Data/format.js'
import { formatInTimeZone } from 'date-fns-tz'

export function getResourceList(resources) {
  return resources.map(resource => resource.name).join(', ')
}

export function getCreatedBy(created_by, sys_created, time_zone_id) {
  return `By ${personName(created_by?.person)} on ${formatInTimeZone(
    sys_created,
    time_zone_id,
    'EEE, d MMMM, yyyy'
  )}`
}
