// this is an autogenerated file from DesignSystem/DataVaxiomPatientInsuranceForm/BillingCenter/Content/Options/Edit/Content/data.graphql
import { gql } from 'Data/Api'

export default gql`
query app__DesignSystem_DataVaxiomPatientInsuranceForm_BillingCenter_Content_Options_Edit_Content($id: numeric!) {
  vaxiom_insurance_billing_centers_by_pk(id: $id) {
    id
    name
    payer_id
    contact_postal_address {
      id
      address_line1
      zip
      city
      state
    }
    contact_phone {
      id
      number
    }
    contact_email {
      id
      address
    }
  }
}

`