import { useDataValue } from 'Simple/Data.js'
import { useMemo } from 'react'

/** @type {import('Simple/types.js').useDataTransform} */
export default function useDataTransform(props, data) {
  let treatment_id = useDataValue({
    viewPath: props.viewPath,
    context: 'appointment_overlay',
    path: 'treatment_id',
  })
  let treatments = useDataValue({
    viewPath: props.viewPath,
    context: 'treatments',
  })

  return useMemo(() => {
    if (!treatment_id || !treatments) return null
    return treatments.find(i => i._id === treatment_id)
  }, [treatment_id, treatments])
}
