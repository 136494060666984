// this is an autogenerated file from App/Account/Content/Patients/Content/Content/Sections/ActiveTab/Profile/Content/NewProfile/Content/ContactInfo/Email/Emails/Content/Email/Address/Related/Content/Popup/Content/Persons/Person/Relation/mutation.graphql
import { gql } from 'Data/Api'

export default gql`
mutation app__App_Account_Content_Patients_Content_Content_Sections_ActiveTab_Profile_Content_NewProfile_Content_ContactInfo_Email_Emails_Content_Email_Address_Related_Content_Popup_Content_Persons_Person_Relation(
  $from_person_id: numeric!
  $to_person_id: numeric!
  $type: String!
  $opposite_type: String!
) {
  from: insert_vaxiom_relationships_one(
    object: {
      from_person: $from_person_id
      to_person: $to_person_id
      type: $type
      role: "OTHER"
      permitted_to_see_info: false
    }
  ) {
    id
  }
  to: insert_vaxiom_relationships_one(
    object: {
      from_person: $to_person_id
      to_person: $from_person_id
      type: $opposite_type
      role: "OTHER"
      permitted_to_see_info: false
    }
  ) {
    id
  }
}

`