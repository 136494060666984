import View from './view.js'
import { useDataChange, useDataValue } from 'Simple/Data'
import React, { useMemo } from 'react'
import { dateISOToDateAndTime } from 'Data/format'
import { join } from 'Data/aggregate'
import TysiaCheckbox from 'DesignSystem/TysiaCheckbox/index.js'
import TysiaCheckboxInput from 'DesignSystem/TysiaCheckbox/TysiaCheckboxInput/index.js'
import ActionsAction from './ActionsAction/index.js'
import NameAction from './NameAction/index.js'

export default function Logic(props) {
  let notes = useDataValue({
    context: 'note_templates',
    viewPath: props.viewPath,
  })
  let selected = useDataValue({
    context: 'note_templates_tab',
    path: 'note_templates',
    viewPath: props.viewPath,
  })
  let change = useDataChange({
    context: 'note_templates_tab',
    viewPath: props.viewPath,
  })

  let tableData = useMemo(
    () => ({
      columns: [
        {
          header: '',
          accessorKey: 'id',
          cell: cell => (
            <TysiaCheckbox
              onChange={value => {
                change(next => {
                  if (value) {
                    next.note_templates.push(cell.getValue())
                  } else {
                    next.note_templates = next.note_templates.filter(
                      item => item !== cell.getValue()
                    )
                  }
                })
              }}
              value={selected.includes(cell.getValue())}
              viewPath={props.viewPath}
            >
              {childProps => <TysiaCheckboxInput {...childProps} />}
            </TysiaCheckbox>
          ),
        },
        {
          header: 'Name',
          accessorKey: 'name',
          cell: cell => {
            return (
              <NameAction
                data={cell.row.original}
                viewPath={`${props.viewPath}/NameAction(${cell.id})`}
              />
            )
          },
        },
        {
          header: 'Used in',
          accessorKey: 'tags',
          cell: cell => join(cell.getValue()),
        },
        {
          header: 'Last edited by',
          accessorKey: 'updated_by',
          cell: cell => cell.getValue() ?? '-',
        },
        {
          header: 'Last edit',
          accessorKey: 'updated_at',
          cell: cell => dateISOToDateAndTime(cell.getValue()),
        },
        {
          header: '',
          accessorKey: 'organization_id',
          cell: cell => {
            return (
              <ActionsAction
                data={cell.row.original}
                viewPath={`${props.viewPath}/ActionsAction(${cell.id})`}
              />
            )
          },
        },
      ],
      data: notes ?? [],
    }),
    [notes, selected, props.viewPath]
  )

  return <View viewPath={props.viewPath} tableData={tableData} />
}
