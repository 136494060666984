import { useMemo } from 'react'
import { useDataValue } from 'Simple/Data.js'

export default function useDataTransform(props, data) {
  let type_id = useDataValue({
    context: 'appointment',
    path: 'type_id',
    viewPath: props.viewPath,
  })

  return useMemo(() => ({ type_id }), [type_id])
}
