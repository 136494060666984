import { useDataSubmit } from 'Simple/Data'

export default function useDataOnSubmit(props) {
  let submit = useDataSubmit({
    context: 'appointment_actions',
    viewPath: props.viewPath,
  })

  return function onSubmit({ value }) {
    submit({ type: 'seat', ...value })
  }
}
