// this is an autogenerated file from App/Account/Content/Patients/Content/Content/Sections/ActiveTab/TreatmentCard/Content/TreatmentPlans/New/Steps/Content/Contracts/Content/Payor/data.graphql
import { gql } from 'Data/Api'

export default gql`
query app__App_Account_Content_Patients_Content_Content_Sections_ActiveTab_TreatmentCard_Content_TreatmentPlans_New_Steps_Content_Contracts_Content_Payor($id: numeric!) {
  treatments_payment_plan_payors(where: { id: { _eq: $id } }) {
    id
    is_primary
    person_id
    treatment_plan_id
    contract_id
    person {
      id
      first_name
      last_name
      birth_date
    }
    treatment_plan {
      id
      tx_id
    }
    contract {
      id
      file
      template_name
      generated_date
      signed_date
      html_file_uuid
      document_template_id
      signature_request_id
      signature_request {
        id
        status
      }
    }
  }
}

`