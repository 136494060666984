import { appointmentTypeColor } from 'Data/format.js'

export function getColor(appointment_types, type_id) {
  let appointment_type = appointment_types.find(item => item.id === type_id)
  return appointmentTypeColor(appointment_type?.color_id)
}

export function getName(appointment_types, type_id) {
  return type_id
    ? appointment_types.find(item => item.id === type_id).name
    : 'Select appointment type'
}
