import { useDataSubmit, useDataValue } from 'Simple/Data'

export function useOnClick(props) {
  let persons = useDataValue({
    context: 'same_address_persons',
    viewPath: props.viewPath,
  })
  let submit = useDataSubmit({
    viewPath: props.viewPath,
    context: 'new_address',
  })
  return function useOnClick(value) {
    submit({
      type: 'add_new_relations',
      relations: persons.filter(p => p.type),
    })
  }
}
