// this is an autogenerated file from App/Account/Content/Settings/AdminContent/SettingsContent/IntegrationsApplications/data.graphql
import { gql } from 'Data/Api'

export default gql`
query app__App_Account_Content_Settings_AdminContent_SettingsContent_IntegrationsApplications{
  # TODO: drop filter once we support installing apps
  apps_apps(
    where: { companies: { status: { _eq: granted } } }
    order_by: [{ name: asc }]
  ) {
    id
    name: metadata(path: "name")
    logo: metadata(path: "logo")
    description: metadata(path: "description")
    website: metadata(path: "website")
    connection: metadata(path: "connection")
    sync: metadata(path: "sync")
    automations: metadata(path: "automations")
    tags: metadata(path: "tags")
    connections {
      id
      status
    }
    companies {
      id
      status
    }
  }
}

`