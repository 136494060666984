// this is an autogenerated file from App/Account/Content/Patients/Content/Content/Sections/ActiveTab/Profile/Content/NewProfile/Content/ContactInfo/Phone/Phones/sendMedicalFormMutation.graphql
import { gql } from 'Data/Api'

export default gql`
mutation app__App_Account_Content_Patients_Content_Content_Sections_ActiveTab_Profile_Content_NewProfile_Content_ContactInfo_Phone_Phones(
  $patient_id: uuid!
  $contact_method_association_id: numeric
) {
  medical_form_send_request(
    patient_id: $patient_id
    contact_method_association_id: $contact_method_association_id
  ) {
    status
  }
}

`