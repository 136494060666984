import { useDataChange, useDataValue } from 'Simple/Data.js'
import {
  getNumberOfInstallmentsPerMonth,
  getPayorShareAfterDiscounts,
  LOCK,
  INCREMENT_STEP,
} from 'Data/payment-plan.js'

export function useOnChange(props) {
  let index = useDataValue({
    context: 'payor_item',
    path: 'index',
    viewPath: props.viewPath,
  })
  let change = useDataChange({
    context: 'payment_plan',
    viewPath: props.viewPath,
  })

  return function onChange(value) {
    change(next => {
      let payor = next.payors[index]
      if (value === payor.downpayment_amount) return

      let base = getPayorShareAfterDiscounts(payor, next)

      // since there is an increment of 5, it doesn't allow reaching the max value
      payor.downpayment_amount =
        value > payor.downpayment_amount && value + INCREMENT_STEP > base
          ? base
          : value

      if (payor.downpayment_amount === base) {
        // no installment amount or installment length as paid in full
        payor.installment_amount = 0
        payor.payment_plan_length = 0
        payor.lock = null
      } else if (payor.payment_plan_length === 0) {
        payor.payment_plan_length = 1
        let total_installments_amount = base - payor.downpayment_amount
        let number_of_installments_per_month = getNumberOfInstallmentsPerMonth(
          payor.installment_interval
        )
        payor.installment_amount = parseFloat(
          (
            total_installments_amount / number_of_installments_per_month
          ).toFixed(2)
        )
        payor.lock = null
      } else if (!payor.lock || payor.lock === LOCK.PAYMENT_PLAN_LENGTH) {
        let total_installments_amount = base - payor.downpayment_amount
        let number_of_installments_per_month = getNumberOfInstallmentsPerMonth(
          payor.installment_interval
        )
        let total_number_of_installments =
          payor.payment_plan_length * number_of_installments_per_month
        let installment_amount =
          total_installments_amount / total_number_of_installments

        payor.installment_amount = parseFloat(installment_amount.toFixed(2))
      } else if (payor.lock === LOCK.INSTALLMENT_AMOUNT) {
        let number_of_installments_per_month = getNumberOfInstallmentsPerMonth(
          payor.installment_interval
        )
        let monthly_installment_amount =
          payor.installment_amount * number_of_installments_per_month
        // prevent it from going over 100% of the payor's share
        let downpayment_amount = Math.min(
          payor.downpayment_amount,
          base - monthly_installment_amount
        )
        let total_installments_amount = base - downpayment_amount

        payor.payment_plan_length = Math.ceil(
          total_installments_amount / monthly_installment_amount
        )
        payor.downpayment_amount = downpayment_amount
      }
    })
  }
}
