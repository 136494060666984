import React, { useEffect, useRef } from 'react'
import { useDataSubmit, useDataValue } from 'Simple/Data.js'

import View from './view.js'

export default function Logic(props) {
  let status = useDataValue({
    context: 'signature',
    path: 'status',
    viewPath: props.viewPath,
  })
  let submit = useDataSubmit({
    context: 'payor',
    viewPath: props.viewPath,
  })
  let prevStatusRef = useRef(status)

  useEffect(() => {
    if (status === 'signed' && prevStatusRef.current !== status) {
      // refetch payor's details to reflect that the contract has been signed
      submit({ type: 'reFetch' })
    }

    prevStatusRef.current = status
  }, [status]) // eslint-disable-line react-hooks/exhaustive-deps
  // ignore submit

  return <View {...props} />
}
