// this is an autogenerated file from App/Account/Content/Patients/Content/Content/Sections/ActiveTab/TreatmentCard/Content/TreatmentCard/Events/Content/Content/Events/Content/Compact/Past/Content/Content/TreatmentNoteRow/data.graphql
import { gql } from 'Data/Api'

export default gql`
query app__App_Account_Content_Patients_Content_Content_Sections_ActiveTab_TreatmentCard_Content_TreatmentCard_Events_Content_Content_Events_Content_Compact_Past_Content_Content_TreatmentNoteRow($id: uuid!) {
  vaxiom_notes(where: { _id: { _eq: $id } }) {
    id
    note
    date: sys_created
    creator {
      id
      person {
        id
        first_name
        last_name
      }
    }
    editor {
      id
      person {
        id
        first_name
        last_name
      }
    }
  }
}

`