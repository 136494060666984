import { useDataValue } from 'Simple/Data.js'
import useStableValue from 'Logic/useStableValue.js'

export default function useDataConfiguration(props) {
  let organization_id = useDataValue({
    context: 'patient',
    path: 'person.organization_id',
    viewPath: props.viewPath,
  })

  let search = useDataValue({
    context: 'selected',
    path: 'carrier_search',
    viewPath: props.viewPath,
  })

  let selected_carrier_id = useDataValue({
    context: 'plan_details',
    path: 'carrier',
    viewPath: props.viewPath,
  })

  let search_term = useStableValue(`%${search}%`)

  return {
    variables: {
      organization_id,
      search_term,
      selected_carrier_id,
      search_selected_carrier_id: !!selected_carrier_id,
    },
  }
}
