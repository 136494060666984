// this is an autogenerated file from DesignSystem/DataVaxiomPatientInsuranceAssignForm/Content/PolicyHolder/Options/AddRelationship/Content/Address/Existing/data.graphql
import { gql } from 'Data/Api'

export default gql`
query app__DesignSystem_DataVaxiomPatientInsuranceAssignForm_Content_PolicyHolder_Options_AddRelationship_Content_Address_Existing($person_id: uuid!) {
  vaxiom_contact_method_associations(
    where: {
      person: { _id: { _eq: $person_id } }
      contact_method: { dtype: { _eq: "postal" } }
    }
  ) {
    id
    contact_order
    contact_method_id
    postal_address {
      id
      address_line1
      city
      state
      zip
    }
  }
}

`