// this is an autogenerated file from DesignSystem/DataVaxiomRelationshipsItem/Edit/NonPatient/Email/Emails/Content/Email/Actions/sendHubInviteMutation.graphql
import { gql } from 'Data/Api'

export default gql`
mutation app__DesignSystem_DataVaxiomRelationshipsItem_Edit_NonPatient_Email_Emails_Content_Email_Actions($email: citext!, $location_id: numeric!) {
  auth_patient_invite(email: $email, location_id: $location_id) {
    ok
  }
}

`