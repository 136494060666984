import { useDataValue } from 'Simple/Data.js'
import { useMutation } from 'Data/Api.js'
import {
  notifyError,
  notifySuccess,
  useNotifications,
} from 'Logic/Notifications.js'
import { useSetFlowTo, normalizePath } from 'Simple/Flow.js'

import mutation from './mutation.graphql.js'

/** @type {import('Simple/types.js').useDataOnSubmit} */
export default function useDataOnSubmit(props, data) {
  let treatment_plan = useDataValue({
    context: 'treatment_plan',
    viewPath: props.viewPath,
  })
  let [, notify] = useNotifications()
  let setFlowTo = useSetFlowTo(props.viewPath)
  let [, executeMutation] = useMutation(mutation)

  return async function onSubmit({ value, originalValue, args, change }) {
    if (
      (value.contact_method_type === 'email' && !value.email_id) ||
      (value.contact_method_type === 'phone' && !value.phone_id)
    ) {
      return true
    }

    let mutationResponse = await executeMutation({
      treatment_plan_id: treatment_plan.id,
      contact_method_association_id:
        value.contact_method_type === 'email' ? value.email_id : value.phone_id,
    })
    if (mutationResponse.error) {
      notify(
        notifyError(
          'There was a problem sending the treatment plan request. Please try again.'
        )
      )
      return
    }

    setFlowTo(normalizePath(props.viewPath, '../No'))
    notify(notifySuccess('Treatment plan request sent successfully'))
  }
}
