export function smsThreadName(
  name,
  inbound_name,
  mobile_numbers_inbound,
  fallback
) {
  if (name === inbound_name) {
    let number_persons = mobile_numbers_inbound
      .flatMap(item => item.vaxiom_persons)
      .filter(item => item?.vaxiom_person) //remove any potential empties
      .map(
        person =>
          `${person.vaxiom_person.first_name} ${person.vaxiom_person.last_name}`
      )

    return number_persons?.length ? number_persons.join(', ') : fallback
  }

  return name
}
