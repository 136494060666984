import React, { useEffect } from 'react'
import { useDataSubmit, useDataValue } from 'Simple/Data.js'

import View from './view.js'

export default function Logic(props) {
  let submit = useDataSubmit({
    context: 'treatments',
    viewPath: props.viewPath,
  })
  let existing_patient_no_treatments = useDataValue({
    context: 'appointment_overlay',
    path: 'existing_patient_no_treatments',
    viewPath: props.viewPath,
  })

  useEffect(() => {
    submit({ type: 'reFetch' })
  }, [existing_patient_no_treatments]) // eslint-disable-line react-hooks/exhaustive-deps

  return <View {...props} />
}
