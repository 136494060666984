import { useSetFlowTo, normalizePath } from 'Simple/Flow.js'
import { useMutation, useClient } from 'Data/Api'
import { useDataValue } from 'Simple/Data'
import {
  notifyError,
  useNotifications,
  notifySuccess,
} from 'Logic/Notifications.js'
import { street, state, city, zip } from 'Data/validate.js'
import { RELATIONSHIPS } from 'Data/constants.js'
import mutation_new_contact_method_association from './newContactMethodAssociationMutation.graphql.js'
import mutation_add_person_contact_methods from './addPersonContactMethodsMutation.graphql.js'
import mutation_update_person_relationships from './updatePersonRelationshipsMutation.graphql.js'
import query from './query.graphql.js'

export default function useDataOnSubmit(props, data) {
  let onActionAddNewRelations = useDataOnActionAddNewRelations(props)
  let onActionNewAddressForPatient = useDataOnActionNewAddressForPatient(props)
  let onActionAddressForPatient = useDataOnActionAddressForPatient(props)
  let onActionAddressFromRelative = useDataOnActionAddressFromRelative(props)
  let onActionFormSubmit = useDataOnActionFormSubmit(props)

  return async function onSubmit({ value, args }) {
    switch (args?.type) {
      case 'add_new_relations': {
        return onActionAddNewRelations({ value, args })
      }
      case 'new_address_for_patient': {
        return onActionNewAddressForPatient({ value, args })
      }
      case 'address_for_patient': {
        return onActionAddressForPatient({ value, args })
      }
      case 'form_submit': {
        return onActionFormSubmit({ value, args })
      }
      default: {
        onActionAddressFromRelative({ value, args })
      }
    }
  }
}

function useDataOnActionFormSubmit(props) {
  let setFlowTo = useSetFlowTo(props.viewPath)
  let client = useClient()
  let [, notify] = useNotifications()

  let person_id = useDataValue({
    viewPath: props.viewPath,
    context: 'edit_relation',
    path: 'from_person',
  })

  let [, executeMutationAddPersonContactMethods] = useMutation(
    mutation_add_person_contact_methods
  )
  return async function onAction({ value: address, args }) {
    if (
      !street(address.address_line1) ||
      !city(address.city) ||
      !state(address.state) ||
      !zip(address.zip)
    ) {
      return true
    }

    let { data } = await client
      .query(query, {
        address_line1: address.address_line1,
        city: address.city,
        state: address.state,
        zip: address.zip,
      })
      .toPromise()

    if (data?.vaxiom_contact_postal_addresses.length === 0) {
      let mutationAddPersonContactMethods =
        await executeMutationAddPersonContactMethods({
          person_id,
          contact_methods: [
            {
              dtype: 'postal',
              data: {
                ...address,
              },
            },
          ],
        })

      if (mutationAddPersonContactMethods.error) {
        notify(
          notifyError(
            'Something went wrong. Please, try again or contact support if the problem persists.'
          )
        )
        return
      }

      notify(notifySuccess('Address saved!'))

      setFlowTo(normalizePath(props.viewPath, '../No'))
      return
    } else {
      setFlowTo(normalizePath(props.viewPath, './SearchForAddress/Content'))
    }
  }
}

function useDataOnActionAddNewRelations(props) {
  let setFlowTo = useSetFlowTo(props.viewPath)
  let [, notify] = useNotifications()
  let [, executeMutationUpdatePersonRelationships] = useMutation(
    mutation_update_person_relationships
  )
  let person_id = useDataValue({
    viewPath: props.viewPath,
    context: 'profile_patient',
    path: 'person.id',
  })
  let person_gender = useDataValue({
    viewPath: props.viewPath,
    context: 'profile_patient',
    path: 'person.gender',
  })
  return async function onAction({ value: address, args }) {
    let relations = args.relations.map(rel => ({
      from_person: {
        id: rel.id,
        type: rel.type,
        role: rel.role,
        permitted_to_see_info: rel.role === 'RESPONSIBLE',
        contact_methods: [],
      },
      to_person: {
        id: person_id,
        type: RELATIONSHIPS.find(r => r.id === rel.type)[person_gender],
        role:
          rel.role === 'RESPONSIBLE'
            ? 'DEPENDENT'
            : rel.role === 'DEPENDENT'
            ? 'RESPONSIBLE'
            : 'OTHER',
        permitted_to_see_info: rel.role === 'DEPENDENT',
        contact_methods: [
          {
            dtype: 'postal',
            data: {
              ...address,
            },
          },
        ],
      },
    }))

    let mutationUpdatePersonRelationshipsResponse =
      await executeMutationUpdatePersonRelationships({
        relationships: relations,
      })
    if (mutationUpdatePersonRelationshipsResponse.error) {
      notify(
        notifyError(
          'Something went wrong. Please, try again or contact support if the problem persists.'
        )
      )
      return
    }

    notify(notifySuccess('Address saved!'))

    setFlowTo(normalizePath(props.viewPath, '../No'))
    return
  }
}

function useDataOnActionNewAddressForPatient(props) {
  let setFlowTo = useSetFlowTo(props.viewPath)
  let [, notify] = useNotifications()

  let [, executeMutationAddPersonContactMethods] = useMutation(
    mutation_add_person_contact_methods
  )

  let person_id = useDataValue({
    viewPath: props.viewPath,
    context: 'edit_relation',
    path: 'from_person',
  })
  return async function onAction({ value: address }) {
    // if the address doesn't exist,
    // and we don't want to add a relation,
    // we just save the new address and add
    // a new association
    let mutationAddPersonContactMethods =
      await executeMutationAddPersonContactMethods({
        person_id,
        contact_methods: [
          {
            dtype: 'postal',
            data: {
              ...address,
            },
          },
        ],
      })

    if (mutationAddPersonContactMethods.error) {
      notify(
        notifyError(
          'Something went wrong. Please, try again or contact support if the problem persists.'
        )
      )
      return
    }

    notify(notifySuccess('Address saved!'))

    setFlowTo(normalizePath(props.viewPath, '../No'))
    return
  }
}

function useDataOnActionAddressForPatient(props) {
  let setFlowTo = useSetFlowTo(props.viewPath)
  let [, notify] = useNotifications()

  let [, executeMutationNewContactMethodAssociation] = useMutation(
    mutation_new_contact_method_association
  )
  let person_id = useDataValue({
    viewPath: props.viewPath,
    context: 'edit_relation',
    path: 'from_person',
  })
  return async function onAction({ args }) {
    // if we found that the address already exists,
    // but we don't want to add a realtionship,
    // we just link the patient to the existing contact_method
    let mutationNewContactMethodAssociationRespone =
      await executeMutationNewContactMethodAssociation({
        contact_method_id: args.contact_method_id,
        person_id,
      })

    if (mutationNewContactMethodAssociationRespone.error) {
      notify(
        notifyError(
          'Something went wrong. Please, try again or contact support if the problem persists.'
        )
      )
      return
    }

    notify(notifySuccess('Address saved!'))

    setFlowTo(normalizePath(props.viewPath, '../No'))
    return
  }
}

function useDataOnActionAddressFromRelative(props) {
  let setFlowTo = useSetFlowTo(props.viewPath)
  let [, notify] = useNotifications()

  let [, executeMutationNewContactMethodAssociation] = useMutation(
    mutation_new_contact_method_association
  )

  let person_id = useDataValue({
    viewPath: props.viewPath,
    context: 'edit_relation',
    path: 'from_person',
  })
  return async function onAction({ value: address }) {
    // if we select a relative's address we save it
    let mutationNewContactMethodAssociationRespone =
      await executeMutationNewContactMethodAssociation({
        contact_method_id: address.contact_method_id,
        person_id,
      })

    if (mutationNewContactMethodAssociationRespone.error) {
      notify(
        notifyError(
          'Something went wrong. Please, try again or contact support if the problem persists.'
        )
      )
      return
    }

    notify(notifySuccess('Address saved!'))

    setFlowTo(normalizePath(props.viewPath, '../No'))
    return
  }
}
