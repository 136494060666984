import { useMemo } from 'react'
import { useDataValue } from 'Simple/Data.js'

/** @type {import('Simple/types.js').useDataTransform} */
export default function useDataTransform(props, data) {
  let connection_app_tags = useDataValue({
    context: 'connection_definition',
    path: 'tags',
    viewPath: props.viewPath,
  })
  return useMemo(() => {
    if (!data?.apps_automations) return null

    let automations = data.apps_automations.automations
      // only keep the missing automations
      .filter(automation => !automation.is_created)
      .map(automation => ({
        ...automation,
        step_type: 'automation',
        status: 'pending',
      }))
    // show setup preferences when iTero connection
    // TODO: ideally should be derived through app's configuration once custom properties/preferences are supported
    // TODO: alternative for now pick up it's iTero from the app's name
    let steps =
      Array.isArray(connection_app_tags) &&
      connection_app_tags.includes('itero')
        ? [
            {
              id: 'preferences',
              step_type: 'preferences',
              status: 'pending',
              name: 'Set Preferences',
              ...data.apps_connection_resource_properties[0],
            },
            ...automations,
          ]
        : automations

    return {
      steps: steps.map((step, index) => ({ ...step, index })),
      current_index: 0,
    }
  }, [connection_app_tags, data])
}
