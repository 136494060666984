import { useDataChange } from 'Simple/Data.js'

export function useOnClick(props) {
  let change = useDataChange({
    context: 'payment_plan',
    viewPath: props.viewPath,
  })
  return function onClick() {
    change(next => {
      next.charges.push({
        id: crypto.randomUUID(),
        type_id: null,
        name: '',
        amount: 0,
        is_included_in_insurance_claim: true,
      })
    })
  }
}
