import { normalizePath, useSetFlowTo } from 'Simple/Flow.js'
export function useOnClick(props) {
  let setFlowTo = useSetFlowTo(props.viewPath)
  return function onClick(action) {
    switch (action) {
      case 'ADD':
        return addPolicyholder()
      default:
        return
    }
  }

  function addPolicyholder() {
    setFlowTo(normalizePath(props.viewPath, './AddRelationship/Content'))
  }
}
