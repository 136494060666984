// this is an autogenerated file from App/Account/Content/Patients/Content/Content/Sections/ActiveTab/Profile/Content/NewProfile/Content/ContactInfo/Postal/Addresses/Content/Address/Edit/Content/query.graphql
import { gql } from 'Data/Api'

export default gql`
query app__App_Account_Content_Patients_Content_Content_Sections_ActiveTab_Profile_Content_NewProfile_Content_ContactInfo_Postal_Addresses_Content_Address_Edit_Content(
  $contact_method_id: numeric!
  $person_id: numeric
) {
  vaxiom_contact_method_associations_aggregate(
    where: {
      contact_method_id: { _eq: $contact_method_id }
      person_id: { _neq: $person_id }
    }
  ) {
    aggregate {
      count
    }
  }
}

`