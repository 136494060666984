import { useDataValue } from 'Simple/Data.js'
/** @type {import('Simple/types.js').useDataConfiguration} */
export default function useDataConfiguration(props, data) {
  let person_id = useDataValue({
    viewPath: props.viewPath,
    context: 'person',
    path: 'id',
  })

  let original_patient_id = useDataValue({
    viewPath: props.viewPath,
    context: 'profile_patient',
    path: 'person._id',
  })

  return {
    variables: { to_person: person_id, original_patient_id },
    pause: !person_id,
  }
}
