// this is an autogenerated file from DesignSystem/DataVaxiomPatientInsuranceForm/Employers/EmployerItem/Employer/Content/Options/Edit/Content/mutation.graphql
import { gql } from 'Data/Api'

export default gql`
mutation app__DesignSystem_DataVaxiomPatientInsuranceForm_Employers_EmployerItem_Employer_Content_Options_Edit_Content(
  $employer_id: numeric!
  $name: String!
  $postal_id: numeric
  $address_line1: String
  $zip: String
  $city: String
  $state: String
  $phone_id: numeric
  $number: String
  $email_id: numeric
  $email: String
) {
  edit_employer(
    args: {
      employer_id: $employer_id
      employer_name: $name
      address_id: $postal_id
      _address_line1: $address_line1
      _city: $city
      _state: $state
      _zip: $zip
      phone_id: $phone_id
      phone_no: $number
      email_id: $email_id
      email_address: $email
    }
  ) {
    id
  }
}

`