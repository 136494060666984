import { useSetFlowTo, normalizePath } from 'Simple/Flow.js'
import { useMutation } from 'Data/Api'
import { useDataValue } from 'Simple/Data'
import {
  notifyError,
  useNotifications,
  notifySuccess,
} from 'Logic/Notifications.js'
import { RELATIONSHIPS } from 'Data/constants.js'
import mutation_update_person_relationships from './updatePersonRelationshipsMutation.graphql.js'

export default function useDataOnSubmit(props, data) {
  let setFlowTo = useSetFlowTo(props.viewPath)
  let [, notify] = useNotifications()

  let [, executeMutationUpdatePersonRelationships] = useMutation(
    mutation_update_person_relationships
  )

  let patient_person_id = useDataValue({
    viewPath: props.viewPath,
    context: 'profile_patient',
    path: 'person.id',
  })
  let patient_gender = useDataValue({
    viewPath: props.viewPath,
    context: 'profile_patient',
    path: 'person.gender',
  })

  return async function onSubmit({ value: edit_relation }) {
    let { type, role, permitted_to_see_info } = edit_relation
    if (!type || !role) {
      return true
    }

    let inv_type = RELATIONSHIPS.find(r => r.id === type)[patient_gender]
    let inv_role =
      role === 'RESPONSIBLE'
        ? 'DEPENDENT'
        : role === 'DEPENDENT'
        ? 'RESPONSIBLE'
        : 'OTHER'
    let inv_permitted_to_see_info = false

    let mutationUpdatePersonRelationshipsResponse =
      await executeMutationUpdatePersonRelationships({
        patient_person_id,
        relative_person_id: edit_relation.from_person,
        type,
        role,
        permitted_to_see_info,
        inv_type,
        inv_role,
        inv_permitted_to_see_info,
      })
    if (mutationUpdatePersonRelationshipsResponse.error) {
      notify(
        notifyError(
          'Something went wrong. Please, try again or contact support if the problem persists.'
        )
      )
      return
    }

    notify(notifySuccess('Relationship Updated!'))

    setFlowTo(normalizePath(props.viewPath, '../No'))
  }
}
