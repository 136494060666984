import { useDataSubmit } from 'Simple/Data.js'
import { normalizePath, useSetFlowTo } from 'Simple/Flow.js'
export function useOnClickSave(props) {
  let submit = useDataSubmit({
    context: 'plan_details',
    viewPath: props.viewPath,
  })
  return function onClick() {
    submit({ type: 'edit' })
  }
}

export function useOnClickNew(props) {
  let setFlowTo = useSetFlowTo(props.viewPath)
  return function onClick() {
    setFlowTo(
      normalizePath(
        props.viewPath,
        '../../../../../../../../../Filter/RegisterAction/Content'
      )
    )
    setFlowTo(normalizePath(props.viewPath, '../../../../../No'))
    setFlowTo(normalizePath(props.viewPath, './No'))
  }
}
