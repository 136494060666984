import { useDataValue, useDataSubmit } from 'Simple/Data.js'
import { normalizePath, useSetFlowTo } from 'Simple/Flow.js'
import { patientName } from 'Data/format.js'

export function useOnClick(props) {
  let setFlowTo = useSetFlowTo(props.viewPath)

  let global_submit = useDataSubmit({
    context: 'global',
    viewPath: props.viewPath,
  })

  let person = useDataValue({
    viewPath: props.viewPath,
    context: 'relationship',
    path: 'from_person_record',
  })
  let action = useDataValue({
    viewPath: props.viewPath,
    context: 'option',
    path: 'id',
  })
  return function onClick() {
    switch (action) {
      case 'REMOVE':
        return removeRelation()
      case 'DETAILS':
        return showDetails()
      case 'OPEN_CARD':
        return goToPatient()
      default:
        return
    }
  }

  function removeRelation() {
    setFlowTo(normalizePath(props.viewPath, '../../Delete/Content'))
  }

  async function showDetails() {
    if (person.patient?._id) {
      setFlowTo(normalizePath(props.viewPath, '../../Edit/Patient'))
    } else {
      setFlowTo(normalizePath(props.viewPath, '../../Edit/NonPatient'))
    }
  }
  async function goToPatient() {
    global_submit({
      type: 'tabs/add',
      tab: {
        id: person.patient._id,
        type: 'Patient',
        name: patientName(person),
        tab: 'profile',
        viewPath: '/App/Account/Content/Patients',
        patient_id: person.patient._id,
      },
    })
  }
}
