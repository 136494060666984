import { DEFAULT_TIMEZONE } from 'Data/constants.js'
import { useDataSubmit, useDataValue } from 'Simple/Data'

export function useOnChange(props) {
  let locations = useDataValue({
    context: 'locations',
    viewPath: props.viewPath,
  })
  let submit = useDataSubmit({
    context: 'tab',
    viewPath: props.viewPath,
  })

  return function onChange(value) {
    if (value) {
      let location = locations.find(item => item._id === value)
      submit({
        type: 'setLocation',
        location_id: value,
        vaxiom_location_id: location.vaxiom_id,
        time_zone_id: location?.time_zone_id || DEFAULT_TIMEZONE,
        location_name: location?.text,
      })
    }
  }
}
