import { useEffect } from 'react'
import getPdfMake from 'Logic/pdfmake.js'
import { isAfter } from 'date-fns'
import {
  timeToMinutes,
  patientName,
  formatTimegridAppointmentTime,
} from 'Data/format.js'

/**
 * @typedef {{id: string, resourceId: string, start: number, end: number, startStr: string, endStr: string, extendedProps: Record<string, any>}} Event
 * @typedef {{start: number, end: number}} Slot
 * @typedef {{start: number, end: number, slots: number}} SlotGroup
 */

let PAGE_WIDTH = 841.89
let PAGE_HEIGHT = 595

/**
 * @param {{calendarRef: React.MutableRefObject<import('@fullcalendar/react').default | null>, hipaa: boolean, fileName: string}} param
 * @returns
 */
export default function useOnPrint({ calendarRef, hipaa, fileName }) {
  async function handleCustomPrintEvent() {
    let api = calendarRef?.current?.getApi()
    if (!api) {
      return
    }

    let pdfMake = await getPdfMake()

    let pdf = generateCalendarPDF(api, pdfMake, hipaa)
    if (pdf) {
      pdf.download(fileName)
    }
  }

  useEffect(() => {
    window.addEventListener('customBeforePrint', handleCustomPrintEvent)

    return () => {
      window.removeEventListener('customBeforePrint', handleCustomPrintEvent)
    }
  }, [hipaa])

  return {}
}

/**
 *
 * @param {import('@fullcalendar/core').CalendarApi} calendar
 * @param {import('pdfmake/build/pdfmake')} pdfmake
 * @param {boolean} hipaa
 * @returns
 */
function generateCalendarPDF(calendar, pdfmake, hipaa) {
  // Gather data from FullCalendar
  let resources = calendar.getResources()
  resources.sort((a, b) => a.extendedProps.position - b.extendedProps.position)

  // Calculate column widths
  let margin = 5
  let netWidth = PAGE_WIDTH - margin * 2 - 20
  let netHeight = PAGE_HEIGHT - margin * 2 - 100
  let timeColWidth = resources.length > 10 ? 20 : 30
  let resourcesCount = resources.length
  let availableForResources = netWidth - timeColWidth
  let resourceColWidth = Math.floor(availableForResources / resourcesCount)
  let tableWidths = [
    timeColWidth,
    ...Array(resourcesCount).fill(resourceColWidth),
  ]

  // Grab slot config
  let slotMinTimeStr = calendar.getOption('slotMinTime')?.toString()
  let slotMaxTimeStr = calendar.getOption('slotMaxTime')?.toString()

  if (!slotMinTimeStr || !slotMaxTimeStr) {
    return
  }

  let slotMinTime = timeToMinutes(
    slotMinTimeStr.substring(0, slotMinTimeStr.lastIndexOf(':'))
  )
  let slotMaxTime = timeToMinutes(
    slotMaxTimeStr.substring(0, slotMaxTimeStr.lastIndexOf(':'))
  )
  let slotDuration = 5

  let events = calendar
    .getEvents()
    .map(event => {
      return {
        id: event.id,
        start: Math.max(
          timeToMinutes(
            event.startStr.substring(
              event.startStr.indexOf('T') + 1,
              event.startStr.lastIndexOf(':')
            )
          ),
          slotMinTime
        ),
        end: Math.min(
          timeToMinutes(
            event.endStr.substring(
              event.endStr.indexOf('T') + 1,
              event.endStr.lastIndexOf(':')
            )
          ),
          slotMaxTime
        ),
        startTime: event.start,
        endTime: event.end,
        startStr: event.startStr,
        endStr: event.endStr,
        resourceId: event.getResources()[0].id,
        display: event.display,
        extendedProps: event.extendedProps,
      }
    })
    .filter(
      ev =>
        ['appointment', 'note', 'blockTime'].includes(ev.extendedProps.type) &&
        ev.display !== 'background'
    )

  events.sort((a, b) => {
    // If types are the same, sort by startTime
    if (a.startTime === null) return 1
    if (b.startTime === null) return -1
    return isAfter(a.startTime, b.startTime) ? 1 : -1
  })

  // 2) Build an array of time slots (vertical axis)
  /** @type {Array<Slot>} */
  let slots = []
  for (let t = slotMinTime; t < slotMaxTime; t += slotDuration) {
    slots.push({ start: t, end: t + slotDuration })
  }

  /** @type {SlotGroup[]} */
  let slotGroups = getSlotsPerPage(slots, events).map(slot => {
    let slotCount = (slot.end - slot.start) / slotDuration
    return {
      start: slot.start,
      end: slot.end,
      slots: slotCount,
    }
  })

  events.sort((a, b) => {
    // Prioritize by type
    let typeOrder = { appointment: 1, note: 2, blockTime: 3 }

    // @ts-ignore
    let aTypePriority = typeOrder[a.extendedProps.type] || 4
    // @ts-ignore
    let bTypePriority = typeOrder[b.extendedProps.type] || 4

    return aTypePriority - bTypePriority
  })

  // Calculate optimal height
  let optimalSlotHeight = findOptimalSlotHeight(
    slotGroups,
    netHeight,
    Math.floor(netHeight / slots.length),
    24
  )

  // Calculate maximum number of slots that can fit on one page
  let maxSlotsPerPage = Math.floor(netHeight / optimalSlotHeight)

  // Initialize array to track which page each slot group belongs to
  // Length matches number of slot groups, initially filled with -1
  let pages = Array.from({ length: slotGroups.length }).map(_ => -1)

  // Array to track how many slots are in each page
  let slotsPerPage = []

  // Keep track of how many slots we've allocated to current page
  let currentPageCount = 0

  // Iterate through each slot group to determine page assignments
  for (let i = 0, currentPage = 1; i < pages.length; i++) {
    let s = slotGroups[i]

    // If adding this slot group would exceed page capacity
    if (currentPageCount + s.slots > maxSlotsPerPage) {
      // Store the slot count for the completed page
      slotsPerPage.push(currentPageCount)
      // Move to next page
      currentPage++
      // Reset count with slots from current group
      currentPageCount = s.slots
    } else {
      // Add slots to current page if there's room
      currentPageCount += s.slots
    }

    // Record which page this slot group belongs to
    // This information is used later when rendering to ensure
    // slot groups are placed on their assigned pages
    pages[i] = currentPage
  }

  // Store the slot count for the last page
  slotsPerPage.push(currentPageCount)

  // This would help us store the content of the PDF
  /** @type {import('pdfmake/interfaces.js').Content} */
  let content = []

  /**
   * We go page by page
   * Get the slots that should be displayed on each page
   * Display the resources and the events
   * Add all of this into the content of the PDF
   */
  for (let i = 0, j = 0; i < pages.length; ) {
    while (j < pages.length && pages[i] === pages[j]) j++

    // Create table for this page
    /** @type {import('pdfmake/interfaces.js').TableCell[][]} */
    let tableBody = []

    // Add header row
    /** @type {import('pdfmake/interfaces.js').TableCell[]} */
    let headerRow = [
      { text: '', border: [false, true, true, true] },
      ...resources.map(r => {
        /** @type {import('pdfmake/interfaces.js').TableCell} */
        let cell = {
          margin: 4,
          stack: [
            {
              text: r.extendedProps.name,
              width: resourceColWidth - 2,
              style: 'eventText',
              bold: true,
            },
            {
              text:
                // @ts-ignore
                r.extendedProps.allocations.find(alloc => alloc.is_provider)
                  ?.name ?? 'No provider',
              width: resourceColWidth - 2,
              style: 'eventText',
            },
            {
              text:
                // @ts-ignore
                r.extendedProps.allocations.find(alloc => alloc.is_assistant)
                  ?.name ?? '-',
              width: resourceColWidth - 2,
              style: 'eventText',
              color: '#616e7c',
            },
          ],
        }
        return cell
      }),
    ]
    tableBody.push(headerRow)

    let slotCount = (slotGroups[j - 1].end - slotGroups[i].start) / slotDuration

    /** @type {import('pdfmake/interfaces.js').TableCell[][]} */
    let rows = Array.from({ length: slotCount }).map(_ =>
      Array.from({ length: resources.length + 1 }).map(_ => ({ text: '' }))
    )

    // Iterate through each time slot in the current page
    for (
      let start = slotGroups[i].start;
      start < slotGroups[j - 1].end;
      start += slotDuration
    ) {
      // Find all events that start at the current time slot
      let evs = events.filter(ev => ev.start === start)

      // Calculate the row index for current time slot
      let x = (start - slotGroups[i].start) / slotDuration

      // Add time label to first column
      rows[x][0] = createSlotLabelCell({
        // Only show hour when time is on the hour (e.g., 9:00, 10:00)
        hours:
          start % 60 === 0
            ? (Math.floor(start / 60) - (start <= 12 * 60 ? 0 : 12)).toString()
            : undefined,
        // Always show minutes, padded with leading zero if needed
        minutes: (start % 60).toString().padStart(2, '0'),
        // Show am/pm only on the hour
        range: start % 60 === 0 ? (start <= 12 * 60 ? 'am' : 'pm') : undefined,
      })

      // Iterate through each resource
      resources.forEach((resource, index) => {
        // Find if there's an event starting at this time slot for this resource
        let event = evs.find(ev => ev.resourceId === resource.id)

        if (event) {
          // Calculate how many slots this event spans
          let rowSpan = (event.end - event.start) / slotDuration

          let isFree = true
          for (let k = 0; k < rowSpan; k++) {
            // @ts-ignore
            isFree = isFree && rows[x + k][index + 1].text === ''
          }

          if (!isFree) {
            console.error(
              `Unable to print event of type ${event.extendedProps.type} with id = ${event.id}`
            )
          } else {
            // Create the event cell
            let cell = getCell({
              event,
              rowSpan,
              cellWidth: resourceColWidth,
              slotHeight: optimalSlotHeight,
              hipaa,
            })

            // Place the actual event cell in the starting row
            rows[x][index + 1] = cell
          }
        }
      })
    }

    tableBody.push(...rows)

    // Add the table to the current page
    // and if it is not the last page, then add a page break too
    content.push({
      table: {
        heights: function (row) {
          if (row === 0) {
            // Header row
            return Math.min(optimalSlotHeight, 45) // Fixed height for resource header cells
          }
          return optimalSlotHeight // Dynamic height for other rows
        },
        headerRows: 1,
        widths: tableWidths,
        body: tableBody,
      },
      layout: {
        hLineWidth: function (i, node) {
          return 1
        },
        vLineWidth: function (i, node) {
          return 1
        },
        hLineColor: function (i, node) {
          return '#E9ECEF'
        },
        vLineColor: function (i, node) {
          return '#E9ECEF'
        },
        paddingLeft: function (i, node) {
          return 0
        },
        paddingRight: function (i, node) {
          return 0
        },
        paddingTop: function (i, node) {
          return 0
        },
        paddingBottom: function (i, node) {
          return 0
        },
      },
      ...(j < pages.length ? { pageBreak: 'after' } : {}),
    })

    // Move to next page
    i = j
  }

  /** @type {import('pdfmake/interfaces.js').TDocumentDefinitions} */
  let docDefinition = {
    pageOrientation: 'landscape',
    pageSize: 'A4',
    content,
    styles: {
      timeSlotCell: {
        fontSize: Math.min(Math.ceil(optimalSlotHeight / 3), 8),
        color: '#9aa5b1',
      },
      eventText: {
        fontSize: Math.min(Math.ceil(optimalSlotHeight / 3), 8),
        color: '#323f4b',
      },
      eventTextSecondary: {
        fontSize: Math.min(Math.ceil(optimalSlotHeight / 3), 8) - 1,
        color: '#323f4b',
      },
    },
    pageMargins: margin,

    defaultStyle: {
      fontSize: Math.min(Math.ceil(optimalSlotHeight / 3), 8),
    },
  }

  return pdfmake.createPdf(docDefinition)
}

/** @type {({event, rowSpan, cellWidth, slotHeight, hipaa}: {event: Event, rowSpan: number, cellWidth: number, slotHeight: number, hipaa: boolean}) => import('pdfmake/interfaces.js').TableCell} param */
function getCell({ event, rowSpan, cellWidth, slotHeight, hipaa }) {
  switch (event.extendedProps.type) {
    case 'appointment': {
      return {
        rowSpan,
        stack: [
          {
            canvas: [
              {
                type: 'rect',
                x: 0,
                y: 0,
                w: cellWidth - 4,
                h: rowSpan * slotHeight,
                r: 4,
                color: '#ffffff',
                lineColor: '#e0e0e0',
                lineWidth: 1,
              },
              {
                type: 'rect',
                x: 0,
                y: 0,
                w: 2,
                h: rowSpan * slotHeight,
                r: 4,
                color: event.extendedProps.appointment_type_color,
              },
            ],
          },
          {
            width: cellWidth - 2,
            height: rowSpan * slotHeight - 2,
            margin: [4, -rowSpan * slotHeight + 2, 2, 0],
            stack: [
              {
                text: patientName({
                  ...event.extendedProps.appointment.patient.person,
                  last_name:
                    !hipaa &&
                    event.extendedProps.appointment.patient.person.last_name,
                }),
                width: cellWidth - 2,
                maxHeight: (rowSpan * slotHeight - 2) * 0.35,
                style: 'eventText',
                bold: true,
              },
              {
                text: event.extendedProps.appointment.type.display_name,
                width: cellWidth - 2,
                maxHeight: (rowSpan * slotHeight - 2) * 0.35,
                style: 'eventText',
              },
              {
                text: formatTimegridAppointmentTime({
                  start: event.startStr,
                  end: event.endStr,
                }),
                style: 'eventTextSecondary',
                width: cellWidth - 2,
                maxHeight: (rowSpan * slotHeight - 2) * 0.3,
                color: '#616e7c',
                marginTop: 2,
              },
            ],
          },
        ],
        margin: [1, 1, 1, 1],
      }
    }

    case 'note': {
      return {
        rowSpan,
        stack: [
          {
            canvas: [
              {
                type: 'rect',
                x: 0,
                y: 0,
                w: cellWidth - 2,
                h: rowSpan * slotHeight,
                r: 4,
                color: '#F8E3A3',
              },
            ],
          },
          {
            width: cellWidth - 2,
            height: rowSpan * slotHeight - 2,
            margin: [4, -rowSpan * slotHeight + 2, 2, 0],
            text: event.extendedProps.content,
            style: 'eventText',
          },
        ],
      }
    }

    case 'blockTime': {
      return {
        rowSpan,
        stack: [
          {
            canvas: [
              {
                type: 'rect',
                x: 0,
                y: 0,
                w: cellWidth - 2,
                h: rowSpan * slotHeight,
                r: 4,
                color: '#CBD2D9',
              },
            ],
          },
          {
            width: cellWidth - 2, // Leave space for margins
            height: rowSpan * slotHeight - 2,
            margin: [4, -rowSpan * slotHeight + 2, 2, 0],
            text: event.extendedProps.content,
            style: 'eventText',
          },
        ],
      }
    }

    default:
      return { text: '' }
  }
}

/**
 * @param {{hours?: string, range?: string, minutes: string}} params
 * @returns {import('pdfmake/interfaces').TableCell}
 */
function createSlotLabelCell({ hours, range, minutes }) {
  return {
    columns: [
      ...(hours
        ? [
            {
              text: hours,
              style: 'eventText',
              color: '#9aa5b1',
              bold: true,
              margin: [-4, 0, 1, 0],
            },
          ]
        : []),

      ...(range
        ? [
            {
              text: range,
              style: 'eventTextSecondary',
              color: '#9aa5b1',
              bold: true,
              margin: [-2, 0, 2, 0],
            },
          ]
        : []),

      {
        text: minutes,
        style: 'eventTextSecondary',
        color: '#7b8794',
        margin: [0, 0, 0, 0],
      },
    ],
    alignment: 'right',
    margin: [0, 0, 0, 0],
    border: [false, false, true, false],
  }
}

/**
 * Given a list of "slots" and a list of "events", we try to group these slots in such
 * a way that all the events that fall within these never overlap with each other
 * Each "slot" is an object with { start: number, end: number }.
 * Each "event" is an object with { id: string, start: number, end: number }.
 *
 * The function returns an array of objects, where each object has
 * { start: string, end: string } in "HH:MM" format.
 *
 * HOW IT WORKS (high-level):
 * 1. We iterate over all `slots` (index i).
 * 2. For each slot, we keep track of `starting_slot` and `ending_slot`.
 * 3. We look at the `events` array (index j), checking if there's an event
 *    whose start time matches `starting_slot`.
 * 4. If found, we record its end time in `event_with_max_duration`.
 *    - If multiple events share the same start, we pick the one with the farthest ending time.
 * 5. If this event's end time goes beyond the current slot's `ending_slot`,
 *    we extend `ending_slot` accordingly.
 * 6. If the newly extended slot merges into the next slot, we move `i` forward
 *    so we effectively merge multiple adjacent slots into one big slot.
 * 7. After finishing possible merges for that chunk, we push the merged slot
 *    (or the original slot) to `slotsPerPage`.
 *
 * NOTE: The logic of incrementing/decrementing `i` within the loop is somewhat
 *       unconventional but achieves a "slot merging" behavior.
 *
 * @param {Array<{start: number, end: number}>} slots - array of slots
 * @param {Array<{ id: string, start: number, end: number }>} events - array of events
 * @returns {Array<{ start: number, end: number }>}
 */
function getSlotsPerPage(slots, events) {
  // Number of input slots
  let n = slots.length

  // This will hold the resulting merged slots with "HH:MM" string format
  /** @type {Array<{start: number, end: number}>} */
  let slotsPerPage = []

  // `j` is an index into the events array
  let j = 0

  // Loop through all slots by index `i`
  for (let i = 0; i < n; ) {
    // Extract the current slot's start/end
    let starting_slot = slots[i].start
    let ending_slot = slots[i].end

    // Will keep track of an event that starts at `starting_slot`
    // and has the maximum end time among all that share the same start.
    /** @type {{ start: number, end: number } | undefined} */
    let event_with_max_duration = undefined

    // Keep merging as long as we can extend the slot
    // or until we no longer match an event start
    while (starting_slot !== ending_slot) {
      // Check if the next event(s) in the list start exactly at `starting_slot`
      // We keep incrementing `j` while that condition holds.
      while (j < events.length && events[j].start === starting_slot) {
        if (!event_with_max_duration) {
          // First event found for this start
          event_with_max_duration = {
            start: events[j].start,
            end: events[j].end,
          }
        } else {
          // If there's a bigger end among multiple events with same start
          if (event_with_max_duration.end <= events[j].end) {
            event_with_max_duration.end = events[j].end
          }
        }
        j++
      }

      // If we found no event that begins at `starting_slot`, stop merging
      if (!event_with_max_duration) {
        break
      }
      // If this event extends beyond the current slot's end, update the slot end
      if (event_with_max_duration.end > ending_slot) {
        ending_slot = event_with_max_duration.end
      }

      // Check if the newly extended slot merges into the next slot
      // If so, move on to the next slot (i.e. combine them),
      // updating `starting_slot` to the next slot's start
      if (i < n - 1) {
        i++
        starting_slot = slots[i].start
      } else {
        // No more slots to merge
        break
      }
    }

    // Once we've exited the while loop,
    // we either have an event that merges multiple slots or none at all.
    if (event_with_max_duration) {
      // We push the merged event range
      slotsPerPage.push({
        start: event_with_max_duration.start,
        end: Math.max(event_with_max_duration.end, ending_slot),
      })

      // Reset
      event_with_max_duration = undefined
    } else {
      // No merging happened, just push the original slot range
      slotsPerPage.push({
        start: starting_slot,
        end: ending_slot,
      })

      i++
    }
  }

  return slotsPerPage
}

/**
 * Checks if a candidate slot height is feasible.
 *
 * @param {Array<{start: number, end: number, slots: number}>} slotGroups - Array of groups. Each group is the number of slots that must stay together.
 * @param {number} containerHeight - The max height of a page.
 * @param {number} candidateHeight - The slot height we want to test.
 * @returns {boolean} True if all slot groups can fit into pages with this slot height.
 */
function canFitAllGroups(slotGroups, containerHeight, candidateHeight) {
  // How many slots can fit on one page at this candidate height?
  let maxSlotsPerPage = Math.floor(containerHeight / candidateHeight)

  // If candidateHeight is so large that even one group doesn’t fit, return false immediately.
  for (let group of slotGroups) {
    if (group.slots > maxSlotsPerPage) {
      return false
    }
  }

  // Greedily place groups in pages.
  let currentPageSlotCount = 0
  for (let group of slotGroups) {
    // If adding this group's slots would exceed what fits on one page,
    // start a new page (i.e., reset the count).
    if (currentPageSlotCount + group.slots > maxSlotsPerPage) {
      // start new page
      currentPageSlotCount = group.slots
    } else {
      // add to the current page
      currentPageSlotCount += group.slots
    }
  }

  // Since there is no limit on the number of pages,
  // if we never had to split a single group, it is feasible.
  return true
}

/**
 * Finds the maximum feasible slot height within [slotMinHeight, slotMaxHeight]
 * such that all groups can be placed in pages of containerHeight.
 *
 * @param {Array<{start: number, end: number, slots: number}>} slotGroups - Array of groups (each group = number of slots).
 * @param {number} containerHeight - Maximum height for one page.
 * @param {number} slotMinHeight - Minimum possible slot height.
 * @param {number} slotMaxHeight - Maximum possible slot height.
 * @returns {number} The largest feasible slot height.
 */
function findOptimalSlotHeight(
  slotGroups,
  containerHeight,
  slotMinHeight,
  slotMaxHeight
) {
  let low = slotMinHeight
  let high = slotMaxHeight
  let bestFeasibleHeight = slotMinHeight

  while (low <= high) {
    let mid = Math.floor((low + high) / 2)

    if (canFitAllGroups(slotGroups, containerHeight, mid)) {
      // mid is feasible; record it and try for a larger height
      bestFeasibleHeight = mid
      low = mid + 1
    } else {
      // mid is not feasible; try smaller height
      high = mid - 1
    }
  }

  return bestFeasibleHeight
}
