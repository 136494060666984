// this is an autogenerated file from App/Account/Content/Patients/Content/Content/Sections/ActiveTab/Profile/Content/NewProfile/txsMutation.graphql
import { gql } from 'Data/Api'

export default gql`
mutation app__App_Account_Content_Patients_Content_Content_Sections_ActiveTab_Profile_Content_NewProfile($updates: [vaxiom_txs_updates!]!) {
  update_vaxiom_txs_many(updates: $updates) {
    affected_rows
  }
}

`