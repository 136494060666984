import useStableValue from 'Logic/useStableValue.js'
import { useDataValue } from 'Simple/Data.js'

export default function useDataConfiguration(props) {
  let organization_id = useDataValue({
    context: 'patient',
    path: 'person.organization_id',
    viewPath: props.viewPath,
  })
  let search = useDataValue({
    context: 'selected',
    path: 'search_term',
    viewPath: props.viewPath,
  })

  let search_term = useStableValue(`%${search}%`)

  return {
    variables: {
      organization_id,
      search_term,
    },
  }
}
