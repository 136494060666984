// this is an autogenerated file from App/Account/Content/Settings/AdminContent/SettingsContent/TreatmentCaseCloser/Configuration/Steps/AddRuleset/Location/Content/data.graphql
import { gql } from 'Data/Api'

export default gql`
query app__App_Account_Content_Settings_AdminContent_SettingsContent_TreatmentCaseCloser_Configuration_Steps_AddRuleset_Location_Content($division_id: uuid!) {
  vaxiom_sys_organizations(
    where: {
      level: { _eq: "location" }
      deleted: { _eq: false }
      parent: { _id: { _eq: $division_id } }
    }
    order_by: { name: asc }
  ) {
    _id
    text: name
  }
}

`