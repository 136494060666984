// this is an autogenerated file from DesignSystem/CommsThread/Content/Topbar/Sms/Name/data.graphql
import { gql } from 'Data/Api'

export default gql`
query app__DesignSystem_CommsThread_Content_Topbar_Sms_Name($id: uuid!) {
  thread_numbers: comms_threads_by_pk(id: $id) {
    id
    mobile_numbers_inbound {
      id
      number
      vaxiom_persons {
        person_id
        vaxiom_person {
          id
          first_name
          last_name
        }
      }
    }
  }
}

`