import { useUppy } from '../FileUploaderProvider/react.js'
import useFileUploaderPluginS3 from './hook.js'
import { S3_BUCKET_FILES, S3_BUCKET_VAXIOM } from 'Data/constants'

export default function FileUploaderPluginS3(props) {
  let uppy = useUppy(props)
  useFileUploaderPluginS3({
    Bucket: props.isLegacyBucket ? S3_BUCKET_VAXIOM : S3_BUCKET_FILES,
    uppy,
    prefix: props.prefix,
    excludeKeyFileExtension: props.excludeKeyFileExtension,
    getKey: props.getKey,
  })

  return null
}
