import { useDataSubmit, useDataValue } from 'Simple/Data'

export function useOnClick(props) {
  let submit = useDataSubmit({
    context: 'treatment_plans',
    viewPath: props.viewPath,
  })

  let treatment_plan_id = useDataValue({
    context: 'treatment_plan',
    path: '_id',
    viewPath: props.viewPath,
  })

  return function onClick() {
    submit({
      type: 'remove',
      treatment_plan_id,
    })
  }
}
