import { useDataSubmit } from 'Simple/Data.js'
import { useSetFlowTo, normalizePath } from 'Simple/Flow.js'

export function useOnChange(props) {
  let submit = useDataSubmit({
    context: 'appointment',
    viewPath: props.viewPath,
  })
  let setFlowTo = useSetFlowTo(props.viewPath)

  return function onChange(value) {
    switch (value) {
      case 'EDIT':
        setFlowTo(normalizePath(props.viewPath, '../../Edit'))
        return
      case 'REMOVE':
        submit({ type: 'remove' })
        return
      default:
        return
    }
  }
}
