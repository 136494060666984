import { useSetFlowTo, normalizePath } from 'Simple/Flow.js'
import { useMutation } from 'Data/Api'
import { useDataValue } from 'Simple/Data'
import {
  notifyError,
  useNotifications,
  notifySuccess,
} from 'Logic/Notifications.js'
import { RELATIONSHIPS } from 'Data/constants.js'
import mutation_update_person_relationships from './updatePersonRelationshipsMutation.graphql.js'

export default function useDataOnSubmit(props, data) {
  let setFlowTo = useSetFlowTo(props.viewPath)
  let [, notify] = useNotifications()

  let [, executeMutationUpdatePersonRelationships] = useMutation(
    mutation_update_person_relationships
  )

  let patient_person_id = useDataValue({
    viewPath: props.viewPath,
    context: 'profile_patient',
    path: 'person.id',
  })
  let person = useDataValue({
    viewPath: props.viewPath,
    context: 'person',
  })
  let patient_gender = useDataValue({
    viewPath: props.viewPath,
    context: 'profile_patient',
    path: 'person.gender',
  })

  return async function onSubmit({ value: new_relation }) {
    let { relationship } = new_relation
    if (!relationship.type || !relationship.role) {
      return true
    }

    let relationships = [
      {
        from_person: {
          id: person.id,
          type: relationship.type,
          role: relationship.role,
          permitted_to_see_info: relationship.permitted_to_see_info || false,
        },
        to_person: {
          id: patient_person_id,
          type: RELATIONSHIPS.find(r => r.id === relationship.type)[
            patient_gender
          ],
          role:
            relationship.role === 'RESPONSIBLE'
              ? 'DEPENDENT'
              : relationship.role === 'DEPENDENT'
              ? 'RESPONSIBLE'
              : 'OTHER',
          permitted_to_see_info: false,
        },
      },
    ]

    let mutationUpdatePersonRelationshipsResponse =
      await executeMutationUpdatePersonRelationships({
        relationships: relationships,
      })
    if (mutationUpdatePersonRelationshipsResponse.error) {
      notify(
        notifyError(
          'Something went wrong. Please, try again or contact support if the problem persists.'
        )
      )
      return
    }

    notify(notifySuccess('Relationship saved!'))

    if (['MOTHER', 'FATHER', 'BROTHER', 'SISTER'].includes(relationship.type)) {
      setFlowTo(normalizePath(props.viewPath, 'CopyRelationships/Content'))
    } else {
      setFlowTo(normalizePath(props.viewPath, '../../../../../../../No'))
    }

    return
  }
}
