import { useDataChange, useDataValue } from 'Simple/Data.js'

export function useOnClick(props) {
  let type_id = useDataValue({
    context: 'item',
    path: 'id',
    viewPath: props.viewPath,
  })
  let change = useDataChange({
    context: 'selected',
    path: 'type_id',
    viewPath: props.viewPath,
  })
  return function onClick() {
    change(type_id)

    // TODO: this is not the right approach, make the Popover a controlled component
    props.closePopover()
  }
}
