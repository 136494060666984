// this is an autogenerated file from App/Account/Content/Settings/AdminContent/SettingsContent/TreatmentCaseCloser/Configuration/DefaultPaymentPlans/Rulesets/Content/Actions/Content/Save/mutation.graphql
import { gql } from 'Data/Api'

export default gql`
mutation app__App_Account_Content_Settings_AdminContent_SettingsContent_TreatmentCaseCloser_Configuration_DefaultPaymentPlans_Rulesets_Content_Actions_Content_Save(
  $rulesets_to_delete: [uuid!]!
  $rulesets_to_insert: [treatments_default_payment_plans_insert_input!]!
  $rulesets_to_update: [treatments_default_payment_plans_updates!]!
  $discounts_to_delete: [uuid!]!
  $discounts_to_insert: [treatments_default_payment_plan_discounts_insert_input!]!
  $discounts_to_update: [treatments_default_payment_plan_discounts_updates!]!
) {
  delete_treatments_default_payment_plans(
    where: { id: { _in: $rulesets_to_delete } }
  ) {
    affected_rows
    returning {
      id
    }
  }
  insert_treatments_default_payment_plans(objects: $rulesets_to_insert) {
    affected_rows
    returning {
      id
    }
  }
  delete_treatments_default_payment_plan_discounts(
    where: { id: { _in: $discounts_to_delete } }
  ) {
    affected_rows
    returning {
      id
    }
  }
  insert_treatments_default_payment_plan_discounts(
    objects: $discounts_to_insert
  ) {
    affected_rows
    returning {
      id
    }
  }
  update_treatments_default_payment_plan_discounts_many(
    updates: $discounts_to_update
  ) {
    affected_rows
    returning {
      id
    }
  }
  update_treatments_default_payment_plans_many(updates: $rulesets_to_update) {
    affected_rows
    returning {
      id
    }
  }
}

`