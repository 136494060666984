import { normalizePath, useSetFlowTo } from 'Simple/Flow.js'
import React, { useEffect } from 'react'

import { useMutation } from 'Data/Api.js'
import mutation from './mutation.graphql.js'
import View from './view.js'

export default function Logic(props) {
  let setFlowTo = useSetFlowTo(props.viewPath)
  let [, executeMutation] = useMutation(mutation)

  useEffect(() => {
    ;(async () => {
      let mutationResponse = await executeMutation({
        current_url: window.location.href,
      })

      if (
        !mutationResponse ||
        mutationResponse.error ||
        mutationResponse.data.apps_external_api_token_swap.status !== 'ok'
      ) {
        setFlowTo(normalizePath(props.viewPath, '../Error'))
      }

      setTimeout(() => window.close(), 2000)
    })()
  }, []) // eslint-disable-line

  return <View viewPath={props.viewPath} />
}
