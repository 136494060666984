// this is an autogenerated file from DesignSystem/TimeGrid/FullCalendarTimegrid/Appointment/PaymentOverdueIcon/data.graphql
import { gql } from 'Data/Api'

export default gql`
query app__DesignSystem_TimeGrid_FullCalendarTimegrid_Appointment_PaymentOverdueIcon($patient_id: uuid!) {
  financial_patient_due_amount(
    args: { _patient_id: $patient_id }
    where: { due_now: { _gt: 0 } }
    distinct_on: [payment_account_id]
  ) {
    id: payment_account_id
  }
}

`