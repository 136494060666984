import React from 'react'

import { useDisabled } from './helpers.js'
import View from './view.js'

export default function Logic(props) {
  let disabled = useDisabled(props)

  return <View {...props} disabled={disabled} />
}
