import { normalizePath, useSetFlowTo } from 'Simple/Flow'
import { useMutation } from 'Data/Api'
import { useDataValue } from 'Simple/Data'
import { notifyError, useNotifications } from 'Logic/Notifications.js'
import mutation_insert_person_referrals from './personReferralsInsertMutation.graphql.js'
import mutation_insert_template_referrals from './templateReferralsInsertMutation.graphql.js'

/** @type {import('Simple/types.js').useDataOnSubmit} */
export default function useDataOnSubmit(props, data) {
  let patient_id = useDataValue({
    context: 'profile_patient',
    viewPath: props.viewPath,
    path: 'id',
  })

  let setFlowTo = useSetFlowTo(props.viewPath)
  let [, executeMutationInsertPersonReferrals] = useMutation(
    mutation_insert_person_referrals
  )
  let [, executeMutationInsertTemplateReferrals] = useMutation(
    mutation_insert_template_referrals
  )
  let [, notify] = useNotifications()

  function getTextValue(value) {
    if (value.type === 'DropDown') {
      return Array.isArray(value?.referral_list_values)
        ? value.referral_list_values.find(
            template => template.id === value.text_value
          )?.value
        : null
    }

    return value.text_value
  }
  return async function onSubmit({ value, change, args }) {
    if (!value.person_id && !value.text_value) {
      change(next => {
        next.submitted = true
      })
      return true
    }
    if (value.person_type) {
      let referralToInsert = {
        patient_id,
        person_id: value.person_id,
        person_type: value.person_type,
      }
      let mutationInsertPersonReferralsResponse =
        await executeMutationInsertPersonReferrals({
          person_referrals: referralToInsert,
        })
      if (mutationInsertPersonReferralsResponse.error) {
        notify(
          notifyError(
            'Something went wrong. Please, try again or contact support if the problem persists.'
          )
        )
        return
      }
    } else {
      let referralToInsert = {
        patient_id,
        referral_template_id: value.id,
        text_value: getTextValue(value),
      }
      let mutationInsertTemplateReferralsResponse =
        await executeMutationInsertTemplateReferrals({
          template_referrals: referralToInsert,
        })
      if (mutationInsertTemplateReferralsResponse.error) {
        notify(
          notifyError(
            'Something went wrong. Please, try again or contact support if the problem persists.'
          )
        )
        return
      }
    }

    setFlowTo(normalizePath(props.viewPath, '../No'))
  }
}
