// this is an autogenerated file from DesignSystem/DataVaxiomPatientInsuranceForm/BillingCenter/Content/Options/Create/Content/mutation.graphql
import { gql } from 'Data/Api'

export default gql`
mutation app__DesignSystem_DataVaxiomPatientInsuranceForm_BillingCenter_Content_Options_Create_Content($object: insert_billing_center_args!) {
  insert_billing_center(args: $object) {
    id
  }
}

`