import { useDataChange, useDataValue } from 'Simple/Data.js'

export function useOnChange(props) {
  let change = useDataChange({
    context: 'new_relation',
    viewPath: props.viewPath,
  })
  let phones = useDataValue({
    context: 'phones',
    viewPath: props.viewPath,
  })
  return function onChange(value) {
    if (!value) return
    let phone = phones.find(p => p.id === value)
    change(next => {
      next.selected_phone = value
      next.phone.number = phone.phone.number
      next.phone.type = phone.phone.type
      next.phone.description = phone.description
    })
  }
}
