// this is an autogenerated file from DesignSystem/DataVaxiomPatientInsuranceForm/BillingCenter/Content/Options/Edit/Content/mutation.graphql
import { gql } from 'Data/Api'

export default gql`
mutation app__DesignSystem_DataVaxiomPatientInsuranceForm_BillingCenter_Content_Options_Edit_Content(
  $billing_center_id: numeric!
  $name: String!
  $payer_id: String!
  $postal_id: numeric
  $postal_address: json
  $phone_id: numeric
  $number: String
  $email_id: numeric
  $email: String
) {
  update_billing_center(
    args: {
      billing_center_id: $billing_center_id
      center_name: $name
      center_payer_id: $payer_id
      email: $email
      email_id: $email_id
      phone_id: $phone_id
      phone_number: $number
      postal_address: $postal_address
      postal_id: $postal_id
    }
  ) {
    id
  }
}

`