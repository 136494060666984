import { useDataValue } from 'Simple/Data.js'
import useStableValue from 'Logic/useStableValue.js'

/** @type {import('Simple/types.js').useDataConfiguration} */
export default function useDataConfiguration(props) {
  let term = useDataValue({
    viewPath: props.viewPath,
    context: 'city_search',
    path: 'term',
  })

  let city = useDataValue({
    context: 'contact_method_association',
    viewPath: props.viewPath,
    path: 'postal_address.city',
  })
  let search_term = useStableValue(`%${term}%`)

  return {
    variables: {
      search_term,
    },
    pause: search_term.length < 5 || term === city,
    requestPolicy: 'cache-and-network',
  }
}
